<template>
    <transition name="fade">
        <div ref="overlay" v-show="isVisible" class="guide-images overlay" @click="onBackgroundClick">
            <div ref="wrapper" class="guide-images__wrapper">
                <div class="guide-images__header" ref="header">
                    <button class="overlay__collapse" v-on:click.prevent="closeModal"></button>
                </div>
                <div ref="imageContainer" class="guide-images__image-container" :style="imageContainerStyle">
                    <div v-for="(image, index) in images" class="guide-images__image-wrapper">
                        <figure v-if="image" :class="contentClass(image, index)">
                            <img class="guide-images__image" v-on:click="navigate(1)" :src="imagePath(image)" :width="image.width" :height="image.height">
                            <caption class="guide-images__caption" v-html="image.captionFormatted" />
                        </figure>
                    </div>
                </div>
                <nav v-if="images.length > 1" class="guide-images__navigation">
                    <a href="#" :class="leftNavClass" v-on:click.prevent="navigate(-1)"><img class="guide-images__nav-image" src="~img/arrow_black_left.svg" alt="previous image"></a>
                    <span class="guide-images__indicator">{{ currentIndex + 1 }} / {{ images.length }}</span>
                    <a href="#" :class="rightNavClass" v-on:click.prevent="navigate(1)"><img class="guide-images__nav-image" src="~img/arrow_black_right.svg" alt="next image"></a>
                </nav>
            </div>
        </div>
    </transition>
</template>
<script>
    import NavKeys from './mixins/NavKeys'
    import { imagePath } from './util'

    export default {
        props: {
            isVisible: Boolean,
            images: Array
        },
        mixins: [NavKeys],
        data() {
            return {
               currentIndex: 0
            }
        },
        mounted() {
            window.addEventListener('keydown', this.onKeydown);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.onKeydown);
        },
        computed: {
            imageContainerStyle() {
                const imageWidth = 840 // 800 + 40 margin right
                const x = 0 // this can just be zero so long as we don't actually have multiple images

                //const x = -this.currentIndex * imageWidth // Desktop fixed-width implementation

                // const x = typeof this.$el !== 'undefined' ? 
                //     Array.prototype.slice.call(this.$el.getElementsByClassName('guide-images__image-container')[0].getElementsByTagName('img'), 0, this.currentIndex)
                //         .map( (img) => img.offsetWidth)
                //         .reduce( (acc, curr) => acc + cur) * -1 
                //     : 0; // Untested flexible implementation
                return {
                    transform: `translate(${x}px, -50%)`
                }
            }
        },
        methods: {
            onBackgroundClick(ev) {
                if (ev.target === this.$refs.overlay || ev.target === this.$refs.wrapper || ev.target === this.$refs.header || ev.target === this.$refs.imageContainer) {
                    this.closeModal()
                }
            },
            onKeydown(ev) {
                if (this.isVisible) {
                    this.onNavKeydown(ev)
                }
            },
            contentClass(image, index) {
                return {
                    'guide-images__content': true,
                    'guide-images__content--current': this.currentIndex === index
                }
            },
            imagePath(image) {
                return imagePath(image, false, 'original');
            },
            leftNavClass() {
                return {
                    'guide-images__nav-link': true,
                    'guide-images__nav-link--disabled': this.currentIndex === 0
                }
            },
            rightNavClass() {
                return {
                    'guide-images__nav-link': true,
                    'guide-images__nav-link--disabled': this.currentIndex === this.images.length - 1
                }
            },
            onArrowPress(arrow) {
                switch (arrow) {
                    case 'left':
                        this.navigate(-1)
                        break
                    case 'right':
                        this.navigate(1)
                        break
                }
            },
            onEscPress() {
                this.closeModal();
            },
            navigate(delta) {
                const index = this.currentIndex + delta;
                if (index < 0 || index >= this.images.length) {
                    return;
                }

                this.currentIndex = index;
            },
            closeModal() {
                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @import "scss/variables/typography";

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
    }
    .fade-enter, .fade-leave-to {
      opacity: 0
    }

    .guide-images__wrapper {
        height: 100vh;
        padding-bottom: 3em;
    }

    .guide-images__header {
        position: fixed;
        top: 0;
        padding: 5%;
        width: 100%;
    }

    .guide-images__image-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 50vh 0 0 0;
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    .guide-images__image-wrapper {
        margin: 0 40px 0 0;
        flex-shrink: 0;
    }

    .guide-images__content {
        display: table;
        max-height: 80vh;
        margin: 0 auto;
        opacity: 0.8;
        transition: opacity 0.3s;
    }

    .guide-images__content--current {
        opacity: 1;
        & .guide-images__caption {
            opacity: 1;
        }
    }

    .guide-images__image{
        margin: 0 auto;
    }

    .guide-images__caption {
        display: table-caption;
        caption-side: bottom;
        padding: 0px;
        margin-top: .75rem;
        color: #000;
        text-align: left;
        font-size: .875rem;
        line-height: 1.125rem;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .guide-images__navigation {
        text-align: right;
        display: block;
        position: fixed;
        bottom: 5rem;
        right: 2em;
        font-size: 1.25rem;
    }

    .guide-images__nav-link{
        margin: 0 .5rem;
    }

    .guide-images__nav-link--disabled{
        opacity: .5;
    }

    .guide-images__nav-image{
        height: 1rem;
    }

    @media screen and (max-width: $breakpoint-medium){

        .guide-images__image-wrapper{
            max-width: 100vw;
        }

        .guide-images__caption{
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .guide-images__image-container{
            // margin: 50vh 0 0 -50vw;
        }

    }

    @media screen and (min-width: $breakpoint-medium){

            .guide-images__image-wrapper{
                width: 800px;
            }

            .guide-images__image-container{
                margin-left: calc(50vw - 400px);
            }

            .guide-images__image {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: contain;
            margin: 0;
        }
    }
</style>