<template>
    <div>
        <ul class="slide-navigation__affiliates">
            <nav-item class="slide-navigation__affiliate-link" v-for="affiliate in affiliates" :page="affiliate" :deep="false" :expanded="false" :key="page.name" :menu-active="menuActive"></nav-item>
        </ul>
        <ul class="slide-navigation__account-links">
            <li v-if="!userIsLoggedIn">
                <a href="#" v-on:click.prevent="openRegisterForm" :tabindex="tabIndex">REGISTER</a>
            </li>
            <li v-if="userIsLoggedIn">
                <a class="truncated" :href="webroot + '/profile'" :tabindex="tabIndex">{{ username | truncate(10) }}</a>
            </li>
            <li>
                <signin-signout-link :tabindex="tabIndex"></signin-signout-link>
            </li>
        </ul>
        <ul class="slide-navigation__social-media-links">
            <li v-for="socialMediaLink in socialMediaLinks">
                <a :href="socialMediaLink.path" target="_blank" :tabindex="tabIndex"><img class="slide-navigation__social-icon" :src="socialMediaLink.icon" :alt="socialMediaLink.name"></a>
            </li>
        </ul>
        <a class="slide-navigation__contact-link" :href="webroot + '/contact'" :tabindex="tabIndex">CONTACT US</a>
    </div>
</template>

<script>
    /**
     * contains elements that go in the drop-down menu that are not defined in the Navigation constant
     */
    import { mapState, mapMutations } from 'vuex';
    import NAVIGATION from 'shared/constants/Navigation';
    import { WEBROOT } from 'shared/constants/Paths';
    import MODALS from 'shared/constants/Modals';
    import signinSignoutLink from 'shared/components/navigation/SigninSignoutLink.vue';
    import activeTabindex from 'shared/components/mixins/navigation/activeTabindex';

    export default {
        mixins: [activeTabindex],
        components: { signinSignoutLink },

        data: function(){
            return {
                affiliates: NAVIGATION.AFFILIATED_PUBLICATIONS,
                accountLinks: NAVIGATION.ACCOUNT_LINKS,
                socialMediaLinks: NAVIGATION.SOCIAL_MEDIA_LINKS,
                webroot: WEBROOT,
            }
        },

        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn,
                username: state => state.user.username
            }),
        },

        methods: {
            ...mapMutations(['openModal']),

            openRegisterForm: function(){
                this.openModal(MODALS.REGISTER_FORM);
            },

            openLoginForm: function(){
                this.openModal(MODALS.LOGIN_FORM);
            },
        },

        filters: {
            truncate: function(value, length){
                return value.substring(0, length)
            }
        },
    };
</script>