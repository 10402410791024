const ARTICLES_PER_PAGE = 12;
const BREAKPOINT_MEDIUM = 840; //px
const BREAKPOINT_SMALL = 600; //px
const ARTGUIDE_PAGE_SIZE = 15;

export {
	ARTICLES_PER_PAGE,
	BREAKPOINT_MEDIUM,
	BREAKPOINT_SMALL,
	ARTGUIDE_PAGE_SIZE
}