<template>
	<section class="cart medium-margins desktop-flex">
		<cart-items class="cart__items--review" :editable="true"></cart-items>
		<cart-subtotal v-if="itemCount > 0">
			<p class="shipping__note">Shipping will be calculated and added at checkout.</p>
			<a :href="checkoutUrl" class="form__submit form__submit--centered checkout-link">Check Out</a>
		</cart-subtotal>
	</section>
</template>

<script>
	import { mapGetters } from 'vuex';
	import cartItems from './CartItems.vue';
	import subtotal from './Subtotal.vue';
	import { WEBROOT } from 'shared/constants/Paths';
	import itemCount from './mixins/ItemCount';

	export default {
		computed: {
			...mapGetters(['cart']),
			checkoutUrl: () => WEBROOT + '/cart/checkout',
			archiveUrl: () => WEBROOT + '/print/archive'
		},

		mixins: [itemCount],

		components: {
			'cart-items': cartItems,
			'cart-subtotal': subtotal
		}
	};
</script>

<style lang="scss">
	@import "scss/variables/breakpoints";
	@import "scss/variables/colors";

	.cart{
		margin-bottom: 3rem;
	}

	@media screen and (min-width: $breakpoint-medium){

		.cart__items--review{
			border-right: 1px solid $grey60;
			width: 50%;
			min-width: 50%;
		}

		.shipping__note{
			border-bottom: 1px solid $grey60;
			padding-bottom: 1rem;
			padding-left: 1rem;
		}

		.checkout-link{
			width: 100px;
		}
	}
</style>