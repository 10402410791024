/**
 * Safari can't parse date strings as returned by SQL
 * This function replaces hyphens with slashes and removes 'ms' to fix safari date parsing
 * 
 * @param {String} str
 * @return {String} 
 */
export default function cleanDateString (str) {
  return str
    .replace(/-/g, '/')
    .replace('T', ' ')
    .replace(/(\..*|\+.*)/, '')
    .replace(/([0-9]+:[0-9]+:[0-9]+)\/(\d)+/, '$1')
}
