<template>
	<a href="#" v-on:click.prevent="openModal(modal)">
		<slot></slot>
	</a>
</template>

<script>
/**
 * A link to open a specific modal window
 */
	import MODALS from 'shared/constants/Modals';
	import _values from 'lodash/values';
	import { mapMutations } from 'vuex';

	export default {
		props: {
			modal: {
				type: String,
				validator: ( value ) => _values(MODALS).indexOf(value) > -1
			},
			text: {
				type: String
			}
		},
		methods: {
			...mapMutations(['openModal'])
		}
	};
</script>

<style></style>