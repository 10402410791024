<template>
	<div :class="{ blinker: true, 'blinker--inverted': invertColor }" v-show="show"></div>
</template>

<script>
	import loadingAnimation from './mixins/loadingAnimation';

	export default {
		mixins: [loadingAnimation]
	};
</script>

<style>
	
	.blinker {
		display: block;
		animation-duration: 350ms;
		animation-name: blink;
		animation-iteration-count: infinite;
		animation-timing-function: steps(2);
		margin-top: 1rem;
		width: 48px;
		height: 12px;
	}

	.blinker--inverted {
		animation-name: blink-inverted !important;
	}

	@keyframes blink {
		from{
			background: rgba(0, 0, 0, 1);
		}
		to{
			background: rgba(0, 0, 0, 0);
		}
	}

	@keyframes blink-inverted {
		from {
			background: rgba(255, 255, 255, 1);
		}
		to {
			background: rgba(255, 255, 255, 0);
		}
	}
</style>