<template>
	<form class="cart-quantity">
		<label class="form__label" for="cart_quantity">QTY</label>
		<input
			id="cart_quantity"
			class="form__text-input form__text-input--inline cart-quantity__input"
			type="text"
			v-model="newQuantity"
			@input="markChanged"
			@blur="resetIfBlank">
		<a class="cart-quantity__submit" :class="{ 'disabled': disabled }" href="#" @click.prevent="submit">UPDATE</a>
		<p v-if="!inStock">There are only {{ available }} copies in stock.</p>
	</form>
</template>

<script>
	/**
	 * Adjust the quantity of items to purchase
	 */
	import { mapActions, mapState } from 'vuex';
	import activeState from 'shared/components/mixins/ActiveState';
	import _pick from 'lodash/pick';

	export default {
		props: {
			id: Number,
			itemIndex: Number,
			quantity: Number,
			available: Number
		},

		data: function(){ 
			return {
				newQuantity: this.quantity,
				changed: false,
				associatedAction: 'updateCart'
			} 
		},

		computed: {
			updatedQuantity: function(){
				return this.newQuantity ? this.newQuantity : this.quantity;
			},
			disabled: function(){
				return this.isActive || !this.changed || !this.inStock || this.isBlank; 
			},
			inStock: function(){
				return this.newQuantity <= this.available;
			},
			isBlank: function (){
				return this.newQuantity === '';
			}
		},

		methods: {
			...mapActions(['updateCart']),
			
			markChanged: function(){
				this.changed = true;
			},

			resetIfBlank: function(){
				if (this.isBlank) {
					this.newQuantity = this.quantity;
					this.changed = false;
				}
			},

			submit: function(){
				if(this.disabled){
					return;
				}
				const options = {id: this.id, itemIndex: this.itemIndex, quantity: this.updatedQuantity};
				this.updateCart(options);
				this.changed = false;
			}
		},

		mixins: [activeState]
	};
</script>

<style lang="scss">
	@import "scss/variables/breakpoints";

	.cart-quantity__input{
		width: 3em !important;
		margin: 0 1em;
		text-align: center;
	}

	.cart-quantity__submit.disabled{
		opacity: .5;
	}

	@media screen and (max-width: $breakpoint-medium){
		.cart-quantity__input{
			margin: 0.5rem;
			width: 2.5rem !important;
		}
	}
</style>