<template>
  <div class="guide-print-nav" @click="onClick">
    print
  </div>
</template>

<script>
  export default {
    methods: {
      onClick() {
        window.print()
      }
    }
  };
</script>

<style>
  .guide-print-nav {
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
    font-style: italic;
    font-weight: 400;
  }
</style>
