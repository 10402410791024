export default {
	/**
	 * Utilities to add recaptcha verification to a form
	 */
	data: function(){
		return{
			recaptchaResponse: '',
			recaptchaError: ''
		}
	},
	methods: {
		recaptchaCallback(response){
			this.recaptchaResponse = response;
		},
		addRecaptchaToRequest(data){
			if(this.recaptchaResponse.length === 0){
				this.recaptchaError = 'Please complete the captcha challenge';
				return;
			}
			data.g_recaptcha_response = this.recaptchaResponse;
		},
		recaptchaIsCompleted(){
			return this.recaptchaResponse.length > 0;
		}
	}
}