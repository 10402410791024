
export default {
    methods: {

        openShareWindow(url){
            window.open(url, '_blank', 'width=550,height=450,resizable=yes');
        },

        onTwitterClick(category, title, shortenedLink){
          var text = this.getTwitterText(category, title, shortenedLink);
          this.openShareWindow(`http://twitter.com/intent/tweet?text=${text}&source=${encodeURIComponent(shortenedLink)}`);
        },

        getTwitterText(category, title, shortenedLink){
           return encodeURIComponent(category.toUpperCase()) + '+%7C+' + encodeURIComponent(title) + '+' + shortenedLink + '+%40artforum';
        },

        onFacebookClick(title, link){
            this.openShareWindow(`http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&t=${encodeURIComponent(title)}`);
        },

        getEmailLink(title, link){
        	return `mailto:?subject=From Artforum%20-%20${encodeURIComponent(title)}&body=${encodeURIComponent(link)}`;
        }
    }
}