<template>
  <span @click="onClick" class="guide__myguide-toggle" :class="myguideClass">
    {{ isMyguide ? '-myguide' : '+myguide' }}
  </span>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import MODALS from 'shared/constants/Modals'

  export default {
    props: ['entityId'],
    data() {
      return { waitingForLogin: false }
    },
    computed: {
      ...mapState({
        hasCheckedLogin: state => state.user.hasCheckedLogin,
        userIsLoggedIn: state => state.user.userIsLoggedIn,
        hasLoadedMyguide: state => state.myguide.hasLoaded,
        myguideExhibitionIds: state => state.myguide.myguideExhibitionIds
      }),
      ...mapGetters({
        currentExhibitions: 'myguide/currentMyguideEntities'
      }),
      isMyguide() {
        return this.myguideExhibitionIds[this.entityId]
      },
      myguideClass() {
        return {
          'guide__myguide-toggle--myguide': this.hasLoadedMyguide && this.isMyguide,
          'guide__myguide-toggle-loading': !this.hasCheckedLogin || (this.userIsLoggedIn && !this.hasLoadedMyguide)
        }
      }
    },
    methods: {
      ...mapActions('myguide', ['toggleMyguideStatus']),
      ...mapMutations('artguideMap', ['triggerNearbyMode']),
      ...mapMutations('login', ['setLoginPrompt']),
      ...mapMutations(['openModal', 'closeModal']),
      onClick() {
        if (this.hasCheckedLogin && !this.userIsLoggedIn) {
          this.waitingForLogin = true
          this.setLoginPrompt('Sign in to add this listing to your <b>myguide</b>.')
          this.openModal(MODALS.LOGIN_FORM)
        }

        if (!this.hasLoadedMyguide) {
          return
        }

        this.toggleMyguideStatus(this.entityId).then(() => {
          if (this.currentExhibitions.length === 0) {
            this.triggerNearbyMode()
          }
        })
      }
    },
    watch: {
      hasLoadedMyguide(hasLoaded) {
        if (hasLoaded && this.waitingForLogin) {
          if (!this.isMyguide) {
            // if we were waiting for login and we are not *already* in myguide, add to myguide
            this.toggleMyguideStatus(this.entityId)
          }

          this.waitingForLogin = false
        }
      }
    }
  }
</script>

<style lang="scss">
  .guide__myguide-toggle {
    font-style: italic;
    cursor: pointer;
    transition: all 0.2s;
  }

  .guide__myguide-toggle--myguide {
    color: #f00;
  }

  .guide__myguide-toggle-loading {
    cursor: auto;
    opacity: 0.5;
  }
</style>
