
export function navigateToPlace (placePath, category = 'all') {
	const categorySlugMap = {
		'art fairs': 'art-fairs',
		'must see': 'must-see',
		'special events': 'special-events',
		"editor's select": 'editors-select'
	}

	const params = new URLSearchParams()
	params.set('category', categorySlugMap[category] || category)

	// May want to migrate district from previous params when switching inter-city

	const url = `${placePath}?${params.toString()}`
	window.location = url
}
