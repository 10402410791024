<template>
	<ul class="search-suggestions" v-if="searchIsActive || mobileSearchIsActive">
		<li class="search-suggestions__suggestion" v-for="suggestion in suggestions"><search-link :item="suggestion.item"></search-link></li>
	</ul>
</template>

<script>
import { mapGetters } from 'vuex';
import searchLink from './SearchLink.vue';
export default {
	computed: {
		...mapGetters(['searchIsActive', 'mobileSearchIsActive']),
	},
	props: {
		suggestions: Array
	},
	components: {
		searchLink: searchLink
	}
};
</script>

<style>
	.search-suggestions__suggestion{
		padding: .125em 1em .125em 2em;
		color: #888;
		font-size: 1em;
		text-align: right;
		text-transform: uppercase;
	}
</style>