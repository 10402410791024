/**
 * This is not currently used or included
 */

import { API_PATH } from 'shared/constants/Paths';

export default {
    namespaced: true,

    state: {
        shippingAddressSchema: null,
    },

    mutations: {
        checkoutAssign(state, data){
            Object.assign(state, data);
        },
    },

    actions: {
        getShippingAddressSchema: function({commit}){
            const ACTION_NAME = 'getShippingAddressSchema';

            const API_ROUTE = `${API_PATH}/order/form/create`;
            const HEADERS = new Headers({
                'Accept': 'application/json'
            })

            commit('addActiveCall', ACTION_NAME, {root: true});

            fetch(API_ROUTE, {
                headers: HEADERS, 
            }).then((res) => {
                return res.json();
            }).then((data) => {
                commit('checkoutAssign', {shippingAddressSchema: data});
            }).catch((e) => {
                console.log(e);
            })
        }
    }
};
