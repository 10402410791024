<template>
  <div class="guide-myguide-nav">
    <a :href="myguidePath" :class="myguideLinkClass" @click="onMyguideLinkClick">myguide</a>

    <ul v-if="isMyguideActive" class="guide-myguide-nav__subnav">
      <li :class="sortByDateClass" @click="setSort('date')">sort by date</li>
      <li :class="sortByCityClass" @click="setSort('city')">sort by city</li>
      <li>
        <a :href="shareMyguideLink" target="_blank">share myguide</a>
      </li>
      <li @click="showClearMyguideWarning">clear myguide</li>
      <div v-if="showingClearMyguideWarning" class="guide-myguide-nav__clear-warning">
        This will remove all exhibitions from myguide.<br/>
        <a class="myguide-clear__option" @click="hideClearMyguideWarning">Cancel</a>
        <a class="myguide-clear__option" @click="confirmMyguideClear">Confirm</a>
      </div>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import MODALS from 'shared/constants/Modals'
  import { formatLocationAddress, formatExhibitionDates, emailCleanExhibitionTitle, formatExhibitionOpeningDates } from './util'
  import he from 'he'

  export default {
    props: {
      page: String,
      myguidePath: String
    },
    data() {
      return {
        showingClearMyguideWarning: false
      }
    },
    computed: {
      ...mapState({
        userIsLoggedIn: state => state.user.userIsLoggedIn,
        listingsSort: state => state.myguide.listingsSort
      }),
      ...mapGetters({
        currentExhibitions: 'myguide/currentMyguideEntities'
      }),
      isMyguideActive() {
        return this.page === 'myguide'
      },
      shareMyguideLink() {
        const subject = 'artguide'

        let body = this.currentExhibitions.map(entity => {
          const address = formatLocationAddress(entity.location);
          const dates = formatExhibitionDates(entity);
          const opening = formatExhibitionOpeningDates(entity);
          return `${he.decode(entity.location.name)}\n${address}\n${emailCleanExhibitionTitle(entity.title, entity.artists)}\n${dates}\n${opening}`
        }).join('\n\n')

        body = `${body}\n\n download artguide: https://artguide.artforum.com/artguideapp`

        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      },
      myguideLinkClass() {
        return { 'guide-myguide-nav__active': this.isMyguideActive }
      },
      sortByDateClass() {
        return { 'guide-myguide-nav__active': this.listingsSort === 'date' }
      },
      sortByCityClass() {
        return { 'guide-myguide-nav__active': this.listingsSort === 'city' }
      }
    },
    methods: {
      ...mapMutations(['openModal', 'closeModal']),
      ...mapMutations('login', ['setLoginPrompt']),
      ...mapMutations('myguide', ['setListingsSort']),
      ...mapMutations('artguideMap', ['triggerNearbyMode']),
      ...mapActions('myguide', ['clearMyguide']),
      onMyguideLinkClick(ev) {
        // if no user is logged in, force login before opening myguide
        if (!this.userIsLoggedIn) {
          ev.preventDefault()
          this.setLoginPrompt('Sign in to access your <b>myguide</b>.')
          this.openModal(MODALS.LOGIN_FORM)
        }
      },
      setSort(sort) {
        if (this.listingsSort !== sort) {
          this.setListingsSort(sort)
          window.scrollTo(0, 0)
        }
      },
      showClearMyguideWarning() {
        this.showingClearMyguideWarning = true
      },
      hideClearMyguideWarning() {
        this.showingClearMyguideWarning = false
      },
      confirmMyguideClear() {
        this.showingClearMyguideWarning = false
        window.scrollTo(0, 0)

        this.clearMyguide().then(() => {
          if (this.currentExhibitions.length === 0) {
            this.triggerNearbyMode()
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "scss/variables/breakpoints";

  .guide-myguide-nav__active,
  a.guide-myguide-nav__active {
    color: #f00;
  }

  .guide-myguide-nav__subnav {
    margin-top: 2px;
    line-height: 1.4;
  }

  .guide-myguide-nav__subnav li {
    margin: 2px 0;
    cursor: pointer;
    font-weight: 400;
  }

  .guide-myguide-nav__clear-warning {
    max-width: 155px;
    margin-top: 6px;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
  }

  .guide-myguide-nav__clear-warning .myguide-clear__option{
    display: inline-block;
  }

  .guide-myguide-nav__clear-warning .myguide-clear__option {
    display: inline-block;
    margin: 0 8px 0 0;
    outline: none;
    background: none;
    cursor: pointer;
    font-style: normal;
    text-decoration: underline;
  }

  @media screen and (max-width: $breakpoint-medium){

    .guide-myguide-nav__subnav li {
      padding: .25rem 0px .25rem 1rem;
      &:last-child{
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
  }
</style>
