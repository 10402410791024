<template>
    <div>
        <svg id="audio-player-prev" data-name="audio-player-prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.96 15.17"><polygon class="cls-1" points="10.82 7.59 22.96 0 22.96 15.17 10.82 7.59"/><polygon class="cls-1" points="0.85 7.59 12.99 0 12.99 15.17 0.85 7.59"/><line class="cls-2" x1="0.85" x2="0.85" y2="15.17"/></svg>
    </div>
</template>

<script>
export default {
    props: {
      iconColor: {
        type: String,
        default: '#000000'
      }
    }
}
</script>

<style>
    .cls-1{ fill: v-bind('iconColor'); }
    .cls-2{ fill:none; stroke: v-bind('iconColor'); stroke-width:1.7px; }
</style>