<template>
    <div></div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    export default {
        props: {
            itemId: {
                type: Number,
                required: true,
            },
            names: {
                type: Array,
                required: false,
                default: () => [],
            },
            order: {
                type: String,
                required: false,
                default: 'ASC',
            },
        },

        data(){
            return {
                active: false
            }
        },

        computed: {
            ...mapGetters('viewport', ['scrollBottom']),
            
            ...mapState({
                page: (state) => state.lightbox.page,
                allPagesLoaded: (state) => state.lightbox.allPagesLoaded,
            }),

            ...mapState(['activeCalls']),

            isActive: function(){
                return this.activeCalls.indexOf('getArticleImages') !== -1;
            }
        },

        methods: {
            ...mapActions('lightbox', ['getArticleImages']),
            ...mapMutations('lightbox', ['addLightboxImages', 'lightboxAssign']),

            loadMore: function(){
                if(this.allPagesLoaded || this.isActive){
                    return;
                }

                this.getArticleImages({articleId: this.itemId, names: [], order: 'DESC', page: this.page})
                .then((images) => { 
                    this.addLightboxImages(images);
                    this.lightboxAssign({page: this.page + 1});
                })
                .catch((e) => {
                    // do nothing
                });
            }
        },
        
        watch: {
            scrollBottom: function(scrollBottom){
                if( ( scrollBottom >= this.$el.offsetTop ) && ( this.active ) ) {
                    this.loadMore();
                }
            }
        },

        mounted: function(){
            setTimeout(() => {
                this.active = true
            }, 4000)
        }
    };
</script>