<template>
    <div class="exhibition-highlights">
        <div class="image-container image-container--1x1">
            <a :href="currentLink">
                <img :src="currentImage" :title="currentTitle">
            </a>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import NAVIGATION from 'shared/constants/Navigation';
    import { IMAGE_BASE_PATH } from 'shared/constants/Paths';

    export default {
        data: function(){
            return {
                index: 0
            }
        },
        computed: {
            ...mapGetters('ads', ['exhibitionHighlights']),

            currentExhibition: function(){
                return this.exhibitionHighlights.length > 0 ? this.exhibitionHighlights[this.index] : {};
            },

            currentImage: function(){
                return this.exhibitionHighlights.length > 0 && this.exhibitionHighlights[this.index].entity.media[0].path_large.length > 0
                    ? `${IMAGE_BASE_PATH}${this.exhibitionHighlights[this.index].entity.media[0].path_large}`
                    : '';
            },

            currentTitle: function(){
                return ( this.exhibitionHighlights[this.index] && this.exhibitionHighlights[this.index].entity.title ) 
                    ? this.exhibitionHighlights[this.index].entity.title
                    : '';
            },

            currentLink: function(){
                const current = this.exhibitionHighlights[this.index];
                return current ? current.entity.location.path : '';
            }    
        },

        methods: {
            ...mapActions('ads', ['getExhibitionHighlights'])
        },

        mounted: function(){
            this.getExhibitionHighlights();
            setInterval( ()=> {
                this.index = this.index < this.exhibitionHighlights.length - 1 ? this.index + 1 : 0;
            }, 5000);
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/typography";
    @import "scss/variables/breakpoints";

    .exhibition-highlights{
        border: 10px solid #f5f5f5;
        box-sizing: border-box;
    }

    .exhibition-highlights::after{
        content: 'FROM SELECT ADVERTISERS';
        font-weight: $book;
        display: block;
        text-align: center;
        background: #f5f5f5;
        padding-top: .5rem;
        padding-bottom: .25rem;
    }

    @media screen and ( min-width: $breakpoint-medium ){
        .exhibition-highlights{
            width: 30%;
        }
    }
</style>