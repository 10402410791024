import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';
import { mapState } from 'vuex';

const picks = {
    namespaced: true,

    state: {
        results: [],
        placeBookmarks: [],
        nextPage: 2, 
        allPagesLoaded: false,
        setStart: false,
    },

    mutations: {
        searchAssign(state, data){
            Object.assign(state, data);
        },
        addResults(state, data){
            state.results = state.results.concat(data);
        },
        placeBookmarkAssign(state, data){
            state.placeBookmarks = state.placeBookmarks.concat(data);
        },
        isLoadComplete(state, data){
            for (let i = 0; i < state.placeBookmarks.length; i++) {
                if (data == state.placeBookmarks[i].place) {
                    state.allPagesLoaded = true;
                }
            }
        }
    },

    computed: {
        ...mapState('viewport', ['scrollY']),
    },

    actions: {
        fetchPickResults: function({ commit, state, rootGetters}, input){
            const ACTION_NAME = 'fetchPickResults';

            var place= input.place;
            var bookmark= input.bookmark;
            if (!state.setStart){
                commit('placeBookmarkAssign', {place: bookmark, startY: 100});
                state.setStart = true;
            }
            commit('isLoadComplete', place);

            if (state.allPagesLoaded) {
                return false;
            }

            if (rootGetters.callIsActive(ACTION_NAME)) {
                return;
            }

            const PATH = `${API_PATH}/picks/${place}`;
            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                if(res.status === 404){
                    commit('searchAssign', {allPagesLoaded: true});
                }
            }).then((data) => {
                commit('addResults', data._embedded.items);
                if (!state.allPagesLoaded) {
                    commit('placeBookmarkAssign', {place: place, startY: scrollY});
                }
                var nextPage = state.nextPage + 1;
                commit('searchAssign', {nextPage: nextPage, allPagesLoaded: state.allPagesLoaded});
                commit('removeActiveCall', ACTION_NAME, { root: true });

                const newUrl = `${window.location.protocol}//${window.location.host}/picks/${place}`;
                history.replaceState({path: newUrl}, '', newUrl);

            }).catch((err) => {
                console.error(err);
            })
        }

    }
};

export default picks;