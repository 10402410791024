/**
 * A wrapper that adds date formatting as a filter in templates
 */

import dateFormat from 'date-fns/format';

export default{
	filters: {
		formatDate: (value, format) => dateFormat(value, format)
	}
}