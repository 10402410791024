<template>
    <span></span>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { HOMEPAGE_MULTIFEATURE_TIMEOUT } from '../../constants/Common';
    export default {

        methods: {
            ...mapMutations('homepage', ['homepageAssign', 'setCurrentMultiFeature']),
        },

        mounted: function(){
            const multiFeatureCount = document.getElementsByClassName('multi-feature__article-image').length;
            this.homepageAssign({multiFeatureCount: multiFeatureCount});
            window.multifeatureTimeout = window.setTimeout(() => {
                this.setCurrentMultiFeature(1);
            }, HOMEPAGE_MULTIFEATURE_TIMEOUT);
        }
    };
</script>