<template>
    <div>
        <dot-dot-dot associated-action="fetchPickResults"></dot-dot-dot>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import DotDotDot from '../animations/DotDotDot.vue';

    export default {
        components: { DotDotDot },
        props: {
            paramsJson:
                { type: String, default: null },
            bookmark:
                { type: String, default: null }
        },
        computed: {
            ...mapState({
                activeCalls: (state) => state.activeCalls
            }),

            ...mapGetters('viewport', ['scrollBottom']),

            isActive: function(){
                return this.activeCalls.indexOf('fetchPickResults') !== -1;
            },
            params: function(){
                return JSON.parse(this.paramsJson);
            }
        },

        methods: {
            ...mapActions('picks', ['fetchPickResults']),

            getNext: function(){
                if (this.next == undefined) {
                    this.next = this.bookmark;
                }
                var places = this.params;
                var b = 0;
                for (let i = 0; i < places.length; i++) {
                    if (places[i]['placeUrl'] == this.next) {
                        var b = i + 1;
                        if (b == places.length) {
                            var b = 0;
                        }
                    }
                }
                this.next = places[b]['placeUrl'];
                return this.next;
            }
        },

        watch: {
            scrollBottom: function(val){
                if ( (val > this.$el.offsetTop) && !this.isActive ) {
                    this.fetchPickResults({place: this.getNext(), bookmark: this.bookmark});
                }
            }
        }
    };
</script>