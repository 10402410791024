<template>
	<section class="hp-section desktop-flex">
		<div class="hp-twocolumn__left-column">
			<dot-dot-dot associated-action="getBookforumHomepageData"></dot-dot-dot>
			<a class="hp-twocolumn__feature-link" href="https://www.bookforum.com/print" target="_blank">
				<img class="hp-twocolumn__image drop-shadow" :src="cover" alt="bookforum">
			</a>
		</div>
		<div class="hp-twocolumn__right-column">
			<div class="hp-bookforum__print">
				<h3 v-if="printArticles.length > 0" class="hp-bookforum__section-heading">highlights from the issue</h3>
				<article class="hp-bookforum__print-article" v-for="article in printArticles">
					<h3 class="hp-twocolumn__title">
						<a :href="article.url" target="_blank" v-html="article.headFormatted"></a>
					</h3> 
					<a :href="article.path" class="hp-twocolumn__teaser" v-html="article.deckFormatted"></a>
				</article>
			</div>
			
			<div class="hp-bookforum__web">
				<h3 v-if="webArticles.length > 0" class="hp-bookforum__section-heading">highlights from bookforum.com</h3>
				<article class="hp-bookforum__web-article" v-for="article in webArticles">
					<h3 class="hp-bookforum__title--web">
						<a :href="article.url" target="_blank">
							<span class="hp-bookforum__web-category"> {{ article.channel}} </span>
						</a>
					</h3> 
					<a :href="article.path" target="_blank" class="hp-bookforum__teaser--web" v-html="article.deckFormatted"></a>
				</article>
			</div>
		</div>
	</section>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex';
	import dotDotDot from '../animations/DotDotDot.vue';
	import { BOOKFORUM_URL } from 'shared/constants/Paths';

	export default {
		data(){
			return {
				loaded: false
			} 
		},

		computed: {
			...mapState({
				feature: state => state.bookforum.homepageFeature,
				printArticles: state => state.bookforum.homepageArticles.print.slice(0,3),
				webArticles: state => state.bookforum.homepageArticles.web.slice(0,3)
			}),
			...mapGetters('viewport', ['scrollBottom']),

			cover: function(){
				return this.feature.length > 0 ? `${BOOKFORUM_URL}${this.feature[0].media[0].path_large}` : '';
			}
		},

		methods: {
			...mapActions('bookforum', ['getBookforumHomepageData'])
		},

		watch: {
			scrollBottom: function(scrollBottom){
				if ( scrollBottom >= this.$el.offsetTop && !this.loaded ) {
                    this.getBookforumHomepageData();
                    this.loaded = true;
                }
			}	
		},
		components: { dotDotDot } 
	};
</script>

<style lang="scss">
@import "scss/variables/typography";
@import "scss/variables/breakpoints";

	.hp-bookforum__web{
		margin-top: 3rem;
	}

	.hp-bookforum__section-heading{
		margin: -.25em 0px .5em 0px;
		font-weight: $book;
		font-size: 1.5rem;
		color: red;
	}

	.hp-bookforum__web-article,
	.hp-bookforum__print-article{
		margin-bottom: 1rem;
	}

	.hp-bookforum__title--web{
		margin: 0;
	}

	.hp-bookforum__teaser--web,
	.hp-bookforum__web-category,
	.hp-bookforum__web-title{
		font-size: 1.25rem;
	}

</style>