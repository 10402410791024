<template>
    <span></span>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { HOMEPAGE_MULTIFEATURE_TIMEOUT } from '../../constants/Common';
    export default {

        methods: {
            ...mapMutations('homepage', ['homepageAssign', 'setCurrentMultiVideo']),
        },

        mounted: function(){
            const multiVideoCount = document.getElementsByClassName('multi-video__article-image').length;
            this.homepageAssign({multiVideoCount: multiVideoCount});
            window.multivideoTimeout = window.setTimeout(() => {
                this.setCurrentMultiVideo(1);
            }, HOMEPAGE_MULTIFEATURE_TIMEOUT);
        }
    };
</script>