var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"audio-player"},[_c('div',{staticClass:"audio__btn-wrap"},[(_vm.showPlaybackRate)?_c('div',{staticClass:"audio__play-rate",style:({
        color: _vm.themeColorB,
      })},[_c('span',{on:{"click":function($event){$event.stopPropagation();_vm.isShowRates = !_vm.isShowRates}}},[_vm._v(_vm._s(_vm.playbackRate.toFixed(1) + 'x'))]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-rate"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRates),expression:"isShowRates"}],staticClass:"audio__play-rate__dropdown",style:({
            backgroundColor: _vm.themeColor,
          })},_vm._l((_vm.playbackRates),function(rate){return _c('div',{key:'pr_' + rate,on:{"click":function($event){$event.stopPropagation();return _vm.handleSetPlaybackRate(rate)}}},[_vm._v("\n            "+_vm._s(rate.toFixed(1) + 'x')+"\n          ")])}),0)])],1):_vm._e(),_vm._v(" "),(_vm.showPrevButton)?_c('div',{staticClass:"audio__play-prev",class:{ disable: !_vm.isLoop && _vm.currentPlayIndex === 0 },style:({
        color: _vm.themeColor,
      }),on:{"click":function($event){$event.stopPropagation();return _vm.playPrev.apply(null, arguments)}}},[_vm._t("play-prev",function(){return [_c('icon-audio-previous',{attrs:{"icon-color":_vm.themeColor}})]})],2):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"audio__play-loading"},_vm._l((8),function(item){return _c('span',{key:item,style:({
          backgroundColor: _vm.themeColor,
        })})}),0):[(!_vm.isPlaying && _vm.showPlayButton)?_c('div',{staticClass:"audio__play-start",style:({
          color: _vm.themeColor,
        }),on:{"click":function($event){$event.stopPropagation();return _vm.play.apply(null, arguments)}}},[_c('icon-audio-play',{attrs:{"icon-color":_vm.themeColor}})],1):(_vm.showPlayButton)?_c('div',{staticClass:"audio__play-pause",style:({
          color: _vm.themeColor,
        }),on:{"click":function($event){$event.stopPropagation();return _vm.pause.apply(null, arguments)}}},[_c('icon-audio-pause',{attrs:{"icon-color":"themeColorB"}})],1):_vm._e()],_vm._v(" "),(_vm.showNextButton)?_c('div',{staticClass:"audio__play-next",class:{
        disable: !_vm.isLoop && _vm.currentPlayIndex === _vm.audioList.length - 1,
      },style:({
        color: _vm.themeColor,
      }),on:{"click":function($event){$event.stopPropagation();return _vm.playNext.apply(null, arguments)}}},[_c('icon-audio-next',{attrs:{"icon-color":_vm.themeColor}})],1):_vm._e(),_vm._v(" "),(_vm.showVolumeButton)?_c('div',{staticClass:"audio__play-volume-icon-wrap"},[_c('svg',{staticClass:"audio__play-icon",style:({
          color: _vm.themeColor,
        }),attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.handleVolumeIconTouchstart.apply(null, arguments)}}},[_c('use',{attrs:{"xlink:href":_vm.currentVolume ? `#icon-play-volume` : `#icon-play-volume-no`}})]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-volume"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowVolume),expression:"isShowVolume"}],ref:"playVolumeWrap",staticClass:"audio__play-volume-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.handleVolumePanmove.apply(null, arguments)},"panmove":_vm.handleVolumePanmove,"panend":_vm.handleVolumePanend}},[_c('div',{ref:"playVolume",staticClass:"audio__play-volume",style:({
              height: _vm.currentVolume * 100 + '%',
              backgroundColor: _vm.themeColor,
            })})])])],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowErrorMessage),expression:"isShowErrorMessage"}],staticClass:"audio__notice"},[_vm._v("\n      "+_vm._s(_vm.noticeMessage)+"\n    ")])],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProgressBar),expression:"showProgressBar"}],ref:"audioProgressWrap",staticClass:"audio__progress-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.handleClickProgressWrap.apply(null, arguments)}}},[_c('div',{ref:"audioProgress",staticClass:"audio__progress",style:({
        backgroundColor: _vm.themeColorB,
      })}),_vm._v(" "),_c('div',{ref:"audioProgressPoint",staticClass:"audio__progress-point",style:({
        backgroundColor: _vm.themeColorB,
        boxShadow: `0 0 10px 0 ${_vm.themeColorB}`,
      }),on:{"panstart":_vm.handleProgressPanstart,"panend":_vm.handleProgressPanend,"panmove":_vm.handleProgressPanmove}})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProgressBar),expression:"showProgressBar"}],staticClass:"audio__time-wrap"},[_c('div',{staticClass:"audio__current-time"},[_vm._v("\n      "+_vm._s(_vm.currentTimeFormatted)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"audio__current-time__deliminator"},[_vm._v("\n     / \n    ")]),_vm._v(" "),_c('div',{staticClass:"audio__duration"},[_vm._v("\n      "+_vm._s(_vm.durationFormatted)+"\n    ")])]),_vm._v(" "),_c('audio',_vm._b({ref:"audio",staticClass:"audio-player__audio",attrs:{"src":_vm.audioList[_vm.currentPlayIndex]},on:{"ended":_vm.onEnded,"timeupdate":_vm.onTimeUpdate,"loadedmetadata":_vm.onLoadedmetadata}},'audio',_vm.$attrs,false),[_vm._v("\n  The browser is too old, please upgrade your browser~\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }