<template>
    <img :srcset="currentSrcset" :sizes="sizes" :src="currentSrc">
</template>

<script>
    /**
     * This component looks like an animated gif, cycling through an array of images
     * It is currently in use in the ad archive
     */
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';

    export default {
        mixins: [srcsetFromMedia],

        props: {
            media: {
                // an array of Media entities
                type: Array,
                required: true,
            }
        },

        data(){
            return {
                index: 0,
            };
        },

        computed: {
            currentSrcset: function(){
                return typeof this.media !== 'undefined' ?  this.srcset(this.media[this.index]) : '';
            },
            currentSrc: function(){
                return this.media ? this.media[this.index].path : '';
            },
        },

        methods: {
            increment: function(){
                this.index = (this.index + 1) % this.media.length;
            },

            flip: function(){
                this.increment();
                window.setTimeout(this.flip, 3500);
            }
        },

        mounted: function(){
            this.flip();
        }
    };
</script>