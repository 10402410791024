import dateFormat from 'date-fns/format';
import he from 'he';
import cleanDateString from 'lib/utils/CleanDateString';

export function formatDate (date, includeYear = false) {
  return includeYear ? dateFormat(date, 'MMMM D, YYYY') : dateFormat(date, 'MMMM D')
}

export function formatDateAbbr (date) {
  return dateFormat(date, 'MMM D, YYYY')
}

export const getStartDate = entity => new Date(cleanDateString(entity.showStart.date || entity.showStart))
export const getEndDate = entity => new Date(cleanDateString(entity.showEnd.date || entity.showEnd))

export const getOpeningDate = entity => new Date(cleanDateString(entity.openingStart))

// export const getOpeningDate = function (entity) {
//   const d = new Date(cleanDateString(entity.openingStart));

//   if (isNaN(d.getTime())){
//     console.warn('bad date', entity.openingStart);
//   }
//   else{
//     console.log('good date', entity.openingStart);
//   }
//   return d;
// }

export function formatExhibitionDates (entity) {
  if (!entity || !entity.showStart || !entity.showEnd) return ''

  const start = getStartDate(entity)
  const end = getEndDate(entity)
  return formatDateAbbr(start) === formatDateAbbr(end)
    ? formatDate(start, true)
    : `${formatDate(start)} - ${formatDate(end, true)}`
}

export function formatExhibitionOpeningDates (entity) {
  if (!entity || !entity.openingStart || !entity.openingEnd) return ''

  const start = new Date(cleanDateString(entity.openingStartAsString))
  const end = new Date(cleanDateString(entity.openingEndAsString))

  var opening = dateFormat(start, 'dddd, MMMM Do, h:mm a')
  var openingEnd = dateFormat(end, ' h:mm a')
  return 'Reception: ' + `${opening} - ${openingEnd}`
}

export function formatLocationAddress (location) {
  return location ? `${location.address}, ${location.town}, ${location.zip}` : ''
}

export function splitExhibitionTitle (title) {
  if (!title) return { artistName: null, exhibitionTitle: null }

  const parts = (title || '').split(/\n+/)

  let artistName = parts[0]
  let exhibitionTitle = parts.length > 1 ? parts[1] : null
  if (artistName.length === 0 && parts.length > 1) {
    artistName = parts[1]
    exhibitionTitle = null
  }

  return { artistName, exhibitionTitle }
}

export function emailCleanExhibitionTitle (exhibitionTitle, artistName) {
  if( artistName && exhibitionTitle ){
    return he.decode(`${artistName}\n${exhibitionTitle}`)
  }
  if( artistName ){
    return he.decode(artistName);
  }
  return he.decode(exhibitionTitle);
}

export function imagePath (image, square = false, version = false) {
  if (!image || image.length == 0) {
    return null;
  }

  let path = false;
  if (version && typeof image.versions[version] !== "undefined") {
      let opt = image.versions[version];
      path = opt.path;
  }
  else {
      path = square ? image.pathSquare : null;
  }

  if (!path) {
    path = image.pathLarge || image.path;
  }

  return `https://artguide.artforum.com${path.replace('/artforum', '')}`
}

export function manhattanDistance (pointA, pointB) {
  return Math.abs(pointA.latitude - pointB.latitude) + Math.abs(pointA.longitude - pointB.longitude)
}

export function getOutlierStats (data) {
  const l = data.length
  const median = data[Math.round(l / 2)]
  const lq = data[Math.round(l / 4)]
  const uq = data[Math.round(3 * l / 4)]
  const iqr = uq - lq

  return { median, iqr, lq, uq, lowCutoff: median - iqr * 5, highCutoff: median + iqr * 5 }
}
