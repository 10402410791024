/**
 * Loads and manages artguide listings
 */
import { API_PATH } from 'shared/constants/Paths';
import { ARTGUIDE_PAGE_SIZE } from 'shared/constants/Common';

const artguideApiPage = {
    namespaced: true,

    state: {
        apiPath:        '',
        hasLoaded:      false,
        nextPage:       1,
        loadedPages:    [],
        loadedEntities: [],
        totalEntities:  null,
        totalPages:     null,
        loading:        false,
        loadError:      null,
        featuredLists:  [],
    },

    mutations: {
        setApiPath(state, path) {
            state.apiPath = path
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setLoadError(state, loadError) {
            state.loadError = loadError
        },
        setNextPage(state, page) {
            state.nextPage = page
        },
        setTotalEntities(state, total) {
            state.totalEntities = total
        },
        setTotalPages(state, total) {
            state.totalPages = total
        },
        addResult(state, result) {
            state.loadedPages = state.loadedPages.concat([result]) // array of arrays of entities
            state.loadedEntities = state.loadedEntities.concat(result) // array of all entities
            state.hasLoaded = true
        },
        artguideApiAssign(state, data) {
            Object.assign(state, data);
        }
    },

    getters: {
        /**
         * checks loading state - if server is already busy loading
         */
        canLoadNextPage: (state) => {
            return !state.loading && (state.totalEntities === null || state.loadedEntities.length < state.totalEntities);
        }
    },

    actions: {
        fetchApiPageResults({ commit, state }, options) {
            const { apiPath, pageSize = ARTGUIDE_PAGE_SIZE, fetchAll = 0 } = options

            commit('addActiveCall', apiPath, { root: true })
            commit('setLoading', true)

            const pathUrl = new URL(apiPath)

            let params = new URLSearchParams(pathUrl.search)
            params.set('page', state.nextPage)
            params.set('size', pageSize)
            params.set('fetchAll', fetchAll)


            const url = `${pathUrl.origin}${pathUrl.pathname}?${params.toString()}`
            return fetch(url, {
                credentials: 'include'
            })
                .then(res => {
                    if (!res.ok) {
                        throw new Error(res.statusText)
                    }
                    return res.json()
                })
                .then(data => {
                    commit('addResult', data._embedded.items);
                    commit('setLoadError', null);
                    commit('setTotalEntities', data.total);
                    commit('setTotalPages', data.pages);
                    commit('setLoading', false);

                    // replace this with pagination results
                    //const hasNextPage = data.length == pageSize // if we have filled a page, we can probably load the next one
                    commit('setNextPage', state.nextPage + 1);

                    // only allow 1 page load per second
                    setTimeout(() => {
                        commit('removeActiveCall', apiPath, { root: true })
                    }, 1000)
                })
                .catch(err => {
                    console.log('error fetching results', err)
                    commit('setLoadError', err)
                    commit('setLoading', false)
                })
        },

        fetchFeaturedListsAction({ commit }){
            const url = `${API_PATH}/guide/featuredlists`;

            fetch(url, {
                'method': 'GET',
                'accept': 'Application/json',
                'credentials': 'include'
            }).then((res) => {
                if(!res.ok){
                    throw new Error(res.statusText);
                }
                return res.json();
            }).then((data) => {
                commit('artguideApiAssign', { featuredLists: Object.values(data) });
            }).catch((err) => {
                console.log(err);
            })
        },
    },
}

export default artguideApiPage;
