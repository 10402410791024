/**
 * @param {String} search - window.location.search
 * @param {String} key
 * 
 * @return {String} value
 */
const getUrlParam = function(search, key){

    var sArray = search.split('&');

    for (let i = 0; i < sArray.length; i++) {
        const p = sArray[i];
        if (p.search(key) != -1) {
            let params = p.split('=');
            return params[1];
        }
    }
    return '';
};

export default getUrlParam;