<template>
    <a class="homepage-news__article" :href="getTrackingUrl" :title="article.titleFormatted | stripTags">
        <div class="image-container image-container--1x1">
            <div v-if="article.primaryImage && article.primaryImage.path_square">
                <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
            <div v-else-if="article.primaryImage">
                <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
        </div>
        <div class="homepage-mostread__category" v-html="slug"></div>
        <h3 class="homepage-mostread__title" v-html="article.titleFormatted"></h3>
        <div class="homepage-mostread__subtitle" v-html="article.subtitleFormatted"></div>
        <div class="homepage-mostread__byline" v-html="article.bylineFormatted"></div>
    </a>
</template>

<script>
    import { BREAKPOINT_MEDIUM } from 'shared/constants/Common.js';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';
    import ITEM_TYPES from '../../constants/ItemTypes';

    export default {
        props: {
            article: Object,
            index: Number,
            slidesPerScreen: Number,
            slideIndex: Number
        },
        computed: {
            queryMobile: () => '(max-width: ' + BREAKPOINT_MEDIUM + 'px)',
            queryDesktop: () => '(min-width: ' + BREAKPOINT_MEDIUM + 'px)',
            loadImg: function(index){
                return this.index <= ( this.slideIndex + 1 ) * this.slidesPerScreen;
            },
            srcsetComputed: function(){
                return this.loadImg ? this.srcset(this.article.primaryImage) : '';
            },
            srcComputed: function(){
                return this.loadImg ? this.article.primaryImage.path : '';
            },
            slug: function(){
                if (this.article.issueTitle) {
                    return 'PRINT';
                }
                else if (this.article.typeTranslated === ITEM_TYPES.ITEM_TYPENAME_VIDEO) {
                    return `VIDEO`;
                }
                else if (this.article.typeTranslated === ITEM_TYPES.ITEM_TYPENAME_PICKS) { 
                    return `CRITICS\' PICKS`;
                }
                else if (this.article.channel) {
                   return `${this.article.channel}`;
                }
                else {
                    return `${this.article.typeTranslated}`;
                }
            },
            getTrackingUrl: function(){
                let url = this.article.url;
                url = addUrlParam(url, 'utm_campaign', 'hp-mostread-module');
                url = addUrlParam(url, 'utm_medium', 'web');
                url = addUrlParam(url, 'utm_source', 'homepage');
                return url;
            }
        },
        methods: {
            /**
             * add to <a> attribute to revive: v-on:click="logClick(article)"
             */
            logClick: function(article){

                let title = article.titleFormatted;
                title.replace(/(<([^>]+)>)/gi, "");
                String(title).substr(0, 39);

                window.gtag("event",
                    "hpc_mostread_clicked",
                    {'item_id' : article.id,
                     'url' : article.url,
                     'item_name' : title
                    });
            },
        },
        mixins: [srcsetFromMedia, stripTags]
    };
</script>

<style lang="scss">
    @import "scss/variables/typography";
    
    .homepage-mostread__category{
        font-size: .9rem;
        text-transform: uppercase;
        font-weight: $book;
        margin: .9rem 0 0 0;
    }
    .homepage-mostread__subtitle{
        font-size: 1rem;
        font-weight: $book;
        margin: 0 0 .2rem 0;
    }
    .homepage-mostread__byline{
        font-size: 1rem;
        font-weight: $medium;
        margin: 0 0 .5rem 0;
    }
    .homepage-mostread__title{
        margin: .4rem 0 0 0;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: $medium;
    }
</style>
