import { ADMIN_API_PATH, ADMIN_SITE_PATH } from 'shared/constants/Paths';

export default {
    ADMIN_SITE_PATH: ADMIN_SITE_PATH,
    PAGES: [
        {
            name: 'Reports',
            path: '',
            slide: true,
            subpages: [
                {
                    name: 'Invoices',
                    path: '/admin/reports/writers/list'
                },
                {
                    name: 'User Registration Tally',
                    path: '/admin/users/report/registration'
                },
                {
                    name: 'Subscriber Geolocation',
                    path: '/admin/users/geolocation'
                },
                {
                    name: 'Email Marketer Statistics',
                    path: '/admin/emailmarketer/statistics'
                },
            ],
            top: false
        },
        {
            name: 'Artguide',
            path: '',
            subpages: [
                {
                    name: 'Locations',
                    path: '/artguide/admin/locations/list'
                },
                {
                    name: 'Location Groups',
                    path: '/artguide/admin/location/groups/list'
                },
                {
                    name: 'Exhibitions',
                    path: '/artguide/admin/exhibitions/list'
                },
                {
                    name: 'Must See Exhibitions',
                    path: '/artguide/admin/exhibitions/list/mustsee'
                },
                {
                    name: 'Featured Lists',
                    path: '/artguide/admin/reports/tags/list'
                },
                {
                    name: 'Notifications',
                    path: '/admin/task/newsletter/update/artguide/list'
                }
            ],
            top: true,
            slide: true
        },
        {
            name: 'Instagram',
            path: '/admin/instagram',
            top: false,
            slide: true
        },
        {
            name: 'Users',
            path: '/admin/user/list',
            top: true,
            slide: true
        },
        {
            name: 'Newsletter',
            path: '',
            slide: true,
            subpages: [
                {
                    name: 'Archive',
                    path: '/admin/newsletter/archive/images',
                },
                {
                    name: 'Dispatch',
                    path: '/admin/newsletter/dispatch_new/images'
                },
                {
                    name: 'Print',
                    path: '/admin/newsletter/print/images',
                },
                {
                    name: 'Shows',
                    path: '/admin/newsletter/shows/images',
                },
                {
                    name: 'Video',
                    path: '/admin/newsletter/video/images',
                },
                {
                    name: 'Stationary',
                    path: '/admin/newsletter/stationary/images',
                },
                {
                    name: 'Artguide',
                    path: '/admin/newsletter/artguide/images',
                },
                {
                    name: 'Segment Lists',
                    path: '/admin/emailmarketer/segment'
                },
                {
                    name: 'Statistics Maintenance',
                    path: '/admin/emailmarketer/statistics'
                },
                {
                    name: 'Computed Statistics',
                    path: '/admin/emailmarketer/computed/statistics'
                },
                {
                    name: 'Sync Maillists',
                    path: '/admin/emailmarketer/update'
                },
                {
                    name: 'Sync Backwards',
                    path: '/admin/emailmarketer/clean'
                },
                {
                    name: 'Fulco Holiday Lists',
                    path: '/admin/emailmarketer/task/holiday/unsubscribe/afholiday'
                },
                {
                    name: 'Refresh Subscriber Cached Counts',
                    path: '/admin/emailmarketer/task/maintenance/count/newsletter'
                },
                {
                    name: 'Reinvigoration Campaign',
                    path: '/admin/users/activity'
                },
                {
                    name: 'Email Links',
                    path: '/admin/emailmarketer/links'
                }
            ],
            top: false
        },
        {
            name: 'Newsletter',
            path: '',
            slide: false,
            subpages: [
                {
                    name: 'Archive',
                    path: '/admin/newsletter/archive/images',
                },
                {
                    name: 'Dispatch',
                    path: '/admin/newsletter/dispatch_new/images'
                },
                {
                    name: 'Print',
                    path: '/admin/newsletter/print/images',
                },
                {
                    name: 'Shows',
                    path: '/admin/newsletter/shows/images',
                },
                {
                    name: 'Video',
                    path: '/admin/newsletter/video/images',
                },
                {
                    name: 'Stationary',
                    path: '/admin/newsletter/stationary/images',
                }
            ],
            top: true
        },
        {
            name: 'Help',
            path: '/admin/help',
            top: true,
            slide: true
        }
    ]
}