<template>
    <section class="hp-section">
        <homepage-carousel :news-link="newsLink" :news-items="newsItems" :news-ads="newsAds" v-if="newsItems.length > 0"></homepage-carousel>
    </section>
</template>
<script>
    /**
     * a container for the news carousel
     * exists so that the carousel is only initialized once news items are loaded
     */
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { WEBROOT } from 'shared/constants/Paths';
    import HomepageCarousel from './HomepageCarousel.vue';

    export default {
        data: function(){
            return {
                newsLink: WEBROOT + '/news'
            }
        },
        computed: {
            ...mapState({
                newsItems: state => state.news.frontPageNews,
                newsAds: state => state.news.frontPageNewsAds,
            }),

            ...mapGetters({
                isMobile: 'viewport/isMobile'
            })
        },
        methods: {
            ...mapActions('news', ['getFrontPageNews', 'getFrontPageNewsAds'])
        },
        mounted: function(){
            this.$nextTick(function(){
                this.getFrontPageNews();
                this.getFrontPageNewsAds();
            })
        },
        components: { HomepageCarousel },
    };
</script>