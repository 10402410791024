<template>
  <div class="guide-share-panel">
    <a class="share-link" href="#" target="_blank" title="Twitter" @click.prevent="onTwitterClick">
    	<img src="~img/twitter_black.svg" alt="Twitter" border="0" />
    </a>

    <a class="share-link" href="#" target="_blank" title="Facebook" @click.prevent="onFacebookClick">
    	<img src="~img/facebook_black.svg" alt="Facebook" border="0" />
    </a>

    <a class="share-link" :href="emailLink" title="Email">
      <img src="~img/mail_black.svg" alt="Email" border="0" />
    </a>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { formatLocationAddress, formatExhibitionDates, emailCleanExhibitionTitle } from './util';
  import he from 'he'

  const downloadLink = 'download artguide https://artguide.artforum.com/artguideapp'

  const isExhibition = item => !!item.location
  const itemTitle = item => emailCleanExhibitionTitle(item.title || item.name || item.location.name, item.artists)
  const url = item => item.path

  const exhibitionText = item => `${itemTitle(item)}\n${formatExhibitionDates(item)}\n${url(item)}`
  const locationText = item => `${item.name}\n${formatLocationAddress(item)}`

  export default {
    props: {
      entity: Object
    },
    computed: {
      ...mapGetters({
        displayedExhibitions: 'artguide/displayedExhibitions'
      }),
      emailLink() {
        const { entity, displayedExhibitions } = this
        const subject = itemTitle(entity)

        let body
        if (isExhibition(entity)) {
          body = he.decode(`${locationText(entity.location)}\n\n${exhibitionText(entity)}`)
        } else {
          const locationExhibitions = displayedExhibitions.filter(item => item.location.id === entity.id)
          const exhibitionTexts = locationExhibitions.map(exhibitionText)
          body = he.decode(`${locationText(entity)}\n${url(entity)}\n\n${exhibitionTexts.join('\n\n')}`)
        }

        body = `${body}\n\n${downloadLink}`

        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      }
    },
    methods: {
      onTwitterClick() {
        const { entity } = this
        const text = encodeURIComponent(`${itemTitle(entity)} | ${url(entity)} — @artforum`)
        this.openShareWindow(`http://twitter.com/intent/tweet?text=${text}&source=${this.url}`)
      },
      onFacebookClick() {
        const { entity } = this
        const title = encodeURIComponent(itemTitle(entity))
        this.openShareWindow(`http://www.facebook.com/sharer/sharer.php?u=${url(entity)}&t=${title}`)
      },
      openShareWindow(url) {
        window.open(url, '_blank', 'width=550,height=450,resizable=yes')
      }
    }
  }
</script>

<style>
  .guide-share-panel {
    margin-left: -15px;
  }
</style>
