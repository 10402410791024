<template>
    <div>
        <div v-for="(chunk, index) in chunkedImages">
            <div class="instagrid">
                <span v-for="image in chunk">
                    <a v-if="imageIsLinked(image)" target="_blank" :href="image.copyright">
                        <img :srcset="srcset(image, 'square')" :sizes="sizes" :src="image.path_large">
                    </a>
                    <img v-else :srcset="srcset(image, 'square')" :sizes="sizes" :src="image.path_large" class="instagram-image--unlinked">
                </span>
            </div>
            <banner-ad :banner-idx="index"></banner-ad>
        </div>
        <load-more-images :item-id="itemId" :page="this.page" order="DESC"></load-more-images>
    </div>
</template>

<script>
    /**
     * This page is accessible only through the link in the Artforum Instagram bio.
     * It's used to link instagram posts to our or advertisers' content.
     *
     */
    import { mapState, mapActions, mapMutations } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import LoadMoreImages from './LoadMoreImages.vue';
    import _chunk from 'lodash/chunk';
    import BannerAd from '../ads/BannerAd.vue';

    export default {
        props: {
            itemId: {
                type: Number,
                required: true,
            }        
        },

        computed: {
            ...mapState({
                lightboxImages: (state) => state.lightbox.lightboxImages,
                page: (state) => state.lightbox.page,
            }),

            chunkedImages: function(){
                return _chunk(this.lightboxImages, 12);
            }
        },

        methods: {
            ...mapActions('lightbox', ['getArticleImages']),
            ...mapMutations('lightbox', ['lightboxAssign']),
            imageIsLinked: function(image){
                return image.copyright.length > 0;
            }
        },

        mounted: function(){
            this.lightboxAssign({page: 1}),
            this.getArticleImages({articleId: this.itemId, names: [], order: 'DESC', page: this.page})
                .then((images) => { 
                    this.lightboxAssign({lightboxImages: images});
                    this.lightboxAssign({page: this.page + 1});
                });
        },

        mixins: [srcsetFromMedia],

        components: { LoadMoreImages, BannerAd },
    };
</script>

<style lang="scss">
    @import "scss/variables/breakpoints";

    .instagrid{
        margin-top: 2rem;
        margin-bottom: 100px;
        display: grid;
        grid-gap: 1em;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
    }

    .instagrid a{
        line-height: 0px;
    }

    .instagram-image--unlinked{
        opacity: .8;
    }

    @media screen and (max-width: $breakpoint-medium){
        .instagrid{
            grid-gap: .5rem;
            margin-bottom: 60px;
        }
    }
</style>
