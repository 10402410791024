/**
 * Stores cart data for a user who is not logged in
 *
 * This uses local storage to store data until the user logs in
 * at which point it transfers the user's cart through the API to the server
 */

import _pick from   'lodash/pick';
import _values from 'lodash/values';
import _pullAt from 'lodash/pullAt';
import _some from 'lodash/some';
import isArray from 'lib/utils/IsArray';

const cartExistsInLocalStorage = function(){
    return typeof localStorage.getItem('cart') === 'string' && localStorage.getItem('cart').length > 0;
}

const clientCart = {
    namespaced: true,

    state: {
        cart: [],
        errors: []
    },

    mutations: {
        setCart(state, cart) {
            state.cart = cart;
        },
        addError(state, error) {
            state.errors.push(error);
        }
    },

    actions: {
        getCart({ commit }){  //rename to fetchCart so not to be mistaken for a 'getter'?
            const cart = cartExistsInLocalStorage() ? JSON.parse(localStorage.getItem('cart')) : [];
            commit('setCart', cart);
        },

        addToCart({ commit, state, dispatch }, options){
            const cart = cartExistsInLocalStorage() ? JSON.parse(localStorage.getItem('cart')) : [];
            const items = isArray(options) ? options : [options];

            // check for duplicate digital issues
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const digitalDuplicate = _some(cart, item) && item.type == 'digital';
                if ( digitalDuplicate ) {
                    items.splice(i, 1);
                    commit('addError', { 
                        message: 'This digital issue is already in your cart.',
                        itemId: item.itemId,
                        type: item.type 
                    })
                    return true;
                };
            }

            const newCart = cart.concat(items);

            commit('addActiveCall', 'addToCart', {root: true});
            localStorage.setItem('cart', JSON.stringify(newCart));
            commit('setCart', newCart);
            const success = new CustomEvent('addedToCart');
            document.dispatchEvent(success); // this notifies the cart component to do its little animation
            setTimeout(() => {
                commit('removeActiveCall', 'addToCart', {root: true});
            }, 1000)
        },

        removeFromCart({ commit, state }, options){
            commit('addActiveCall', 'removeFromCart', {root: true});
            const cart = JSON.parse(localStorage.getItem('cart'));
            const items = isArray(options) ? options : [options];
            //const indexes = _values(_pick(items, 'itemIndex'));
            const indexes = _map(items, (item) => item.itemIndex);
            _pullAt(cart, indexes); // statefully removes items at selected indices from cart
            
            localStorage.setItem('cart', JSON.stringify(cart));
            commit('setCart', cart);
            commit('removeActiveCall', 'removeFromCart', {root: true});
        },

        updateCart({ commit }, options){
            const cart = JSON.parse(localStorage.getItem('cart'));

            const items = isArray(options) ? options : [options];
            //const indexes = _values(_pick(items, 'itemIndex'));
            for ( let i = 0; i < items.length; i++ ) {
                const idx = items[i].itemIndex;
                cart[idx] = items[i].item;
            }
            cart[options.itemIndex] = options.item;

            localStorage.setItem('cart', JSON.stringify(cart));
            commit('setCart', cart);
        }
    }
}

export default clientCart;