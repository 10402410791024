/**
 * Fetches ads and manages data from the ad server
 */

import { API_PATH } from 'shared/constants/Paths';
import _values from 'lodash/values';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import MODALS from 'shared/constants/Modals';
import shuffle from 'lib/utils/FisherYatesShuffle';
import isArray from 'lib/utils/IsArray';
import toQueryString from 'lib/utils/ToQueryString';

const cleanGalleryAdsResponse = function(galleryAds){
    const mobile = Object.values(galleryAds.batch_mobile).map(Object.values);
    const desktop = Object.values(galleryAds.batch_desktop).map(Object.values);

    return {mobile, desktop};
}

const shiftAds = function(ads){
    if(isArray(ads) && ads.length > 1){
        ads.push(shuffle(ads.shift())); 
    }  
}

const ads = {

    namespaced: true,

    state: {
        leaderboardAd:                 '',
        leaderboardHeight:             0,
        galleryAds:                    {},
        bannerAds:                     [],
        popupAd:                       '',
        popupAdCounter:                0 ,
        exhibitionHighlights:          [],
        minDesktopGalleryAds:          6,
        featuredSponsoredVideos:       [],
        toutAd:                        [],
        issueAds:                      []
    },

    getters: {
        exhibitionHighlights(state){
            const imagesOnly = state.exhibitionHighlights.map( (highlight) => {
                highlight.entity.media = highlight.entity.media.filter((media) => media.mimetype !== 'application/pdf');
                return highlight;
            })

            return imagesOnly.filter((highlight) => highlight.entity.media.length > 0);
        },
    },

    mutations: {
        adsAssign(state, data){
            Object.assign(state, data);
        },

        setPopupAd(state, ad){
            state.popupAd = ad.src;
            state.popupAdUrl = ad.url;
        },

        shiftGalleryAds(state){
            shiftAds(state.galleryAds.desktop);
        },

        issueAdsAssign(state, data){
            Object.assign(state, data);
        },
        addIssueAds(state, data){
            state.issueAds = state.issueAds.concat(data);
        }
    },

    actions: {
        getAds({ commit }, section){
            const params = !(section === undefined) ? `?section=${section}` : '';
            const ADS_URL = API_PATH + '/ads' + params;
            
            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(ADS_URL, {
                headers: headers
            })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res);
                }
                return res.json();
            }).then((ads)=>{
                const leaderboards = ads.hasOwnProperty('970') ? _values(ads['970']) : [];
                const leaderboard  = leaderboards.length > 0 ? leaderboards[Math.floor(leaderboards.length*Math.random())] : '';
                const bannerAdsA    = ads.hasOwnProperty('750') ? _values(ads['750']) : [];
                const bannerAdsB    = ads.hasOwnProperty('750B') ? _values(ads['750B']) : [];
                const bannerAdsC    = ads.hasOwnProperty('750C') ? _values(ads['750C']) : [];
                const bannerAds = bannerAdsA.concat(bannerAdsB, bannerAdsC);

                commit('adsAssign', { leaderboardAd: leaderboard, bannerAds: bannerAds })
            }).catch((err) => {
                console.warn(err);
            });
        },

        getGalleryAds({ commit, state, rootGetters }, section){
            const pageHeight = rootGetters['viewport/pageHeight'];
            
            const adHeight = 110;
            const adPadding = 50;

            const adsFitOnScreen = Math.floor(pageHeight/(adHeight + adPadding));
            const desktopBatchSize = adsFitOnScreen > state.minDesktopGalleryAds ? adsFitOnScreen : state.minDesktopGalleryAds;
            const mobileBatchSize = 4;
            const params = !(section === undefined) ? `?section=${section}&batch_mobile=${mobileBatchSize}&batch_desktop=${desktopBatchSize}` : '';
            const ADS_URL = API_PATH + '/ads/galleries' + params;
            
            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(ADS_URL, {
                headers: headers
            }).then((res) => {  
                if (!res.ok) {
                    throw new Error('could not fetch gallery ads', res);
                }
                return res.json();
            }).then((data)=>{
                const galleryAds = data.hasOwnProperty('110') ? cleanGalleryAdsResponse(data['110']) : [];
                commit('adsAssign', { galleryAds: galleryAds });
                window.setInterval(()=>{
                    commit('shiftGalleryAds')
                }, 10000);
            }).catch((err) => {
                console.log(err);
            });    
        },

        getExhibitionHighlights({ commit }){
            const HIGHLIGHTS_URL = API_PATH + '/ads/buttons';

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(HIGHLIGHTS_URL, {
                headers: headers
            }).then((res) => {
                if(!res.ok) {
                    throw new Error('could not fetch exhibition highlights', res);
                }
                return res.json();
            }).then((data) => {
                commit('adsAssign', { exhibitionHighlights: data._embedded.items })
            })
        },

        getInternalAd({ commit, state }, section){
            const INTERNAL_ADS_URL = `${API_PATH}/ads/internal?section=${section}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(INTERNAL_ADS_URL, {
                headers: headers
            }).then((res) =>{
                if(!res.ok) {
                    throw new Error(res.status);
                }
                return res.json();
            }).then((data) => {
                const popupAd = data.hasOwnProperty('415x415') ? data['415x415'] : '';
                const popupAdSrc = popupAd.hasOwnProperty('src') ? popupAd.src : '';
                const popupAdName = popupAd.hasOwnProperty('name') ? popupAd.name : 'popupAdCounter';

                commit('setPopupAd', popupAd);
                
            }).catch((err) => {
                console.log(err);
            });

        },

        getToutAd({ commit, state }, section){
            const INTERNAL_ADS_URL = `${API_PATH}/ads/tout?section=${section}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(INTERNAL_ADS_URL, {
                headers: headers
            }).then((res) =>{
                if(!res.ok) {
                    throw new Error(res.status);
                }
                return res.json();
            }).then((data) => {
                const toutAd = data.hasOwnProperty('0') ? data['0'] : '';
                commit('adsAssign', { toutAd: toutAd })
                
            }).catch((err) => {
                console.log(err);
            });

        },

        getFeaturedSponsoredVideos({ commit }){
            const PATH = `${API_PATH}/video/featured-sponsored`;

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(PATH, {
                headers: headers
            }).then((res) => {
                if(!res.ok) {
                    throw new Error(res.status);
                }
                return res.json();
            }).then((data) => {
                commit('adsAssign', { featuredSponsoredVideos: data._embedded.items });
            }).catch((err) =>{ 
                console.log(err) 
            });
        },

        // used on the homepage
        getIssueAds({ commit, state }){
            const ACTION_NAME = 'fetchIssueAds';

            const params = { pageSize: 3 };
            if (state.issueAds.length > 0) {
                params.exclude_ids = state.issueAds.map((item) => item.id);
            }
            
            const queryString = toQueryString(params);
            const PATH = `${API_PATH}/ads/issue${queryString}`;

            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) =>{
                commit('removeActiveCall', ACTION_NAME, { root: true });
                if(res.ok){
                    return res.json();
                }
            }).then((data) => {
                commit('addIssueAds', data._embedded.items);
            }).catch((err) => {
                console.error(err);
            })
        },
    }
}

export default ads;