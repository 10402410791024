<template>
  <div :class="containerClass">
    <section class="hours-control__section hours-control__section--days">
      <select :disabled="!enabled" class="form__select" :name="dayFromName" v-model="dayFrom">
        <option v-for="day in days" :value="day">{{ formatDay(day) }}</option>
      </select>
      <div class="hours-control__select-spacer">to</div>
      <select :disabled="!enabled" class="form__select" :name="dayUntilName" v-model="dayUntil">
        <option v-for="day in days" :value="day">{{ formatDay(day) }}</option>
      </select>
    </section>

    <section class="hours-control__section hours-control__section--times">
      <select :disabled="!enabled" class="form__select" :name="hourFromName" v-model="hourFrom">
        <option v-for="hour in hours" :value="hour">{{ formatHour(hour) }}</option>
      </select>
      <div class="hours-control__select-spacer">to</div>
      <select :disabled="!enabled" class="form__select" :name="hourUntilName" v-model="hourUntil">
        <option v-for="hour in hours" :value="hour">{{ formatHour(hour) }}</option>
      </select>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      index: Number, // to determine the select name
      enabled: Boolean
    },
    data() {
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'daily']

      const hours = []
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : i
        hours.push(`${hour}:00:00`)
        hours.push(`${hour}:30:00`)
      }

      return {
        hours,
        days,
        dayFrom: days[0],
        dayUntil: days[0],
        hourFrom: '12:00:00',
        hourUntil: '12:00:00'
      }
    },
    computed: {
      containerClass() {
        return {
          'hours-control': true,
          'hours-control--disabled': !this.enabled
        }
      },
      dayFromName() {
        return `hours_parts[${this.index}][day_from]`
      },
      dayUntilName() {
        return `hours_parts[${this.index}][day_until]`
      },
      hourFromName() {
        return `hours_parts[${this.index}][hour_from]`
      },
      hourUntilName() {
        return `hours_parts[${this.index}][hour_until]`
      }
    },
    methods: {
      formatDay(day) {
        return day === 'daily' ? '- daily -' : day
      },
      formatHour(hour) {
        const [hours, minutes] = hour.split(':')
        const time = (hours % 12 || 12) + (Number(minutes) ? `:${minutes}` : '')
        const label = Number(hours) < 12 ? 'AM' : 'PM'
        return `${time} ${label}`
      }
    },
    watch: {
      // ensure that if one day is selected "daily", the other day matches
      dayFrom(val, oldVal) {
        if (val === 'daily' && this.dayUntil !== 'daily') {
          this.dayUntil = 'daily'
        }

        if (oldVal === 'daily') {
          this.dayUntil = val
        }
      },
      dayUntil(val, oldVal) {
        if (val === 'daily' && this.dayFrom !== 'daily') {
          this.dayFrom = 'daily'
        }

        if (oldVal === 'daily') {
          this.dayFrom = val
        }
      }
    }
  }
</script>

<style lang="scss">
  .hours-control {
    margin: 0 1px 10px 0;
  }

  .hours-control--disabled {
    opacity: 0.5;
  }

  .hours-control__section {
    display: inline-block;
  }

  .hours-control__select-spacer {
    display: inline-block;
    margin: 0 6px;
  }

  .hours-control__section--times {
    margin-left: 20px;
  }

  .hours-control .form__select {
    width: auto;
    margin: 0;
  }
</style>
