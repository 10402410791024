<template>
    
    <svg
      id="audio-player-next"
      data-name="audio-player-next"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.96 15.17">
      <polygon class="cls-1" points="12.14 7.59 0 0 0 15.17 12.14 7.59"/>
      <polygon class="cls-1" points="22.11 7.59 9.97 0 9.97 15.17 22.11 7.59"/>
      <line class="cls-2" x1="22.11" x2="22.11" y2="15.17"/>
    </svg>
  
</template>

<script>
export default {
    props: {
      iconColor: {
        type: String,
        default: '#000000'
      }
    }
}
</script>

<style scoped>
  .cls-1{ fill: v-bind('iconColor');}
  .cls-2{ fill:none; stroke: v-bind('iconColor'); stroke-width:1.7px;}
</style>