<template>

    <input v-if="!formSubmitted" type="submit" :class=buttonclass :value=title @click="checkForm">

    <input v-else type="submit" :class=processingclass :value=title @click="pauseForm">

</template>

<script>
    /**
     * prevent duplicate submissions
     * check for required fields and scroll to first empty field
     */
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: {
            title:
                { type: String, default: 'place your order' },
            buttonclass:
                { type: String, default: 'form__submit' },
            formId:
                { type: String }
        },
        data: function(){
            return {
                incompleteFields: [],
            }
        },

        computed: {
            ...mapState({
                formSubmitted: (state) => state.form.formSubmitted,
                formComplete: (state) => state.form.formComplete,
            })
        },
        methods: {
            ...mapMutations('form', ['markFormSubmitted', 'markFormComplete']),

            checkForm: function(){
                var el = this.isRequiredComplete();
                if (el.length > 0) {
                    let sliced = el.slice(0, 1);
                    this.scrollToIncomplete(sliced[0]);
                }
                this.markFormSubmitted(true);
                setTimeout(function () { this.markFormSubmitted(false) }.bind(this), 3000)
            },

            pauseForm: function(e){
                e.preventDefault();
                alert('Your request is processing, please be patient.');
            },

            scrollToIncomplete: function(elementId) {
                var el = document.getElementById(elementId);
                let newOffset =  el.offsetTop - 150;
                window.scrollTo(0, newOffset);
            },

            isRequiredComplete: function() {
                let allAreFilled = true;
                let el = [];
                document.getElementById(this.formId).querySelectorAll("[required]").forEach(function(i) {
                    if (!i.value) {
                        el.push(i.id);
                        allAreFilled = false;
                        i.classList.add('invoice-form--error');
                    }
                    else {
                        this.removeClass(i);
                    }
                    if (i.type === "radio") {
                        let radioValueCheck = false;
                        document.getElementById(this.formId).querySelectorAll(`[name=${i.name}]`).forEach(function(r) {
                          if (r.checked) radioValueCheck = true;
                        })
                        allAreFilled = radioValueCheck;
                    }
                    if (!allAreFilled) {
                        el.push(i.id);
                    }
                })
                if (allAreFilled) {
                    this.markFormComplete();
                }
                return el;
            }
        },

        removeClass: function(el) {
            const classes = el.classList;
            classes.remove('invoice-form--error');
            el.textContent = classes;
        },

        created(){
            this.processingclass = `${this.buttonclass} submit_processing`;
        },
    };
</script>

<style>

.submit_processing {
    opacity: .3;
}
.invoice-form--error {
    border: 1px solid red;
}

</style>