<template>
    <div class="progress-bar__wrapper">
        <span class="load-state" v-show="show"></span> 
        <div class="progress-bar" v-bind:class="`progress-bar-` + progress"></div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import isArray from 'lib/utils/IsArray';
    import EvaluateField from '../../mixins/EvaluateField';

    export default {
        mixins: [EvaluateField],

        computed: {
            ...mapState({
                condition: (state) => state.subscribe.condition
            }),

            show: function(){

                if(!isArray(this.condition)){
                    return this.testCondition(this.condition);
                }

                this.progress = this.condition.map((condition) => this.testCondition(condition)).reduce((accumulator, value) => accumulator + value);

                if (this.getIsDigital()) {
                    this.progress += 1;
                }

                this.isComplete();
                return this.progress;
            },

            conditionIsArray: function(){
                return isArray(this.condition);
            }
        },
        methods: {
            ...mapGetters('subscriptions', ['getIsDigital']),
            ...mapMutations('subscribe', ['setSectionIncomplete', 'removeSectionIncomplete']),

            parseCondition: function(conditionString){
                return conditionString.split('/');
            },

            testCondition: function(cond){
                var conditionString = cond.path;
                var k = cond.key;
                const parsed = this.parseCondition(conditionString);
                var status = this.$store.state[parsed[0]][parsed[1]];

                if (status) {
                    this.removeSectionIncomplete(k);
                } else {
                    this.setSectionIncomplete(k);
                }

                return status;
            },

            isComplete: function(){
                let isComplete = this.progress >= this.condition.length;
                this.$store.commit('subscribe/markFormComplete', isComplete);
            },
        },
    };
</script>
<style lang="scss">
    @import "scss/variables/colors";

    .progress-bar__wrapper {
        width: 100%;
        background-color: #FFF;
    }
    .progress-bar {
        width: 50%;
        background-color: $active-highlight;
        height: 8px;
    }
    .progress-bar-1 {
        width: 65%;
    }
    .progress-bar-2 {
        width: 75%;
    }
    .progress-bar-3 {
        width: 85%;
    }
    .progress-bar-4 {
        width: 100%;
    }
    .progress-bar-5 {
        width: 100%;
    }
</style>