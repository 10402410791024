<template>
    <div class="article-list" v-if="articles.length > 0">

        <slot></slot>
        <div v-for="(article, index) in articles">
            <div class="slug--single-article" v-if="showSlug">
                <a :href="article.channelPath">{{ article.categoryLabel }}</a>
            </div>
            <news-list-article v-if="article.channel === 'news'" 
                :article="article" 
                preferred-img-crop="square" 
                :desktop-img-size="240"
                :showChannel="mixed"></news-list-article>
            
            <topten-list-article v-else-if="article.channel === 'topten' && category === 'Top Ten'"  
                :article="article" 
                preferred-img-crop="square" 
                :desktop-img-size="240"
                :showChannel="mixed"></topten-list-article>

            <passages-list-article v-else-if="article.channel === 'passages'" 
                :article="article" 
                preferred-img-crop="original" 
                :desktop-img-size="600"
                :showChannel="mixed"></passages-list-article>

            <video-list-article v-else-if="article.type == '80'" 
                :article="article" 
                :current-tag="currentTag"
                preferred-img-crop="original" 
                :desktop-img-size="600"
                :showChannel="mixed"></video-list-article>
            
            <list-article v-else 
                :article="article" 
                preferred-img-crop="landscape" 
                :desktop-img-size="810"
                :showChannel="mixed"></list-article>
            
            <banner-ad 
                v-if="showAd(index, bannerCountOffset, bannerFreq)" 
                :idx="getAdjustedIndex(index)"
                :freq="bannerFreq"
                :bannerIdx="bannerIdx(index)"
            ></banner-ad>
            
            <gallery-ads-mobile 
                v-if="showAd(index, -1, 6)"
                :batch-index = "getGalleryAdsBatchIndex(index, -1)"
            ></gallery-ads-mobile>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import listArticle from './ListArticle.vue';
    import newsListArticle from './NewsListArticle.vue';
    import toptenListArticle from './TopTenListArticle.vue';
    import passagesListArticle from './PassagesListArticle.vue';
    import videoListArticle from './VideoListArticle.vue';
    import bannerAd from '../ads/BannerAd.vue';
    import galleryAdsMobile from '../ads/GalleriesMobile.vue';
    import { ARTICLES_PER_PAGE } from 'shared/constants/Common';
    
    export default {
        data: function(){
            return {
                galleryAdsFrequency: 6,
                galleryAdsPerGroup: 4,
                bannerCountOffset: 0,
            }
        },

        props: {
            currentTag: {
                required: false,
                default: false
            }
        },

        computed: {
            ...mapState({
                articles: state => state.articles.dynamicallyLoadedArticles,
                category: state => state.navigation.category
            }),

            bannerFreq: function(){
                return this.category.toLowerCase() === 'news' ? 4 : 2;
            },

            showSlug: function(){
                return !this.category;
            },

            mixed: function(){
                return !this.articles.map((article) => article.channel).every((val, i, arr) => val === arr[0]);
            }
        },

        methods: {
            /**
             * Add the articles already renedered in twig to the article index
             *
             * @param {Number} index - the article index
             *
             * @return {Number} the index plus the articles already displayed
             */
            getAdjustedIndex: function(index){
                const ARTICLES_ALREADY_DISPLAYED = ARTICLES_PER_PAGE;
                return index + ARTICLES_ALREADY_DISPLAYED;
            },

            /**
             * Decide whether to render a given ad component
             *
             * @param {Number} index - the article index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             * @param {Number} frequency - the frequency with which to show the ad
             *
             * @return {Boolean} - do or don't show the ad
             */
            showAd: function(index, indexOffset, frequency){
                return (index + indexOffset) % frequency === 0;
            },

            /**
             * Determine the offset prop - the index from which to get a slice of the
             * gallery ads for a mobile gallery ad component, accounting for the ads that have
             * already been displayed.
             *
             * @param {Number} index - the current index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             *
             * @return {Number} - the offset
             */
            getGalleryAdsOffset: function(index, indexOffset){
                const ADS_PER_GROUP = this.galleryAdsPerGroup;
                return this.getGalleryAdsBatchIndex(index, indexOffset) * ADS_PER_GROUP;
            },

            /**
             * Determine index of the gallery ads batch to use for mobile, based on the article index,
             *
             * @param {Number} index - the current index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             *
             * @return {Number} - the index of the batch of ads to be shown
             */
            getGalleryAdsBatchIndex: function(index, indexOffset){
                const INDEX_ADJUSTED_OFFSET = this.getAdjustedIndex(index) + indexOffset;
                const ADS_FREQUENCY = this.galleryAdsFrequency;

                return ( INDEX_ADJUSTED_OFFSET / ADS_FREQUENCY );
            },

            /**
             * Figure out what banner we are on based on the loop index. Yuck.
             * 
             * @param {Number} index - the current loop index
             * 
             * @return {Number} - the "index" of the banner
             */
            bannerIdx: function(index){
                const nthAd = (this.getAdjustedIndex(index) + this.bannerCountOffset) / this.bannerFreq;
                if(!Number.isInteger(nthAd)){
                    console.warn(`The banner index at article ${this.getAdjustedIndex(index)} is not an integer, so it is being rounded down. Check your ad display logic`);
                    return Math.floor(nthAd);
                }
                return nthAd;
            },
        },
        components: {
            listArticle: listArticle,
            newsListArticle: newsListArticle,
            bannerAd: bannerAd,
            galleryAdsMobile: galleryAdsMobile,
            toptenListArticle: toptenListArticle,
            passagesListArticle: passagesListArticle,
            videoListArticle: videoListArticle
        }
    };
</script>
<style></style>