<template>
    <div class="lory__wrapper">
        <section class="homepage-carousel__slider homepage-carousel__js_slider lory__slider">
            <div class="homepage-carousel__frame homepage-carousel__js_frame lory__frame">
                <ul class="homepage-carousel__slides homepage-carousel__js_slides lory__slides">
                    <li class="hp-viewing-room__slide homepage-carousel__js_slide lory__slide" v-for="(item, index) in itemsPlus">
                        <carousel-item :article="item" 
                            :desktop-img-size="263" 
                            preferred-img-crop="square" 
                            :index="index"
                            :slides-per-screen="slidesPerScreen" 
                            :slide-index="slideIndex"></carousel-item>
                    </li>
                </ul>
            </div>
        </section>
        <span class="js_prev prev">
            <img class="slider__arrow" src="~img/arrow_black_left_new.svg" alt="previous">
        </span>
        <span class="js_next next">
            <img class="slider__arrow" src="~img/arrow_black_right_new.svg" alt="next">
        </span>
    </div>
</template>

<script>
    /**
     * Homepage viewing-room carousel - adapted from News carousel
     */
    import carouselItem from './CarouselItem.vue';
    import { lory } from 'lory.js';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            items: Array
        },
        data: function(){
            return{
                slideIndex: 0
            }
        },
        computed: {
            ...mapGetters('viewport', ['isSmall']),
            slidesPerScreen: function(){
                return this.isSmall ? 1 : 3;
            },

            itemsPlus: function(){
                const roundedUp = Math.ceil(this.items.length/this.slidesPerScreen) * this.slidesPerScreen;
                if (roundedUp !== this.items.length) {
                    const extraCount = roundedUp - this.items.length;
                    const extras = this.items.slice(0, extraCount);
                    return this.items.concat(extras);
                }
                return this.items;
            }
        },
        methods: {

            onLoryInit: function(){
                this.$el.getElementsByClassName('homepage-carousel__js_slide')[0].style.left = '-5vw'
            },

            setCurrentSlide(e){
                this.slideIndex = e.detail.currentSlide;

            },

            /**
             * to revive: uncomment this.logImpression();
             */
            logImpression: function(){
                let lastSlide = this.slideIndex + this.slidesPerScreen;
                let items = this.itemsPlus.slice(this.slideIndex, lastSlide);

                const promoItems = [];
                items.forEach(item => {
                    let title = item.titleFormatted;
                    title.replace(/(<([^>]+)>)/gi, "");
                    String(title).substr(0, 39);
                    let article = {
                      item_id: item.id,
                      item_name: title,
                      item_category: "spotlight",
                    };
                   promoItems.push(article)
                })
 
                window.gtag("event", "view_promotion", {
                  creative_name: "Spotlight Homepage",
                  creative_slot: "carousel",
                  location_id: "HP_spotlight",
                  promotion_id: "1",
                  promotion_name: "spotlight",
                  items: promoItems
                });

                promoItems.forEach(r => {
                    window.gtag("event",
                        "hpc_spotlight_impression",
                        {
                            item_id : r.item_id,
                            item_name: r.item_name,
                            item_category : r.item_category
                        });
                });

            },
    
            adjustFirstSlide: function(e){
                if( e.detail !== null && e.detail.currentSlide > 1 ){
                    return;
                }
                const offset = e.detail === null || e.detail.currentSlide === 0 ? '-5vw' : '0vw'; 
                this.$el.getElementsByClassName('homepage-carousel__js_slide')[0].style.left = offset;
            },

            initLory: function(isSmall){
                document.addEventListener('after.lory.slide', this.setCurrentSlide);

                if (isSmall) {
                    document.addEventListener('after.lory.init', this.adjustFirstSlide);
                    document.addEventListener('after.lory.slide', this.adjustFirstSlide);
                }
                else{
                    document.removeEventListener('after.lory.init', this.adjustFirstSlide);
                    document.removeEventListener('after.lory.slide', this.adjustFirstSlide);
                    this.$el.getElementsByClassName('homepage-carousel__js_slide')[0].style.left = '0vw';
                }

                const slidesToScroll = this.slidesPerScreen;

                this.$nextTick(function(){
                    lory(this.$el, {
                        classNameFrame: 'homepage-carousel__js_frame',
                        classNameSlideContainer: 'homepage-carousel__js_slides',
                        slidesToScroll: slidesToScroll,
                        rewindOnResize: false
                    });
                });
            }
        },
        watch: {
            isSmall(bool){
                this.initLory(bool);
            },
            slideIndex(oldVal, newVal){
                // this.logImpression();
            }
        },
        mounted: function(){
            this.$nextTick(function(){
                this.initLory(this.isSmall);    
            });
            // this.logImpression();
        },
        components: {carouselItem},
    };
</script>

<style lang="scss">
    
    @import "scss/variables/breakpoints";

    .homepage-carousel__slider .next, .homepage-carousel__slider .prev {
        top: 32%;
    }

    .homepage-carousel__slides{
        display: block;
    }

    .homepage-carousel__more-news-link__text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 100;
        text-transform: uppercase;
        font-size: 1.25rem;
    }

    @media screen and (min-width: $breakpoint-small){

        .hp-viewing-room__slide{
            width: 33%;
            padding: 0 1rem .125rem 1rem;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width: $breakpoint-small){
        .homepage-carousel__frame{
            width: 80vw;
            margin: 0 auto;
            overflow: visible;
        }
    
        .hp-viewing-room__slide{
            width: 80vw;
            padding: 0 2rem 0 0;
        }
    
        .hp-viewing-room__slide:first{    
            position: relative;
        }
    }
</style>