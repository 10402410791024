<template>
	<a class="search-suggestion__link" :href="searchLink">{{item}}</a>
</template>
<script>
	import { SITE_PATH } from 'shared/constants/Paths';
	export default {
		props: ['item'],
		computed: {
			searchLink: function(){
				return `${SITE_PATH}/search?search=${this.item}`;
			}
		}
	};
</script>
<style>
	.search-suggestion__link{
		color: inherit;
	}
</style>