import "core-js/features/object";
import "core-js/features/array";
import "core-js/features/promise";
import "core-js/features/string/split";
import "core-js/features/date";

import Vue from 'vue';
import styles from '../../scss/main.scss';

import App from                      './components/App.vue';
import SlideNavigation from          'shared/components/navigation/SlideNavigation.vue';
import Header from                   './components/Header.vue';
import MoreImagesLink from           './components/article/MoreImagesLink.vue';
import Lightbox from                 './components/article/Lightbox.vue';
import OpenViewingRoomLightboxLink from './components/viewing-room/OpenLightboxLink.vue';
import LoadMoreButton from           './components/listing/LoadMoreButton.vue';
import MoreArticles from             './components/listing/MoreArticles.vue';
import MoreVideos from               './components/listing/MoreVideos.vue';

import MoreContributorArticles from  './components/contributor/MoreContributorArticles.vue';
import LoadMoreContributor from      './components/contributor/LoadMoreContributor.vue';

import LoadMoreSearch from           './components/search/LoadMoreSearch.vue';
import MoreSearch from               './components/search/MoreSearch.vue';

import LoadMorePicks from            './components/picks/LoadMorePicks.vue';
import MorePicks from                './components/picks/MorePicks.vue';

import LoginModal from                './components/forms/LoginModal.vue';
import LoginForm from                 './components/forms/LoginForm.vue';
import LoginOrRegister from           './components/forms/LoginOrRegister.vue';
import SigninSignoutLink from         'shared/components/navigation/SigninSignoutLink.vue';
import RegisterForm from              './components/forms/RegisterForm.vue';
import RegisterFooter from            './components/forms/RegisterFooter.vue';
import PasswordRecovery from          './components/forms/PasswordRecovery.vue';
import Recaptcha from                 './components/forms/ReCaptcha.vue';
import AnimatedFormSection from       './components/forms/animated-form/AnimatedFormSection.vue';
import EvaluateFormSection from       './components/forms/animated-form/EvaluateFormSection.vue';
import HideShiptoSection from         './components/forms/animated-form/HideShiptoSection.vue';
import BillingCompleteButton from     './components/forms/subscribe/BillingCompleteButton.vue';
import DeliveryCountrySelection from  './components/forms/subscribe/DeliveryCountrySelection.vue';
import SubscriptionTypeSelection from './components/forms/subscribe/SubscriptionTypeSelection.vue';
import SubscriptionPlans from         './components/forms/subscribe/SubscriptionPlans.vue';
import SubscriptionReview from        './components/forms/subscribe/SubscriptionReview.vue';
import SubscriptionProgress from      './components/forms/subscribe/SubscriptionProgress.vue';
import SubscriptionGift from          './components/forms/subscribe/SubscriptionGift.vue';
import SubscriptionDiscount from      './components/forms/subscribe/SubscriptionDiscount.vue';
import SubmitButton from              './components/forms/SubmitButton.vue';
import SubmitButtonRequired from      './components/forms/SubmitButtonRequired.vue';
import SubscriptionButton from        './components/forms/SubscriptionButton.vue';
import PrivacyForm from               './components/forms/Privacy.vue';

import ShareCopyLink from            './components/article/ShareCopyLink.vue';
import RelatedArticles from          './components/article/RelatedArticles.vue';
import StickyAnchorLinks from        './components/navigation/StickyAnchorLinks.vue';
import LeaderboardAd from            './components/ads/Leaderboard.vue';
import GalleryAdsDesktop from        './components/ads/GalleriesDesktop.vue';
import GalleryAdsMobile from         './components/ads/GalleriesMobile.vue';
import BannerAd from                 './components/ads/BannerAd.vue';
import Tout from                     './components/ads/Tout.vue';
import ShareToggle from              './components/article/ShareToggle.vue';
import SearchSort from               'shared/components/search/SearchSort.vue';
import PurchaseForm from             './components/cart/PurchaseForm.vue';
import Cart from                     './components/cart/Cart.vue';
import CartItems from                './components/cart/CartItems.vue';
import CartSubtotal from             './components/cart/Subtotal.vue';
import CartShipping from             './components/cart/Shipping.vue';
import CartTotal from                './components/cart/Total.vue';
import ShipToBilling from            './components/cart/ShipToBilling.vue';

import MultiFeatureArticle from      './components/homepage/MultiFeatureArticle.vue';
import MultiFeatureInit from         './components/homepage/MultiFeatureInit.vue';
import MultiVideoArticle from        './components/homepage/MultiVideoArticle.vue';
import MultiVideoInit from           './components/homepage/MultiVideoInit.vue';

import HomepageNews from             './components/homepage/News.vue';
import HomepageMostRead from         './components/homepage/MostRead.vue';
import HomepageBlogList from         './components/homepage/BlogList.vue';
import HomepageBookforum from        './components/homepage/Bookforum.vue';
import HomepageSponsoredVideos from  './components/homepage/SponsoredVideos.vue';
import ExhibitionHighlights from     './components/homepage/ExhibitionHighlights.vue';

import HomepageViewingRoom from      './components/viewing-room/HomepageViewingRoom.vue';
import ViewingRoomLightbox from      './components/viewing-room/ViewingRoomLightbox.vue';
import MfaSpotlightHomepageAd from   './components/mfa-spotlight/HomepageAd.vue';

import LoadMoreViewingRooms from     './components/viewing-room/LoadMoreViewingRooms.vue';
import MoreViewingRooms from         './components/viewing-room/MoreViewingRooms.vue';

import PurchaseCover from            './components/cart/PurchaseCover.vue';
import ModalLink from                './components/links/ModalLink.vue';
import LazyLoadMore from             './components/listing/LazyLoadMore.vue';
import Toggle from                   'shared/components/container/Toggle.vue';
import DotCarousel from              './components/carousel/DotCarousel.vue';
import DotDotDot from                './components/animations/DotDotDot.vue';
import Blinker from                  './components/animations/Blinker.vue';
import PopupAd from                  './components/ads/PopupAd.vue';
import VideoSliderJS from            './components/video/SliderJS.vue';
import Instagram from                './components/instagram/Instagram.vue';
import LazyVideo from                './components/viewing-room/video/Video.vue';

// import DatePicker from               'shared/components/forms/DatePicker.vue';

import ArtguideListing from 'shared/components/artguide/Listing.vue';
import ArtguideMapHeader from './components/artguide/MapHeader.vue';
import ArtguideMyGuideNav from './components/artguide/MyguideNav.vue';
import ArtguidePrintNav from './components/artguide/PrintNav.vue';
import ArtguideMyGuideList from './components/artguide/MyguideList.vue';
import ArtguideNavSelectors from './components/artguide/select/NavSelectors.vue';
import ArtguideParticipateHeader from './components/artguide/ParticipateHeader.vue';
import ArtguidePlaceSelector from './components/artguide/select/PlaceSelector.vue';
import ArtguideApp from './components/artguide/ArtguideApp.vue';
import ArtguideSearch from './components/artguide/Search.vue';
import ArtguideSearchPager from './components/artguide/SearchPager.vue';
import ArtguideApiDrivenPage from './components/artguide/ApiDrivenPage.vue';
import ArtguideVenue from './components/artguide/Venue.vue';
import ArtguideVenueHoursInput from './components/artguide/VenueHoursInput.vue';

import FlipBook from './components/image/FlipBook.vue';
import Slideshow from './components/mfa-spotlight/Slideshow.vue';

import store from './store/index.js';
import trackFocus from 'lib/utils/TrackFocus';

/* miscellaneous */
import setSelectFromQueryParam from 'shared/misc/setSelectFromQueryParam'; // temporary
import addUrlParam from 'lib/utils/AddUrlParam';

import PaywallCounter from           './components/paywall/Counter.vue';
import PaywallWall from              './components/paywall/Wall.vue';

import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards);

import AudioPlayer from '@liripeng/vue-audio-player';
Vue.use(AudioPlayer);
import ArtforumAudioPlayer from 'shared/components/audio/PlayerContainer.vue';
import ArtforumAudioPlayerBase from 'shared/components/audio/PlayerBase.vue';

/* App container */
Vue.component('af-app', App);


/* Navigation */
Vue.component('af-sticky-anchor-links', StickyAnchorLinks);
Vue.component('af-slide-navigation', SlideNavigation);
Vue.component('af-header', Header);

/* Lightbox and images */
Vue.component('af-more-images-link', MoreImagesLink);
Vue.component('af-lightbox', Lightbox);
Vue.component('af-open-viewing-room-lightbox-link', OpenViewingRoomLightboxLink);

/* Article listings */
Vue.component('af-load-more-button', LoadMoreButton);
Vue.component('af-lazy-load-more', LazyLoadMore);
Vue.component('af-more-articles', MoreArticles);
Vue.component('af-more-videos', MoreVideos);

Vue.component('af-more-by-contributor', MoreContributorArticles);
Vue.component('af-load-more-contributor', LoadMoreContributor);

Vue.component('af-more-search', MoreSearch);
Vue.component('af-load-more-search', LoadMoreSearch);

Vue.component('af-more-picks', MorePicks);
Vue.component('af-load-more-picks', LoadMorePicks);

/* Forms */
Vue.component('af-login-modal', LoginModal);
Vue.component('af-login-form', LoginForm);
Vue.component('af-login-or-register', LoginOrRegister);
Vue.component('af-signin-signout-link', SigninSignoutLink);
Vue.component('af-related-articles', RelatedArticles);
Vue.component('af-register-form', RegisterForm);
Vue.component('af-register-form-footer', RegisterFooter);
Vue.component('af-password-recovery-form', PasswordRecovery);
Vue.component('af-recaptcha', Recaptcha);
Vue.component('af-date-picker', () => import(/* webpackChunkName: "DatePicker" */ 'shared/components/forms/DatePicker.vue'));
Vue.component('af-animated-form-section', AnimatedFormSection);
Vue.component('af-evaluate-form-section', EvaluateFormSection);
Vue.component('af-hide-shipto-section', HideShiptoSection);
Vue.component('af-billing-complete-button', BillingCompleteButton);
Vue.component('af-delivery-country-selection', DeliveryCountrySelection);
Vue.component('af-subscription-type-selection', SubscriptionTypeSelection);
Vue.component('af-subscription-plans', SubscriptionPlans);
Vue.component('af-subscription-progress', SubscriptionProgress);
Vue.component('af-subscription-review', SubscriptionReview);
Vue.component('af-subscription-gift', SubscriptionGift);
Vue.component('af-subscription-discount', SubscriptionDiscount);
Vue.component('af-submit-button', SubmitButton);
Vue.component('af-submit-button-required', SubmitButtonRequired);
Vue.component('af-subscription-button', SubscriptionButton);

/* Ads */
Vue.component('af-leaderboard-ad', LeaderboardAd);
Vue.component('af-gallery-ads-desktop', GalleryAdsDesktop);
Vue.component('af-gallery-ads-mobile', GalleryAdsMobile);
Vue.component('af-banner-ad', BannerAd);
Vue.component('af-tout', Tout);
Vue.component('af-popup-ad', PopupAd);
Vue.component('af-instagram', Instagram);


Vue.component('af-share-toggle', ShareToggle);
Vue.component('af-search-sort', SearchSort);
Vue.component('af-share-copy-link', ShareCopyLink);

/* Cart */
Vue.component('af-purchase-cover', PurchaseCover);
Vue.component('af-purchase-form', PurchaseForm);
Vue.component('af-cart', Cart);
Vue.component('af-cart-items', CartItems);
Vue.component('af-cart-subtotal', CartSubtotal);
Vue.component('af-cart-shipping', CartShipping);
Vue.component('af-cart-total', CartTotal);
Vue.component('af-ship-to-billing', ShipToBilling);

/* Homepage */
Vue.component('af-multi-feature-article', MultiFeatureArticle);
Vue.component('af-multi-feature-init', MultiFeatureInit);

Vue.component('af-multi-video-article', MultiVideoArticle);
Vue.component('af-multi-video-init', MultiVideoInit);

Vue.component('af-homepage-news', HomepageNews);
Vue.component('af-homepage-most-read', HomepageMostRead);
Vue.component('af-homepage-bloglist', HomepageBlogList);
Vue.component('af-homepage-bookforum', HomepageBookforum);
Vue.component('af-exhibition-highlights', ExhibitionHighlights);
Vue.component('af-homepage-sponsored-videos', HomepageSponsoredVideos);
Vue.component('af-homepage-viewing-room', HomepageViewingRoom);
Vue.component('af-mfa-spotlight-homepage-ad', MfaSpotlightHomepageAd);

/* Viewing Room aka Spotlicht */
Vue.component('af-load-more-viewing-rooms', LoadMoreViewingRooms);
Vue.component('af-more-viewing-rooms', MoreViewingRooms);
Vue.component('af-viewing-room-lightbox', ViewingRoomLightbox);

/* Animations */
Vue.component('af-dot-dot-dot', DotDotDot);
Vue.component('af-blinker', Blinker);

/* Misc */
Vue.component('af-toggle', Toggle);
Vue.component('af-dot-carousel', DotCarousel);
Vue.component('af-video-slider-js', VideoSliderJS);
Vue.component('af-modal-link', ModalLink);
Vue.component('af-flip-book', FlipBook);
Vue.component('af-slideshow', Slideshow);
Vue.component('af-lazy-video', LazyVideo);
Vue.component('af-audio-player', ArtforumAudioPlayer);
Vue.component('af-audio-player-base', ArtforumAudioPlayerBase);

/* Artguide */
Vue.component('af-artguide-listing', ArtguideListing);
Vue.component('af-artguide-header',  ArtguideMapHeader);
Vue.component('af-artguide-myguide-nav',  ArtguideMyGuideNav);
Vue.component('af-artguide-print-nav',  ArtguidePrintNav);
Vue.component('af-artguide-myguide-list',  ArtguideMyGuideList);
Vue.component('af-artguide-nav-selectors',  ArtguideNavSelectors);
Vue.component('af-artguide-participate-header',  ArtguideParticipateHeader);
Vue.component('af-artguide-place-selector', ArtguidePlaceSelector);
Vue.component('af-artguide-app', ArtguideApp);
Vue.component('af-artguide-search',  ArtguideSearch);
Vue.component('af-artguide-search-pager',  ArtguideSearchPager);
Vue.component('af-artguide-api-driven-page',  ArtguideApiDrivenPage);
Vue.component('af-artguide-venue',  ArtguideVenue);
Vue.component('af-artguide-hours-input', ArtguideVenueHoursInput);

Vue.component('af-paywall-counter', PaywallCounter);
Vue.component('af-paywall-wall', PaywallWall);

Vue.config.devtools = true;

function populateLightboxImages(articleId){
    store.dispatch('getArticleImages', articleId);
}

function recaptchaOnloadCallback(){
    store.commit('reCaptcha/recaptchaLoaded');
}

function subscriptionTermSelectCallback(val){
    history.pushState(window.history.state, '', addUrlParam(window.location.search, 'orderTerm', val));
    const selected = val !== '';
    store.commit('subscribe/termSelected', selected);
    store.commit('subscribe/termSelectedVal', val);
}

function checkOrderTermSelect(){
    const $ots = document.getElementById('order_term');
    if(!$ots){
        return;
    }
    const val = $ots.value;
    const selected = val !== '';
    store.commit('subscribe/termSelected', selected);
}

function handleOrderTerm(){
    setSelectFromQueryParam('order_term', 'orderTerm', false);
    checkOrderTermSelect();
}

window.subscriptionTermSelectCallback = subscriptionTermSelectCallback;

window.populateLightboxImages = populateLightboxImages;
window.recaptchaOnloadCallback = recaptchaOnloadCallback;
window.addUrlParam = addUrlParam;
window.handleOrderTerm = handleOrderTerm;

(function(){

    const app = new Vue({
        el: '#container',
        store: store,

        directives: { VueClipboards },
        data() {
            return {
                copyData: 'copy data'
            }
        }
    });

    // for(let i = 0; i < vueElements.length; i++){

    //     let els = document.getElementsByTagName(vueElements[i]);
    //     /** 
    //      * using a while loop here because transforming a vue element
    //      * into a Vue component removes it from the HTMLCollection in real time,
    //      * so looping through the elements reduces els.length to 0
    //      */
    //     while(els.length > 0){
    //         let el = els.item(0);
    //         try{
    //             const app = new Vue({
    //                 el: el,
    //                 store: store,
    //             });
    //         }
    //         catch(e){
    //             console.warn(e);
    //             el.parentNode.removeChild(el); // avoid bad infinity
    //         }
    //     }
    // }

    // this disables outlining for clicked focusable elements
    // while leaving focus styles intact for tab navigation
    document.body.classList.add('focus--mouse');
    trackFocus(document.body);

    // load recaptcha - necessary to load via js so the callback is available on load
    var recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit';
    document.body.appendChild(recaptchaScript);
})();
