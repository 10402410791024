<template>
  <div class="venue-hours">
    <label class="venue-hours__label">opening hours:</label>
    <div class="venue-hours__inputs">
      <div v-for="hourIndex in hourIndices" class="venue-hours__hours">
        <HoursControl :index="hourIndex" :enabled="!byAppointmentOnly" />
      </div>
      <div class="venue-hours__inputs__modifiers">
        <div class="form__checkbox-row">
          <div class="checkbox-container">
            <input type="checkbox" value="By appointment only" name="hours" id="appointment-only-input" v-model="byAppointmentOnly" class="form__checkbox" />
            <label for="appointment-only-input"><span></span></label>
          </div>
          <div>by appointment only</div>
        </div>
        
        <button class="venue-hours__add-another-date" @click.prevent="addAnotherDate">
          + add another date
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import HoursControl from './HoursControl.vue'

  export default {
    components: { HoursControl },
    data() {
      return {
        hourIndices: [0],
        byAppointmentOnly: false
      }
    },
    computed: {
 
    },
    methods: {
      addAnotherDate() {
        this.hourIndices.push(this.hourIndices.length)
      }
    }
  };
</script>

<style>
  .venue-hours {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
  }

  .venue-hours__label {
    margin-top: 28px;
  }

  .venue-hours__inputs__modifiers {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .venue-hours__add-another-date {
    background: none;
  }
</style>
