export default {
  methods: {
    onNavKeydown(ev) {
      switch (ev.which) {
        case 13:
          this.onEnterPress(ev)
          break
        case 27:
          this.onEscPress(ev)
          break
        case 37:
          this.onArrowPress('left', ev)
          break
        case 38:
          this.onArrowPress('up', ev)
          break
        case 39:
          this.onArrowPress('right', ev)
          break
        case 40:
          this.onArrowPress('down', ev)
          break
      }
    },
    onEnterPress() {},
    onEscPress() {},
    onArrowPress(arrow) {}
  }
}
