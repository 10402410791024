<template>
	<div class="related-articles__slider lory__slider related-articles__js_simple_dots related-articles__js_slider">
		<div class="related-articles__frame lory__frame related-articles__js_frame">
			<ul class="related-articles__slides lory__slides related-article__js_slides">
				<li class="related-articles__slide lory__slide related-articles__js_slide" v-for="article in articles">
					<related-article preferred-img-crop="landscape" :desktop-img-size="336" :article="article"></related-article>
				</li>
			</ul>
		</div>
		<span class="js_prev prev">
      		<img class="slider__arrow" src="~img/arrow_black_left.svg">
  		</span>
  		<span class="js_next next">
  		    <img class="slider__arrow" src="~img/arrow_black_right.svg">
  		</span>
	</div>
</template>
<script>
	/**
	 * This is used if there are more than 3 related articles
	 */
	import RelatedArticle from './RelatedArticle.vue';
	import { lory } from 'lory.js';

	export default {
		name: 'desktop-carousel',
		props: ['articles'],
		mounted: function(){
			this.$nextTick(function(){
				lory(this.$el, {
					classNameFrame: 'related-articles__js_frame',
					classNameSlideContainer: 'related-article__js_slides',
					slidesToScroll: 1
				});
			});
		},
		components: {
			'related-article': RelatedArticle
		}
	};
</script>
<style lang="scss">
	@import "scss/variables/typography";
	
	.related-articles__slides {
	    margin:1rem 0 1rem 0;
	    display: block;
	}
	
	.related-articles__slide {
	    text-align: center;
	    width: calc(33.3333333% - 1.33333333333rem);
	    // padding: 0 1.5% 0 1.5%;
	    padding-right: 2rem;
	    &:last-child{
	    	padding-right: 0px;
	    }
	}
	
	.related-articles__slider .prev{
	    position: absolute;
	    top: 31% !important;
	}
	
	.related-articles__slider .next{
	    position: absolute;
	    top: 31% !important;
	}
	
	.related-articles__slider .prev, .related-articles__slider .next{
	    display: none;
	}
	
	@media screen and (min-width: 840px){
	    .related-articles__slider .prev, .related-articles__slider .next{
	        display: inline-block;
	    }
	}
</style>