<template>
	<div class="sticky-navigation">
		<div class="sticky-navigation__internal-links" id="tocSectionNav">
    	    <a v-for="section in sections" :class="[{'sticky-navigation__internal-link--active': activeSection == section.name}, 'sticky-navigation__internal-link', linkClass]" :href="'#' + section.name">{{section.text}}</a>
    	</div>
    </div>
</template>
<script>
/**
 * sticky anchor links that highlight when you are scrolled within the current section
 * The links are generated from anchor tags matching a class.
 * The anchor tags should have a name property and an id matching the pattern {prefix}-{name value}
 */
	import { mapState } from 'vuex';
	export default {
		props: {
			reactive: {
				type: Boolean,
				default: true
			},
			linkClass: {
				type: String,
				required: false,
				default: ''
			}
		},
		data(){
			return {
				activeSection: ''
			}
		},
		computed: {
			...mapState('viewport', ['scrollY']),
			sections: () => {
				// TODO: move this function to library? used to call array map on DOMCollection
				const map = f => x => Array.prototype.map.call(x, f);
				const links = document.querySelectorAll('.anchor-link');
				return map((link) => {
					const id   = link.id;
					const name = link.id.split('-')[1];
					const offsetTop = link.offsetTop;
					const text = link.textContent;
					return { id, name, offsetTop, text };
				})(links);
			}
		},
		watch: {
			scrollY: function(scroll){
				if(!this.reactive){
					return;
				}
				let greatest = this.sections.reduce( ( greatest, current ) => {
					if ( ( scroll >= current.offsetTop - 10 ) && ( current.offsetTop > greatest.offsetTop )){
						return current;
					}
					return greatest;
				}, { name: '', offsetTop: 0 });
				this.activeSection = greatest.name;
			}
		},
		mounted: function(){
			const updateAnchorPositions = () => {
				for(let i = 0; i < this.sections.length; i++){
					let section = this.sections[i];
					// unfortunately, if the DOM nodes saved in local state, their offsets return 0
					let newOffset = document.getElementById(section.id).offsetTop
					if(newOffset !== section.offsetTop){
						section.offsetTop = newOffset;
					}
				}
				keepAnchorPositionsUpdated();
			}
			function keepAnchorPositionsUpdated(){
				window.requestAnimationFrame(updateAnchorPositions);
			}
			if(this.reactive){
				keepAnchorPositionsUpdated();				
			}
		}
	};
</script>
<style lang="scss">
@import "scss/variables/issue";
@import "scss/variables/colors";
@import "scss/variables/typography";
@import "scss/variables/grid";
@import "scss/mixins";
	.sticky-navigation__internal-link{
		text-transform: uppercase;
		display: block;
		margin-bottom: .25em;
		color: #000;
		font-weight: $book;
	}
	
	.sticky-navigation__internal-link--active{
		color: $active-highlight;
	}

	.sticky-navigation__internal-link--small{
		font-size: 1.125rem;
	}
	
	.sticky-navigation__internal-link--current{
		color: $issue-color;
	}
	@media (max-width: $breakpoint-medium){
		.sticky-navigation{
			display: flex;
			flex-direction: column-reverse;
		}
		.left-navigation__navigation > .sticky-navigation{
			margin-top: $slug-margin;
		}
		.sticky-navigation__internal-links{
			display: flex;
			margin-bottom: 2rem;
			justify-content: space-between;
		}
		.sticky-navigation--no-flex .sticky-navigation__internal-links{
			display: block;
		}
	}
	@media (min-width: $breakpoint-medium){
		.sticky-navigation{
			position: -webkit-sticky;
			position: sticky;
			top: $sticky-position-top;
		}
		.sticky-navigation__internal-links{
			margin-bottom: 2rem;
			margin-top: 1.375rem;
			font-size: 1.5rem;
		}
	}
</style>