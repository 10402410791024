<template>
    <transition-group name="drawer-reverse">

        <div class="paywall-wall" key="paywall-wall" v-show="true">
            <div class="wrapper">

                <div class="desktop-flex">
                    <div class="print-navigation-column">
                        <div class="paywall-toggle__link-mobile">
                            <table style="width:100%">
                              <tr>
                              <td style="text-align:left !important;">
                                  <a :href="toclink">
                                      <img src="/static-assets/arrow_black_left.svg" alt="back"> Back
                                  </a>
                              </td>
                              <td style="text-align:right">

                                    <span v-if="userIsLoggedIn">
                                        Subscriber? <a :href="activateLink" class="paywall-underline">Activate your access</a>.
                                    </span>

                                    <span v-else >
                                        Subscriber? Login <a href="#" v-on:click.prevent="openSigninForm" class="paywall-underline">here</a>.
                                    </span>
                              </td>
                              </tr>
                            </table>
                        </div>
                    </div>

                    <div class="paywall-column">

                        <h1 class="paywall-wall__bold">
                        You have reached your limit of complimentary articles for the month.
                        </h1>

                        <div class="desktop-flex">
                            <div class="paywall-image-container">
                                <a :href="subscribeLink">
                                    <img :src="img" width="100%">
                                </a>
                            </div>
                            <div class="paywall-text-container">
                                <p>
                                <span class="paywall-wall__bold"><a :href="subscribeLink">SUBSCRIBE starting at $50 a year</a>
                                </span>
                                  <a :href="subscribeLink">
                                  Choose your subscription&#8212;either print or digital&#8212;and get immediate access to all online content, including the archive.
                                  </a>
                                </p>
                                <p>
                                <span class="paywall-wall__bold"><a :href="singleLink('digital')">DIGITAL ISSUE $5.99</a>
                                </span>
                                  <a :href="singleLink('digital')">
                                    Immediate online access to this issue.
                                  </a>
                                </p>
                                <p>
                                <span class="paywall-wall__bold"><a :href="singleLink('print')">BACK ISSUES STARTING AT $15</a>
                                </span>
                                  <a :href="singleLink('print')">
                                    A single copy of the print issue, mailed to you.
                                  </a>
                                </p>
                            </div>
                        </div>

                    </div>

                    <div class="paywall-navigation-column">
                        <p class="paywall-toggle__link">

                            <span v-if="userIsLoggedIn">
                                Subscriber? <a :href="activateLink" class="paywall-login">Activate your access</a>.
                            </span>
                            <span v-else>
                                Subscriber? Login <a href="#" v-on:click.prevent="openSigninForm" class="paywall-login">here</a>.
                            </span>

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </transition-group>
</template>

<script>
    import { SITE_PATH } from 'shared/constants/Paths';
    import { mapState, mapMutations } from 'vuex';
    import MODALS from 'shared/constants/Modals';

    export default {
        props: {
            img: {
                type: String,
                required: false,
                default: false,
            },
            purchaselink: {
                type: String,
                required: false
            },
            toclink: {
                type: String,
                required: false
            }
        },

        data(){
            return {
                minimized: false
            }
        },

        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn
            }),
            subscribeLink: function(){
                return `${SITE_PATH}/subscribe?tid=pws1&utm_campaign=pw_wall&utm_medium=web&utm_source=print`;
            },
            activateLink: function(){
                return `${SITE_PATH}/profile/activate`;
            }
        },

        methods: {
            ...mapMutations(['openModal']),

            openSigninForm: function(){
                this.openModal(MODALS.LOGIN_FORM);
                this.resetMessages();
            },
            singleLink: function(format){
                return `${SITE_PATH}${this.purchaselink}?format=${format}`;
            }
        }
    };
</script>

<style lang="scss">
    @import "scss/transitions/drawer-reverse";
    @import "scss/variables/breakpoints";
    @import "scss/variables/colors";

    .paywall-wall{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        background: #BFF190;
        color: #000;
        z-index: 1;
        min-height: 40vh;
        line-height: 1.2em;
    }

    .paywall-wall a {
        color: #000;
        border-bottom: none;
    }
    a.paywall-login {
        border-bottom: solid 1px #000;
    }
    .paywall-wall p {
        padding: 0 0 20px 0;
        margin: 0;
        line-height: 1.3em;
        font-size: 1.2rem;
        font-weight: 500 !important;
    }
    h1.paywall-wall__bold {
        padding: .8rem 0;
        display: block;
        font-size: 1.3rem;
    }
    span.paywall-wall__bold {
        display: block;
        font-size: 1.2rem;
        font-weight: 700;
    }
    .paywall-wall__text {
        text-align: left !important;
    }
    p.paywall-toggle__link {
        margin-left: 1rem;
        font-size: 83%;
        padding: .5rem 0;
    }
    .paywall-toggle__link-mobile {
        display: none;
    }
    .paywall-text-container {
        width: 60%;
    }
    .paywall-image-container {
        margin: 0 30px 30px 0;
        width: 35%;
        float: left;
        display: inline;
        filter: drop-shadow(10px 10px 5px #88A61C);
    }
    paywall-image-container img {
        width: 100%;
    }


    @media screen and (max-width: $breakpoint-medium){
        .paywall-wall {
            text-align: center;
        }
        .paywall-text-container {
            width: 100%;
        }

        .paywall-image-container {
            display: block;
            width: 100%;
            filter: none;
            margin-right: 0;
        }
        .paywall-image-container img {
            width: 65%;
            margin: 0 auto !important;
            padding: 0;
        }

        .paywall-toggle__link {
            display: none;
        }
        .paywall-toggle__link-mobile {
            display: block;
            font-size: 14px;
            padding-top: .75rem;
        }
        .paywall-toggle__link-mobile a.paywall-underline {
            border-bottom: solid 1px #000;
        }
        .paywall-toggle__link-mobile img {
            height: 10px;
        }
        h2.paywall-wall__bold {
            font-size: 12px;
            line-height: 13px;
        }
        .paywall-wall p {
            padding: 0 10px 25px 10px;
        }
    }
</style>