<template>
    <div>
        <form v-if="available">
            <p class="form__message--error" v-if="errors.length > 0">{{ errors[errors.length - 1].message }}</p>
            <div class="styled-select styled-select--full-width">
                <select v-model="type">
                    <option class="purchase-form__format-option" 
                        v-for="(format, key) in formats" 
                        v-if="format.instock > 0" 
                        :value="key">
                        {{ format.label.toUpperCase() }} ${{ format.price }}</option>
                </select>
            </div>
            <submit-button class="form__submit form__submit--purchase form__submit--centered" :callback="submit" associatedAction="addToCart" buttonText="Add To Cart"></submit-button>
        </form>
        <p v-if="!available">We're sorry, this issue is currently unavailable for purchase.</p>
    </div>
</template>
<script>
    /**
     * Form to add an issue to your cart
     */
    import submitButton from 'shared/components/forms/SubmitButton.vue';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import _findKey from 'lodash/findKey';
    import getQueryParameterByName from 'lib/utils/GetQueryParameterByName';

    export default {
        props: {
            itemId: Number,
            formatsString: String
        },
        data(){
            return {
                type: '',
                quantity: 1
            }
        },

        computed: {
            ...mapGetters({ errors: 'cartErrors' }),

            formats: function(){
                return JSON.parse(this.formatsString);
            },
            available: function() {
                return this.formats.print.instock > 0 || this.formats.digital.instock > 0 || this.formats.product.instock > 0;
            }
        },

        methods: {
            ...mapActions(['addToCart']),
            updateFormat: function(event){
                this.type = event.target.value;
            },
            submit: function(){
                const options = {
                    itemId: this.itemId,
                    type: this.type,
                    quantity: 1
                };

                this.addToCart(options);
            }
        },

        mounted: function(){
            const firstAvailable = _findKey(this.formats, (format) => format.instock > 0);
            const fromUrl = getQueryParameterByName('format');
            this.type = fromUrl ? fromUrl : firstAvailable;
        },

        components: {
            'submit-button': submitButton
        }
    };
</script>

<style>
    .purchase-form__format-option{
        text-transform: uppercase;
    }

    .form__submit--purchase{
        margin-top: 2.5rem;
        margin-bottom: 3.5rem;
    }
</style>