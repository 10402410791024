<template>
	<div class="video-category-listing">
		<video-article v-for="article in articles" 
			:article="article" 
			:key="article.id" 
			preferred-img-crop="landscape" 
			:desktop-img-size="270"></video-article>
	</div>
</template>

<script>
	import videoArticle from './VideoArticle.vue';
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState({
				articles: state => state.articles.dynamicallyLoadedArticles
			})
		},

		components: {
			videoArticle
		}
	};
</script>
