<template>
    <div class="hp-sponsored-video">
        <div>
            <div class="image-container image-container--hp-sponsored-video">
                <a :href="currentLink">
                    <img :srcset="currentSrcset" :src="currentSrc" :title="currentTitle">
                </a>
            </div>
        </div>
        <div class="hp-sponsored-video__info">
            <div class="hp-sponsored-video__category" v-if="hasVideos">VIDEO<span v-if="currentSource">: {{currentSource}}</span></div>
            <h3 class="hp-sponsored-video__title"><a :href="currentLink" v-html="currentTitle"></a></h3>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import NAVIGATION from 'shared/constants/Navigation';
    import srcSetFromMedia from 'shared/components/mixins/SrcsetFromMedia';

    export default {
        /**
         * This component selects a weighted random video from an array
         * 
         * It does this by calculating the cumulative distribution function for the probability weights of the video array,
         * selecting a random number bewteen 0 and the sum of the weights, and doing a binary search for the weight range.
         * Yes, binary search is overkill.
         */
        mixins: [srcSetFromMedia],

        data: function(){
            return {
                // index: 0,
                random: 0,
            }
        },
        computed: {
            ...mapState({
                featuredSponsoredVideos: (state) => state.ads.featuredSponsoredVideos,
            }),

            hasVideos: function(){
                return this.featuredSponsoredVideos.length > 0;
            },

            /**
             * @return {Array} the cumulative distribution function for each element
             * cumulative distribution function - https://en.wikipedia.org/wiki/Cumulative_distribution_function
             */
            cdf: function(){
                return this.weights.map((current, idx, arr) => {
                    const prevSum = idx > 0 ? arr.slice(0, idx).reduce((acc, curr) => acc + curr) : 0;
                    return parseInt(prevSum);
                })
            },

            /**
             * gives a weighted random index in the array of videos
             */
            index: function(){
                return this.cdf.length ? this.binarySearch(this.cdf, this.random) : 0;
            },

            weights: function(){
                return this.featuredSponsoredVideos.map((item) => parseInt(item.probabilityweight));
            },

            probabilityTotal: function(){
                var pt = this.weights.reduce((acc, curr) => acc + parseInt(curr));
                return pt;
            },

            currentSrcset: function(){
                return this.hasVideos ? this.srcset(this.featuredSponsoredVideos[this.index].primaryImage, 'square') : '';
            },

            currentSrc: function(){
                return this.hasVideos ? this.featuredSponsoredVideos[this.index].primaryImage.path_large : '';
            },

            titleSplit: function(){
                return this.featuredSponsoredVideos.length > 0 ? this.featuredSponsoredVideos[this.index].titleFormatted.split(/(From .*):/) : '';
            },

            currentTitle: function(){
                return this.titleSplit.length === 3 ? this.titleSplit[2] : this.titleSplit[0];
            },

            currentSource: function(){
                return this.titleSplit.length === 3 ? this.titleSplit[1] : null;
            },

            currentLink: function(){
                return this.hasVideos ? this.featuredSponsoredVideos[this.index].url : ''; 
            },
        },

        methods: {
            ...mapActions('ads', ['getFeaturedSponsoredVideos']),

            getRandom: function(){
                return Math.floor(Math.random() * this.probabilityTotal);
            },

            binarySearch: function(haystack, needle){
                const length = haystack.length;
                const midPoint = Math.floor(length / 2);
                const val = haystack[midPoint];
                const nextVal = haystack[midPoint + 1];

                if( needle >= val && ((needle < nextVal) || typeof nextVal === 'undefined') ){
                    return midPoint;
                }

                if(needle < val){
                    // search lower
                    const newHaystack = haystack.slice(0, midPoint);
                    return this.binarySearch(newHaystack, needle);
                }

                if(needle > val){
                    // search higher
                    const newHaystack = haystack.slice(midPoint, length);
                    return midPoint + this.binarySearch(newHaystack, needle);
                }
            }
        },

        mounted: function(){
            this.getFeaturedSponsoredVideos();

            // update the random number every 8 seconds
            setInterval( ()=> {
                this.random = this.getRandom();
            }, 8000);
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/typography";
    @import "scss/variables/breakpoints";

    .hp-sponsored-video{
        flex-basis: 30%;
        flex-grow: 0;
        overflow: visible;
        background: #f6f6f6;
    }

    .image-container--hp-sponsored-video{
        padding-bottom: 108.5%;
    }

    .hp-sponsored-video__info{
        padding-top: 1rem;
        // padding-bottom: 29%;
    }

    .hp-sponsored-video__title{
        font-size: 1.75rem;
        font-weight: $medium;
        margin-top: 0;
        line-height: 2rem;
        margin-top: -.25rem;
        padding: 0px 1rem;
    }

    .hp-sponsored-video__category{
        // margin-top: 1rem;
        margin-bottom: .5rem;
        text-transform: uppercase;
        padding: 0px 1rem;
    }

    .exhibition-highlights::after{
        content: 'FROM SELECT ADVERTISERS';
        font-weight: $book;
        display: block;
        text-align: center;
        background: #f5f5f5;
        padding-top: .5rem;
        padding-bottom: .25rem;
    }

    @media screen and ( min-width: $breakpoint-medium ){
        .exhibition-highlights{
            width: 30%;
        }

        .hp-sponsored-video{
            // max-height: 330px;
        }
    }

    @media screen and ( max-width: $breakpoint-medium ){
        .hp-sponsored-video{
            height: 0px;
            padding-bottom: 130%;
        }

        .hp-sponsored-video__info{
            padding-bottom: 20%;
            height: 0px;
        }
    }
</style>