<template>
    <ul class="viewing-room-listing__items">
        <li v-for="item in viewingRooms" class="viewing-room-listing__item">
            <a :href="item.url">
                <img v-if="hasMedia(item)" :srcset="srcset(item.primaryImage, 'square')" :sizes="sizes">
                <div class="viewing-room-listing__artists" v-html="item.subtitleFormatted"></div>
                <div class="viewing-room-listing__exhibition-title" v-html="item.leadFormatted"></div>
                <div class="viewing-room-listing__venue" v-html="item.titleFormatted"></div>
                <div class="viewing-room-listing__locale">{{ item.section }}</div>
            </a>
        </li>
    </ul>
</template>

<script>
    import { mapState } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    export default {
        mixins: [srcsetFromMedia],
        computed: {
            ...mapState({
                viewingRooms: (state) => state.viewingRoom.viewingRooms,
            }),
        },

        methods: {
          hasMedia: function(item){
            return item.hasOwnProperty('primaryImage');
          }  
        }
    };
</script>