import localConfig from '../../../localConfig';

/* local configs*/
const SITE_URL = localConfig.baseUrl;
const WEBROOT = localConfig.webroot;
const IMAGE_BASE_PATH = localConfig.imageBasePath;

/* public website paths */
const SITE_PATH = SITE_URL + WEBROOT;
const API_PATH = SITE_URL + WEBROOT + '/api';

/* admin paths */
const ADMIN_API_PATH = SITE_URL + WEBROOT + '/api/admin';
const ADMIN_SITE_PATH = SITE_URL + WEBROOT + '/admin';
const CRM_SITE_PATH = SITE_URL + WEBROOT + '/crm/help';
const CRM_API_PATH = SITE_URL + WEBROOT + '/crm/api';
const GUIDE_SITE_PATH = SITE_URL + WEBROOT + '/artguide/admin';
const GUIDE_API_PATH = SITE_URL + WEBROOT + '/artguide/admin/api';
const GUIDE_MEMBER_SITE_PATH = SITE_URL + WEBROOT + '/artguide/participant';

const BOOKFORUM_URL = localConfig.bookforumUrl;

export { 
    SITE_URL,
    WEBROOT,
    IMAGE_BASE_PATH,
    SITE_PATH,
    API_PATH,
    ADMIN_API_PATH,
    ADMIN_SITE_PATH,
    CRM_SITE_PATH,
    CRM_API_PATH,
    BOOKFORUM_URL,
    GUIDE_SITE_PATH,
    GUIDE_API_PATH,
    GUIDE_MEMBER_SITE_PATH
};