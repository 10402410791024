import { API_PATH }from 'shared/constants/Paths';

const ARTGUIDE_SEARCH_URL = API_PATH + '/guide/search'

const artguideSearch = {
	namespaced: true,

	state: {
    nextPage:     0,
		resultPages:  []
	},

	mutations: {
    setNextPage(state, page) {
      state.nextPage = page
    },
    addResult(state, result) {
      state.resultPages = state.resultPages.concat([result])
    }
	},

	actions: {
		fetchArtguideSearchResults({ commit }, options) {
			let { query, page } = options

			commit('addActiveCall', 'fetchArtguideSearchResults', { root: true })

			let params = new URLSearchParams()
			params.set('query', query)
			params.set('page', page)

			const url = `${ARTGUIDE_SEARCH_URL}?${params.toString()}`
			return fetch(url, {
				credentials: 'include'
			})
				.then(res => res.json())
				.then(data => {
					commit('addResult', data.resultSections)
					commit('setNextPage', data.nextPage)

					// only allow search per second
					setTimeout(() => {
						commit('removeActiveCall', 'fetchArtguideSearchResults', { root: true })
					}, 1000)
				})
		}
	}
}

export default artguideSearch;
