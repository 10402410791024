<template>
    <a v-if="itemCount > 0" 
        :class="{ 'cart-widget': true, 'desktop-only': slideMenuIsActive }" 
        :href="cartLink" 
        :title="`There ${itemCount > 1 ? 'are' : 'is'} ${itemCount} item${itemCount > 1 ? 's' : ''} in your cart`">
        <img class="cart-widget__icon" src="~img/cart.svg" alt="shopping bag">
        <span class="visually-hidden">cart:</span>
        <span class="cart-widget__count">{{ itemCount }}</span>
        <span class="visually-hidden">items</span>
    </a>
</template>
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { WEBROOT } from 'shared/constants/Paths';
    import whichTransitionEvent from 'lib/utils/WhichTransitionEvent';
    import itemCount from './mixins/ItemCount';
    
    export default {
        data(){
        return {
                items: []
            }
        },
        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn,
                activeCalls: state => state.activeCalls
            }),

            ...mapGetters(['cart', 'slideMenuIsActive']),

            cartLink: function(){
                return WEBROOT + '/cart'
            },

            isUpdating: function(){
                return this.activeCalls.indexOf('addToCart') > -1;
            },
        },

        mounted(){
            document.addEventListener('addedToCart', (e) => {
                this.$el.classList.add('cart-widget--updating');
                setTimeout( () => {
                    this.$el.classList.remove('cart-widget--updating');
                }, 2000);
            });
        },

        methods: {
            ...mapActions(['getCart'])
        },

        mixins: [itemCount]
    };

</script>
<style lang="scss">
    
    @import "scss/variables/breakpoints";    
    
    .cart-widget{
        margin: 0 .75rem -.125rem 0;
        position: relative;
        flex-shrink: 0;
    }

    .cart-widget.cart-widget--updating{
        animation-name: hop;
        animation-duration: .5s;
        animation-iteration-count: 1;
    }

    .cart-widget__icon{
        width: 28px;
        height: 28px;
    }

    .cart-widget__count{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $breakpoint-medium){
        .cart-widget{
            margin: 0 .55rem -.125rem 0;
        }
    }
    @media screen and (max-width: 340px){
        .cart-widget{
            display:none;
        }
    }

    @keyframes hop {
        0% {
            top: 0px;
            transform: rotateZ(0deg)
        }

        50% {
            top: -16px;
        }

        100% {
            top: 0px;
            transform: rotateZ(360deg)
        }
    }
</style>