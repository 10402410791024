import { BREAKPOINT_MEDIUM } from 'shared/constants/Common';
import { IMAGE_BASE_PATH } from 'shared/constants/Paths';

/**
 * This mixin adds the following properties to a component for building srcset and sizes properties
 * to an img tag.
 *
 * props: 
 * - preferredImgCrop ('square', 'landscape' 'original'), 
 * - desktopImgSize (the size that images should appear past the "desktop" breakpoint.
 *
 * computed property: sizes (the sizes property)
 * method: srcset() builds a srcset property given a Media object 
 */

const buildSrcSet = (set)=>{
    let srcset = '';
    try{
        for (let key in set ) {
            if (set.hasOwnProperty(key)){
                srcset = `${srcset}${IMAGE_BASE_PATH}${set[key]} ${key}w, `;
            }
        }
    }
    catch (e){
        console.warn(e);
    }
    return srcset;
}

export default {
    props: {
        preferredImgCrop: {
            type: String,
            required: false,
            default: 'original'
        },
        desktopImgSize: {
            type: Number,
            required: false,
            default: 810
        }
    },

    data(){
        return {
            breakpointMedium: BREAKPOINT_MEDIUM,
            IMAGE_BASE_PATH: IMAGE_BASE_PATH,
        }
    },

    computed: {
        /**
         * @return {String}
         */
        sizes: function(){
            return `(min-width: ${this.breakpointMedium}px) ${this.desktopImgSize}px`
        }
    },
    
    methods: {
        /**
         * @param {Object} media
         * @param {String} preferredCrop
         *
         * @return {String}
         */
        srcset: function(media, preferredCrop = this.preferredImgCrop){

            const crops = media.crops;

            if(crops.hasOwnProperty(preferredCrop)){
                return buildSrcSet(crops[preferredCrop]);
            }

            else if(crops.hasOwnProperty('original')){
                return buildSrcSet(crops['original']);
            }

            else return media.path_large ? `${this.IMAGE_BASE_PATH}${media.path_large}` : `${this.IMAGE_BASE_PATH}${media.path}`;
        },

        srcsetSearch: function(media, preferredCrop = this.preferredImgCrop){

            if (media == null) {
                return '';
            }

            const crops = media.hasOwnProperty(crops) ? media.crops : null;
            var fallback = '';
            if (media.path_large) {
                var fallback = `${this.IMAGE_BASE_PATH}${media.path_large}`;
            }
            else if (media.pathLarge) {
                var fallback = `${this.IMAGE_BASE_PATH}${media.pathLarge}`;
            }
            else if (media.path) {
                var fallback = `${this.IMAGE_BASE_PATH}${media.path}`;
            }

            if (crops == null) {
                return fallback;
            }
            else if (crops.hasOwnProperty(preferredCrop)){
                return buildSrcSet(crops[preferredCrop]);
            }
            else if (crops.hasOwnProperty('original')){
                return buildSrcSet(crops['original']);
            }
            else {
                return fallback;
            }
        }

    }
}