/* duplicated from /inc/sitesettings.inc $ITEM_CATEGORIES */

const ITEM_TYPES = {
    ITEM_TYPENAME_PICKS : 'picks',
    ITEM_TYPENAME_VIDEO : 'video',
    ITEM_TYPENAME_NEWS  : 'news',
    ITEM_TYPENAME_DIARY : 'diary',
    ITEM_TYPENAME_500_WORDS: 'words',
    ITEM_TYPENAME_SLANT: 'slant',
    ITEM_TYPENAME_MUSEUMS: 'museums',
    ITEM_TYPENAME_INTERVIEW: 'interview',
    ITEM_TYPENAME_PRINT: 'print',
    ITEM_TYPENAME_PASSAGES: 'passages'
}

export default ITEM_TYPES;

