/** 
 * a modulo function that works with subtraction and negative numbers
 * @param {Number} n - the number
 * @param {Number} m - the modulus
 * @return {Number}
 */
function mod(n, m) {
  return ((n % m) + m) % m;
}

export default mod;