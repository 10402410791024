<template>
    <transition name="fade">
        <div v-show="viewingRoomLightboxIsVisible" class="lightbox viewing-room-lightbox overlay overlay--opaque">
            <div v-on:scroll="onScroll" class="lightbox__wrapper">
                <header class="viewing-room-lightbox__header">
                    <h4 class="viewing-room-lightbox__title" v-html="exhibitionInfo"></h4>
                    <nav v-if="hasManyMedia" class="viewing-room-lightbox__navigation">
                        <a href="#" class="lightbox__nav-link lightbox__nav-link--small" @click.prevent="previousImage()"><img class="lightbox__nav-image lightbox__nav-image--small" src="~img/arrow_black_left.svg" alt="previous image"></a>
                        <span class="lightbox__indicator lightbox__indicator--small">{{ currentIndex + 1 }} of {{ totalMedia }}</span>
                        <a href="#" class="lightbox__nav-link lightbox__nav-link--small" @click.prevent="nextImage()"><img class="lightbox__nav-image lightbox__nav-image--small" src="~img/arrow_black_right.svg" alt="next image"></a>
                    </nav>
                </header>
            <div>
                <button class="viewing-room-lightbox__collapse lightbox__collapse" v-on:click.prevent="closeModal">CLOSE</button>
            </div>
                <div>
                    <figure v-for="(image, index) in images" class="lightbox__content viewing-room-lightbox__content" :class="{'lightbox__content--visible': index === currentIndex }">
                        <img class="lightbox__image" :src="fullImagePath(image.path_large)">
                        <caption class="lightbox__caption" v-html="image.caption_formatted"></caption>
                        <!-- <div v-if="lightboxImages.length > 1" class="lightbox__count">{{index + 1}} of {{lightboxImages.length}}</div> -->
                    </figure>
                    <video-embed :key="video.id" v-for="(video, index) in videos" :video="video" :class="{'lightbox__content': true, 'lightbox__content--visible': videoIndex(index) === currentIndex }"></video-embed>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import scrollDirection from '../mixins/ScrollDirection';
import { IMAGE_BASE_PATH } from 'shared/constants/Paths';
import KEYCODES from '../../constants/Keycodes';
import VideoEmbed from './video/Video.vue';
import createClickOutsideHandler from 'lib/utils/CreateClickOutsideHandler';

export default {
    mixins: [scrollDirection],

    components: {
        'video-embed': VideoEmbed,
    },

    props: {
        images: {
            type: Array,
            required: false,
            deafult: []
        },
        videos: {
            type: Array,
            required: false,
            default: [],
        },
        artists: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        venue: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: function(){
        return {
            currentIndex: 0,
        }
    },

    computed: {
        ...mapState({
            lightboxCurrentIndex: state => state.lightbox.lightboxCurrentIndex,
            viewportWidth: state => state.viewport.windowWidth,
        }),

        ...mapGetters([
            'viewingRoomLightboxIsVisible'
        ]),

        totalMedia: function(){
            return this.videos.concat(this.images).length;
        },

        hasManyMedia: function(){
            return this.totalMedia > 1;
        },

        exhibitionInfo: function(){
            return `<strong>${this.formatForTitle(this.artists)}<em>${this.formatForTitle(this.title)}</em></strong>${this.formatForTitle(this.venue)}`;
        }
    },

    methods: {
        ...mapMutations(['closeModal']),

        fullImagePath(path){
            return `${IMAGE_BASE_PATH}${path}`;
        },

        videoIndex(index){
            return this.images.length + index;
        },

        nextImage(){
            this.currentIndex = (this.currentIndex + 1) % this.totalMedia;
        },

        previousImage(){
            let isFirstImage = this.currentIndex === 0;
            let lastIndex = this.totalMedia - 1;
            let nextIndex = isFirstImage ? lastIndex : this.currentIndex - 1;
            this.currentIndex = nextIndex;
        },

        rightAdvancesLightbox : function(e){
            if(e.keyCode === KEYCODES.RIGHT){
                this.nextImage();
            }
        },

        leftDecrementsLightbox : function(e){
            if(e.keyCode === KEYCODES.LEFT){
                this.previousImage();
            }
        },

        formatForTitle: function(str){
            return str.length > 0 ? `${str}<br>` : str;
        },

        clickHandler: function(e){
            const method = e.pageX > (this.viewportWidth / 2) ? this.nextImage : this.previousImage;
            const fun = createClickOutsideHandler(['.viewing-room-lightbox__navigation', '.lightbox__caption', '.video-placeholder', '.video-wrapper', '.video-activate'], method);
            fun(e);
        },
    },

    watch: {
        viewingRoomLightboxIsVisible(status){
            if(status){
                window.addEventListener('keyup', this.rightAdvancesLightbox);
                window.addEventListener('keyup', this.leftDecrementsLightbox);
                setTimeout(() => { window.addEventListener('click', this.clickHandler)}, 250);
            }
            else{
                window.removeEventListener('keyup', this.rightAdvancesLightbox);
                window.removeEventListener('keyup', this.leftDecrementsLightbox);
                window.removeEventListener('click', this.clickHandler);
            }
        }
    }
};
</script>

<style lang="scss">
    @import 'scss/variables/colors';
    @import 'scss/variables/breakpoints';
    @import 'scss/variables/typography';

    .viewing-room-lightbox__header{
        margin:0;
        position: absolute;
        top: 2rem;
        left: 2rem;
        width: 75%;
        strong {
            font-weight: $medium;
        }
    }

    .viewing-room-lightbox__title{
        font-size: 1.125rem;
        font-weight: normal;
        margin: 0;;
    }

    .viewing-room-lightbox__collapse{
        float: right;
        background: none;
    }

    .overlay--opaque{
        background: $grey-background !important;
    }

    .lightbox__nav-link--small, .lightbox__indicator--small{
        font-size: 1.125rem;
    }

    .lightbox__nav-image--small{
        height: .875rem;
    }

    .viewing-room-lightbox__navigation{
        position: static !important;
        margin: .5rem 0 0 0;
        .lightbox__nav-link:first-of-type{
            margin: 0 1rem 0 0;
        }
        .lightbox__nav-link:last-of-type{
            margin: 0 0 0 1rem;
        }
    }

    @media screen and (min-width: $breakpoint-medium){
        .viewing-room-lightbox__content{
            // width: 70vw !important;
        }
    }

    @media screen and (max-width: $breakpoint-medium){
        .viewing-room-lightbox__navigation{
            display: none;
        }

        .viewing-room-lightbox__collapse{
            position: fixed;
            top: 1rem;
            right: 0;
        }

        .viewing-room-lightbox__header{
            position: static;
            margin: 1rem 0 0 1rem;
        }

        .viewing-room-lightbox{
            .lightbox__content:first-of-type{
                margin-top: 2rem;
            }
        }
    }

</style>