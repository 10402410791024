<template>
  <div>
    <!-- <MobileModal /> -->
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  // This Component is like a sub "App.vue" component that manages artguide specific
  // app-level tasks like syncing myguide and managing the artguide nav scroll position.
  export default {
    props: {
      activePlacesJson: String,
      place: { type: String, default: null },
      category: { type: String, default: null },
    },
    computed: {
      ...mapState('viewport', ['scrollY']),
      ...mapState({
        userIsLoggedIn: state => state.user.userIsLoggedIn,
        isMapMounted: state => state.artguideMap.isMapMounted,
        isMapFullscreen: state => state.artguideMap.isMapFullscreen,
      })
    },
    watch: {
      scrollY () {
        // on scroll, update the nav list position
        this.setNavListPosition()
      },
      isMapFullscreen(isMapFullscreen) {
        this.navList.style.zIndex = isMapFullscreen ? '-1' : ''
      },
      userIsLoggedIn(isLoggedIn) {
        if (isLoggedIn) {
          setTimeout(() => {
            this.syncMyguide()
          }, 100)
        } else {
          this.removeAllFromMyguide()
        }
      }
    },
    mounted() {
      // handle the position of nav on scroll
      this.navList = document.querySelector('.artguide-nav__items')
      this.setNavListPosition()

      this.setArtguideShowing(true)

      // set active places / nav selections
      const activePlaces = JSON.parse(this.activePlacesJson)
      this.setActivePlaces(activePlaces)
      this.setSelectedPlace(this.place)
      this.setSelectedCategory(this.category)

      // sync myguide with user
      if (this.userIsLoggedIn) {
        this.syncMyguide()
      }

      // handle history events
      window.onpopstate = ev => {
        const state = ev.state || {}
        this.handleMapPopState(state)
        this.handleMyguidePopState(state)
      }
    },
    methods: {
      ...mapActions('myguide', ['syncMyguide']),
      ...mapMutations('myguide', ['removeAllFromMyguide', 'handleMyguidePopState']),
      ...mapMutations('artguide', ['setActivePlaces', 'setSelectedPlace', 'setSelectedCategory', 'setArtguideShowing']),
      ...mapMutations('artguideMap', ['handleMapPopState']),
      setNavListPosition() {
        // position: sticky doesn't work in this particular situation, so we manually make the left nav stick

        const BelowMenuTop = 130 // the highest position that sits below the menu
        const FooterHeight = 200
        const RequiredWindowHeight = BelowMenuTop + FooterHeight + this.navList.clientHeight + 30
        const initialTop = this.isMapMounted ? 385 : 205

        var top = Math.max(BelowMenuTop, initialTop - this.scrollY)

        // if window is too short, allow the nav to push up further
        top -= Math.max(0, RequiredWindowHeight + this.scrollY - document.body.scrollHeight)

        this.navList.style.top = top + 'px'
      }
    }
  }
</script>
