<template>
    <section class="hp-bloglist hp-section">
        <div class="flex">
            <h2 class="section-heading"><a :href="sectionPath">{{ this.categoryLabel !== '' ? this.categoryLabel : this.category }}</a></h2>
        </div>
        <div v-if="section.length > 0">
            <blog-list-article   
                v-for="(item, index) in section" 
                :article="item" 
                :index="index" 
                :key="`blogList${category}${index}`"
                ></blog-list-article>
        </div>
        <div v-else>
            <blog-list-placeholder :index="0"></blog-list-placeholder>
        </div>
    </section>
</template>

<script>
    /**
     * Shows channel listings on the home page
     */
    import { mapState, mapGetters, mapActions } from 'vuex';
    import blogListArticle from './BlogListArticle.vue';
    import blogListPlaceholder from './BlogListPlaceholder.vue';

    export default {
        props: {
            category: {
                type: String,
                required: true
            },
            categoryLabel: {
                type: String,
                required: false,
                default: '',
            },
            pageSize: {
                type: Number,
                required: false,
                default: 3
            },
            sectionPath: {
                type: String,
                required: false
            }
        },
        
        data(){
            return {
                loaded: false,
            }
        },
        computed: {
            ...mapGetters('viewport', ['scrollBottom']),
            ...mapState({
                section: function(state){
                    return state.articles.sectionFeatured[this.category]
                }
            }),
        },
        methods: {
            ...mapActions('articles', ['getSectionFeaturedArticles']),
        },
        watch: {
            scrollBottom: function(scrollBottom){
                const options = { 
                    category: this.category,
                    pageSize: this.pageSize 
                };
                if ( scrollBottom >= this.$el.offsetTop && !this.loaded ) {
                    this.getSectionFeaturedArticles(options);
                    this.loaded = true;
                }
            }
        },
        components: { blogListArticle, blogListPlaceholder }
    };
</script>