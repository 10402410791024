<template>
  <div :class="containerClass">
    <div class="guide-map-sidebar__expansion-toggle" @click="toggleExpansion">
      <span class="guide-map-sidebar__expansion-toggle__arrow">
        {{ expanded ? '▶' : '◀' }}
      </span>
    </div>

    <div ref="content" class="guide-map-sidebar__content">
      <h1 class="guide-map-sidebar__title">{{ title }}</h1>
      <ul class="guide-map-sidebar__locations">
        <li :ref="`locationsListItem-${item.location.id}`" v-for="item in locations" :key="item.id">
          <MapListLocation
            :location="item.location"
            :entities="isCountry ? item.placeEntities : item.entities"
            :isCountry="isCountry"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import _throttle from 'lodash/throttle'
  import detectIt from 'detect-it'
  import { mapState, mapMutations } from 'vuex'
  import MapListLocation from './MapListLocation.vue'

  export default {
    components: { MapListLocation },
    props: {
      expanded: Boolean,
      title: String,
      locations: Array,
      isCountry: Boolean
    },
    data() {
      return {
        hasExpanded: false
      }
    },
    computed: {
      ...mapState({
        loading:          state => state.activeCalls.includes('fetchMapPlaceExhibitions'),
        selectedLocationId: state => state.artguideMap.selectedLocationId
      }),
      containerClass() {
        return {
          'guide-map-sidebar': true,
          'guide-map-sidebar--expanded': this.expanded,
          'guide-map-sidebar--loading': this.loading
        }
      }
    },
    methods: {
      ...mapMutations('artguideMap', ['selectMapLocation']),
      checkFirstExpansion() {
        if (!this.hasExpanded && this.locations.length > 0) {
          this.$emit('expandRequest', true)
          this.hasExpanded = true
        }
      },
      toggleExpansion() {
        this.$emit('expandRequest', !this.expanded)
      },
      getListItemOffset(locationId) {
        const listItem = this.$refs[`locationsListItem-${locationId}`][0]
        return listItem.offsetTop
      },
      scrollToSelectedLocation() {
        if (this.selectedLocationId) {
          clearInterval(this.selectionScrollInterval)

          const content = this.$refs.content
          const start = content.scrollTop
          const end = this.getListItemOffset(this.selectedLocationId) - 50
          const frames = 12
          const delta = (end - start) / frames
          let frameCount = 0
          this.selectionScrollInterval = setInterval(() => {
            if (content) {
              content.scrollTop += delta
            }

            frameCount += 1
            if (frameCount === frames) {
              clearInterval(this.selectionScrollInterval)
              this.selectionScrollInterval = null
            }
          }, 16.67)
        }
      },
      onContentScroll() {
        // Here, we select/highlight pins on the map as we scroll through the list
        if (this.contentScrollAnimationFrame || this.selectionScrollInterval) {
          return
        }

        const { locations } = this

        this.contentScrollAnimationFrame = requestAnimationFrame(() => {
          const { scrollTop } = this.$refs.content

          for (let i = locations.length - 1; i >= 0; i--) {
            const { location } = locations[i]
            const offset = this.getListItemOffset(location.id) - 200
            if (scrollTop >= offset) {
              if (this.selectedLocationId !== location.id) {
                this.lastScrollSelectionTime = Date.now()
                this.selectMapLocation({ id: location.id, method: 'scroll' })
              }
              break
            }
          }

          this.contentScrollAnimationFrame = null
        })
      }
    },
    watch: {
      locations() {
        this.checkFirstExpansion()
      },
      selectedLocationId(selectedLocationId) {
        // if it has been a second since we selected a marker via scrolling, then scroll to the selected location
        if (Date.now() - this.lastScrollSelectionTime >= 500) {
          this.scrollToSelectedLocation()
        }
      }
    },
    mounted() {
      this.lastScrollSelectionTime = 0

      // throttle listening to content scroll for performance
      this.$refs.content.addEventListener('scroll', _throttle(() => {
        this.onContentScroll()
      }), detectIt.passiveEvents ? {passive: true} : false)

      this.checkFirstExpansion()

      if (!this.isCountry && !this.selectedLocationId && this.locations.length > 0) {
        this.selectMapLocation({ id: this.locations[0].location.id, method: 'sidebarOpen' })
      }

      setTimeout(this.scrollToSelectedLocation, 500)
    }
  };
</script>

<style lang="scss">
  .guide-map-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    height: 100%;
    transition: transform 0.3s;
    transform: translateX(300px);
  }

  .guide-map-sidebar--expanded {
    transform: none;
  }

  .guide-map-sidebar--loading {
    opacity: 0.5;
  }

  .guide-map-sidebar__content {
    margin-left: 20px;
    padding: 50px 15px 15px 15px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.75);
  }

  .guide-map-sidebar__locations {
    margin-bottom: 80px;
  }

  .guide-map-sidebar__expansion-toggle {
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    left: 0;
    width: 20px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    background: #000;
    color: #fff;
    padding: 0 4px;
    font-size: 14px;
  }

  .guide-map-sidebar__expansion-toggle__arrow {
    display: block;
    transform: translateY(1px);
  }

  .guide-map-sidebar__title {
    margin: 0;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 20px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.95);
  }
</style>
