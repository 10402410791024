/**
 * State data for the animated Subscribe form
 */

export default {
    namespaced: true,

    state: {
        termIsSelected: false,
        billingIsComplete: false,
        shippingIsComplete: false,
        termSelectedVal: false,
        formSubmitted: false,
        formComplete: false,
        sectionsIncomplete: [],
        condition: [
            {'path': 'user/userIsLoggedIn', 'key': 'authentication', 'msg': 'Please sign in or register'},
            {'path': 'subscribe/termIsSelected', 'key': 'term', 'msg': 'Please select a subscription plan'},
            {'path': 'subscribe/billingIsComplete', 'key': 'billing', 'msg': 'Please complete payment information'},
            {'path': 'subscribe/shippingIsComplete', 'key': 'shipping', 'msg': 'Please complete shipping information'}
        ]
    },

    mutations: {
        termSelected: function(state, selected){
            state.termIsSelected = selected;
        },
        termSelectedVal: function(state, selectedVal){
            state.termSelectedVal = selectedVal;
        },
        billingComplete: function(state, complete){
            state.billingIsComplete = complete;
        },
        shippingComplete: function(state, complete){
            state.shippingIsComplete = complete;
        },
        markFormSubmitted: function(state, val){
            state.formSubmitted = val;
        },
        markFormComplete: function(state, val){
            state.formComplete = val;
        },
        setSectionIncomplete: function(state, val){
            var k = state.sectionsIncomplete.indexOf(val);
            if (k < 0) {
                state.sectionsIncomplete.push(val);
            }
        },
        removeSectionIncomplete: function(state, val){
            var k = state.sectionsIncomplete.indexOf(val);
            if (k > -1) {
                state.sectionsIncomplete.splice(k, 1);
            }
        }
    },
    getters: {
        getSectionsIncomplete (state) {
            return state.sectionsIncomplete;
        }
    },

};
