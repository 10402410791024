<template>
    <div class="footer__signup">
        <h4 class="footer__signup-header">ARTFORUM INBOX</h4>
        <p class="footer__signup-note">
            Register to receive our newsletters&mdash;<em>The Archive</em>, <em>Must See</em>, <em>Dispatch</em>, and <em>The Issue</em>&mdash;as well as special offers from <em>Artforum</em>.
        </p>
        
        <dot-dot-dot associated-action="registerNewUser" :invert-color="true"></dot-dot-dot>

        <div class="footer__signup-form" v-show="showForm">
            <form v-show="showForm">
                <div class="flex">
                <label for="register_footer_email" class="visually-hidden">email</label>
                <input 
                    id="register_footer_email"
                    class="footer__signup-input" 
                    name="email" type="text"
                    placeholder="E-MAIL" 
                    v-model="userEmail"
                    v-on:focus="clearMessages" />
                <button 
                    class="footer__signup-submit" 
                    type="submit" 
                    @click.prevent="submitForm()">SUBMIT</button></div>
                <re-captcha ref="recaptcha" v-show="showCaptcha" :callback="recaptchaCallback" id="footerCaptcha" class="recaptcha footer__recaptcha"></re-captcha>
                <div class="form__field-error form__field-error--white" v-html="error" v-if="error.length" role="alert"></div>
            </form>
            <p class="form__message" v-html="registrationMessage" v-if="registrationMessage.length" role="alert"></p>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState, mapMutations } from 'vuex';
    import reCaptcha from './ReCaptcha.vue';
    import dotDotDot from '../animations/DotDotDot.vue';
    import recaptchaIntegration from './mixins/recaptchaIntegration';

    export default {

        props: {
            origin: {
                type: String,
                required: false,
                default:  '',
            },
        },

        data(){
            return {
                userEmail: '',
                error: '',
                success: false
            }
        },
        computed:{
            ...mapState({
                registrationMessage: (state) => state.user.registrationMessage
            }),

            ...mapState(['activeCalls']),

            showCaptcha: function(){
                return this.userEmail.length > 0;
            },

            showForm: function(){
                return ( this.activeCalls.indexOf('registerNewUser') < 0 ) && !this.success;
            }
        },
        methods: {
            ...mapActions('user', ['registerNewUser']),

            ...mapMutations('user', ['setRegistrationMessage']),

            clearMessages: function() {
                this.setRegistrationMessage('');
            },

            submitForm: function(){
                const user = { email: this.userEmail, origin: this.origin };
                const data = { user };
                if ( !this.recaptchaIsCompleted() ) {
                    this.error = 'Please complete the captcha challenge.'
                    return;
                }
                this.addRecaptchaToRequest(data);
                this.registerNewUser(data)
                .then((res)=>{
                    this.userEmail = '';
                    this.$refs.recaptcha.reset();
                    if ( res.ok ){
                        this.success = true
                    }
                })
                .catch((err) => {
                    this.$refs.recaptcha.reset();
                    this.error = '';
                    this.setRegistrationMessage(err);
                });
            }
        },
        components: {
            reCaptcha,
            dotDotDot
        },
        mixins: [
            recaptchaIntegration
        ]
    };
</script>

<style>
    .footer__recaptcha{
        margin-top: .5rem;
    }

    .footer__signup-header{
        font-weight: 100;
    }

    .footer__signup .form__message{
        color: #000;
    }
</style>