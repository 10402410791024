<template>
<div class="print-article-column reviews__content">
    <ul class="reviews-list">
        <li v-for="(row, index) in results">
            <article class="reviews-list__article reviews-list__article--pick">

                <div v-if="hasMedia(row)" class="review__primary-image review__primary-image--pick">
                    <af-more-images-link class="mobile-full-width"
                        :article-id="row.id"
                        :image-id="row.primaryImage.id"
                        article-title="row.titleFormatted"
                        image-names="[]">
                        <img :src="row.primaryImage.path"
                            :srcset="srcset(row.primaryImage, 'original')"
                            sizes="(min-width: 840px) 322px" :alt="row.titleFormatted | stripTags">
                    </af-more-images-link>

                    <caption aria-hidden="true">
                        <p class="blog-article__caption" v-html="row.primaryImage.captionFormatted"></p>
                    </caption>
                </div>



                <div class="review__review">

                    <h2 v-if="row.placeListedUnder" class="review__place" v-html="row.placeListedUnder"></h2>

                    <h1 class="picks__h1">
                        <a :href="row.url" v-html="row.titleFormatted"></a>
                    </h1>

                    <h2 class="review__venue">

                        <span v-if="row.locationOverride" v-html="row.locationOverride"></span>
                        <span v-else="row.locationName" v-html="row.locationName"></span>
                        <br />

                        <span v-if="row.locationAddress" v-html="row.locationAddress" class="review__details"></span>
                        <br />
                        <span v-if="row.runDates" v-html="row.runDates" class="review__details"></span>

                    </h2>

                    <h2 v-if="row.curatedBy" class="review__curated-by" v-html="row.curatedBy"></h2>

                    <section class="review__content" v-html="row.bodyFormatted"></section>

                </div>

            </article>

            <banner-ad
                v-if="showAd(index, bannerCountOffset, bannerFreq)" 
                :idx="getAdjustedIndex(index)"
                :freq="bannerFreq"
                :bannerIdx="bannerIdx(index)"
            ></banner-ad>

            <gallery-ads-mobile 
                v-if="showAd(index, -1, galleryAdsFrequency)"
                :batch-index = "getGalleryAdsBatchIndex(index, -1)"
            ></gallery-ads-mobile>

        </li>
    </ul>
</div>
</template>

<script>
    import { mapState } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import formatDate from 'shared/components/mixins/FormatDate';
    import bannerAd from '../ads/BannerAd.vue';
    import galleryAdsMobile from '../ads/GalleriesMobile.vue';
    import { ARTICLES_PER_PAGE } from 'shared/constants/Common';
    import shareText from '../mixins/ShareText';

    export default {
        props: {
            total: { type: String, default: 0 }
        },
        data: function(){
            return {
                galleryAdsFrequency: 3,
                galleryAdsPerGroup: 4,
                bannerCountOffset: 0,
                bannerFreq: 2,
                currentPlace: '',
            }
        },
        mixins: [srcsetFromMedia, formatDate, shareText],
        computed: {
            ...mapState('viewport', ['scrollY']),
            ...mapState({
                results: (state) => state.picks.results,
                placeBookmarks: (state) => state.picks.placeBookmarks,
            })
        },

        methods: {
            hasMedia: function(row){
                return row.hasOwnProperty('primaryImage');
            },

            /**
             * Add the articles already rendered in twig to the article index
             *
             * @param {Number} index - the article index
             * @return {Number} the index plus the articles already displayed
             */
            getAdjustedIndex: function(index){
                return index + this.total;
            },

            /**
             * Decide whether to render a given ad component
             *
             * @param {Number} index - the article index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             * @param {Number} frequency - the frequency with which to show the ad
             *
             * @return {Boolean} - do or don't show the ad
             */
            showAd: function(index, indexOffset, frequency){
                return (index + indexOffset) % frequency === 0;
            },

            /**
             * Determine the offset prop - the index from which to get a slice of the
             * gallery ads for a mobile gallery ad component, accounting for the ads that have
             * already been displayed.
             *
             * @param {Number} index - the current index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             *
             * @return {Number} - the offset
             */
            getGalleryAdsOffset: function(index, indexOffset){
                const ADS_PER_GROUP = this.galleryAdsPerGroup;
                return this.getGalleryAdsBatchIndex(index, indexOffset) * ADS_PER_GROUP;
            },

            /**
             * Determine index of the gallery ads batch to use for mobile, based on the article index,
             *
             * @param {Number} index - the current index
             * @param {Number} indexOffset - the position from which to begin showing this ad type
             *
             * @return {Number} - the index of the batch of ads to be shown
             */
            getGalleryAdsBatchIndex: function(index, indexOffset){
                const INDEX_ADJUSTED_OFFSET = this.getAdjustedIndex(index) + indexOffset;
                const ADS_FREQUENCY = this.galleryAdsFrequency;
                return ( INDEX_ADJUSTED_OFFSET / ADS_FREQUENCY );
            },

            /**
             * Figure out what banner we are on based on the loop index. Yuck.
             * 
             * @param {Number} index - the current loop index
             * 
             * @return {Number} - the "index" of the banner
             */
            bannerIdx: function(index){
                const nthAd = (this.getAdjustedIndex(index) + this.bannerCountOffset) / this.bannerFreq;
                if(!Number.isInteger(nthAd)){
                    console.warn(`The banner index at article ${this.getAdjustedIndex(index)} is not an integer, so it is being rounded down. Check your ad display logic`);
                    return Math.floor(nthAd);
                }
                return nthAd;
            },
        },
        components: {
            bannerAd: bannerAd,
            galleryAdsMobile: galleryAdsMobile,
        },
        watch: {
            scrollY () {
                if (this.placeBookmarks.length > 0) {
                    for (let i = 0; i < this.placeBookmarks.length; i++) {
                        var j = i + 1;
                        var nextBookmark = this.placeBookmarks[j] == undefined ? scrollY + 100 : this.placeBookmarks[j].startY;
                        if (scrollY > this.placeBookmarks[i].startY && scrollY < nextBookmark) {
                            var place = this.placeBookmarks[i].place;
                            if (this.currentPlace != place) {
                                const newUrl = `${window.location.protocol}//${window.location.host}/picks/${place}`;
                                history.replaceState({path: newUrl}, '', newUrl);
                                this.currentPlace = place;
                            }
                        }
                    }
                }
            },
            currentPlace () {
                // switch active class
                var activeClass = "reviews-city-list__city--current";
                var li_current = document.getElementsByClassName(activeClass);
                for (var i = 0; i < li_current.length; i++) {
                    li_current[0].classList.remove(activeClass);
                }

                var li_id = 'reviews-city-list__' + this.currentPlace;
                var d = document.getElementById(li_id);
                d.className += " " + activeClass;
            }
        },
    };
</script>