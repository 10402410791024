import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';
import { mapState } from 'vuex';

const print = {
    namespaced: true,

    state: {
        issueResults: [],
    },

    mutations: {
        searchAssign(state, data){
            Object.assign(state, data);
        },
        addResults(state, data){
            state.issueResults = state.issueResults.concat(data);
        },
    },

    actions: {
        fetchCurrentIssue: function({ commit, state, rootGetters}, input){
            const ACTION_NAME = 'ArchiveIssue';

            if (rootGetters.callIsActive(ACTION_NAME)) {
                return;
            }

            const PATH = `${API_PATH}/issue/current`;
            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                if(res.status === 404){
                    commit('searchAssign', {allPagesLoaded: true});
                }
            }).then((data) => {
                commit('addResults', data._embedded.items);
                commit('removeActiveCall', ACTION_NAME, { root: true });
            }).catch((err) => {
                console.error(err);
            })
        }

    }
};

export default print;