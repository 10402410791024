/**
 * This is used to let the recaptcha component know that google's recaptcha
 * javascript has been loaded so that it can be rendered. That is all.
 */

const reCaptcha = {
	namespaced: true,

	state: {
		recaptchaIsLoaded: false,
	},
	
	mutations: {
		recaptchaLoaded(state){
            state.recaptchaIsLoaded = true;
        },
	}
}

export default reCaptcha;