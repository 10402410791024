<template>
	<section class="cart__totals">
		<table class="totals__table">
			<tr>
				<td class="totals__label">Total</td>
				<td class="totals__amount">${{ total }}</td>
			</tr>
		</table>
	</section>
</template>

<script>
	/**
	 * Gives the cart total plus the region-dependent shipping cost
	 */
	import { mapState } from 'vuex';
	import { mapGetters } from 'vuex';

	export default {
		computed: {
			...mapState({
				// shipping: state => state.serverCart.isDomesticShipping ? state.serverCart.shipping.us : state.serverCart.shipping.international,
				subtotal: state => state.serverCart.subtotal
			}),
			...mapGetters(
				'serverCart',
				['shippingByRegion']
			),
			total: function(){
				return (this.shippingByRegion + this.subtotal).toFixed(2);
			}
		}
	};
</script>