/**
 * Most Read Articles
 */

import { API_PATH }from 'shared/constants/Paths';

const mostRead = {
    namespaced: true,

    state: {
        mostReadArticles: []
    },

    mutations: {
        setMostReadArticles(state, articles){
            state.mostReadArticles = articles;
        },
    },

    actions: {
        getMostReadArticles({ commit }){
            const NEWS_URL = API_PATH + '/items/flag?flagName=most_read&pageSize=4';

            const headers = new Headers({
                'Accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            });

            fetch(NEWS_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if(!res.ok){
                    throw new Error('could not fetch most read articles');
                }
                return res.json();
            }).then((data) => {
                commit('setMostReadArticles', data._embedded.items);
                return true;
            })
        }
    }
}

export default mostRead;