<template>

    <div v-show="isGift" class="subscription-form--section-wrapper subscription-form--gift">

        <input name="giftMessage" id="giftMessage" placeholder="personalize message to recipient" type="text" value="" class="form__text-input" style="margin-top: 2em;">

        <div class="desktop-flex">
            <input name="giftEmail" id="giftEmail" placeholder="gift recipient email" type="text" value=""
            class="form__text-input">

            <input name="name" id="name" placeholder="from" type="text" value="" class="form__text-input">
        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters('subscriptions', ['isGift']),
        },
    };
</script>
<style>
  .subscription-form--gift .form__text-input {
      margin-right: 1em;
  }
</style>