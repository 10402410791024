<template>
	<div class="search-results__sort">
        <a :class="{'sort--current': newestIsSelected}" href="#" @click.prevent="changeUrlParam('sort', 'date')">newest</a> 
        <a :class="{'sort--current': mostRelevantIsSelected}" href="#" @click.prevent="changeUrlParam('sort', 'relevance')">most relevant</a>
    </div>
</template>
<script>
	export default{
		computed: {
			newestIsSelected: function(){
				return location.href.indexOf('sort=date') > -1 || location.href.indexOf('sort=') < 0;
			},
			mostRelevantIsSelected: function(){
				return location.href.indexOf('sort=relevance') > -1;
			}
		},
		methods: {
			changeUrlParam: function(key, value) {
			  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
			  var separator = location.href.indexOf('?') !== -1 ? "&" : "?";
			  var updated = location.href.match(re) ? 
			  	location.href.replace(re, '$1' + key + "=" + value + '$2') 
			  	: location.href + separator + key + '=' + value;
			  location.href = updated;
			}
		}
	};
</script>
<style lang="scss">
	@import "scss/variables/typography";
	.sort--current{
		font-weight: $demi;
	}
</style>