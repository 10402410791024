import toQueryString from 'lib/utils/ToQueryString';

const homepage = {
    namespaced: true,
    state: {
        currentMultiFeature: 0,
        multiFeatureCount: 0,
        currentMultiVideo: 0,
        multiVideoCount: 0,
    },

    mutations: {
        setCurrentMultiFeature: function(state, index){
            state.currentMultiFeature = index % state.multiFeatureCount;
        },

        setCurrentMultiVideo: function(state, index){
            state.currentMultiVideo = index % state.multiVideoCount;
        },

        homepageAssign: function(state, data){
            Object.assign(state, data);
        },
    }

};

export default homepage;