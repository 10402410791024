/**
 * Homepage news only
 */

import { API_PATH }from 'shared/constants/Paths';

const news = {
    namespaced: true,

    state: {
        frontPageNews: [],
        frontPageNewsAds: []
    },

    mutations: {
        setFrontpageNews(state, news){
            state.frontPageNews = news;
        },
        setFrontpageNewsAds(state, news){
            state.frontPageNewsAds = news;
        }
    },

    actions: {
        getFrontPageNews({ commit }){
            const NEWS_URL = API_PATH + '/items?category=news&pageSize=19';

            const headers = new Headers({
                'Accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            });

            fetch(NEWS_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if(!res.ok){
                    throw new Error('could not fetch news');
                }
                return res.json();
            }).then((data) => {
                commit('setFrontpageNews', data._embedded.items);
                return true;
            })
        },
        getFrontPageNewsAds({ commit }){
            const NEWS_AD_URL = API_PATH + '/items?category=news_ads&pageSize=1';

            const headers = new Headers({
                'Accept': 'application/json',
            });

            fetch(NEWS_AD_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if(!res.ok){
                    throw new Error('could not fetch news ads');
                }
                return res.json();
            }).then((data) => {
                if (data._embedded && data._embedded.items) {
                    commit('setFrontpageNewsAds', data._embedded.items);
                }
                return true;
            })
        }
    }
}

export default news;