import { render, staticRenderFns } from "./VideoArticle.vue?vue&type=template&id=57fa9912&"
import script from "./VideoArticle.vue?vue&type=script&lang=js&"
export * from "./VideoArticle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports