<template>
    <article class="news__article">
        <div class="news-list__main desktop-flex">
            <a v-if="showImage(article)" class="news-list__image" :href="article.url">
                <div class="image-container image-container--1x1 mobile-full-width">
                    <img class="news-list__primary-image" :src="`${IMAGE_BASE_PATH}${srcPath}`" :srcset="srcset(article.primaryImage)" :sizes="sizes" :alt="article.titleFormatted | stripTags">
                </div>
            </a>
            <div class="news-list__words">
                <div class="news-list__article-meta">
                    {{ article.published | formatDate("MMMM Do, YYYY [at] h:mma") }}
                </div>
                <h1 class="news__title">
                    <a :href="article.url" v-html="article.titleFormatted"></a>
                </h1>
                
                <section v-if="article.bodyExcerpt" class="news-list__content" v-html="article.bodyExcerpt"></section>
                <div class="article-list__read-more">
                    <a class="article-list__read-more-link" :href="article.url">Read more</a>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    import article from './mixins/article';
    import cleanDateString from 'lib/utils/CleanDateString';

    export default {
        mixins: [article],
        computed: {
            srcPath: function(){
                return this.article.primaryImage.path_square ? this.article.primaryImage.path_square : this.article.primaryImage.path;
            },
        },

        methods: {
            // temporary hack
            showImage: function(article){
                const published = new Date(cleanDateString(article.published));
                const show = article.primaryImage && (new Date(published) > new Date('January 1, 2019 00:00:00'));
                return show;
            }
        }
    };
</script>
