<template>
    <div class="share">
        <a href="#" v-if="!expanded" @click.prevent="toggle()" class="share__open">SHARE</a>
        <div v-if="expanded" class="share__buttons">
            <slot></slot>
            <a href="#" @click.prevent="toggle()" class="share-link share__collapse"><img src="~img/x-01.svg"></a>
        </div>
    </div> 
</template>
<script>
    export default {
        data: function(){
            return {
                expanded: false
            }
        },
        methods: {
            toggle: function(){
                this.expanded = !this.expanded;
            }
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/typography";
    .share{
        text-align: right;
        height: 20px;
    }

    .share__open{
        display: block;
        font-family: $sans-base-font-family;
        font-size: .875rem;
        font-weight: $demi;
        line-height: 20px;
        text-transform: uppercase;
    }

    .share__open img{
        width: 2rem;
        margin-top: -.25rem;
    }

    .share__open, .share__collapse{
        color: #000;
    }

    .share img{
        max-height: 100%;
        max-width: 100%;
    }
</style>