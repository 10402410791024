<template>

        <div :class="getWrapperClass()">
            <header v-if="label" class="homepage-carousel__header desktop-flex">
                <h3 class="homepage-carousel__heading">
                   {{ label }}
                </h3>
            </header>
            <section class="homepage-carousel__slider homepage-carousel__js_slider lory__slider">
                <div class="homepage-carousel__frame homepage-carousel__js_frame lory__frame">
                    <ul class="homepage-carousel__slides homepage-carousel__js_slides lory__slides">

                        <span v-for="(newsItem, index) in newsItems">

                            <li v-if="isAdPosition(index)" class="homepage-carousel__slide homepage-carousel__js_slide lory__slide" >
                                    <a class="homepage-carousel__article" :href="newsAd.url">
                                    <div class="image-container image-container--1x1">
                                        <div v-if="newsAd.primaryImage">
                                            <img class="hp-bloglist__image" :srcset="srcset(newsAd.primaryImage, 'square')" :sizes="sizes" :src="newsAd.primaryImage.path" :alt="newsAd.titleFormatted | stripTags">
                                        </div>
                                    </div>
                                    <div class="homepage-carousel__date active-highlight" v-html="newsAd.leadFormatted"></div>
                                    <h3 class="homepage-carousel__title active-highlight" v-html="newsAd.titleFormatted"></h3>
                                    </a>
                            </li>

                            <li class="homepage-carousel__slide homepage-carousel__js_slide lory__slide">

                                <span v-if="(category=='news')">
                                    <news-item :article="newsItem" 
                                        :desktop-img-size="263" 
                                        preferred-img-crop="square" 
                                        :index="index"
                                        :slides-per-screen="slidesPerScreen" 
                                        :slide-index="slideIndex"></news-item>
                                </span>
                                <span v-else>
                                    <most-read-item :article="newsItem" 
                                        :desktop-img-size="263" 
                                        preferred-img-crop="square" 
                                        :index="index"
                                        :slides-per-screen="slidesPerScreen" 
                                        :slide-index="slideIndex"></most-read-item>
                                </span>
                            </li>

                        </span>

                        <li v-if="(category=='news')" class="homepage-carousel__slide homepage-carousel__js_slide lory__slide">
                            <a class="homepage-carousel__more-news-link" :href="newsLink">
                                <span class="homepage-carousel__more-news-link__text">More News</span>
                            </a>
                        </li>
                    </ul>
                </div>
                  <span class="js_prev prev" v-show="showArrows()">
                      <img class="slider__arrow" src="~img/arrow_black_left.svg" alt="previous">
                  </span>
                  <span class="js_next next" v-show="showArrows()">
                      <img class="slider__arrow" src="~img/arrow_black_right.svg" alt="next">
                  </span>
            </section>
        </div>

</template>

<script>
    import newsItem from './NewsItem.vue';
    import mostReadItem from './MostReadItem.vue';
    import { lory } from 'lory.js';
    import { mapGetters } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';

    export default {
        props: {
            newsItems: Array,
            newsAds: Array,
            newsLink: String,
            label: {
                type: String,
                default: null,
            },
            category: {
                type: String,
                default: 'news',
            },

        },
        data: function(){
            return{
                slideIndex: 0,
            }
        },
        mixins: [srcsetFromMedia, stripTags],
        computed: {
            ...mapGetters('viewport', ['isSmall']),
            slidesPerScreen: function(){
                return this.isSmall ? 1 : 4;
            }
        },
        methods: {
            showArrows(){
               return this.newsItems.length > this.slidesPerScreen;
            },
            setCurrentSlide(e){
                this.slideIndex = e.detail.currentSlide;
            },
            isAdPosition(index){
                if (this.newsAds.length > 0) {
                    this.newsAd = this.newsAds[0];
                }
                return index === 2 && this.newsAd;
            },
            getWrapperClass() {
                if (this.label==null) {
                   return 'lory__wrapper mobile-full-width ';
                }
               let wrapperClass = this.label.replace(/\s/g, '_') + '--wrapper';
               return 'lory__wrapper mobile-full-width most_read--mobile-full-width ' + wrapperClass.toLowerCase();
            }, 
            
            // this method is to flush the first slide to the left of the page on mobile
            adjustFirstSlide: function(e){
                if( e.detail !== null && e.detail.currentSlide > 1 ){
                    return;
                }
                const offset = e.detail === null || e.detail.currentSlide === 0 ? '-5vw' : '0vw'; 
                this.$el.getElementsByClassName('homepage-carousel__js_slide')[0].style.left = offset;
            },

            initLory: function(isSmall){
                document.addEventListener('after.lory.slide', this.setCurrentSlide);

                if (isSmall) {
                    document.addEventListener('after.lory.init', this.adjustFirstSlide);
                    document.addEventListener('after.lory.slide', this.adjustFirstSlide);
                }
                else{
                    document.removeEventListener('after.lory.init', this.adjustFirstSlide);
                    document.removeEventListener('after.lory.slide', this.adjustFirstSlide);
                    this.$el.getElementsByClassName('homepage-carousel__js_slide')[0].style.left = '0vw';
                }

                const slidesToScroll = this.slidesPerScreen;

                this.$nextTick(function(){
                    lory(this.$el, {
                        classNameFrame: 'homepage-carousel__js_frame',
                        classNameSlideContainer: 'homepage-carousel__js_slides',
                        slidesToScroll: slidesToScroll,
                        rewindOnResize: false
                    });
                });
            },

            getEventName: function(){
                return this.category=='news' ? 'hpc_news_impression' : 'hpc_mostread_impression';
            },

            /**
             * to revive: uncomment this.logImpression();
             */
            logImpression: function(){
                let lastSlide = this.slideIndex + this.slidesPerScreen;
                let items = this.newsItems.slice(this.slideIndex, lastSlide);

                const promoItems = [];
                items.forEach(item => {
                    let title = item.titleFormatted;
                    title.replace(/(<([^>]+)>)/gi, "");
                    String(title).substr(0, 39);
                    let article = {
                      item_id: item.id,
                      item_name: title,
                      item_category: this.category,
                    };
                   promoItems.push(article)
                });
                let eventName = this.getEventName();

                promoItems.forEach(r => {
                    window.gtag("event",
                        eventName,
                        {
                            item_id : r.item_id,
                            item_name: r.item_name,
                            item_category : r.item_category
                        });
                });

            },
    
        },
        watch: {
            isSmall(bool){
                this.initLory(bool);
            },
            slideIndex(oldVal, newVal){
                // this.logImpression();
            }
        },
        mounted: function(){
            this.$nextTick(function(){
                this.initLory(this.isSmall);    
            });

            if (this.newsAds.length > 0) {
                // trim an item off
                this.newsItems.pop();
            }

            // this.logImpression();
        },
        components: {newsItem, mostReadItem},
    };
</script>

<style lang="scss">
    
    @import "scss/variables/breakpoints";

    .homepage-carousel__slider .next, .homepage-carousel__slider .prev {
        top: 32%;
    }

    .homepage-carousel__slides{
        display: block;
    }

    .homepage-carousel__slide{
        width: calc(25% - .75rem);
        padding-right: 1rem;
    }

    .homepage-carousel__header{
        padding: 0 2rem;
    }

    .homepage-carousel__heading{
        margin: 0 0 1rem 0;
        position: relative;
        left: -.5rem;
    }

    

    .homepage-carousel__more-news-link{
        position: relative;
        display: block;
        height: 0px;
        padding: 50%;
        background: #fafafa;
    }

    .homepage-carousel__more-news-link__text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 100;
        text-transform: uppercase;
        font-size: 1.25rem;
    }

    @media screen and (min-width: $breakpoint-small){
        .homepage-carousel__slide:nth-of-type(4n){
            padding-right: 0px;
        }
    }

    @media screen and (max-width: $breakpoint-small){
        .homepage-carousel__frame{
            width: 80vw;
            margin: 0 auto;
            overflow: visible;
        }
    
        .homepage-carousel__slide{
            width: 80vw;
            padding: 0 2rem 0 0;
        }
    
        .homepage-carousel__slide:first{    
            position: relative;
        }
    }
</style>