<template>
    <div>
        <input name="flags" id="flags" type="hidden" :value="getFlags()">
        <input name="discountCode" id="discountCode" type="hidden" :value="getDiscountCode()">
         
        <div class="desktop-flex__center">
            <div id="individual" class="form__select--button recipient-buttons recipient-selected" role="button" tabindex="0" v-on:click="selectRecipient('individual')" v-bind:class="{ 'button--selected' : isSelected('individual') }">Individual</div>

            <div id="gift" class="form__select--button recipient-buttons" role="button" tabindex="0" v-on:click="selectRecipient('gift')" v-bind:class="{ 'button--selected' : isSelected('gift') }">Gift</div>

            <div id="student" class="form__select--button recipient-buttons" role="button" tabindex="0" v-on:click="selectRecipient('student')" v-bind:class="{ 'button--selected' : isSelected('student') }">Student</div>

            <div id="institution" class="form__select--button recipient-buttons" role="button" tabindex="0" v-on:click="selectRecipient('institution')" v-bind:class="{ 'button--selected' : isSelected('institution') }">Institution</div>
        </div>

        <div v-show="isStudent()" class="desktop-flex__center form__input-note subscription-form--note" style="padding-bottom:0;">
            <p style="padding: 0 40px;margin-bottom:0;">
                Subscribe with a valid college or university e-mail address to receive the student discount.
                <span v-show="userIsLoggedIn">Change the e-mail address associated with this account <a href="/profile" target="_Blank" class="active-highlight">{{  username }}</a>?</span>
            </p>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex';
    import addUrlParam from 'lib/utils/AddUrlParam';

    export default {
        props: {
            discount: String,
            type: String
        },
        data: function(){
            return {
                gift: 36,                // subscription + gift flag
                new: 4,                  // new subscription flag
                flags: 4,
                recipient: 'individual',
                discountCode: '',
            }
        },
        computed: {
            ...mapState({
                userIsLoggedIn: (state) => state.user.userIsLoggedIn,
                username: (state) => state.user.username
            }),
        },
        methods: {
            ...mapActions('subscriptions', ['loadSubscriptionOptions', 'setDiscountCode', 'setFlags', 'setPlan']),
            ...mapGetters('subscriptions', ['getDiscountCode', 'getFlags']),

            isSelected: function(recipient){
                return (this.recipient == recipient);
            },
            isStudent: function(){
                return (this.discountCode == 'student');
            },
            selectRecipient: function(recipient){
                this.recipient = recipient;
                if (this.recipient == 'individual') {
                    this.flags = this.new;
                    this.discountCode = this.discount;
                }
                else if (this.recipient == 'gift') {
                    this.flags = this.gift;
                    this.discountCode = this.discount;
                }
                else if (this.recipient == 'student') {
                    this.flags = this.new;
                    this.discountCode = 'student';
                }
                else if (this.recipient == 'institution') {
                    this.flags = this.new;
                    this.discountCode = 'institution';
                }
                this.updateUrl(this.discountCode);

                this.setDiscountCode({ discountCode: this.discountCode });
                this.setFlags({ flags: this.flags });
                this.setPlan(false);
                this.loadSubscriptionOptions({});
                this.$store.commit('subscribe/termSelected', false);
            },
            updateUrl: function(code){
                //let newUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}?code=${code}`;
                //history.replaceState({path: newUrl}, '', newUrl);

                history.pushState(window.history.state, '', addUrlParam(window.location.search, 'code', code));
            },
        },
        created(){
            if (this.type.length > 0 && this.type == 'gift') {
                this.recipient = 'gift';
                this.flags = this.gift;
                this.setFlags({ flags: this.flags });
            }
        },
        beforeMount(){
            if (this.discount) {
                this.setDiscountCode({ discountCode: this.discount});
                if (this.discount == 'student' || this.discount == 'institution') {
                    // local var discount is form type selection in form above
                    this.recipient = this.discount;
                    this.discountCode = this.discount;
                    this.discount = '';
                }
            }
        },
    };
</script>