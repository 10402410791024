<template>
    <ul v-if="galleryAds.length > 0 && isMobile" class="gallery-ads--mobile">
        <li v-for="ad in galleryAdsSegment" class="gallery-ad--mobile" v-html="ad"></li>
    </ul>
</template>
<script>
    /**
     * For gallery print advertisers – the 1x1 formatted ads.
     */
    import { mapState, mapGetters } from 'vuex';
    import { ARTICLES_PER_PAGE } from '../../constants/Common';

    export default {
        props: {
            offset: Number,
            batchIndex: Number,
        },

        data: function(){
            return {
                adCount: 4,
            }
        },

        computed: {
            ...mapGetters({isMobile: 'viewport/isMobile'}),
            ...mapState({
                galleryAds: (state) => state.ads.galleryAds.mobile ? state.ads.galleryAds.mobile : []
            }),

            galleryAdsSegment: function(){
                const idx = this.batchIndex;
                return this.galleryAds[idx] ? this.galleryAds[idx] : [];
            }
        }
    };
</script>
<style lang="scss">
@import "scss/variables/breakpoints";

    .gallery-ads--mobile{
        display: flex;
        justify-content: space-between;
        position: relative;
        //top: -4rem;
        margin-bottom: 100px;
    }
    .gallery-ad--mobile{
        width: 23%;
        height: 0px;
        padding-bottom: 23%;
        max-width: 80px;
    }

    .gallery-ad--mobile img{
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: $breakpoint-medium){
        .gallery-ads--mobile{
            margin-bottom: 60px;
        }
    }

    @media screen and (min-width: $breakpoint-medium){
        .gallery-ads--mobile, .gallery-ad--mobile * {
            display: none;
        }
    }
</style>