<template>
    <li>
        <article class="article-list__article">
            <header>
                <div v-if="showChannel" class="slug--single-article">
                    <a :href="channelLink">{{article.categoryLabel}}</a>
                </div>
                <div class="article-list__primary-image">
                    <div class="image-container image-container--2x1" v-if="typeof article.primaryImage !== 'undefined'">
                        <a :href="article.url">
                            <img v-if="article.primaryImage.path_large" :srcset="srcset(article.primaryImage)" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path_large}`" :sizes="sizes" :alt="article.primaryImage.caption_formatted | stripTags">
                            <img v-else-if="typeof article.primaryImage !== 'undefined'" :srcset="srcset(article.primaryImage)" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path}`" :alt="article.titleFormatted| stripTags">
                        </a>
                    </div>
                </div>
                <h1 class="article-list__h1">
                    <a :href="article.url" v-html="article.hedFormatted"></a>
                </h1>
                <div class="article-list__dek" v-if="article.dekFormatted">
                    <a :href="article.url" v-html="article.dekFormatted"></a>
                </div>
                <div class="article-list__article-meta">
                    <span class="article-list__date-section" v-if="!article.issueTitle">
                        {{ article.published | formatDate('MMMM D, YYYY') }}
                        <span v-if="article.channel === 'diary'"> • {{ article.section }}</span>
                    </span> 
                    <span class="article-list__date-section" v-if="article.issueTitle">
                        <strong>PRINT</strong> {{ article.issueTitle }}
                    </span>
                    <span v-if="article.bylineFormatted" class="article-list__byline">
                         • <span v-html="article.bylineFormatted"></span>
                    </span>
                </div>
            </header>
               <div class="article-list__content" v-html="article.bodyExcerpt"></div>
            <div class="article-list__read-more">
                <a class="article-list__read-more-link" :href="article.url">
                    Read more
                </a>
            </div>
        </article>
    </li>
</template>
<script>
    import article from './mixins/article';
    
    export default {
        name: 'list-article',
        mixins: [article]
    };
</script>