<template>
    <div>
        <div v-for="n in itemCount" v-show="index === (n - 1)">
            <slot :name="`item${n - 1}`"></slot>
        </div>
    </div>
</template>

<script>
    import mod from 'lib/utils/Mod';

    export default {
        props: {
            itemCount: {
                type: Number,
                required: true,
            },
        },

        data: function(){
            return {
                index: 0,    
            };
        },

        methods: {
            loop: function(){
                this.index = mod(this.index + 1, this.itemCount);
                window.setTimeout(this.loop, 5000);
            },
        },

        mounted: function(){
            this.loop();
        }
    };
</script>