import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';
import _sortBy from 'lodash/sortBy';

const search = {
    namespaced: true,

    state: {
        suggestions: [],
        searchResults: [],
        nextPage: 2, 
        allPagesLoaded: false
    },

    mutations: {
        setSuggestions: function(state, suggestions){
            state.suggestions = suggestions;
        },
        searchAssign(state, data){
            Object.assign(state, data);
        },
        addSearchResult(state, data){
            state.searchResults = state.searchResults.concat(data);
        }
    },

    actions: {
        getSuggestions: function({ commit }, input){
    
            const QUERY_STRING = toQueryString({ search: input });
            const SUGGESTIONS_URL = `${API_PATH}/suggest${QUERY_STRING}`; 
            const headers = new Headers({
                'Accept': 'application/json'
            });
            
            fetch(SUGGESTIONS_URL, {
                headers: headers
            }).then( (res) => {
                if(!res.ok){
                    throw new Error(res);
                }
                return res.json();
            }).then( (data)=>{
                const suggestions = _sortBy((data), (result) => {
                    return result.score * -1
                });
                commit('setSuggestions', suggestions);
            }).catch((err)=>{
                console.warn(err);
            })
        },

        fetchSearchResults: function({ commit, state, rootGetters}, input){
            const ACTION_NAME = 'fetchSearchResults';

            if (state.allPagesLoaded) {
                return false;
            }

            if (rootGetters.callIsActive(ACTION_NAME)) {
                return;
            }

            var params = JSON.parse(input);
            params.page = state.nextPage;
            params.pageSize = 12; // TODO
            const queryString = toQueryString(params);

            const PATH = `${API_PATH}/search${queryString}`;
            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                if(res.status === 404){
                    commit('searchAssign', {allPagesLoaded: true});
                }
            }).then((data) => {
                commit('addSearchResult', data.results);
                const allPagesLoaded = data.page >= data.pages;
                var nextPage = state.nextPage + 1;
                commit('searchAssign', {nextPage: nextPage, allPagesLoaded: allPagesLoaded});
                commit('removeActiveCall', ACTION_NAME, { root: true });

                const newUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}${queryString}`;
                history.replaceState({path: newUrl}, '', newUrl);

            }).catch((err) => {
                console.error(err);
            })
        }

    }
};

export default search;