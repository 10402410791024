/**
 * AKA Spotlight
 */

import { API_PATH } from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';

const CAROUSEL_PAGE_SIZE = 60;
const PAGE_SIZE = 12;
const SECTION_FEATURED_FLAG = 256;
const VIEWING_ROOM_TYPE = 600;

export default {
    namespaced: true,

    state: {
        viewingRooms: [],
        nextPage: 2, 
        allPagesLoaded: false,
    },

    mutations: {
        viewingRoomAssign(state, data){
            Object.assign(state, data);
        },
        addViewingRooms(state, data){
            state.viewingRooms = state.viewingRooms.concat(data);
        }
    },

    actions: {
        // used on the homepage // flag: SECTION_FEATURED_FLAG
        fetchRandomViewingRooms({ commit, state }){
            const ACTION_NAME = 'fetchRandomViewingRooms';

            const params = { pageSize: CAROUSEL_PAGE_SIZE };
            if(state.viewingRooms.length > 0){
                params.exclude_ids = state.viewingRooms.map((item) => item.id);
            }
            
            const queryString = toQueryString(params);
            const PATH = `${API_PATH}/items/issue/type/${VIEWING_ROOM_TYPE}${queryString}`;

            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) =>{
                commit('removeActiveCall', ACTION_NAME, { root: true });
                if(res.ok){
                    return res.json();
                }
            }).then((data) => {
                commit('addViewingRooms', data._embedded.items);
            }).catch((err) => {
                console.error(err);
            })
        },

        // used on the listing page
        fetchViewingRooms({ commit, state, rootGetters}){
            const ACTION_NAME = 'fetchViewingRooms';

            if(state.allPagesLoaded){
                return false;
            }

            if(rootGetters.callIsActive(ACTION_NAME)){
                return;
            }

            var params = { page: state.nextPage, pageSize: PAGE_SIZE, 'sort_column': 'e.title', 'sort_order': 'ASC', 'flag' : SECTION_FEATURED_FLAG };
            const queryString = toQueryString(params);

            const PATH = `${API_PATH}/items/type/${VIEWING_ROOM_TYPE}${queryString}`;
            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                if(res.status === 404){
                    commit('viewingRoomAssign', {allPagesLoaded: true});
                }
            }).then((data) => {
                commit('addViewingRooms', data._embedded.items);
                const allPagesLoaded = data.page === data.pages;
                var nextPage = state.nextPage + 1;
                commit('viewingRoomAssign', {nextPage: nextPage, allPagesLoaded: allPagesLoaded});
                commit('removeActiveCall', ACTION_NAME, { root: true });
            }).catch((err) => {
                console.error(err);
            })
        }
    }
}