<template>

    <span>
        <span v-if="webShareApiSupported" class="share-link share-link-button">
            <img src="/static-assets/arrow-copy.png" alt="Share" @click="shareViaWebShare" />
        </span>
        <span v-else>
            <button class="share-link share-link-button" :class="{'share-link-button__clicked': isAddClass}" v-clipboard="copyData" @success="handleSuccess" @error="handleError" >
                <img src="/static-assets/arrow-copy.png" alt="Copy Text">
            </button>
        </span>
    </span>

</template>
<script>
    import { mapState } from 'vuex';
    import VueClipboards from 'vue-clipboards';

    export default {
        props: {
            url: String,
            title: String
        },
        data() {
            return {
                copyData: this.url,
                isAddClass: false,
            }
        },
        methods: {
            handleSuccess(e) {
                this.isAddClass = true;
                alert("url copied");
            },
            handleError(e) {
                console.log(e);
            },
            shareViaWebShare() {
                navigator.share({
                    title: this.title,
                    url: this.url
                })
            }
        },
        computed: {
            webShareApiSupported() {
                return navigator.share
            }
        }

    };
</script>
<style lang="scss">
    .share-link-button {
        padding: 0 !important;
        background-color: #FFF;
    }
    .share-link-button__clicked {
        opacity: 0.6;
    }
</style>