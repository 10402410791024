<template>
  <div class="guide-search-pager">
    <div v-for="page in loadedPages" class="guide-search-pager__page">
      <section v-for="section in page" class="artguide-listings__section artguide-listings__section--search">
        <h2 class="section-heading artguide-listings__section__heading--small">
          artguide {{ section.title }}
        </h2>
        <ul class="artguide-listings__section__list">
          <li v-for="entity in section.entities">
            <template v-if="section.type === 'exhibition'">
              <Listing :entityJson="entityJSON(entity)" />
            </template>
            <template v-else-if="section.type === 'location'">
              <Venue :entityJson="entityJSON(entity)" listItem="true" />
            </template>
          </li>
        </ul>
      </section>
    </div>

    <div ref="loadMarker" class="guide-search-pager__load-marker">
      <div v-if="loading">
        Loading more pages...
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import Listing from 'shared/components/artguide/Listing.vue'
  import Venue from './Venue.vue'

  export default {
    components: { Listing, Venue },
    props: {
      query: String,
      nextPageString: String
    },
    computed: {
      ...mapState({
        loading:      state => state.activeCalls.includes('fetchArtguideSearchResults'),
        nextPage:     state => state.artguideSearch.nextPage,
        loadedPages:  state => state.artguideSearch.resultPages
      }),
      canLoadNextPage() {
        return this.nextPage && !this.loading
      },
    },
    methods: {
      ...mapMutations('artguideSearch', ['setNextPage']),
      ...mapActions('artguideSearch', ['fetchArtguideSearchResults']),
      onWindowScroll() {
        if (!this.canLoadNextPage) {
          return
        }

        // load next page if pager is almost visible
        const windowBottom = window.scrollY + window.innerHeight
        const loadMorePosition = this.$refs.loadMarker.offsetTop - 200
        if (windowBottom > loadMorePosition) {
          this.loadNextPage()
        }
      },
      loadNextPage() {
        if (!this.canLoadNextPage) {
          return
        }

        this.fetchArtguideSearchResults({
          query: this.query,
          page: this.nextPage
        })
      },
      entityJSON(entity) {
        return JSON.stringify(entity)
      }
    },
    mounted() {
      // convert next page prop to vuex data
      this.setNextPage(Number(this.nextPageString) || null)

      window.addEventListener('scroll', this.onWindowScroll)
      this.onWindowScroll()
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onWindowScroll)
    },
  }
</script>

<style lang="scss">
  .guide-search-pager__load-marker {
    margin-top: 20px;
    min-height: 10px;
    font-style: italic;
  }
</style>
