<template>
    <section class="hp-section hp-most_read">
        <homepage-carousel category='mostRead' :news-link="newsLink" :news-items="mostReadArticles" :news-ads="[]" v-if="mostReadArticles.length > 0" label="MOST READ"></homepage-carousel>
    </section>
</template>
<script>
    /**
     * a container for the carousel
     * exists so that the carousel is only initialized once items are loaded
     */
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { WEBROOT } from 'shared/constants/Paths';
    import HomepageCarousel from './HomepageCarousel.vue';

    export default {
        data: function(){
            return {
                newsLink: WEBROOT + '/news'
            }
        },
        computed: {
            ...mapState({
                mostReadArticles: state => state.mostRead.mostReadArticles
            }),

            ...mapGetters({
                isMobile: 'viewport/isMobile'
            })
        },
        methods: {
            ...mapActions('mostRead', ['getMostReadArticles'])
        },
        mounted: function(){
            this.$nextTick(function(){
                this.getMostReadArticles();
            })
        },
        components: { HomepageCarousel },
    };
</script>

<style lang="scss">
    @import 'scss/variables/typography';
    @import "scss/variables/breakpoints";
    
    .most_read--wrapper{
        padding-bottom: 35px;
        border: #c8f0c8 3px solid;
    }
    .most_read--wrapper .homepage-carousel__header {
        padding: 0;
    }
    .most_read--wrapper .homepage-carousel__heading{
        margin: 1.5rem 0;
        position: relative;
        left: 35px;
        font-weight: $medium;
        font-size: 1.125rem;
    }
    .most_read--wrapper section {
        padding:0 35px;
    }

    .hp-most_read {
        position: relative;
        width: 100%;
        box-sizing: border-box;
    }

    .hp-most_read .next, .hp-most_read .prev{
        background: none;
        width: 2.625rem;
        height: 42px;
        height: 2.625rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .hp-most_read .next{
        right: 0px;
        .slider__arrow{
            transform: translate(0%, -50%);
        }
    }

    .hp-most_read .prev{
        left: 0px;
        .slider__arrow{
            transform: translate(-100%, -50%);
        }
    }
    @media screen and (max-width: $breakpoint-medium){
        .most_read--mobile-full-width {
            margin-left: -2.5vw !important;
        }
    }
</style>