<template>
    <div>
        <div class="desktop-flex" v-on:advance="incr()">
            <a href="#" @click.prevent="rewind">
                <slot name="rewind"></slot>
            </a>
            <div class="af-slideshow__controls">
                <a href="#" @click.prevent="decr" class="af-slideshow__prev-link">
                    <slot name="prevbutton">prev</slot>
                </a>
                {{ counterText }}
                <a href="#" @click.prevent="incr" class="af-slideshow__next-link">
                    <slot name="nextbutton">next</slot>
                </a>
            </div>
        </div>
        <div class="af-slideshow">
            <div class="af-slideshow__mask">
            <div :class="slidesClasses" :style="slidesStyle">
                <div class="af-slideshow__slide" :style="slideStyle" v-for="n in itemCount" :key="slotName(n)">
                    <slot :incr="incr" :name="slotName(n)"></slot>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import _throttle from 'lodash/throttle';
    import getQueryParameterByName from 'lib/utils/GetQueryParameterByName';
    import addUrlParam from 'lib/utils/AddUrlParam';
    import KEYCODES from '../../constants/Keycodes';
    import createClickOutsideHandler from 'lib/utils/CreateClickOutsideHandler';
    import mod from 'lib/utils/Mod';

    export default {
        props: {
            itemCount: {
                required: true,
                type: Number,
            }
        },

        data: function(){
            return {
                index: 0,
                totalWidth: 0,
                frameWidth: 0,
                sliding: false,
                x0: 0,
                adjust: 0,
                slideSpacing: 36, // this must match the padding defined in the slide style below
                swipeThreshold: 60,
                dragThreshold: 20,
            }
        },

        computed: {

            ...mapState({
                windowWidth: (state) => state.viewport.windowWidth,
            }),

            slideStyle: function(){
                return `width: ${this.frameWidth}px; padding-right: ${this.slideSpacing}px;`
            },

            slidesStyle: function(){
                return `width: ${this.totalWidth}px; transform: translateX(-${((this.frameWidth + this.slideSpacing) * this.index) + this.adjust}px)`;
            },

            slidesClasses: function(){
                return {
                    'af-slidedhow__slides': true,
                    'sliding': this.sliding,
                }
            },

            counterText: function(){
                return `${parseInt(this.index) + 1} of ${this.itemCount}`;
            }
        },

        methods: {
            slotName: function(n){
                // n is 1-indexed
                return `item${n - 1}`;
            },

            isCurrent: function(n){
                return this.index === n - 1;
            },

            activateAnimation: function(){
                this.sliding = true;
                setTimeout(() => this.sliding = false, 500);
            },

            incr: function(){
                // this.index = (this.index + 1) % this.itemCount;
                this.index = mod(this.index + 1, this.itemCount);
                this.updateUrl();
                this.activateAnimation();
            },

            decr: function(){
                // this.index = (this.index >= this.itemCount) || this.index <= 0 ? 0 : this.index - 1;
                this.index = mod(this.index - 1, this.itemCount);
                this.updateUrl();
                this.activateAnimation();
            },

            updateUrl: function(){
                window.history.pushState(window.history.state, '', addUrlParam(window.location.search, 'idx', this.index));   
            },

            updateDimensions: function(){
                this.frameWidth = (this.$el.parentNode.offsetWidth);
                const children = this.$el.getElementsByClassName('af-slideshow__slide');
                const asArray = Array.from(children);
                this.$nextTick(() => this.totalWidth = asArray.reduce((acc, el, idx) => acc + el.offsetWidth + this.slideSpacing, 0));
            },

            rewind: function(){
                this.index = 0;
                this.activateAnimation();
                this.updateUrl();
            },

            unifyEvent: function(e) {
                // unifies touch and click events 
                return e.changedTouches ? e.changedTouches[0] : e 
            },

            lock: function(e){
                this.x0 = this.unifyEvent(e).clientX;
            },

            move: function(e){
                if(this.x0 || this.x0 === 0){
                    this.adjust = 0;
                    const dx = this.x0 - this.unifyEvent(e).clientX;
                    if (Math.abs(dx) > this.swipeThreshold) {
                        if(dx > 0){
                            if(this.index >= (this.itemCount - 1)){
                                return;
                            }
                            this.incr();
                        }
                        else{
                            this.decr();
                        }
                    }
                }
            },

            drag: function(e){
                if (this.x0 || this.x0 === 0){
                    const dx = this.x0 - this.unifyEvent(e).clientX;
                    if (Math.abs(dx) > this.dragThreshold) {
                        this.adjust = dx;
                    }
                }
            },

            clickHandler: function(e){
                const method = e.pageX > (this.windowWidth / 2) ? this.incr : this.decr;
                const fun = createClickOutsideHandler(['p', 'a', 'figure', 'h1'], method);
                fun(e);
            }
        },

        mounted: function(){
            this.updateDimensions();
            window.addEventListener('resize', _throttle(() => this.updateDimensions(), 250));
            this.$nextTick(() => {
                const idx = getQueryParameterByName('idx');
                if(idx){
                    this.index = idx;
                }
            });
            this.$el.addEventListener('touchstart', this.lock, false);            
            this.$el.addEventListener('touchend', this.move, false);
            this.$el.addEventListener('touchmove', this.drag, false);

            this.$on('advance', this.incr);

            this.$el.addEventListener('mouseup', this.clickHandler);

            document.addEventListener('keyup', (e) => {
                if(e.keyCode === KEYCODES.LEFT){
                    this.decr();
                    return;
                }
                if(e.keyCode === KEYCODES.RIGHT){
                    this.incr();
                    return;
                }
            })
        },
    };
</script>

<style>
    .af-slideshow{
        position: relative;
    }

    .af-slideshow__slides{
        font-size: 0px;
        transition: transform .5s;
    }

    .sliding{
        transition: transform .5s;   
    }

    .af-slideshow__mask{
        width: 100%;
        overflow: hidden;
    }

    .af-slideshow__slide{
        position: relative;
        display: inline-block;
        vertical-align: top;
    }

    .af-slideshow__slide img{
        pointer-events: none;
    }

    .af-slideshow__controls{
        margin-top: 1rem;
        font-size: 1.25rem;
    }

    .af-slideshow__init-next{
        position: absolute;
        right: 0;
        top: 300px;
        transform: translateY(-50%);
    }

    .af-slideshow__init-arrow{
        width: 1.5rem;
    }

    /*.af-slideshow__next-link{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .af-slideshow__prev-link{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }*/

</style>