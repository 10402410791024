<template>
    <a class="more-images" href="#" v-on:click.prevent="showLightbox()">
        <slot><img class="enlarge-icon" src="~img/enlarge-01.svg"></slot>
    </a>
</template>

<script>
    import { mapMutations } from 'vuex';
    import MODALS from 'shared/constants/Modals';

    export default {
        methods: {
            ...mapMutations(['openModal']),

            showLightbox: function() {
                this.openModal(MODALS.VIEWING_ROOM_LIGHTBOX);
            },
        }
    };
</script>