import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';

const contributor = {
    namespaced: true,

    state: {
        results: [],
        nextPage: 2, 
        allPagesLoaded: false,
    },

    mutations: {
        searchAssign(state, data){
            Object.assign(state, data);
        },
        addResults(state, data){
            state.results = state.results.concat(data);
        }
    },

    actions: {
        fetchContributorResults: function({ commit, state, rootGetters}, input){
            const ACTION_NAME = 'fetchContributorResults';

            if (state.allPagesLoaded) {
                return false;
            }

            if (rootGetters.callIsActive(ACTION_NAME)) {
                return;
            }

            var params = JSON.parse(input);
            params.page = state.nextPage;
            params.pageSize = 12;
            var slug = params.slug;
            const queryString = toQueryString(params);

            const PATH = `${API_PATH}/contributor/${slug}${queryString}`;
            const HEADERS = new Headers({
                'Accept': 'application/json',
            });
            commit('addActiveCall', ACTION_NAME, { root: true });
            fetch(PATH, {
                headers: HEADERS,
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                if(res.status === 404){
                    commit('searchAssign', {allPagesLoaded: true});
                }
            }).then((data) => {
                commit('addResults', data._embedded.items);
                const allPagesLoaded = data.page >= data.pages;
                var nextPage = state.nextPage + 1;
                commit('searchAssign', {nextPage: nextPage, allPagesLoaded: allPagesLoaded});
                commit('removeActiveCall', ACTION_NAME, { root: true });

                const newUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}?paged=${params.page}`;
                history.replaceState({path: newUrl}, '', newUrl);

            }).catch((err) => {
                console.error(err);
            })
        }

    }
};

export default contributor;