<template>
    <svg 
    id="Layer_1" 
    data-name="Layer 1" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 229.01 229.01">
        <path class="cls-1" d="M313.5,200.37A113.5,113.5,0,1,1,200.15,86.87,113.5,113.5,0,0,1,313.5,200.37ZM98,200.38c-.3,55.52,45.3,101.52,100.84,102.05C255.42,303,301.74,257.46,302,201,302.33,144.73,257,98.65,201,98.35A102,102,0,0,0,98,200.38Z" transform="translate(-85.5 -85.87)"/><polygon class="cls-2" points="175.23 114.47 83.78 57.32 83.78 171.62 175.23 114.47"/>
    </svg>
</template>

<script>
export default {
    props: {
      iconColor: {
        type: String,
        default: '#000000'
      }
    }
}
</script>

<style scoped>
  .cls-1{
      stroke: v-bind('iconColor');
      stroke-miterlimit: 10;
      stroke-width: 2px;
      }
  .cls-2{fill: v-bind('iconColor');}
</style>