import { GUIDE_SITE_PATH } from 'shared/constants/Paths';

export default {
    GUIDE_SITE_PATH : GUIDE_SITE_PATH,
    PAGES: [
        {
            name: 'ARTGUIDE',
            path: '/artguide',
            top: true,
            slide: true,
        },
        {
            name: 'LOCATIONS',
            path: '/artguide/admin/locations/list',
            top: true,
            slide: true,
        },
        {
            name: 'EXHIBITIONS',
            path: '/artguide/admin/exhibitions/list',
            top: true,
            slide: true,
        },
        {
            name: 'MEMBERS',
            path: '/artguide/admin/subscriptions/list',
            top: true,
            slide: true,
        },
        {
            name: 'NOTIFICATIONS',
            path: '/artguide/admin/notifications/list',
            top: true,
            slide: true,
        },
        {
            name: 'MUST SEE',
            path: '/artguide/admin/exhibitions/list/mustsee',
            top: false,
            slide: true,
        },
        {
            name: 'CITYPAGES',
            path: '/artguide/admin/citypages/list?citypage=Australia',
            top: true,
            slide: true,
        },
        {
            name: 'FEATURED LISTS',
            path: '/artguide/admin/reports/tags/list',
            top: false,
            slide: true,
        },
        {
            name: 'GROUPS',
            path: '/artguide/admin/location/groups/list',
            top: false,
            slide: true,
        },
        {
            name: 'HELP',
            path: '/artguide/admin/help',
            top: true,
            slide: true,
        },
        {
            name: 'CMS',
            path: '/admin/articles',
            top: false,
            slide: true,
            addClass: 'issue-spine__highlight'
        },
        {
            name: 'CRM',
            path: '/crm/users/list',
            top: false,
            slide: true,
            addClass: 'issue-spine__highlight'
        },
        {
            name: 'LOGOUT',
            path: '/artguide/admin/logout',
            top: false,
            slide: true,
        },
    ]
}