<template>
    <div class="cart-item">
        <a :href="pathPurchase">
          <img :class="'cart-item__' + type" :src="'https://www.artforum.com' + cover" :alt="title" />
        </a>
        <div class="cart-item__summary">
            <h3 class="cart-item__title">
                <a :href="pathPurchase">
                    <span class="cart-item__month-year">{{ title }}</span>
                    <span v-if="subtitle" class="cart-item__month-year"><br>{{ subtitle }}</span> 
                    <span v-if="volume" class="cart-item__volume">Vol. {{ volume }}, No. {{volumeNumber}}</span>
                </a>
            </h3>
            <div v-if="type!='product'" class="cart-item__type">{{ type }}</div>
            <div class="cart-item__price">${{ price }}</div>
            <update-quantity :id="id" :item-index="itemIndex" :quantity="quantity" :available="available" v-if="(type == 'print' || type == 'product') && editable"></update-quantity>
            <div class="cart-item__quantity" v-if="!editable">Quantity: {{ quantity }}</div>
        </div>
        <remove-button :itemId="id" :itemIndex="itemIndex" v-if="editable"></remove-button>
    </div>
</template>

<script>
    import quantity from './Quantity.vue';
    import removeButton from './RemoveButton.vue';
    import { mapGetters } from 'vuex';
    import _pick from 'lodash/pick';
    import _values from 'lodash/values';
    import _map from 'lodash/map';

    export default {
        props: {
            entity: Object,
            itemIndex: Number,
            editable: Boolean
        },

        data(){
            return {
                title: this.entity.item.title,
                subtitle: this.entity.item.subtitle,
                volume: this.entity.item.inventory.volume,
                volumeNumber: this.entity.item.inventory.volume_number,
                price: this.entity.price,
                quantity: this.entity.quantity,
                type: this.entity.flags_translated,
                id: this.entity.id,
                itemId: this.entity.item.id,
                cover: this.entity.primary_image.path,
                pathPurchase: this.entity.path_purchase
            }
        },

        computed: {
            ...mapGetters(['cart']),
            available: function(){
                return this.entity.item.inventory.format[this.type].instock;
            }
        },

        components: {'update-quantity': quantity, 'remove-button': removeButton}
    };
</script>

<style lang="scss">
    @import "scss/variables/typography";
    @import "scss/mixins";
    @import "scss/variables/colors";
    @import "scss/variables/breakpoints";

    .cart-item{
        display: flex;
        justify-content: space-between;
        align-item: flex-start;
    }

    .cart-item:last-child{
        border-bottom: none;
    }

    .cart-item__print,
    .cart-item__digital,
    .cart-item__product
    {
        flex-grow: 0;
        flex-shrink: 0;
        width: 155px;
        height: 155px;
        margin-right: 3rem;
    }
    .cart-item__print,
    .cart-item__digital
    {
        @include drop-shadow;
    }
    .cart-item__product
    {
        height: auto;
    }

    .cart-item__summary{
        flex-grow: 1;
    }

    .cart-item__title{
        margin: 0;
        font-weight: $book;
        font-size: 1.25rem;
    }

    .cart-item__month-year{
        text-transform: uppercase;
        font-weight: $medium;
    }

    .cart-item__type{
        margin-bottom: 1rem;
        font-size: 1.25rem;
        text-transform: capitalize;
    }

    .cart-item__price{
        font-size: 1.25rem;
        margin-bottom: 1em;
    }

    .cart-item__quantity{
        font-size: 1.25rem;
    }

    @media screen and (min-width: $breakpoint-medium){
        .cart-item{
            padding: 1rem 1rem 1rem 0px;
            border-bottom: 1px solid $grey60;    
        }
    }

    @media screen and (max-width: $breakpoint-medium){

        .cart-item{
            padding-bottom: 2rem;
        }

        .cart-item__print,
        .cart-item__digital,
        .cart-item__product
        {
            width: 125px;
            height: 125px;
            margin-right: 2rem;
        }

        .cart-item__title, .cart-item__type, .cart-item__price{
            font-size: 1rem;
        }

        .cart-item__quantity{
            font-size: 1rem;
        }

        .cart-item__month-year, .cart-item__volume{
            display: block;
        }

        .cart-item__type, .cart-item__price{
            display: inline-block;
        }

        .cart-item__type::after{
            content: ",";
        }
    }
</style>