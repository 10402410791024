<template>
  <p v-if="showLoginPrompt">
      Already a member of artguide? Sign in
      <span class="guide__participate-login-prompt" @click="onPromptClick">here</span>.
  </p>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import MODALS from 'shared/constants/Modals'

  export default {
    computed: {
      ...mapState({
        hasCheckedLogin: state => state.user.hasCheckedLogin,
        userIsLoggedIn: state => state.user.userIsLoggedIn
      }),
      showLoginPrompt() {
        return this.hasCheckedLogin && !this.userIsLoggedIn
      }
    },
    methods: {
      ...mapMutations(['openModal', 'closeModal']),
      onPromptClick() {
        this.openModal(MODALS.LOGIN_FORM)
      }
    }
  }
</script>

<style>
  .guide__participate-login-prompt {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
