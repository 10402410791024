import { SITE_URL, WEBROOT, SITE_PATH, API_PATH, ADMIN_API_PATH } from './Paths';

const NAVIGATION = 
{
    SITE_URL: SITE_URL,
    WEBROOT: WEBROOT,
    SITE_PATH: SITE_PATH,
    PAGES: [
        {
            name: 'explore',
            path: '/artguide/place/new-york?category=galleries',
            top: true,
            slide: true
        },
        {
            name: 'must see',
            path: '/artguide/mustsee',
            top: true,
            slide: true
        },
        {
            name: 'art fairs',
            path: '/artguide/artfairs',
            top: true,
            slide: true
        },
        {
            name: 'special events',
            path: '/artguide/special',
            top: true,
            slide: true
        },
        {
            name: 'myguide',
            path: '/artguide/myguide',
            top: true,
            slide: true
        },
        {
            name: 'about',
            path: '/artguide/help',
            top: true,
            slide: true
        },
        {
            name: 'become a member',
            path: '/artguide/participate',
            top: true,
            slide: true
        },
        {
            name: 'SUBSCRIBE',
            path: 'https://subscribe.artforum.com/',
            top: false,
            slide: true,
            addClass: 'issue-spine__highlight'
        },
    ],
    SOCIAL_MEDIA_LINKS: [
        {
            name: 'instagram',
            icon: '/static-assets/social-icons/instagram_black.svg',
            path: 'https://www.instagram.com/artforum'
        },
        {
            name: 'twitter',
            icon: '/static-assets/social-icons/twitter_black.svg',
            path: 'https://twitter.com/artforum'
        },
        {
            name: 'youtube',
            icon: '/static-assets/social-icons/yt_play_black.png',
            path: 'https://www.youtube.com/user/ArtforumInt'
        },
        {
            name: 'facebook',
            icon: '/static-assets/social-icons/facebook_black.svg',
            path: 'https://www.facebook.com/artforuminternational/'
        },
    ],
    ACCOUNT_LINKS: [
        {
            name: 'SIGN IN',
            path: '/login'
        },
        {
            name: 'REGISTER',
            path: '/register'
        }
    ],
}

export default NAVIGATION;
