<template>
	<section class="cart__totals">
		<table class="totals__table">
			<tr>
				<td class="totals__label">Subtotal</td>
				<td class="totals__amount">${{ subtotal }}</td>
			</tr>
		</table>
		<slot></slot>
	</section>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState({
				subtotal: state => state.serverCart.subtotal
			})
		}
	};
</script>

<style lang="scss">
	@import "scss/variables/colors";
	@import "scss/mixins";

	.totals__table{
		border-bottom: 1px solid $grey60;
		padding-left: 1rem;
		width: 100%;
	}

	.totals__table td{
		padding-bottom: 1rem;

	}

	.totals__amount{
		text-align: right;
	}

	@media screen and (min-width: $breakpoint-medium){
		.totals__table td:first-child{
			padding-left: 1rem;
		}
	}
</style>