<template>
    <svg 
    id="Layer_1" 
    data-name="Layer 1" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 229.01 229.01">
        <path class="cls-1" d="M509.51,306a113.5,113.5,0,1,1-113.35-113.5A113.5,113.5,0,0,1,509.51,306ZM294,306c-.3,55.53,45.3,101.53,100.84,102.06,56.62.54,102.94-45,103.24-101.46C498.34,250.36,453,204.28,397,204A102,102,0,0,0,294,306Z" transform="translate(-281.51 -191.51)"/><rect class="cls-1" x="71.64" y="61.78" width="30.83" height="105.51"/><rect class="cls-1" x="126.54" y="61.78" width="30.83" height="105.51"/></svg>
</template>

<script>
export default {
    props: {
      iconColor: {
        type: String,
        default: '#000000'
      }
    }
}
</script>
<style scoped>
    .cls-1{stroke:v-bind('iconColor');stroke-miterlimit:10;stroke-width:2px;}
</style>