<template>
    <div>
        <div class="video-wrapper lightbox-video-wrapper">
          <iframe id="ytplayer" type="text/html" 
          :src="src"
          frameborder="0" class="Video"></iframe>
        </div>
        <!-- <p class="video-caption"></p> -->
    </div>
</template>

<script>
    import { SITE_URL } from 'shared/constants/Paths';

    export default {
        props: {
            videoId: {
                type: String,
                required: true,
            }
        },

        computed: {
            src: function(){
                return `//www.youtube.com/embed/${this.videoId.trim()}?autoplay=0&origin=${SITE_URL}&rel=0`;
            }
        }
    };
</script>