<template>
    <article class="hp-bloglist__article">
        <a v-if="index === 0" :href="article.url">
            <div class="image-container image-container--2x1 image-container--1x1-mobile mobile-full-width">
                <picture>
                    <source :media="mediaQueryMobile" :srcset="srcset(article.primaryImage, 'square')" :sizes="sizes">
                    <source :media="mediaQueryDesktop" :srcset="srcset(article.primaryImage, 'landscape')" :sizes="sizes">
                    <img class="hp-bloglist__image" :srcset="srcset(article.primaryImage, 'landscape')" :sizes="sizes" :src="`${IMAGE_BASE_PATH}${article.primaryImage.pathLarge}`" :alt="article.titleFormatted | stripTags">
                </picture>
            </div>
        </a>
        <div class="hp-column__spacing">
            <h3 class="hp-singlefeature__hed"><a :href="article.url" v-html="article.hedFormatted"></a></h3>
            <p class="hp-singlefeature__dek"><a :href="article.url" v-html="article.dekFormatted"></a></p>
            <p v-show="article.bylineFormatted" class="hp-singlefeature__byline"><a :href="article.url" v-html="article.bylineFormatted"></a></p>
        </div>
    </article>
</template>

<script>
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';
    import { BREAKPOINT_MEDIUM } from 'shared/constants/Common';

    export default {
        props: {
            article: {
                required: true,
                type: Object
            },
            index: {
                required: true,
                type: Number
            },
        },
        data(){
            return {
                mediaQueryMobile: `(max-width: ${BREAKPOINT_MEDIUM}px)`,
                mediaQueryDesktop: `(min-width: ${BREAKPOINT_MEDIUM}px)`,
            }
        },

        computed: {
            subtitle: function(){
                return this.article.subtitleFormatted ? this.article.subtitleFormatted : `&mdash; ${this.article.writerFormatted}`;
            }
        },

        mixins: [srcsetFromMedia, stripTags],
    };
</script>