<template>
  <article :class="articleClass">
    <div class="guide-venue__image">
      <picture @click="enlargeImages">
        <source :srcset="imagePath(entity.primaryImage, true)" :media="mediaQueryDesktop" >
        <source :srcset="imagePath(entity.primaryImage, false)" :media="mediaQueryMobile" >
        <img :src="primaryImageUrl" />
      </picture>
    </div>

    <div class="guide-venue__info">
      <div class="guide-venue__info__content">
        <div class="guide-venue__location">
          <h2 v-if="listItem">
            <a :href="entity.path" v-html="entity.name" />
          </h2>
          <div class="guide-venue__location__data">
            <div>
              <a :class="addressClass" v-html="formattedAddress" v-on:click="enterMap" href="#" />
              <template v-if="listItem">, <a :href="`http://${entity.url}`" target="_blank">{{ entity.url }}</a></template>
            </div>
            <div>{{ formattedPhoneNumber }}</div>
          </div>
          <div v-if="!listItem" class="guide-venue__location__data">
            <a :href="`http://${entity.url}`" target="_blank">{{ entity.url }}</a>
          </div>
          <div class="guide-venue__location__data">
            <a :href="`mailto:${entity.email}`" target="_blank">{{ entity.email }}</a>
          </div>
          <div class="guide-venue__location__data">
            {{ entity.hours }}
          </div>
          <div class="guide-venue__location__data" v-html="entity.description"></div>
        </div>
        <ul v-if="listMemberOf.length > 0" class="guide-listing__exhibition__flags">
          <li v-if="sectionType !== 'featuredList'" v-for="list in listMemberOf"><a :href="list.url">{{ list.name }}</a></li>
        </ul>

        <ul class="guide-venue__options">
          <li :class="shareButtonClass" @click="toggleSharePanel">share</li>
        </ul>
      </div>

      <div v-if="isShowingSharePanel" class="guide-listing__share-panel">
        <SharePanel :entity="entity" />
      </div>

      <ImageViewer
        :isVisible="isShowingImageViewer"
        :images="imageViewerImages"
        @close="hideImageViewer"
      />
    </div>
  </article>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { format as formatPhoneNumber } from 'libphonenumber-js'
  import { formatLocationAddress, imagePath } from './util'
  import ImageViewer from 'shared/components/artguide/ImageViewer.vue'
  import SharePanel from 'shared/components/artguide/SharePanel.vue'
  import BREAKPOINT_MEDIUM from 'shared/constants/Common';
  import imageHelper from './mixins/ImageHelper'

  export default {
    components: { SharePanel, ImageViewer },
    props: {
      entityJson: String,
      listItem: { type: Boolean, default: false },
      sectionType: {type: String, default: null},
    },
    mixins: [imageHelper],
    data() {
      return {
        isShowingSharePanel: false,
        isShowingImageViewer: false
      }
    },
    computed: {
      ...mapState({
        isMapMounted: state => state.artguideMap.isMapMounted,
        featuredLists: state => state.artguideApiPage.featuredLists,
      }),
      entity() {
        return JSON.parse(this.entityJson)
      },
      articleClass() {
        return {
          'guide-venue': true,
          'guide-venue--list-item': this.listItem
        }
      },
      addressClass() {
        return {
          'guide-venue__location__data__address': true,
          'guide-venue__location__data__address--map-mounted': this.isMapMounted
        }
      },
      primaryImageUrl() {
        return imagePath(this.entity.primaryImage)
      },
      imageViewerImages() {
        return [this.entity.primaryImage]
      },
      formattedAddress() {
        return this.listItem ? this.entity.address : formatLocationAddress(this.entity)
      },
      formattedPhoneNumber() {
        if (!this.entity.phone) {
          return null
        }

        return formatPhoneNumber(this.entity.phone, 'International').replace(/ /g, '-')
      },
      shareButtonClass() {
        return { active: this.isShowingSharePanel }
      },
      listMemberOf() {
        const locationId = this.entity.id;
        return this.featuredLists
          .filter((list) => { return list.location_ids.indexOf(locationId) !== -1 })
          .map((list) => { return { name: list.name, url: list.site_url } });
      }
    },
    methods: {
      ...mapMutations('artguide', ['addDisplayedLocation', 'removeDisplayedLocation']),
      ...mapMutations('artguideMap', ['enterMapWithLocationId']),
      enterMap() {
        if (this.isMapMounted) {
          this.enterMapWithLocationId(this.entity.id)
        }
      },
      toggleSharePanel() {
        this.isShowingSharePanel = !this.isShowingSharePanel
      },
      enlargeImages() {
        this.isShowingImageViewer = true
      },
      hideImageViewer() {
        this.isShowingImageViewer = false
      }
    },
    watch: {
      entity(entity, oldEntity) {
        if (entity.id !== oldEntity.id) {
          this.removeDisplayedLocation(oldEntity)
          this.addDisplayedLocation(entity)
        }
      }
    },
    mounted() {
      this.addDisplayedLocation(this.entity)
    },
    beforeDestroy() {
      this.removeDisplayedLocation(this.entity)
    }
  };
</script>

<style lang="scss">
  @import "scss/variables/breakpoints";

  .guide-venue {
    margin-top: 25px;
    // display: flex;
  }

  .guide-venue__image {
    & img {
      background-color: #ccc;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .guide-venue__location__data__address--map-mounted {
    text-decoration: underline;
  }

  .guide-venue__info {
    width: 100%;
    font-size: 14px;
  }

  

  .guide-venue__location {
    margin-bottom: 10px;

    & h2 {
      margin: 0 0 5px 0;
      text-transform: uppercase;
    }
  }

  .guide-venue__location__data {
    margin-bottom: 10px;
  }

  .guide-venue__location__data a {
    text-decoration: underline;
  }

  .guide-venue__location__data__address--map-mounted {
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .guide-venue__options {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    user-select: none;

    & li {
      margin: 0 18px 0 0;
      font-style: italic;
      cursor: pointer;

      &.active {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: $breakpoint-medium){
    .guide-venue__info{
      margin-top: 10px;
    }
  }

  @media (min-width: $breakpoint-medium){
    .guide-venue{
      display: flex;
    }

    .guide-venue--list-item .guide-venue__options{
      position: absolute;
      bottom: 0;
    }

    .guide-venue__image {
      width: 320px;
      height: 200px;
      margin-right: 25px;
  
      & img {
        min-width: 320px;
        width: 320px;
        max-height: 200px;
      }
    }

    .guide-venue--list-item {
    margin: 15px 0 30px 0;

    .guide-venue__image {
      width: 200px;

      & img {
        min-width: 200px;
        width: 200px;
        min-height: 200px;
      }
    }

    .guide-venue__location__data {
      margin-bottom: 5px;
    }

    .guide-venue__options {
      
    }
  }

    .guide-venue__info__content {
      position: relative;
      min-height: 200px;
    }
  }
</style>
