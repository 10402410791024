/**
 * The cart for a logged-in user
 */

import { API_PATH }from 'shared/constants/Paths';
import auth from 'lib/auth';
import isArray from 'lib/utils/IsArray';
import _values from 'lodash/values';
import _pick from 'lodash/pick';
import _map from 'lodash/map';

const serverCart = {
    namespaced: true,

    state: {
        cart: [],
        subtotal: 0,
        shipping: {
            us: 0,
            international: 0
        },
        shippingCountry: 'US',
        billingCountry: '',
        shipToBilling: false,
        errors: []
    },

    getters: {
        shippingByRegion: (state) => {
            const country = state.shipToBilling ? state.billingCountry : state.shippingCountry;
            return ( country === 'US' || country === '' ) ? state.shipping.us : state.shipping.international;
        }
    },

    mutations: {
        serverCartAssign(state, data){
            Object.assign(state, data);
        },
    },

    actions: {
        getCart({ commit }){
            const CART_URL = `${API_PATH}/cart`;
                
            const headers = new Headers({
                'Accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            });
    
            fetch(CART_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(res);
                }
                return res.json();
            }).then((data) => {
                const {cart, shipping, subtotal} = data;
                commit('serverCartAssign', { cart: cart, shipping: shipping, subtotal: subtotal });
            }).catch((err) => {
                console.error(err);
            });
        },

        addToCart({ commit, state, dispatch }, options){
            commit('addActiveCall', 'addToCart', { root: true });

            const ADD_ITEM_URL = `${API_PATH}/cart/add`;
            
            const headers = {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            };

            const cart = isArray( options ) ? { cart: options } : {cart: [options]};

            fetch(ADD_ITEM_URL, {
                method: 'POST',
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(cart)
            }).then((res) => {
                if (res.ok){
                    const success = new CustomEvent('addedToCart');
                    document.dispatchEvent(success);
                }
                return res.json();
            }).then((data) => {
                commit('removeActiveCall', 'addToCart', { root: true });
                const { cart, subtotal, errors } = data;
                commit('serverCartAssign', { cart: cart, subtotal: subtotal, errors: errors });
            }).catch((err) => {
                console.log(err);
                commit('removeActiveCall', 'addToCart', { root: true });
            })
        },
        
        removeFromCart({ commit, dispatch }, options){
            const REMOVE_ITEM_URL = `${API_PATH}/cart/remove`;

            const headers = {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            };

            const removed = isArray(options) ? options : [options];
            const data = { cart: _map(removed, (item) => _pick(item, 'id'))};

            commit('addActiveCall', 'removeFromCart', {root: true});

            fetch(REMOVE_ITEM_URL, {
                method: 'POST',
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(data)
            }).then((res) =>{
                if (!res.ok) {
                    throw new Error(res);
                }
                return res.json();
            }).then((data) => {
                commit('removeActiveCall', 'removeFromCart', {root: true});
                const { cart, subtotal } = data;
                commit('serverCartAssign', {cart: cart, subtotal: subtotal });
            }).catch((err) => {
                console.log(err);
                commit('removeActiveCall', 'removeFromCart', {root: true});
            })
        },

        updateCart({commit}, options){
            const UPDATE_CART_URL = `${API_PATH}/cart/update`;

            const headers = {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            };

            const items = isArray(options) ? options : [options];
            const data = _map(items, (item) => _pick(item, ['id', 'quantity']));
            const cart = {cart: data};
 
            commit('addActiveCall', 'updateCart', {root: true});

            fetch(UPDATE_CART_URL, {
                method: 'PATCH',
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(cart)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error('error updating item');
                }
                return res.json();
            }).then((data) => {
                const { cart, subtotal } = data;
                commit('serverCartAssign', { cart: cart, subtotal: subtotal });
                commit('removeActiveCall', 'updateCart', {root: true});
            })
        }
    }
}

export default serverCart;