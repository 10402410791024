<template>
  <NavSelect ref="select" initialValue="city" :options="options" @select="onPlaceSelect" />
</template>

<script>
  import { tier1Cities } from '../cities';
  import navSelector from './nav-selector';
  import NavSelect from './NavSelect.vue';
  import { navigateToPlace } from './util';

  export default {
    components: { NavSelect },
    mixins: [navSelector],
    props: {
      activePlaces: Array,
      place: { type: String, default: null },
    },
    computed: {
      places() {
        const tier1Places = tier1Cities
          .map(city => this.activePlaces.find(item => item.place === city.place))
          .filter(place => place)

        const tier2Places = this.activePlaces.filter(item => {
          return !tier1Cities.find(tier1City => tier1City.place === item.place)
        })

        let places = tier1Places

        if (tier2Places.length > 0) {
          places = places
            .concat([{ separator: true }])
            .concat(tier2Places)
        }

        return places
      },
      options() {
        const places = this.places.map(city => city.place ? city.place : city)
        return this.hasPlace ? places : [{ text: 'city', disabled: true }].concat(places)
      }
    },
    methods: {
      onPlaceSelect(place) {
        if (place === this.place) return

        if (place !== 'city') {
          const placePath = this.activePlaces.find(item => item.place === place).path
          navigateToPlace(placePath, 'galleries')
        }

        if (this.selectHandler) {
          this.selectHandler(place === 'city' ? null : place)
        }
      }
    },
    watch: {
      place(place) {
        this.$refs.select.setValue(place || 'city')
      }
    }
  }
</script>
