<template>
    <div v-if="!selectedPlan">
        <div class="subscription-form--review">
            <a href="#chooseyoursubscription" class="active-highlight">Please select a subscription plan above.</a>
        </div>
    </div>
    <div v-else>
        <div class="subscription-form--review">
            <p class="active-highlight subscription-plan__label" v-html="selectedPlan.title + ' $' + formatPrice(selectedPlan.price)" style="margin-block-end: 0;">
            </p>
            <p style="margin-block-start: .3em;"">
            Once subscribed, log in for immediate digital access to the current issue and the complete archive.  Your subscription will renew automatically.
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState({
                selectedPlan: state => state.subscriptions.selectedPlan,
            }),
        },
        methods: {
            formatPrice: function(price) {
                const regex = new RegExp('\.0{2}');
                if (regex.test(price)) {
                   return Math.round(price);
                }
                return price;
            }
        }
    };
</script>