<template>
    <div v-show="!this.isPrivacyAccepted">
        <transition-group name="drawer-reverse">
            <div class="privacy-acknowledgement" key="privacy-acknowledgement">
                <div class="privacy__content">
                    <div class="privacy__text">
                        We use cookies to ensure that we give you the best experience on our website. Please only use this website if you agree to the terms in our <a :href="webroot + '/privacy'" style="text-decoration:underline;">Privacy Policy</a>
                    </div>

                    <div class="privacy__button">
                        <button v-on:click="savePreference()" class="privacy-form__submit">Accept</button>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<script>
    import { WEBROOT } from 'shared/constants/Paths';

    export default {

       data: function(){
            return {
                isPrivacyAccepted: 0,
                webroot: WEBROOT,
            }
        },

        mounted() {
            if (localStorage.getItem('isPrivacyAccepted')) {
                try {
                    this.isPrivacyAccepted = localStorage.getItem('isPrivacyAccepted');
                } catch(e) {
                    
                }
            }
        },

        methods: {
            savePreference() {
                this.isPrivacyAccepted = 1;
                localStorage.setItem('isPrivacyAccepted', 1);
            }
        }

    };
</script>
<style lang="scss">
    @import "scss/transitions/drawer-reverse";
    @import "scss/variables/breakpoints";
    @import "scss/variables/colors";

    .privacy-acknowledgement {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background: #000;
        color: #FFF;
        z-index: 1;
    }
    .privacy-acknowledgement a {
        color: #FFF;
    }
    .privacy__content {
        display: flex;
        justify-content: space-between;
        width: 90vw;
        margin: 0 auto;
    }
    .privacy__text {
        width: 90%;
        padding: 8px;
        font-size: 90%;
    }
    .privacy__button {
        margin: 8px;
    }
    .privacy-form__submit {
        padding: 2px 8px;
        background-color: $active-highlight;
        border-radius: 0px;
        font-size: .7rem;
        text-transform: uppercase;
        display: block;
        text-align: center;
    }
    @media (max-width: $breakpoint-medium){
        .privacy__content {
            width: 80vw;
            margin: 0 auto;
            display: block;
        }
        .privacy__text {
            font-size: 4vmin;
            padding: 0;
            margin: 10px 0;
            width: 100% !important;
            text-align: center !important;
        }
        .privacy-form__submit {
            padding: 6px 8px;
            margin-bottom: 10px;
            max-width: 80px;
            margin: 0 auto;
        }
    }
</style>