<template>
	<a class="load-more" :class="{ 'load-more--active': isActive }" v-if="!allPagesLoaded" href="#" v-on:click.prevent="loadMore">MORE {{ displayCategory }}</a>
</template>
<script>
	import { ARTICLES_PER_PAGE } from 'shared/constants/Common';
	import activeState from 'shared/components/mixins/ActiveState';
	import loadMore from './mixins/LoadMore';
	import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
	import pruneObject from 'lib/utils/PruneObject';

	export default {
		computed: {
			displayCategory: function(){
				const category = this.tag ? this.tag : this.category;
				return category === 'topten' ? 'top ten' : category.replace(/_/gi, ' ');
			}
		},
		mixins: [ loadMore ]
	};
</script>
<style lang="scss">
	@import "scss/variables/grid";
	@import "scss/variables/typography";
	@import "scss/variables/breakpoints";

	.load-more{
		display: block;
		max-width: 720px;
		margin: 0 0 1rem 0;
		text-align: center;
		border: 1px solid #1f1f1f;
		line-height: 1em;
		padding: .75em;
		text-transform: uppercase;
		color: #1f1f1f;
		font-weight: $demi;
		font-size: 1.125rem;
	}

	.load-more--active{
		// opacity: .5;
		visibility: hidden;
	}

	@media screen and (min-width: $breakpoint-medium){
		.load-more{
			margin: 0px;
		}

		.load-more--centered{
			margin: 0px auto;
		}
	}
</style>