import { API_PATH }from 'shared/constants/Paths';

const MYGUIDE_EXHIBITIONS_URL = API_PATH + '/guide/myguide/exhibitions'
const MYGUIDE_EXHIBITION_CHANGE_URL = API_PATH + '/guide/myguide/exhibition'

const myguide = {
	namespaced: true,

	state: {
		listingsSort: 'date',
		hasLoaded: false,
		placeUrl: { placeUrl: null, pushState: false },
		myguideExhibitionIds: {},
		myguideEntities: []
	},

	getters: {
		// necessary because entities from the server can be removed from myguide while on the myguide page
		currentMyguideEntities: state => {
			return state.hasLoaded
				? state.myguideEntities.filter(item => state.myguideExhibitionIds[item.id])
				: state.myguideEntities
		},

		placeFilteredMyguideEntities: (state, getters) => {
			const entities = state.hasLoaded ? getters.currentMyguideEntities : state.myguideEntities
			const { placeUrl } = state.placeUrl
			return entities.filter(item => !placeUrl || item.location.place_url === placeUrl)
		},
	},

	mutations: {
		setListingsSort(state, sort) {
			state.listingsSort = sort;
		},
		addToMyguide(state, listingId) {
			state.myguideExhibitionIds = { ...state.myguideExhibitionIds, [listingId]: true }
		},
		removeFromMyguide(state, listingId) {
			state.myguideExhibitionIds = { ...state.myguideExhibitionIds, [listingId]: false }
		},
		setPlaceUrl(state, placeUrl) {
			state.placeUrl = placeUrl
		},
		setMyguideExhibitions(state, exhibitionIds) {
			state.myguideExhibitionIds = exhibitionIds
		},
		setMyguideEntities(state, entities) {
			state.myguideEntities = entities
		},
		setHasLoadedMyguide(state, hasLoaded) {
			state.hasLoaded = hasLoaded
		},
		removeAllFromMyguide(state) {
			state.myguideExhibitionIds = {}
		},
		handleMyguidePopState(state, data) {
			let placeUrl = data.myguide ? (data.myguide.placeUrl || null) : null
			state.placeUrl = { placeUrl, pushState: false }
		}
	},

	actions: {
		syncMyguide({ commit, state }) {
			commit('addActiveCall', 'syncMyguide', { root: true })

			const headers = new Headers({
					'Accept': 'application/json',
			})

			return fetch(MYGUIDE_EXHIBITIONS_URL, {
					headers,
					credentials: 'include'
			})
				.then(res => res.json())
				.then(data => {
					// data is an object mapping exhibition id -> true
					let exhibitionIds = data
					commit('setMyguideExhibitions', exhibitionIds)
					commit('setHasLoadedMyguide', true)

					commit('removeActiveCall', 'syncMyguide', { root: true })
				})
		},

		toggleMyguideStatus({ commit, state }, entityId) {
			let shouldRemove = state.myguideExhibitionIds[entityId]
			let method = shouldRemove ? 'DELETE' : 'POST'
			let mutationName = shouldRemove ? 'removeFromMyguide' : 'addToMyguide'
			let reverseMutationName = shouldRemove ? 'addToMyguide' : 'removeFromMyguide'

			// make local change
			commit(mutationName, entityId)

			// make remote change
			commit('addActiveCall', 'toggleMyguideStatus', { root: true })

			let url = `${MYGUIDE_EXHIBITION_CHANGE_URL}/${entityId}`
			return fetch(url, { method, credentials: 'include' }).then(res => {
				if (!res.ok) {
					// reverse the local change
					commit(reverseMutationName, entityId)
				}

				commit('removeActiveCall', 'toggleMyguideStatus', { root: true })
			})
		},

		clearMyguide({ commit, state }) {
			// save local myguide
			const {myguideExhibitionIds} = state

			// make local change
			commit('removeAllFromMyguide')

			// make remote change
			commit('addActiveCall', 'clearMyguide', { root: true })

			return fetch(MYGUIDE_EXHIBITIONS_URL, {
					method: 'DELETE',
					credentials: 'include'
			}).then(res => {
					if (!res.ok) {
						// reverse the local change
						commit('setMyguideExhibitions', myguideExhibitionIds)
					}

					commit('removeActiveCall', 'clearMyguide', { root: true })
				})
		}
	}
}

export default myguide;
