<template>
	<li class="topten-listing__item"> <!-- TODO RESTORE PAYWALL STATUS CLASS -->
        <article class="topten-listing__article desktop-flex">
            <div v-if="showChannel" class="slug--single-article">
                <a :href="channelLink">{{article.categoryLabel}}</a>
            </div>
            <div class="topten-listing__image">
                <div class="image-container image-container--1x1">
                    <a :href="article.url"><img :src="`${IMAGE_BASE_PATH}${article.primaryImage.path}`" :srcset="srcset(article.primaryImage)" :sizes="sizes" :alt="article.titleFormatted | stripTags"></a>
                </div>
            </div>
            <div class="topten-listing__text">
                <h3 class="topten-listing__print-issue"><strong>PRINT</strong> {{ article.issueTitle }}</h3>
                <h1 class="topten-listing__title"><a :href="article.url" v-html="article.titleFormatted"></a></h1>
                <div class="topten-listing__body" v-html="article.bodyExcerpt">
                </div>
            </div>
        </article>
    </li>
</template>

<script>
    import article from './mixins/article';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
	export default {
        mixins: [article]
	};
</script>