<template>
  <div class="audio-player-container">
 
    <af-audio-player-base
      ref="audioPlayer"
      :audio-list="audioList.map(elm => elm.url)"
      :before-play="handleBeforePlay"
      theme-color="#000000"
      theme-color-b="#ec008b"
    />

    <div class="title-social-flex">
        <div class="audio-player-title" id="tickerwrap">
            <div id="ticker">
              {{ currentAudioName || audioList[0].name }}
            </div>
        </div>
        <div class="audio-player-social">
          <ul>
              <li class="active-highlight audio-social-label">Subscribe: </li>
              <li><a href="https://open.spotify.com/show/5e8VkGMHzaW2cFFyl2fFwC" title="Spotify" target="_Blank"><img src="/media/icons/audio/spotify.png" alt="Spotify"></a></li>
              <li><a href="https://podcasts.apple.com/us/podcast/artists-on-writers-writers-on-artists/id1641981235" title="Apple" target="_Blank"><img src="/media/icons/audio/apple.png" alt="Apple"></a></li>
              <li><a href="https://soundcloud.com/user-876649384-910717659" title="Soundcloud" target="_Blank"><img src="/media/icons/audio/soundcloud.png" alt="Soundcloud" class="Soundcloud"></a></li>
          </ul>
        </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['name', 'url'],
  data() {
    return {
      currentAudioName: '',
      audioList: [
        {
          name: this.name,
          url: this.url
        },
      ]
    }
  },

  methods: {

    handleBeforePlay(next) {
      this.currentAudioName = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;
      next();
    }
  }
}
</script>
<style>
    @import "scss/variables/breakpoints";
    @import "scss/variables/typography";
    @import "scss/variables/colors";
    @import "scss/variables/grid";

    .audio-player-title,
    .audio-player-social {
        font-family: $sans-base-font-family;
        font-weight: $medium;
        font-size: 1em;
    }
    .audio-player-title,
    .audio-player-social {
       margin-top: 6px;
    }

    .audio-player .audio__current-time__deliminator,
    .audio-player .audio__current-time,
    .audio-player .audio__duration {
      font-weight: $medium;
    }
    .audio-player-social img {
        width: 18px;
    }
    .audio-player-social img.Soundcloud {
        width: 22px;
    }
    .audio-player-social ul {
        padding: 0;
        margin: 0;
    }
    .audio-player-social li {
        float: left;
        display: inline;
        margin: 0 0 0 7px;
        padding: 0;
    }
    .audio-player-social li a {
        border: none;
    }

    .audio-player-container {
        margin: 5px 0 0 0;
        padding:12px 10px 5px;
        background-color: #eeeff0;
    }

    /** icon on progress bar */
    div.audio__progress-point {
        box-shadow: none ! important;
    }

    .audio-player .audio__progress-point {
        color: $active-highlight;
    }
    .audio-player .audio__progress-point:after {
        display: none;
    }


    .title-social-flex {
        display: flex;
        justify-content: space-between;
    }


    .audio-player .audio__current-time__deliminator {
        margin: 0 5px;
    }

    // audio play buttons

    .audio-player .audio__play-rate {
        display: none;
    }
    .audio-player .audio__play-pause,
    .audio-player .audio__play-start {
        margin: 0 3px;
    }

    .audio-player .audio__play-volume-icon-wrap {
        height: 20px;
        margin-left: 3px;
        margin-right: 3px;
        z-index: 200;
    }

    @keyframes customticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }



    @media screen and (max-width: 900px){

        .audio-player-transcript__wrapper {
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: $sticky-position-top-mobile;
            background-color: #FFF;
            padding-bottom: 3px;
        }
        div.video-transcript__wrapper .toggle {
            margin-left: $mobile-margins-left;
        }

        .audio-player-container {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }

    @media screen and (max-width: 730px){

        .audio-player {
            display: block;
        }

        .audio-player-social,
        .audio-player-title {
            margin-top: 2px;
        }
        .audio-player-social {
           min-height: 20px;
           min-width: 82px !important;
        }

        li.audio-social-label {
           display:none;
        }
        .audio-player-social li {
            float: left;
        }

        .audio-player .audio__play-volume-icon-wrap {
            margin-right: 0;
        }

        .audio__duration {
            padding-bottom: 2px;
        }

        /* Formatting the full-width ticker wrapper background, font color, padding and initial content state (the "padding-left" item) */
        #tickerwrap {
              width: 70%;
              overflow: hidden;
              padding-left: 70%;
        }


        /* Formatting the ticker content background, font color, padding and final content state (the padding-right item) */
        #ticker {
              display: inline-block;
              white-space: nowrap;
              padding-right: 100%;
              -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
              -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
              -webkit-animation-name: customticker;
              animation-name: customticker;
              -webkit-animation-duration: 30s;
              animation-duration: 30s;
        }

    }

</style>