/**
 * State of form
 */

export default {
    namespaced: true,

    state: {
        formSubmitted: false,
        formComplete: false,
    },

    mutations: {
        markFormSubmitted: function(state, val){
            state.formSubmitted = val;
        },
        markFormComplete: function(state, val){
            state.formComplete = val;
        },
    },

};
