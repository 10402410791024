<template>
  <div class="guide-nav-selectors mobile-flex">
    <PlaceSelector :activePlaces="activePlaces" :place="place" :selectHandler="selectPlace" />
    <CategorySelector :category="category" :selectHandler="selectCategory" :disabled="!hasPlace" class="guide-nav-selectors__category-selector" />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import CategorySelector from './CategorySelector.vue';
  import PlaceSelector from './PlaceSelector.vue';
  import { navigateToPlace } from './util'

  export default {
    components: { CategorySelector, PlaceSelector },
    computed: {
      ...mapState({
        activePlaces: state => state.artguide.activePlaces,
        place: state => state.artguide.selectedPlace,
        category: state => state.artguide.selectedCategory
      }),
      hasCategory() {
        return this.category !== null && this.category !== 'category'
      },
      hasPlace() {
        return this.place !== null && this.place !== 'city'
      }
    },
    methods: {
      ...mapMutations('artguide', ['setSelectedPlace', 'setSelectedCategory']),
      selectPlace(place) {
        this.setSelectedPlace(place)
        this.reactToSelection()
      },
      selectCategory(category) {
        this.setSelectedCategory(category)
        this.reactToSelection()
      },
      reactToSelection() {
        // if we have selected place *and* category, then navigate to new route
        if (this.hasPlace && this.hasCategory) {
          const {place, category, activePlaces} = this

          const placePath = activePlaces.find(item => item.place === place).path

          navigateToPlace(placePath, category)
        }
      }
    }
  };
</script>

<style lang="scss">
@import "scss/variables/breakpoints";

@media screen and (min-width: $breakpoint-medium){
  .guide-nav-selectors {
    margin: 40px 0 30px 0;
  }
}

@media screen and (max-width: $breakpoint-medium){

  .guide-nav-selectors {
    margin: .5rem 0 0 0;
  }

  .guide-nav-selectors__category-selector{
    margin-left: 5px !important;
    // flex-shrink: 1;
    // width: auto !important;
  }

  .guide-nav-selectors__category-selector .styled-select{
    min-width: 0px !important;
  }
}
</style>
