<template>
	<div></div>
</template>

<script>
	import { lory } from 'lory.js';
	export default {
		mounted: function(){
    			var initFeaturedVideoSlider = function(e){

    			    var featured = document.querySelector('.js_video_featured_slider');
    			    var video_events = document.querySelector('.js_video_features_events');

    			    var attachOnclickHandlerToLinksFactory = function(func){
    			        return function(el){
    			            var links = el.getElementsByTagName('a');
    			            for(var i = 0; i < links.length; i++){
    			                var link = links[i];
    			                link.onclick = func;
    			            }
    			        }
    			    }

    			    var preventClick = function(e){
    			        e.preventDefault();
    			        return false;
    			    }
			
    			    var disableLinks = attachOnclickHandlerToLinksFactory(preventClick);
    			    var enableLinks = attachOnclickHandlerToLinksFactory(null);
			
    			    video_events.addEventListener('after.lory.init', function(e){
    			        var slides = e.target.querySelectorAll('.video-slider__slide--featured');
    			        
    			        disableLinks(slides[0]); // disable the 'back' slide
    			        
    			        for ( var i = 2; i < slides.length; i++ ){ // disable all the following slides
    			            var slide = slides[i];
    			            disableLinks(slide);
    			        }
    			    });
			
    			    video_events.addEventListener('before.lory.slide', function(e){
			
    			        var slides = e.target.querySelectorAll('.video-slider__slide--featured');
    			        
    			        var slideOut = slides[e.detail.index];
    			        var slideIn = slides[e.detail.nextSlide];
    			        var slideNext = slides[e.detail.nextSlide + 1]
    			        
    			        disableLinks(slideOut);
    			        enableLinks(slideIn);
    			        disableLinks(slideNext);
    			    });


    			    var featured_slider = lory(featured, {
    			        infinite: 1,
    			        enableMouseEvents: true
    			    });
    			}
			
    			var initCategoryVideoSliders = function(e){
    			    var video_sliders = document.querySelectorAll('.js_video_slider');
    			    
    			    if(video_sliders){
    			        for ( let i = 0; i < video_sliders.length; i++ ) {
    			            try {
    			                lory(video_sliders[i], {
    			                    infinite: 3,
    			                    enableMouseEvents: true
    			                })
    			            }
    			            catch (e) {
    			                console.warn(e);
    			            }
    			        }
    			    }
    			}
    			this.$nextTick(function(){
    				initFeaturedVideoSlider();
    				initCategoryVideoSliders();
    			});
		}
	};
</script>