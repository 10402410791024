<template>
  <form class="guide-search-container" @submit.prevent="performSearch" @keydown="onNavKeydown" role="search" aria-label="artguide">
    <label for="artguide_search_input" class="visually-hidden">search artguide</label>
    <input
      id="artguide_search_input"
      class="guide-search-container__search"
      type="search"
      placeholder="search artguide"
      v-model="searchText"
      @focus="onSearchFocus"
      @blur="onSearchBlur"
    />
    <ul v-if="showAutocomplete" class="guide-search-container__autocomplete-list">
      <li
        v-for="option in filteredAutocompleteOptions"
        :ref="option"
        :class="autocompleteOptionClass(option)"
        @mouseenter="onAutocompleteMouseEnter(option)"
        @click="onAutocompleteClick(option)"
      >
        {{ option }}
      </li>
    </ul>
  </form>
</template>

<script>
  import { mapState } from 'vuex'
  import NavKeys from './mixins/NavKeys'

  export default {
    mixins: [NavKeys],
    data() {
      return {
        searchText: '',
        focused: false,
        activeAutocompleteOption: null
      }
    },
    computed: {
      ...mapState({
        activePlaces: state => state.artguide.activePlaces
      }),
      autocompleteOptions() {
        return this.activePlaces.map(item => item.place.toUpperCase())
      },
      showAutocomplete() {
        return this.focused && this.searchText.length > 0
      },
      filteredAutocompleteOptions() {
        const prefix = this.searchText.toUpperCase()
        return this.autocompleteOptions
          .filter(item => {
            return item.indexOf(prefix) === 0 && item !== prefix
          })
      }
    },
    methods: {
      onSearchFocus() {
        this.focused = true
        this.lastFocusTime = Date.now()
      },
      onSearchBlur() {
        setTimeout(() => {
          if (Date.now() - this.lastFocusTime >= 100) {
            this.focused = false
            this.setActiveAutocompleteOption(null)
          }
        }, 100)
      },
      onEnterPress(ev) {
        ev.preventDefault()
        if (this.activeAutocompleteOption) {
          this.focused = false
          this.onAutocompleteClick(this.activeAutocompleteOption)
        } else {
          this.performSearch()
        }
      },
      onArrowPress(arrow) {
        let optionIndex = this.filteredAutocompleteOptions.indexOf(this.activeAutocompleteOption)
        if (optionIndex < 0) optionIndex = -1

        switch (arrow) {
          case 'up':
            optionIndex = optionIndex <= 0 ? this.filteredAutocompleteOptions.length - 1 : optionIndex - 1
            break
          case 'down':
            optionIndex = (optionIndex + 1) % this.filteredAutocompleteOptions.length
            break
        }

        this.setActiveAutocompleteOption(this.filteredAutocompleteOptions[optionIndex])
      },
      onAutocompleteMouseEnter(option) {
        this.setActiveAutocompleteOption(option)
      },
      autocompleteOptionClass(option) {
        return {
          'guide-search-container__autocomplete-list__item': true,
          'guide-search-container__autocomplete-list__item--active': option === this.activeAutocompleteOption
        }
      },
      setActiveAutocompleteOption(option) {
        this.activeAutocompleteOption = option

        if (option) {
          const ref = this.$refs[option]
          if (ref && ref[0]) {
            ref[0].scrollIntoView()
          }
        }
      },
      onAutocompleteClick(option) {
        this.searchText = option
        this.performSearch()
      },
      performSearch() {
        const pathname = window.location.pathname
        const searchUrl = pathname.substring(0, pathname.indexOf('artguide')) + `artguide/search?search=${this.searchText}`
        window.location = searchUrl
      }
    }
  }
</script>

<style>
.guide-search-container__search {
  margin: 40px 0 0 0;
  padding: 0 0 5px 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
}

.guide-search-container__search::-webkit-input-placeholder,
.guide-search-container__search::-moz-placeholder,
.guide-search-container__search:-ms-input-placeholder,
.guide-search-container__search::placeholder {
  color: #000;
}

.guide-search-container__autocomplete-list {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  z-index: 1;
  width: 160px;
  max-height: 300px;
  overflow-y: auto;
}

.guide-search-container__autocomplete-list__item {
  margin: 0;
  padding: 5px 0;
  cursor: pointer;
}

.guide-search-container__autocomplete-list__item:hover,
.guide-search-container__autocomplete-list__item:focus,
.guide-search-container__autocomplete-list__item--active {
  text-decoration: underline;
}
</style>
