import Vue from 'vue';
import Vuex from 'vuex';
import isArray from 'lib/utils/IsArray';

// plugins
import viewportPlugin from 'shared/store/plugins/Viewport';
import location from './plugins/Location';
import keyListeners from './plugins/KeyListeners';
import shippingCountry from './plugins/ShippingCountry';
import adsPlugin       from './plugins/Ads';
import homepagePlugin from './plugins/Homepage';

// constants
import MODALS from 'shared/constants/Modals';

// modules
import clientCart      from './modules/clientCart';
import serverCart      from './modules/serverCart';
import checkout        from './modules/checkout';
import news            from './modules/news';
import mostRead        from './modules/mostRead';
import bookforum       from './modules/bookforum';
import lightbox        from './modules/lightbox';
import viewport        from 'shared/store/modules/viewport';
import user            from 'shared/store/modules/user';
import ads             from './modules/ads';
import relatedArticles from './modules/relatedArticles';
import reCaptcha       from './modules/reCaptcha';
import articles        from './modules/articles';
import navigation      from 'shared/store/modules/navigation';
import search          from './modules/search';
import artguide        from 'shared/store/modules/artguide';
import artguideMap     from './modules/artguideMap';
import artguideSearch  from './modules/artguideSearch';
import artguideApiPage from 'shared/store/modules/artguideApiPage';
import myguide         from 'shared/store/modules/myguide';
import login           from './modules/login';
import subscribe       from './modules/subscribe';
import viewingRoom     from './modules/viewingRoom';
import contributor     from './modules/contributor';
import homepage        from './modules/homepage';
import picks           from './modules/picks';
import print           from './modules/print';
import subscriptions   from './modules/subscriptions';
import form            from './modules/form';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeModal:                   '',
        activeCalls:                   []
    },

    getters: {
        callIsActive: (state) => (callName) => state.activeCalls.indexOf(callName) !== -1,
        // in case a generic function would be cleaner?
        modalIsActive: (state) => (modal) => {
            return state.activeModal === modal;
        },
        slideMenuIsActive: (state) => state.activeModal === MODALS.SLIDE_MENU,
        registerFormIsActive: (state) => state.activeModal === MODALS.REGISTER_FORM,
        searchIsActive: (state) => state.activeModal === MODALS.SEARCH,
        lightboxIsVisible: (state) => state.activeModal === MODALS.LIGHTBOX,
        viewingRoomLightboxIsVisible: (state) => state.activeModal === MODALS.VIEWING_ROOM_LIGHTBOX,
        loginFormIsVisible: (state) => state.activeModal === MODALS.LOGIN_FORM,
        mobileSearchIsActive: (state) => state.activeModal === MODALS.MOBILE_SEARCH,
        popupAdIsActive: (state) => state.activeModal === MODALS.POPUP_AD,

        cart: (state) => state.user.userIsLoggedIn ? state.serverCart.cart : state.clientCart.cart,
        cartErrors: (state) => state.user.userIsLoggedIn ? state.serverCart.errors : state.clientCart.errors,

    },

    mutations: {
        openModal(state, modal){
            state.activeModal = modal;
        },

        closeModal(state){
            state.activeModal = '';
        },

        addActiveCall(state, callName){
            state.activeCalls.push(callName);
        },

        removeActiveCall(state, callName){
            state.activeCalls = state.activeCalls.filter((item)=> item !== callName)
        },
    },

    actions: {
        /*
        *  root-state cart-related actions, with the exception of 'syncCart' are simply
        *  point guard functions which, depending on login status, dispatch actions to the client
        *  or server cart
        */
        getCart({ state, commit, dispatch }){
            if(state.user.userIsLoggedIn){
                dispatch('serverCart/getCart');
            }
            else{
                dispatch('clientCart/getCart');
            }
        },

        addToCart: function({ commit, state, dispatch }, options){
            if (state.user.userIsLoggedIn) {
                dispatch('serverCart/addToCart', options);
            }
            else{
                dispatch('clientCart/addToCart', options);
            }
        },

        removeFromCart: function({ commit, state, dispatch }, options){

            const items = isArray(options) ? options : [ options ]; // accepts single object or an array of objects

            if (state.user.userIsLoggedIn) {
                dispatch('serverCart/removeFromCart', options);
            }
            else {
                dispatch('clientCart/removeFromCart', options);
            }
        },

        updateCart: function({state, dispatch}, options){
            if(state.user.userIsLoggedIn){
                dispatch('serverCart/updateCart', options);
            }
            else{
                dispatch('clientCart/updateCart', options);
            }
        },

        syncCart: function({state, dispatch}){
            const clientCart = localStorage.getItem('cart');
            if ( !clientCart || clientCart.length === 0 || !state.user.userIsLoggedIn ){
                dispatch('getCart');
                return;
            }
            dispatch('addToCart', JSON.parse(clientCart)).then( ()=> {
                localStorage.setItem('cart', '');
            });
        }
    },

    modules: {
        clientCart:      clientCart,
        serverCart:      serverCart,
        checkout:        checkout,
        news:            news,
        mostRead:        mostRead,
        bookforum:       bookforum,
        lightbox:        lightbox,
        viewport:        viewport,
        user:            user,
        ads:             ads,
        relatedArticles: relatedArticles,
        reCaptcha:       reCaptcha,
        articles:        articles,
        navigation:      navigation,
        search:          search,
        artguide:        artguide,
        artguideMap:     artguideMap,
        artguideSearch:  artguideSearch,
        artguideApiPage: artguideApiPage,
        myguide:         myguide,
        login:           login,
        subscribe:       subscribe,
        viewingRoom:     viewingRoom,
        contributor:     contributor,
        homepage:        homepage,
        picks:           picks,
        print:           print,
        subscriptions:   subscriptions,
        form:            form
    },

    plugins: [viewportPlugin, location, keyListeners, shippingCountry, adsPlugin, homepagePlugin]
});
