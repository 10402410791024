<template>
    <transition name="drawer">
        <div class="top-navigation__background" v-show="!hidden">
            <nav class="top-navigation wrapper">
                <ul class="top-navigation__list">
                    <nav-item class="top-navigation__item" v-for="page in pages" :page="page" :deep="true" :key="page.name"></nav-item>
                    
                </ul>
            </nav>
            <hr class="border top-navigation__border">
        </div>
    </transition>
</template>

<script>
import navItem from './NavItemTop.vue';
import { mapState } from 'vuex';
import NAVIGATION from 'shared/constants/Navigation';

export default {
    props: {
        navigation: {
            type: Object,
            required: true,
        },

        hidden: { 
            type: Boolean, 
            default: false 
        }
    },

    data: function(){
        return {
            socialMediaLinks: NAVIGATION.SOCIAL_MEDIA_LINKS,
        }
    },

    computed: {
        pages: function(){
            let pages = this.navigation.PAGES.filter((page) => page.top);
            return pages;
        },
    },

    components: {
        navItem: navItem
    }
};
</script>

<style lang="scss">
    @import "scss/transitions/drawer";
</style>
