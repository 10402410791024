<template>
	<div class="cart__items">
		<transition-group name="cartItem">
			<cart-item v-for="entity in cart" :key="entity.id" :entity="entity" :editable="editable"></cart-item>
		</transition-group>
		<p v-if="cart.length === 0">Your cart is empty. You can purchase back issues from our <a :href="archiveUrl">archive</a>.</p>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import cartItem from './CartItem.vue';
	import { WEBROOT } from 'shared/constants/Paths';

	export default {
		props: {
			editable: Boolean
		},

		computed: {
			...mapGetters(['cart']),
			archiveUrl: () => WEBROOT + '/print/archive'
		},

		components: {
			'cart-item': cartItem,
		}
	};
</script>

<style>
	.cartItem-leave-active {
		transition: all .5s;
	}

	.cartItem-leave-to {
		opacity: 0;
	}
</style>