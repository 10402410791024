<template>
    <div>
        <a name="chooseyoursubscription"></a>
        <input name="product" id="product" type="hidden" :value=product>
        <input name="price" id="price" ref="price" type="hidden" :value=total>
        <input name="product_id" id="product_id" ref="product_id" type="hidden" :value=product_id >
        
        <div class="desktop-flex__center desktop-flex__plans">

            <div v-for="(plan, index) in items" class="form__select--button subscription-plan--button"
                role="button" tabindex="0" v-on:click="selectProduct(index, plan)" v-bind:class="{ 'button--selected' : isSelected(index, plan.price), 'subscription-plan--button_secondary' : isSecondary(plan.id) }">

                <span v-show="!isSecondary(plan.id)" class="subscription-highlight"><em>Best Deal!</em></span>
                <div class="subscription-plan--button__padding">
                    <span class="subscription-plan--label" v-html="plan.title"></span>
                    <span class="subscription-plan--button-price">Select ${{ formatPrice(plan.price) }}</span>
                  
                    <ul class="subscription-plan--list">
                        <span v-html="plan.description"></span>
                    </ul>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import addUrlParam from 'lib/utils/AddUrlParam';
    import getUrlParam from 'lib/utils/GetUrlParam';

    export default {
        props: {
            discount: String,
            type: String
        },
        data: function(){
            return {
                product: 0,
                total: 0,
                product_id: '',
                primary_plans: ['P2Y-US', 'P2Y-ITNL', 'P2Y-CA'],
                autoSelect: true
            }
        },
        computed: {
            ...mapState({
                items: state => state.subscriptions.dynamicallyLoadedPlans,
            }),
        },
        methods: {
            ...mapActions('subscriptions', ['loadSubscriptionOptions', 'setPlan', 'setDiscountCode', 'setFlags']),
            ...mapGetters('subscriptions', ['isGift']),

            isSelected: function(index, price){
                return (this.product == index && price == this.total);
            },
            formatPrice: function(price) {
                const regex = new RegExp('\.0{2}');
                if (regex.test(price)) {
                   return Math.round(price);
                }
                return price;
            },
            setDefault: function(plans){
                const planCount = plans.length;
                if (this.autoSelect && plans.length > 0) {
                    if (this.selected_id != undefined){
                        const pid = this.selected_id;
                        this.regex = new RegExp(pid);
                    }
                    else if (planCount == 1) {
                        const pid = plans[0].id;
                        this.regex = new RegExp(pid);
                    }
                    else {
                        this.regex = new RegExp('P2Y-(US|CA|ITNL)');
                    }

                    plans.forEach((item, index) => {
                        const found = item.id.match(this.regex);
                        if (found != null) {
                            this.selectProduct(index, item);
                            this.autoSelect = false;
                        }
                    });
                    
                }
            },
            selectProduct: function(key, plan){
                this.product = key;
                this.total = plan.price;
                this.product_id = plan.id;

                let flags = 4;
                if (plan.flags != undefined) {
                    flags = plan.flags;
                }
                else {
                    flags = 4;
                }
                if (this.isGift()) {
                   flags =  parseInt(flags) + 32;
                }
                this.setFlags({ flags: flags });

                this.setPlan({
                  productKey: product,
                  price: plan.price,
                  title: plan.title,
                  description: plan.description,
                  id: this.product_id
                  });
                this.$store.commit('subscribe/termSelected', true);
                this.updateUrl(this.product_id);
            },
            isSecondary: function(id){
                return !this.primary_plans.includes(id);
            },
            updateUrl: function(id){
                history.pushState(window.history.state, '', addUrlParam(window.location.search, 'id', id));
            },
        },
        created(){
            this.loadSubscriptionOptions({});
        },
        beforeMount(){
            if (this.type.length > 0 && this.type == 'gift') {
                this.setFlags({ flags: 36 });
            }
            if (this.discount) {
                this.setDiscountCode({ discountCode: this.discount});
            }

            const params = location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=')
                return Object.assign(acc, {[k]: v})
            }, {});
            if (params.id != undefined) {
                this.selected_id = params.id;
            }
        },

        watch: {
            items(newValue, oldValue) {
                this.autoSelect = true;
                this.setDefault(newValue);
            }
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @import "scss/variables/colors";
    @import "scss/variables/typography";

    .subscription-plan--label {
        font-weight: $medium;
    }
    .subscription-plan--button-price {
        color: #FFF;
        padding: 10px 20px;
        margin: 15px 0;
        background: $active-highlight;
        border-radius: 0px;
        border: none;
        display: block;
        text-align: center;
    }
    .subscription-plan--button {
        border: 1px solid #000;
        font-weight: $book;
        font-size: 1.1rem;
        cursor: pointer;
        padding: 0;
        max-width: 300px;
        min-width: 240px;
    }
    .subscription-plan--button__padding {
        padding: 10px 20px;
        
    }
    .subscription-highlight {
        background-color: $active-highlight;
        color: #FFFFFF;
        display: block;
        padding: 5px 0;
        width: 100%;
    }
    .subscription-plan--list {
        padding: 0 10px 0 10px;
    }
    .subscription-plan--list li{
        list-style-type: disc;
        list-style-position: inside;
        font-size: .9em;
        margin-top: 8px;
    }
    .subscription-plan--list a{
        text-decoration: underline;
    }
    .desktop-flex__plans {
        display: flex;
        flex-direction: row;
    }
    .desktop-flex__plans :nth-child(1) { order: 3; }
    .desktop-flex__plans :nth-child(2) { order: 2; }
    .desktop-flex__plans :nth-child(3) { order: 1; }

    
    @media screen and (max-width: $breakpoint-medium){
        .desktop-flex__plans{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
        .subscription-plan--button {
            max-width: 1000px;
        }

        .desktop-flex__plans :nth-child(1) { order: 2; }
        .desktop-flex__plans :nth-child(2) { order: 1; }
        .desktop-flex__plans :nth-child(3) { order: 3; }
    }
</style>