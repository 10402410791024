<template>
    <div v-if="toutHtml" v-html="toutHtml"></div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        props: {
            wrapper: String
        },
        computed: {
            ...mapState({
                toutAd: (state) => state.ads.toutAd
            }),

            toutHtml: function(){
                var parent = document.getElementById(this.wrapper);
                var paragraphs = parent.getElementsByTagName('p');
                if (paragraphs.length > 3) {
                    var center_i = 3;
                }
                else{
                    var center_i = Math.floor(paragraphs.length/2);
                }

                var content = (this.toutAd['html']
                    ? '<div id="article-tout" class="article-tout"><div class="article-tout_content">' + this.toutAd['html'] + '</div>'
                    : '');
                paragraphs[center_i].insertAdjacentHTML( 'afterEnd', content);
                return;
            }
        },

    };
</script>
<style lang="scss">
    @import "scss/variables/colors";
    @import "scss/variables/typography";
    @import "scss/variables/breakpoints";

    .article-tout {
        font-family: $sans-base-font-family;
        line-height: 1.3em;
        font-size: 1rem;
        border-top: 1px solid $active-highlight;
        border-bottom: 1px solid $active-highlight;
        width: 76%;
        margin: 40px auto;
        text-align: center;
    }
    .article-tout_content {
        padding: 20px 54px;
    }
    @media screen and (max-width: $breakpoint-medium){
        .article-tout {
            width: 90%;
        }
        .article-tout_content {
            padding: 5px 10px 5px 15px;
        }
    }
</style>