import _throttle from 'lodash/throttle';
import detectIt from 'detect-it';
import bodyScroll from 'lib/utils/BodyScroll';
import MODALS from 'shared/constants/Modals';
import mobileDetect from 'mobile-detect';

export default function(store){

    window.addEventListener('scroll', _throttle(function(e){
        store.commit('viewport/receiveScrollY', window.scrollY);
    }), detectIt.passiveEvents ? {passive: true} : false);

    window.addEventListener('DOMContentLoaded', function(e){
        store.commit('viewport/setWindowHeight', window.innerHeight);
        store.commit('viewport/setWindowWidth', window.innerWidth);
    });

    window.addEventListener('resize', _throttle(function(e){
    	store.commit('viewport/setWindowHeight', window.innerHeight);
    	store.commit('viewport/setWindowWidth', window.innerWidth);
    }), detectIt.passiveEvents ? {passive: true} : false);

    store.subscribe( (mutation, state) => {
        if ( mutation.type === 'openModal' ) {
            const stopScrollModals = [MODALS.LOGIN_FORM, MODALS.LIGHTBOX, MODALS.REGISTER_FORM, MODALS.POPUP_AD, MODALS.VIEWING_ROOM_LIGHTBOX];
            const formModals = [MODALS.LOGIN_FORM, MODALS.REGISTER_FORM];

            if (stopScrollModals.indexOf(mutation.payload) > -1) {
                bodyScroll.disableScroll();
            }

           /** 
            *  this rule can be removed after Apple fixes this bug
            *  https://bugs.webkit.org/show_bug.cgi?id=176896
            */
            if ( formModals.indexOf(mutation.payload) > -1 ) {
                const md = new mobileDetect(window.navigator.userAgent);
                if (md.is('iOS')){
                    console.log('iOS detected');
                    bodyScroll.iosFormFix();
                }
            }
        }
        else if( mutation.type === 'closeModal' ) {
            bodyScroll.enableScroll();
            bodyScroll.removeIosFormFix();
        }
    });
}