<template>
    <af-overlay v-show="registerFormIsActive" content-class="register__content">
        <form class="form register-form">
            <h1 class="form__header">REGISTER</h1>
            <p class="form__text">For more access to artforum.com and to receive our Print and Dispatch newsletters, art & education, and special offers.</p>
            <p :class="{'form__message--error': errStatus, 'form__message--emphasized': !errStatus}" v-html="registrationMessage"></p>
            <span class="form__field-error"></span>
            <label for="register_form_username" class="visually-hidden">email</label>
            <input id="register_form_username" type="text" class="form__text-input" placeholder="e-mail" name="username" v-model="userEmail" />
            <span class="form__field-error">{{ this.errorMessages.userPasswordMessage }}</span>
            <label for="register_form_password" class="visually-hidden">password</label>
            <input id="register_form_password" type="password" class="form__text-input form__password-input" placeholder="password" name="password" v-model="userPassword" />
            <span class="form__field-error">{{ this.errorMessages.userPasswordConfirmMessage }}</span>
            <label for="register_form_password_confirm" class="visually-hidden">confirm password</label>
            <input id="register_form_password_confirm" type="password" class="form__text-input form__password-input" placeholder="repeat password" name="password-confirm" v-model="userPasswordConfirm" />
            <re-captcha ref="recaptcha" :callback="recaptchaCallback" id="registerCaptcha"></re-captcha>
            <submit-button class="form__submit" :callback="submitForm" associated-action="registerNewUser" button-text="Create Account"></submit-button>
            <p class="form__text">
                Already have an account? <a href="#" v-on:click.prevent="openSigninForm">Sign in here.</a>
            </p>
            <p class="form__fine-print">
                By signing up you agree to receive updates and special offers from <em>Artforum</em>. 
                You may unsubscribe at any time. To receive full access to our issue archive, <a :href="subscribeLink">subscribe</a>.
            </p>
            <p class="form__fine-print">By submitting your information, you agree to the <a href="https://pmc.com/privacy-policy/" title="privacy policy" target="_Blank">Privacy Policy</a> and <a href="https://pmc.com/terms-of-use/" title="terms and conditions" target="_Blank">Terms of Use</a> (including as applicable the mandatory arbitration and class action waiver provisions). We use vendors to collect and store information.</p>
        </form>
    </af-overlay>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
    import overlay from 'shared/components/container/Overlay.vue';
    import reCaptcha from './ReCaptcha.vue';
    import submitButton from 'shared/components/forms/SubmitButton.vue';
    import recaptchaIntegration from './mixins/recaptchaIntegration';
    import MODALS from 'shared/constants/Modals'; 
    import { WEBROOT } from 'shared/constants/Paths';
    import _values from 'lodash/values';
    export default {
        name: 'af-register-form',

        props: {
            origin: {
                type: String,
                required: false,
                default: '',
            },
        },

        data(){
            return {
                userEmail: '',
                userPassword: '',
                userPasswordConfirm: '',
                errorMessages: {
                    userPasswordMessage: '',
                    userPasswordConfirmMessage: '',
                },
                privacyLink: `${WEBROOT}/privacy`,
                termsLink: `${WEBROOT}/terms`,
                subscribeLink: 'https://subscribe.artforum.com/',
            }
        },
        computed: {
            ...mapState({
                registrationMessage: state => state.user.registrationMessage,
                errStatus: state => state.user.registrationError
            }),
            
            ...mapGetters(['registerFormIsActive']),

            hasErrors: function(){
                return _values(this.errorMessages).reduce((totalMessage, message) => totalMessage + message, '').length > 0;
            }
        },
        methods: {
            ...mapMutations(['openModal', 'closeModal']),
            ...mapMutations('user', ['setRegistrationMessage', 'setRegistrationError']),

            ...mapActions('user', ['registerNewUser', 'userLogin']),

            resetFields: function(){
                this.userEmail = '';
                this.userPassword = '';
                this.userPasswordConfirm = '';
            },

            resetMessages: function(){
                this.setRegistrationMessage('');
                this.setRegistrationError('');
                for ( let prop in this.errorMessages ){
                    if(this.errorMessages.hasOwnProperty(prop)){
                        this.errorMessages[prop] = '';
                    }
                }
            },

            submitForm: function(){
                if ( !this.recaptchaIsCompleted() || this.hasErrors ) {
                    return false;
                }
                const user = { email: this.userEmail, password: this.userPassword, origin: this.origin };
                const data = { user };
                this.addRecaptchaToRequest(data);
                this.registerNewUser(data)
                .then(() => {
                    this.$refs.recaptcha.reset();
                    if(!this.errStatus){
                        this.registrationComplete = true;
                    }
                    this.userLogin({ _username: this.userEmail, _password: this.userPassword });
                    this.resetFields();
                })
                .catch((err) => {
                    console.log(err.errorMessage);
                    this.resetFields();
                    this.$refs.recaptcha.reset();
                });
            },

            openSigninForm: function(){
                this.openModal(MODALS.LOGIN_FORM);
                this.resetMessages();
            },
        },
        
        watch: {
            registerFormIsActive: function(val){
                this.resetFields();
                this.resetMessages();
            },
            userPassword: function(inputVal){
                if( inputVal.length > 0 && inputVal.length < 8 ){
                    this.errorMessages.userPasswordMessage = 'Your password must be at least 8 characters long.';
                }
                else{
                    this.errorMessages.userPasswordMessage = '';
                }
            },
            userPasswordConfirm: function(inputVal){
                if(inputVal.length >= this.userPassword.length && inputVal !== this.userPassword){
                    this.errorMessages.userPasswordConfirmMessage = 'Your passwords do not match.';
                }
                else{
                    this.errorMessages.userPasswordConfirmMessage = '';
                }
            }
        },

        components: {
            afOverlay: overlay,
            reCaptcha,
            submitButton
        },
        mixins: [
            recaptchaIntegration
        ]
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @import "scss/variables/grid";
    .register__content{
        margin-left: $mobile-margins-left;
        margin-right: $mobile-margins-right;
    }
    @media screen and (min-width: $breakpoint-medium){
        .register__content{
            max-width: 510px;
            margin: 0 auto;
        }
    }
</style>