<template>
    <a :class="{'search__toggle': true, 'search__toggle--hidden': slideMenuIsActive}" @click.prevent="callback" @keyup.enter="callback" tabindex="0">
        <img class="search__icon" src="~img/search.svg" alt="Open Search">
    </a>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        props: ['callback', 'active'],
        computed: {
            ...mapGetters(['slideMenuIsActive'])
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @media screen and (max-width: $breakpoint-medium){
        .search__toggle--hidden{
            display: none;
        }

        .search__icon{
            bottom: -2px;
        }
    }

    @media screen and (min-width: $breakpoint-medium){
        .search__icon{
            top: -2px;
        }
    }
</style>