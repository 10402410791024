<template>
    <ul class="results-list">
        <li v-for="row in results" class="results-list__article">

            <div v-if="hasMedia(row)">
                <figure class="results-list__image">
                    <a :href="row.url">
                        <img v-if="hasMedia(row)" :srcset="srcset(row.primaryImage, 'square')" :sizes="sizes">
                    </a>
                </figure>
            </div>
            <div v-else class="results-list__image-blank"></div>

            <div class="results-list__text">

                <div class="results-list__meta">

                    <span class="results-list__category" v-if="row.channel">
                        {{ row.channel }}
                    </span>
                    <span class="results-list__category" v-else="row.typeTranslated">
                        {{ row.typeTranslated }}
                    </span>

                    <span class="results-list__date" v-if="row.issueTitle">
                        {{ row.issueTitle }}
                    </span>
                    <span class="results-list__date" v-else-if="row.published">
                        {{ row.published | formatDate('MMMM D, YYYY') }}
                    </span>

                </div>

                <h1 class="results-list__h1">
                    <a :href="row.url" v-html="row.titleFormatted"></a>
                </h1>

                <div v-if="row.writerFormatted" class="results-list__author" v-html="row.writerFormatted"></div>

                <section class="results-list__content">
                    <p v-if="row.bodyExcerpt" v-html="row.bodyExcerpt"></p>
                </section>

            </div>

        </li>
    </ul>
</template>

<script>
    import { mapState } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import formatDate from 'shared/components/mixins/FormatDate';

    export default {
        mixins: [srcsetFromMedia, formatDate],
        computed: {
            ...mapState({
                results: (state) => state.contributor.results,
            }),
        },

        methods: {
            hasMedia: function(row){
                return row.hasOwnProperty('primaryImage');
            }
        }
    };
</script>