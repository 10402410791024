<template>
  <div class="guide-api-page">
    <div v-if="noResults" class="artguide-empty-state">
      <div class="guide-api-page__empty-state-message" v-html="emptyStateMessage"></div>
      Find more on the <a :href="artguidePath">artguide homepage</a>.
    </div>
    <div v-else>
      <ListingsSection
        v-for="(section, index) in sections"
        :key="section.title.index"
        :title="section.title"
        :subtitle="section.subtitle"
        :entities="section.entities"
        :subsections="section.subsections"
        :show-all-link="showAllLink"
        :all-active-toggle="allActiveToggle"
        :section-type="sectionType"
      ></ListingsSection>
    </div>

    <div v-if="loadError" class="guide-api-page__load-error">
      Error loading results. Refresh the page to try again.
    </div>

    <div ref="loadMarker" class="guide-api-page__load-marker">
      <div v-if="loading">
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * This component is used for most artguide listing pages, except the landing page, which is twig.
   */
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import ListingsSection from './ListingsSection.vue'
  import { SECTION_TYPES, makeSections } from './sections'
  import { ARTGUIDE_PAGE_SIZE } from 'shared/constants/Common';

  export default {
    components: { ListingsSection },
    props: {
      apiPath: String,
      sectionType: String,
      emptyStateMessage: String,
      artguidePath: String,
      sectionOptions: { type: String, default: null},
      showAllLink: String,
      allActiveToggle: {type: String, default: null},
      page: {
        type: String,
        default: ''
      },
      lastPageLoaded: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('viewport', ['scrollY']),
      ...mapState('artguideApiPage', ['nextPage', 'loadedEntities', 'totalEntities', 'totalPages', 'loadError', 'loading', 'hasLoaded']),
      ...mapGetters({canLoadNextPage: 'artguideApiPage/canLoadNextPage'}),
      ...mapGetters('viewport', ['scrollBottom']),
      loadingMessage() {
        return this.nextPage === 1 ? 'Loading...': 'Loading next page...'
      },
      sectionOptionsParsed(){
        return this.sectionOptions ? JSON.parse(this.sectionOptions) : null;
      },
      sections() {
        const options = this.sectionOptionsParsed;
        return makeSections(this.sectionType, this.loadedEntities, options)
      },
      noResults() {
        return !this.canLoadNextPage && !this.loading && this.loadedEntities.length === 0
      }
    },
    watch: {
      hasLoaded(val, oldVal) {
        // if we just loaded for the first time, select something on the map
        if (val && !oldVal) {
          if (this.loadedEntities.length > 0) {
            const firstEntity = this.loadedEntities[0]
            this.selectMapLocation({
              id: firstEntity.location ? firstEntity.location.id : firstEntity.id,
              method: 'loadedApiPage'
            })
          }
        }
      },
      scrollY () {
        // load next page if pager is almost visible
        const loadMorePosition = this.$refs.loadMarker.offsetTop - 200
        if (this.scrollBottom > loadMorePosition) {
          this.loadNextPage()
        }
      },
    },
    methods: {
      ...mapMutations('artguideApiPage', ['setNextPage', 'setApiPath']),
      ...mapMutations('artguideMap', ['selectMapLocation']),
      ...mapActions('artguideApiPage', ['fetchApiPageResults', 'fetchFeaturedListsAction']),
      loadNextPage() {

        if (!this.canLoadNextPage) {
          return;
        }

        if (this.lastPageLoaded) {
          return;
        }

        var total = this.nextPage * ARTGUIDE_PAGE_SIZE;


        if (this.totalEntities != null && this.nextPage > this.totalPages) {
            this.lastPageLoaded = true;
        }

        // TODO - remove this duct tape
        // const pageSize = this.sectionOptionsParsed && this.sectionOptionsParsed.category === 'must see' ? 200 : ARTGUIDE_PAGE_SIZE

        this.fetchApiPageResults({
          apiPath: this.apiPath,
          pageSize: ARTGUIDE_PAGE_SIZE,
        })
      },
    },
    mounted() {
      this.setApiPath(this.apiPath)
      this.loadNextPage()
      this.fetchFeaturedListsAction()
    },
  };
</script>

<style lang="scss">
  .guide-api-page__load-error {
    margin-top: 20px;
    font-size: 20px;
    font-style: italic;
  }

  .guide-api-page__load-marker {
    margin-top: 20px;
    min-height: 10px;
    font-style: italic;
  }

  .guide-api-page__empty-state-message{
    margin-top: 4rem;
  }
</style>
