import { HOMEPAGE_MULTIFEATURE_TIMEOUT } from '../../constants/Common';

export default function(store){
    store.subscribe((mutation, state) => {
        if(mutation.type === 'homepage/setCurrentMultiFeature'){
            window.clearTimeout(window.multifeatureTimeout);
            window.multifeatureTimeout = window.setTimeout(() => {
                store.commit('homepage/setCurrentMultiFeature', (mutation.payload + 1) % store.state.homepage.multiFeatureCount)
            }, HOMEPAGE_MULTIFEATURE_TIMEOUT);
        }

        if(mutation.type === 'homepage/setCurrentMultiVideo'){
            window.clearTimeout(window.multivideoTimeout);
            window.multifeatureTimeout = window.setTimeout(() => {
                store.commit('homepage/setCurrentMultiVideo', (mutation.payload + 1) % store.state.homepage.multiVideoCount)
            }, HOMEPAGE_MULTIFEATURE_TIMEOUT);
        }
    });
}