
export const tier1Cities = [
  { place: 'New York', place_url: 'new-york' },
  { place: 'Los Angeles', place_url: 'los-angeles' },
  { place: 'London', place_url: 'london' },
  { place: 'Berlin', place_url: 'berlin' },
  { place: 'Paris', place_url: 'paris' },
  { place: 'Milan', place_url: 'milan' },
  { place: 'Beijing', place_url: 'beijing' },
  { place: 'Hong Kong', place_url: 'hong-kong'},
  { place: 'Mexico City', place_url: 'mexico-city'},
]
