<template>
    <div>
        <div class="desktop-flex form-wrapper__single-col">
            <input name="code" id="code" type="text" placeholder="Discount code? Apply it here." v-model="code" class="form__text-input form__input-inline"  v-bind:class="{ 'form__text--hidden' : getDiscountCode() }">

            <div id="submitDiscount" class="form__button-inline discount-button" role="button" tabindex="0" v-on:click="search()" v-bind:class="{ 'form__select--hidden' : getDiscountCode() }">enter</div>
        </div>

        <div v-show=isDiscount() class="active-highlight">
            Discount reflected in prices below.
            <br>
            rate: {{ getDiscountCode() }}
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        data: function(){
            return {
                code: '',
                currentCode: '',
            }
        },
        methods: {
            ...mapActions('subscriptions', ['loadSubscriptionOptions', 'findDiscountCode']),
            ...mapGetters('subscriptions', ['getDiscountCode']),

            search: function(){
                this.findDiscountCode(this.code);
                this.currentCode = this.code;
            },
            isDiscount: function() {
                return this.getDiscountCode() && this.getDiscountCode() != 'institution';
            }
        },

        watch: {
          currentCode(newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSubscriptionOptions({discount: newVal});
            }
          }
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";

    .form__select--hidden,
    .form__text--hidden {
        display: none;
    }
    .form-wrapper__single-col{
        max-width:450px;
        margin: 0 auto;
    }
    @media screen and (max-width: $breakpoint-medium) {
        .form-wrapper__single-col {
           max-width: 100%;
        }
    }
</style>