/**
 * Loads and manages data for the artguide map header
 */

import { API_PATH }from 'shared/constants/Paths';

const EXHIBITIONS_BY_PLACE_URL = API_PATH + '/guide/exhibitions'

const artguideMap = {
	namespaced: true,

	state: {
		isMapMounted:       		false,
		isMapAvailable:             true,
		mapConfig:          		null,
		isMapFullscreen:    		false,
		selectedLocationId: 		null,
		selectedLocationMethod: null,
		entryLocationId:    		null,
		nearbyExhibitions:  		{},
		showMobileModal:       false,
	},

	mutations: {
		setMapMounted(state, mounted) {
			state.isMapMounted = mounted
		},
		setMapAvailable(state, available){
			state.isMapAvailable = available;
		},
		setMapConfig(state, config) {
			state.mapConfig = config
		},
		setShowMobileModal(state, show){
			state.showMobileModal = show
		},
		triggerNearbyMode(state) {
			state.mapConfig = { ...state.mapConfig, exploreStyle: 'nearby' }
		},
		pushMapConfig(state, config) {
			state.mapConfig = config
			let historyData = { map: { isFullscreen: state.isMapFullscreen, entryLocationId: state.entryLocationId, config } }
			history.pushState(historyData, `${document.title} - Map`, `?map=${historyData.map.isFullscreen}&place_url=${config.place_url}`)
		},
		selectMapLocation(state, { id, method }) {
			state.selectedLocationId = id
			state.selectedLocationMethod = method
		},
		setMapNearbyExhibitions(state, options) {
			let { key, data } = options
			state.nearbyExhibitions = Object.assign({ [key]: data }, state.nearbyExhibitions)
		},
		enterMapWithLocationId(state, id) {
			if(!state.isMapAvailable){
				state.showMobileModal = true;
				return;
			}
			let historyData = { map: { isFullscreen: true, entryLocationId: id, selectedLocationId: id, selectedLocationMethod: 'entry', config: state.mapConfig } }
			history.pushState(historyData, `${document.title} - Map`, `?map=true&entryLocationId=${id}`)
			state.isMapFullscreen = true
			state.entryLocationId = id
			state.selectedLocationId = id
			state.selectedLocationMethod = historyData.map.selectedLocationMethod
		},
		enterMapWithoutLocation(state) {
			if(!state.isMapAvailable){
				state.showMobileModal = true;
				return;
			}
			let historyData = { map: { isFullscreen: true, entryLocationId: null, selectedLocationId: null, selectedLocationMethod: null, config: state.mapConfig } }
			history.pushState(historyData, `${document.title} - Map`, `?map=true`)
			state.isMapFullscreen = true
			state.entryLocationId = null
			state.selectedLocationId = null
			state.selectedLocationMethod = historyData.map.selectedLocationMethod
		},
		handleMapPopState(state, data) {
			if (!data.map) {
				state.isMapFullscreen = false
				state.entryLocationId = null
				state.mapConfig.currentPlace = null
				state.mapConfig.otherPlaces = null
			} else {
				state.isMapFullscreen = data.map.isFullscreen || false
				state.entryLocationId = data.map.entryLocationId || null
				state.selectedLocationId = data.map.selectedLocationId || null
				state.selectedLocationMethod = data.map.selectedLocationMethod || null
				if (data.map.config) {
					state.mapConfig = data.map.config
				}
			}
		}
	},

	actions: {
		fetchMapPlaceExhibitions({ commit, state }, options) {
			if(!state.isMapAvailable){
				return;
			}
			let { place_url } = options

			commit('addActiveCall', 'fetchMapPlaceExhibitions', { root: true })

			const url = `${EXHIBITIONS_BY_PLACE_URL}/${place_url}`
			return fetch(url, {
				credentials: 'include'
			})
				.then(res => res.ok ? res.json() : [])
				.then(data => {
					const entities = data._embedded.items;
					let newConfig = Object.assign({}, state.mapConfig, options, {
						entities: entities,
						previousConfig: state.mapConfig
					})
					commit('pushMapConfig', newConfig)
					const firstLocationId = data.length > 0 && data[0].location && data[0].location.id
					commit('selectMapLocation', { id: firstLocationId || null, method: 'fetchMapPlaceExhibitions' })
					commit('removeActiveCall', 'fetchMapPlaceExhibitions', { root: true })
				})
		},

		fetchMapNearbyExhibitions({ commit, state }, options) {
			if(!state.isMapAvailable){
				return;
			}
			let { place_url, geo } = options

			commit('addActiveCall', 'fetchMapNearbyExhibitions', { root: true })

			const url = geo
				? `${EXHIBITIONS_BY_PLACE_URL}/geo?latitude=${geo.latitude}&longitude=${geo.longitude}`
			 	: `${EXHIBITIONS_BY_PLACE_URL}/${place_url}`
			const key = geo ? JSON.stringify(geo) : place_url

			return fetch(url, { credentials: 'include' })
				.then(res => res.ok ? res.json() : [])
				.then(data => {
					const entities = data._embedded.items
					commit('setMapNearbyExhibitions', { key, data: entities})
					commit('removeActiveCall', 'fetchMapNearbyExhibitions', { root: true })
				})
		}
	}
}

export default artguideMap;
