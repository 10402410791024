<template>
    <section class="hp-section hp-viewing-room mobile-full-width">
        <div v-if="items.length > 0">
            <header class="hp-viewing-room__header desktop-flex">
                <h3 class="hp-viewing-room__heading">
                    Spotlight on select {{ getMonth() }} Advertisers
                </h3>
            </header>
            <carousel :items="items"></carousel>
        </div>
    </section>
</template>
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { WEBROOT } from 'shared/constants/Paths';
    import Carousel from './Carousel.vue';

    const VIEWING_ROOM_ITEM_COUNT = 52;
    export default {
        components: { Carousel },
        props: {
            index: {
                type: Number,
                default: 0,
            },
        },

        data(){
            return {
                spotlightLink: WEBROOT + '/spotlight',
                loaded: false,
            }
        },
        computed: {
            ...mapState({
                viewingRoom: state => state.viewingRoom.viewingRooms,
            }),

            ...mapGetters({
                isMobile: 'viewport/isMobile',
                scrollBottom: 'viewport/scrollBottom',
            }),
            startIndex: function(){
                return this.index * VIEWING_ROOM_ITEM_COUNT;
            },

            items: function(){
                return this.viewingRoom.slice(this.startIndex, this.startIndex + VIEWING_ROOM_ITEM_COUNT);
            },
        },
        methods: {
            ...mapActions('viewingRoom', ['fetchRandomViewingRooms']),

            getMonth: function() {
                const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

                const d = new Date();
                let m = d.getMonth();

                if (m >=5 && m <= 7) {
                    return 'Summer';
                }

                let name = month[d.getMonth()];
                return name;
            }
        },
        mounted: function(){
            this.$nextTick(function(){
                if(this.scrollBottom > this.$el.offsetTop && !this.loaded){
                    this.fetchRandomViewingRooms();
                }
            })
        },

        watch: {
            scrollBottom: function(scrollBottom){
                const options = { 
                    category: this.category,
                    pageSize: this.pageSize 
                };
                if ( scrollBottom >= this.$el.offsetTop && !this.loaded ) {
                    this.fetchRandomViewingRooms(options);
                    this.loaded = true;
                }
            }
        },
    };
</script>

<style lang="scss">
    @import 'scss/variables/typography';
    @import 'scss/variables/breakpoints';
    @import 'scss/variables/colors';

    .hp-viewing-room {
        background: $grey-background;
        position: relative;
        width: 100%;
        padding: .9rem 1.5rem 2rem;
        box-sizing: border-box;
    }

    .hp-viewing-room__heading{
        margin: 0 0 1rem 1rem;
        font-weight: $book;
        font-size: 1.25rem;
    }

    .hp-viewing-room__notice{
        font-weight: $book;
        position: relative;
        right: .5rem;
    }

    .hp-viewing-room .next, .hp-viewing-room .prev{
        width: 2.625rem;
        height: 42px;
        height: 2.625rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .hp-viewing-room .next{
        right: 0px;
        .slider__arrow{
            transform: translate(0%, -50%);
        }
    }

    .hp-viewing-room .prev{
        left: 0px;
        .slider__arrow{
            transform: translate(-100%, -50%);
        }
    }

    @media screen and (max-width: $breakpoint-medium){

        .hp-viewing-room{
            padding: 2rem 0;
        }

        .hp-viewing-room__header{
            padding: 0 2rem;
        }

        .hp-viewing-room__heading{
            margin: 0 0 1rem 0;
            position: relative;
            left: -.5rem;
        }

        .hp-viewing-room__notice{
            margin-bottom: 2rem;
        }

        .hp-viewing-room .next{
            right: .75rem;
        }

        .hp-viewing-room .prev{
            left: .75rem;
        }
    }

</style>