const ARTICLES_PER_PAGE = 12;
const BREAKPOINT_MEDIUM = 840; //px
const BREAKPOINT_SMALL = 600; //px

const HOMEPAGE_MULTIFEATURE_TIMEOUT = 15000;

export {
    ARTICLES_PER_PAGE,
    BREAKPOINT_MEDIUM,
    BREAKPOINT_SMALL,
    HOMEPAGE_MULTIFEATURE_TIMEOUT
}