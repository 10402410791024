/**
 * TODO: This is a left-over from when we were storing credentials
 * in localStorage. We are using cookies for authentication. Can probably be deleted.
 */

import storage from 'local-storage-fallback';
import NAVIGATION from 'shared/constants/Navigation';

const API_URL = NAVIGATION.API_URL;
const LOGIN_URL = API_URL + "login_check";

export default {
    setToken (token) {
        storage.setItem('id_token', token);
    },
    clearToken () {
        storage.removeItem('id_token');
    },
    requestAnonymousToken () {
        let creds = {
            _username: 'guest',
            _password: 'guest'
        }
        login(creds);
    },
    checkAuth () {
        var jwt = storage.getItem('id_token');
        return jwt ? true : false;
    },
    getAuthHeader () {
        return {
            'Authorization': 'Bearer ' + storage.getItem('id_token')
        }
    },
    getToken () {
        return storage.getItem('id_token');
    }
}