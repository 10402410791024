<template>
    <div v-on:click="isComplete">
        <slot></slot>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import EvaluateField from '../../mixins/EvaluateField';

    export default {
        mixins: [EvaluateField],
        data () {
            return {
                isScrolledPast: false,
                completed: false
            }
        },
        props: {
            wrapper: {
                required: true,
            },
            ids: {
                required: true,
            },
            setter: {
                required: true,
            },
        },

        methods: {
            isComplete: function($e){

               var isComplete = this.ids.map((id) => this.hasValue(id)).reduce((accumulator, value) => accumulator && value);
               this.completed = isComplete;

               this.$store.commit(this.setter, isComplete);
            }
        },
        computed: {
            ...mapState('viewport', ['scrollY', 'scrolledDown']),

            positionY(){
                //return this.$el.offsetTop;
                let offset = 500;
                let h = document.getElementById(this.wrapper).offsetHeight;
                let t = document.getElementById(this.wrapper).scrollTop;
                return h + t + offset;
            },
        },
        watch: {
            scrollY: function(scrollPos){
                this.isScrolledPast = scrollPos > this.positionY;
                if (this.isScrolledPast) {
                    this.isComplete();
                }
            }
       },
    };
</script>
