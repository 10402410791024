import { API_PATH } from 'shared/constants/Paths';
import auth       from 'lib/auth';
import toQueryString from 'lib/utils/ToQueryString';

const user = {
	namespaced: true,

	state: {
		hasCheckedLogin: false,
		userIsLoggedIn: false,
        username: '',
        email: '',
		loginMessage: '',
		registrationMessage: '',
        registrationError: false,
        passwordRecoverySent: false,
        passwordRecoveryMessage: '',
	},

	mutations: {
		setLoginStatus(state, status){
			state.hasCheckedLogin = true;
            state.userIsLoggedIn = status;
        },

        setLoginMessage(state, message){
            state.loginMessage = message;
        },

        setRegistrationMessage(state,message){
            state.registrationMessage = message;
        },

        setRegistrationError(state, bool){
            state.registrationError = bool;
        },

        setPasswordRecoverySent(state, status){
            state.passwordRecoverySent = status;
        },

        setPasswordRecoveryMessage(state, message){
            state.passwordRecoveryMessage = message;
        },

        setUsername(state, username){
            state.username = username;
        },

        setEmail(state, email){
            state.email = email;
        },

    },

    actions: {

        /**
         * data format - { user: {email: EMAIL, password: PASSWORD}, g_recaptcha_response: RECAPTCHA_RESPONSE }
         */
        registerNewUser({commit}, options){

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(options);

                const headers = new Headers({
                    'Content-Type': 'text/plain;charset=UTF-8',
                    'Accept'      : 'application/json'

                });

                commit('addActiveCall', 'registerNewUser', { root: true });

                fetch(API_PATH + '/user/create', {
                    method : 'POST',
                    headers: headers,
                    body   : data
                }).then((res) => {
                    const errStatus = res.ok ? false : true;
                    commit('setRegistrationError', errStatus);
                    return res.json();
                }).then((data) => {
                    if (data.message) {
                        commit('setRegistrationMessage', data.message);
                    }
                    commit('removeActiveCall', 'registerNewUser', { root: true });
                    resolve(true);
                }).catch((err) => {
                    commit('setRegistrationMessage', 'There was an error processing your request.')
                    commit('removeActiveCall', 'registerNewUser', { root: true });
                    reject(false);
                });
            })
        },

        /**
         * creds take the form of {_username: email, _password: password}
         */
        userLogin({ commit, dispatch }, creds){
            const LOGIN_URL = API_PATH + '/login_check';

            const headers = new Headers({
                'Content-Type': 'application/json',
                'Accept'      : 'application/json',
            });

            commit('addActiveCall', 'userLogin', { root: true });

            let reload = creds.reload != undefined ? creds.reload : true;

            fetch(LOGIN_URL, {
                method     : 'POST',
                headers    : headers,
                credentials: 'same-origin',
                body       : JSON.stringify(creds)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error('You have entered an invalid username or password. Please try again.')
                }
                return res.json();
            }).then((data) => {
                auth.setToken(data.token);
                if ( auth.checkAuth() ) {
                    commit('setUsername', data.data.username);
                    commit('setEmail', data.data.email);
                    commit('setLoginStatus', true);
                    commit('setLoginMessage', 'Hello, ' + data.data.username + '.');
                    commit('removeActiveCall', 'userLogin', { root: true });
                    //dispatch('syncCart', null, { root: true }); - not necessary when reloading page. leaving here for reference in case.
                    if (reload) {
                        window.location.reload(true);
                    }
                }
            }).catch((err) => {
                commit('setLoginMessage', err.message);
                commit('removeActiveCall', 'userLogin', { root: true });
                return(false);
            })
        },

        userLogout({ commit }){
            const LOGOUT_URL = API_PATH + '/logout';

            const headers = new Headers({
                'Accept': 'application/json',
            });

            return fetch(LOGOUT_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if (res.ok) {
                    commit('setLoginStatus', false);
                    window.location.reload(true);
                }
            });
        },

        checkLoginStatus({ commit }){
            const TOKEN_CHECK_URL = API_PATH + '/token_check';

            const headers = new Headers({
                'Accept': 'application/json',
            });

            return fetch(TOKEN_CHECK_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if (res.ok) {
                    commit('setLoginStatus', true);
                }
                else {
                    commit('setLoginStatus', false);
                }
            }).catch((error) => {
                console.warn(error);
            });
        },

        requestPasswordReset({ commit }, options){
            const PASSWORD_RESET_URL = API_PATH + '/user/recover';

            const data = JSON.stringify(options);

            const headers = new Headers({
                'Accept': 'application/json'
            });

            commit('addActiveCall', 'requestPasswordReset', { root: true });

            fetch(PASSWORD_RESET_URL, {
                method: 'POST',
                headers: headers,
                body: data
            }).then((res) => {
                if (res.ok) {
                    commit('setPasswordRecoverySent', true);
                }
                return res.json();
            }).then((data) => {
                if ( data.errorMessage ){
                    commit('setPasswordRecoveryMessage', data.errorMessage);
                }
                commit('removeActiveCall', 'requestPasswordReset', { root: true });
            }).catch((err) => {
                commit('removeActiveCall', 'requestPaswordReset', {root: true});
            });
        },

        // also accepts non-email username
        searchUsers({ commit }, email){
            return new Promise((resolve, reject) => {
                const ACTION_NAME = 'searchUsers';
                const QUERY_STRING = toQueryString({email: email});
                const PATH = `${API_PATH}/user/search${QUERY_STRING}`;

                const headers = new Headers({
                    'Accept': 'application/json'
                });

                commit('addActiveCall', ACTION_NAME, {root: true});

                fetch(PATH, {
                    method: 'GET',
                    headers: headers,
                }).then((res) => {
                    commit('removeActiveCall', ACTION_NAME, {root: true});
                    return res.json();
                }).then((data) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                })
            })
        }
	}
}

export default user;
