<template>
    <div>
        <div class="video-wrapper lightbox-video-wrapper">
        <iframe :src="src" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="Video"></iframe>
        </div>
        <p class="video-caption"></p>
    </div>
</template>

<script>
    export default {
        props: {
            videoId: {
                type: String,
                required: true,
            }
        },

        computed: {
            src: function(){
                return `//player.vimeo.com/video/${this.videoId.trim()}?color=f8efea&title=0&byline=0&portrait=0`
            }
        }
    };
</script>