<template>
    <div style="padding-bottom: 25px;">
        <div v-if="hasMessage(loginMessage, registrationMessage)">
            <p class="form__message--error" v-html="loginMessage"></p>
            <p class="form__message--error" v-html="registrationMessage"></p>
        </div>

        <div v-else-if="userIsLoggedIn">
            Hello, {{ username }}.
            <hr>
        </div>

        <div v-if="(showEmailField && userIsLoggedIn)">
            <input name="email" type="text" class="form__text-input" placeholder="email address" v-model="authEmail" >
            <p class="form__input-note">
                {{ this.description }}
            </p>
        </div>

        <div v-show="!userIsLoggedIn">
            <div class="desktop-flex">
                <transition name="fade">
                    <input type="text" class="form__text-input form__input-inline" v-model="email" placeholder="enter your email address or username">
                </transition>

                <submit-button class="form__button-inline form__button-inline__highlighted" v-show="!searched || (!found && !validEmail)" :callback="search" associated-action="searchUsers" button-text="continue" ></submit-button>
            </div>

            <div v-show="!searched" class="desktop-flex">
                <p class="form__input-note">
                    {{ this.description }}
                </p>
            </div>

            <div class="desktop-flex">
                <p v-if="searched && validEmail" v-html="foundText"></p>
            </div>
            <div>
                <transition name="fade">
                    <re-captcha v-show="searched && !found && validEmail" ref="recaptcha" :callback="recaptchaCallback" id="emailSearchCaptcha"></re-captcha>
                </transition>
            </div>
            <div>
                <transition name="fade">
                    <div v-show="searched && found" class="desktop-flex">
                        <input type="password" class="form__text-input form__input-inline" v-model="password" placeholder="password" v-on:keyup.enter="onEnter">
                        
                        <submit-button class="form__button-inline form__button-inline__highlighted" :callback="submit" :associated-action="submitAssociatedAction" button-text="continue"></submit-button>
                    </div>
                </transition>
            </div>
            <div>
                <transition name="fade">
                    <div v-show="searched && !found && validEmail">

                        <input type="password" class="form__text-input form__input-inline" v-model="password" placeholder="password" @keydown="nameKeydown($event)">

                        <div class="desktop-flex">
                            <input type="password" class="form__text-input" v-model="passwordConfirm" placeholder="repeat password">

                            <submit-button class="form__button-inline form__button-inline__highlighted" :callback="submit" :associated-action="submitAssociatedAction" button-text="continue"></submit-button>
                        </div>
                    </div>
                </transition>

            </div>
            <div class="desktop-flex">
                <p v-show="searched && validEmail" class="form__fine-print"><a :href="recoverLink" target="_blank">Forgot your password?</a></p>
            </div>

        </div>

    </div>
</template>

<script>
    /**
     * This component searches for a username. If the user exists, it presents a login form.
     * If it doesn't exist, it presents a registration form.
     */
    import { mapState, mapMutations, mapActions } from 'vuex';
    import { WEBROOT } from 'shared/constants/Paths';
    import reCaptcha from './ReCaptcha.vue';
    import submitButton from 'shared/components/forms/SubmitButton.vue';
    import recaptchaIntegration from './mixins/recaptchaIntegration';

    export default {
        mixins: [recaptchaIntegration],
        components: { reCaptcha, submitButton },

        props: {
            description: {
                type: String,
                required: false,
                default: 'We will e-mail your receipt and grant you online access here.',
            },
            showEmailField: {
                type: Boolean,
                required: false,
                default: false
            },
            showEmail: {
                type: String,
                required: false,
                default: ''
            }
        },

        data: function(){
            return {
                email: '',
                searched: false,
                found: false,
                validEmail: false,
                password: '',
                passwordConfirm: ''
            }
        },

        computed: {
            ...mapState({
                userIsLoggedIn: (state) => state.user.userIsLoggedIn,
                username: (state) => state.user.username,
                useremail: (state) => state.user.email,
                termIsSelected: (state) => state.subscribe.termIsSelected,
                loginMessage: (state) => state.user.loginMessage,
                registrationMessage: (state) => state.user.registrationMessage,
                registrationError: state => state.user.registrationError
            }),

            foundText: function(){
                return this.found ? 'Welcome back! Please enter your password.' : 'Welcome! Please enter a password to create your account.';
            },

            authEmail: function(){
                return this.useremail.length > 0 ? this.useremail : this.showEmail;
            },

            submitAssociatedAction: function(){
                return this.found ? 'userLogin' : 'registerNewUser';
            },

            recoverLink: () => `${WEBROOT}/recover`
        },

        methods: {
            ...mapActions('user', ['registerNewUser', 'userLogin', 'searchUsers']),

            ...mapMutations('user', ['setRegistrationMessage']),

            search: function(){
                if (this.email.length == 0) {
                    this.setRegistrationMessage('Please enter an email or username.');
                }
                else {
                    this.setRegistrationMessage('');
                    this.searchUsers(this.email).then((data) => {
                        this.searched = true;
                        this.found = data.found;
                        this.validEmail = data.valid;
                        if (!this.found && !this.validEmail) {
                            this.setRegistrationMessage('Please enter a valid email.');
                        }
                    });
                }
            },

            onEnter: function () {
               this.submit();
            },

            hasMessage: function(m1, m2){
                return (m1 || m2);
            },

            nameKeydown: function(e) {
                if (/(<|>)$/.test(e.key)) {
                    this.setRegistrationMessage('The characters <> are not allowed.');
                    e.preventDefault();
                }
            },

            submit: function(){
                if(this.found){
                    this.userLogin( {_username: this.email, _password: this.password, reload: false} );
                    return;
                }
                if(this.password !== this.passwordConfirm){
                    this.setRegistrationMessage('The passwords do not match.')
                    return;
                }

                const user = {email: this.email, password: this.password};
                const data = { user };
                this.addRecaptchaToRequest(data);
                this.registerNewUser(data)
                    .then(() => {
                        if (this.registrationError) {
                            //console.log(this.registrationMessage);
                            this.$refs.recaptcha.reset();
                        }
                        else {
                            this.userLogin( {_username: this.email, _password: this.password, reload: false} );
                        }
                    });
            },
        },

        watch: {
            email: function(val){
                if(val.length === 0){
                    this.searched = false;
                }
            },

            passwordConfirm: function(inputVal){
                if(inputVal.length >= this.password.length && inputVal !== this.password){
                    this.setRegistrationMessage('The passwords do not match.')
                }
                else{
                    this.setRegistrationMessage('');
                }
            },

            password: function(inputVal){
                if(inputVal.length >= this.password.length && inputVal !== this.password){
                    this.setRegistrationMessage('The passwords do not match.')
                }
                else{
                    this.setRegistrationMessage('');
                }
            },
        }
    };
</script>
