<template>
	<article class="video-category-listing__video">
		<a :href="article.url">
            <div class="image-container image-container--2x1">
                <img v-if="article.primaryImage.path" :srcset="srcset(article.primaryImage)" :sizes="sizes" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path}`" :alt="article.titleFormatted | stripTags">
            </div>
        </a>
        <div>
           <h3 class="video__category video__category--category">{{ article.section }}</h3>
           <h1 class="video__title--category">
               <a :href="article.url" v-html="article.titleFormatted"></a>
           </h1>
           <section v-html="article.bodyExcerpt" class="video__content--category"></section>
        </div>

	</article>
</template>

<script>
  import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
  import article from './mixins/article';

	export default {
		props: {
			article: Object
		},
    mixins: [ srcsetFromMedia, article ]
	};
</script>