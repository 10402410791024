<template>
  <div :class="containerClass">
    <template v-if="!isCountry">
      <img class="guide-map-location__image" :src="imageUrl" @click="selectSelf" />

      <h2 class="guide-map-location__venue-name">
        <a :href="location.path" v-html="location.name" />
      </h2>
      <div class="guide-map-location__address" v-html="location.address" />
      <ul class="guide-map-location__entities">
        <li v-for="(entity, i) in entities" :key="entity.id">
          <a :href="entity.path" class="guide-map-location__entity-title">
            <h4 v-html="splitTitles[i].artistName" />
            <div v-if="splitTitles[i].exhibitionTitle" v-html="splitTitles[i].exhibitionTitle" />
          </a>
          <div class="guide-map-location__entity-dates">
            {{ entityDates(entity) }}
          </div>
          <div class="guide-map-location__myguide">
            <MyguideToggle :entityId="entity.id" />
          </div>
        </li>
      </ul>
    </template>

    <h2 v-else class="guide-map-location__venue-name" @click="fetchCountryPlaceExhibitions" v-html="countryTitle" />
  </div>
</template>

<script>
  /**
   * A listing as it appears in the map sidebar
   */
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { formatExhibitionDates, splitExhibitionTitle, imagePath } from './util'
  import MyguideToggle from 'shared/components/artguide/MyguideToggle.vue'

  export default {
    components: { MyguideToggle },
    props: {
      location: Object,
      entities: Array,
      isCountry: Boolean
    },
    computed: {
      ...mapState({
        selectedLocationId: state => state.artguideMap.selectedLocationId
      }),
      isSelected() {
        return this.location.id === this.selectedLocationId
      },
      containerClass() {
        return {
          'guide-map-location': true,
          'guide-map-location--selected': this.isSelected,
          'guide-map-location--country': this.isCountry
        }
      },
      imageUrl() {
        const entityWithImage = this.entities.find(entity => entity.images.length > 0)
        if (entityWithImage) {
          return imagePath(entityWithImage.images[0])
        }

        return imagePath(this.location.primaryImage)
      },
      countryTitle() {
        return this.isCountry ? `${this.location.place } (${this.entities.length})` : null
      },
      splitTitles () {
        return this.entities.map(entity => splitExhibitionTitle(entity.title))
      }
    },
    methods: {
      ...mapMutations('artguideMap', ['selectMapLocation']),
      ...mapActions('artguideMap', ['fetchMapPlaceExhibitions']),
      selectSelf() {
        this.selectMapLocation({ id: this.location.id, method: 'sidebarClick' })
      },
      entityDates(entity) {
        return formatExhibitionDates(entity)
      },
      fetchCountryPlaceExhibitions() {
        // on place click from country map, fetch exhibitions for place and reload map
        this.fetchMapPlaceExhibitions({
          place_url: this.location.place_url,
          title: this.location.place,
          placeStyle: 'countryPlace'
        })
      }
    }
  }
</script>

<style lang="scss">
  .guide-map-location {
    margin-bottom: 30px;

    & h3, & h4 {
      margin: 0;
    }
  }

  .guide-map-location--selected {
    & .guide-map-location__venue-name,
    & .guide-map-location__venue-name a {
      color: #f00;
    }
  }

  .guide-map-location--country {
    margin-bottom: 8px;

    & .guide-map-location__venue-name {
      font-weight: 400;
    }
  }

  .guide-map-location__image {
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-height: 140px;
    max-height: 220px;
    background-color: #ccc;
    object-fit: cover;
    object-position: center; /* Center the image within the element */
    cursor: pointer;
  }

  .guide-map-location__venue-name {
    margin: 6px 0 0 0;
    text-transform: uppercase;
    cursor: pointer;
  }

  .guide-map-location__address {
    margin-top: 2px;
    font-size: 13px;
  }

  .guide-map-location__entities {
    margin-top: 6px;
    & li {
      margin-bottom: 15px;
    }
  }

  .guide-map-location__entity-title {
    display: block;
    margin-bottom: 5px;
  }

  .guide-map-location__entity-dates {
    font-size: 14px;
    line-height: 1.2;
  }

  .guide-map-location__myguide {
    margin-top: 3px;
    font-size: 15px;
  }
</style>
