<template>
    <div>
        <div class="shipToBilling">
            <input :id="inputId" class="form__checkbox" type="checkbox" name="visibility" v-model="checked" v-on:change="updateShipToBillingField">
            <label :for="inputId"><span></span></label>
            <span style="vertical-align: top">{{ label }}</span>
        </div>
        <div v-show="visible">
            <slot></slot>
        </div>
    </div>
</template>
<script>
  /**
   * This is a special input that toggles the visibility of the shipping address
   * and also controls a hidden field on the checkout form (the actual shipToBilling data).
   */
	import { mapState, mapMutations } from 'vuex';

	export default {
		props: {
			label: String,
			inverted: Boolean,
			default: Boolean,
			inputId: String,
			nobillingterm: Number,
		},

		data: function(){
			return {
				checked: this.default,
				noBilling: this.nobillingterm
			}
		},

		computed: {
		    ...mapState({
		        termSelectedVal: (state) => state.subscribe.termSelectedVal
		    }),
			visible: function(){
			    if (this.termSelectedVal == this.noBilling) {
			        this.checked = true;
			    }
				return this.inverted ? !this.checked : this.checked;
			}
		},

        methods: {
            ...mapMutations('serverCart', ['serverCartAssign']),

            updateShipToBillingField(){
                const shipToBillingField = document.getElementById('order_shipToBillingAddress');
                const val = this.checked ? 1 : 0;
                if(shipToBillingField !== null) shipToBillingField.value = val;

                this.serverCartAssign({ shipToBilling: this.checked });
            }
        }
    };
</script>

<style>
    .shipToBilling{
        margin: 1rem 0;
    }
</style>