
const DEFAULT_LOGIN_PROMPT = ''

const login = {
	namespaced: true,

	state: {
    prompt: DEFAULT_LOGIN_PROMPT
	},

	mutations: {
		resetLoginPrompt(state) {
			state.prompt = DEFAULT_LOGIN_PROMPT
		},
		setLoginPrompt(state, prompt) {
			state.prompt = prompt
		}
	}
}

export default login
