<template>
    <transition name="fade">
        <div class="test" v-show="show">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
/**
 * This component shows/hides based on a number of namespaced vuex state conditions.
 * It's purpose is to add a dynamic workflow to a statically server-rendered form.
 *
 * The current implementation assumes a namespece of depth 1. It could be modified if need be.
 *
 * Currently it is used in the Subscribe form
 *
 * use example: 
 *     <af-animated-form-section condition="subscribe/termIsSelected">
 *          Text to appear if true
 *     </af-animated-form-section>
 *
 *     or for mutiple conditions
 *     <af-animated-form-section :condition="['user/userIsLoggedIn', 'subscribe/termIsSelected']">
 *
 */
    import { mapState } from 'vuex';
    import isArray from 'lib/utils/IsArray';

    export default {
        props: {
            condition: {
                // type: String,
                required: true,
            },
        },

        computed: {

            conditionParsed: function(){
                
            },

            show: function(){
                if(!isArray(this.condition)){
                    return this.testCondition(this.condition);
                }
                return this.condition.map((condition) => this.testCondition(condition)).reduce((accumulator, value) => accumulator && value);
            },

            conditionIsArray: function(){
                return isArray(this.condition);
            }
        },

        methods: {
            parseCondition: function(conditionString){
                return conditionString.split('/');
            },

            testCondition: function(conditionString){
                const parsed = this.parseCondition(conditionString);
                return this.$store.state[parsed[0]][parsed[1]];
            }
        },
    };
</script>
