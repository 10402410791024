<template>
	<a class="more-images" href="#" v-on:click.prevent="showLightbox()">
		<slot><img class="enlarge-icon" src="~img/enlarge-01.svg"></slot>
	</a>
</template>
<script>
	/**
	 * This can wrap any element to open the lightbox for the associated article
	 */
	import { mapMutations, mapState, mapActions } from 'vuex';
	import MODALS from 'shared/constants/Modals';

	export default {
		props: {
			articleId: {
				type: Number,
				required: true
			}, 
			articleTitle: {
				type: String,
				required: false
			},
			imageId: {
				type: Number,
				required: false
			},
			imageNames: {
				type:String,
				required: false,
				default: '', // handle Vue quirk
			}
		},

		computed: {
			...mapState({ 
				lightboxImages: state => state.lightbox.lightboxImages,
				lightboxCurrentArticle: state => state.lightbox.lightboxCurrentArticle
			}),

			imageNamesParsed: function(){
				return this.imageNames.length ? JSON.parse(this.imageNames) : [];
			}
		},

		methods: {
			...mapMutations('lightbox', [
				'openLightbox',
				'lightboxAssign',
				'lightboxSetIndex',
			]),
			
			...mapMutations(['openModal']),

			...mapActions('lightbox', [
				'getArticleImages'
			]),
			
			showLightbox: function(){

				if ( this.articleId === this.lightboxCurrentArticle ) {
					this.lightboxSetIndex(this.imageId);
					this.openModal(MODALS.LIGHTBOX);
					return;
				}

				this.lightboxAssign({ title: this.articleTitle });
				this.getArticleImages({
					articleId: this.articleId, 
					names: this.imageNamesParsed
				})
				.then( (images) => {
					this.lightboxAssign({lightboxImages: images});
					this.lightboxSetIndex(this.imageId);
					this.openModal(MODALS.LIGHTBOX);
					this.lightboxAssign({ lightboxCurrentArticle:this.articleId });
				})
			}
		}
	};
</script>
<style lang="scss">
	@import "scss/variables/typography";
	@import "scss/variables/breakpoints";
	@import "scss/variables/grid";

	.more-images{
		display: block;
		font-family: $sans-base-font-family;
		font-size: .875rem;
		font-weight: $demi;
		line-height: 1.375rem;
		text-transform: uppercase;
	}

	.more-images--text{
		margin-top: .25rem;
	}

	.enlarge-icon{
		width: 1rem;
		height: 1rem;
	}

	@media screen and (min-width: $breakpoint-medium){
		.more-images--text{
			margin-left: 0px;
			margin-right: 0px;
		}
	}
</style>