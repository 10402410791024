<template>
  <article class="guide-listing desktop-flex">
    <div class="guide-listing__image" v-show="primaryImageUrl">
      <picture @click="enlargeImages">
        <source :srcset="imagePath(primaryImage, true)" :media="mediaQueryDesktop">
        <source :srcset="imagePath(primaryImage, false)" :media="mediaQueryMobile">
        <img :src="primaryImageUrl" alt="" />
      </picture>
    </div>
    <div class="guide-listing__info">
      <div class="guide-listing__info__content">
        <div v-if="displayContext !== 'venue'" class="guide-listing__location">
          <h2>
            <a :href="entity.location.path" v-html="entity.location.name"></a>
          </h2>
          <div class="guide-listing__location-data">
            <span :class="addressClass" @click="enterMap" v-html="entity.location.address"></span>,
            <a :href="`http://${entity.location.url}`" target="_blank">{{ entity.location.url }}</a>
          </div>
          <div class="guide-listing__location-data">
            {{ entity.location.hours }}
          </div>
        </div>

        <div class="guide-listing__exhibition">
          <ul v-if="hasFlags || listMemberOf.length > 0" class="guide-listing__exhibition__flags">
            <li v-if="entity.isMustSee">Must See</li>
            <li v-if="entity.criticsPick"><a :href="entity.criticsPick.path">Critics' Pick</a></li>
            <li v-if="entity.preview"><a :href="entity.preview.path">Preview</a></li>
            <li v-if="sectionType !== 'featuredList'" v-for="list in listMemberOf"><a :href="list.url">{{ list.name }}</a></li>
            <li v-if="entity.viewingRoom"><a :href="entity.viewingRoom.path">Spotlight</a></li>
          </ul>

          <a :href="entity.path">
            <h4 v-if="entity.artists" class="guide-listing__artist-name" v-html="entity.artists"></h4>
            <h4 v-else class="guide-listing__artist-name" v-html="entity.title"></h4>
            <div v-if="entity.artists" v-html="entity.title"></div>
          </a>
          <div v-if="entity.subtitle" v-html="entity.subtitle"></div>

          <div>
            {{ listingDates }}
            <br />
            {{ openingDates }}
          </div>

          <div v-if="hasDescription" class="guide-listing__exhibition__description" v-html="descriptionReformatted"></div>

          <a v-if="displayContext !== 'venue' && entity.location.hasMoreExhibitions" class="guide-listing__exhibition__more" :href="entity.location.path">
            more at this location
          </a>
        </div>
      </div>
      <ul class="guide-listing__options">
        <li v-if="showMyguideToggle">
          <MyguideToggle :entityId="entity.id"></MyguideToggle>
        </li>
        <li v-if="hasPressRelease" :class="pressReleaseButtonClass">
          <a v-if="pressReleasePath" :href="pressReleasePath" target="_blank">press release</a>
          <span v-else @click="togglePressRelease">press release</span>
        </li>
        <li :class="shareButtonClass" @click="toggleSharePanel">
          share
        </li>
      </ul>

      <div v-if="isShowingSharePanel" class="guide-listing__share-panel">
        <SharePanel :entity="entity"></SharePanel>
      </div>

      <div
        v-if="isShowingPressRelease"
        v-html="entity.descriptionExtended"
        class="guide-listing__press-release"
      ></div>

      <ImageViewer
        :isVisible="isShowingImageViewer"
        :images="imageViewerImages"
        @close="hideImageViewer"
      ></ImageViewer>
    </div>
  </article>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { formatExhibitionDates, formatExhibitionOpeningDates, splitExhibitionTitle, imagePath } from './util'
  import ImageViewer from './ImageViewer.vue'
  import MyguideToggle from './MyguideToggle.vue'
  import SharePanel from './SharePanel.vue'
  import imageHelper from './mixins/ImageHelper';

  export default {
    components: { ImageViewer, MyguideToggle, SharePanel },
    props: {
      entityJson: String,
      displayContext: { type: String, default: null },
      sectionType: {type: String, default: null},
      showMyguideToggle: { type: String, default: 1 }
    },
    mixins: [imageHelper],
    data() {
      return {
        isShowingSharePanel: false,
        isShowingPressRelease: false,
        isShowingImageViewer: false
      }
    },
    computed: {
      ...mapState({
        isMapMounted: state => state.artguideMap.isMapMounted,
        featuredLists: state => state.artguideApiPage.featuredLists,
      }),
      entity() {
        return JSON.parse(this.entityJson)
      },
      splitTitle() {
        return splitExhibitionTitle(this.entity.title)
      },
      addressClass() {
        return {
          'guide-listing__location-data__address': true,
          'guide-listing__location-data__address--map-mounted': this.isMapMounted
        }
      },
      hasPressRelease() {
        return this.entity.descriptionExtended || this.entity.pressRelease;
      },
      pressReleasePath() {
        return imagePath(this.entity.pressRelease)
      },
      pressReleaseButtonClass() {
        return { active: this.isShowingPressRelease }
      },
      shareButtonClass() {
        return { active: this.isShowingSharePanel }
      },
      primaryImageUrl() {
        return imagePath(this.primaryImage, true)
      },
      primaryImage(){
        const image = this.entity.primaryImage
          || (this.entity.images && this.entity.images.find(i => i.name === 'exhibition'))
          || (this.entity.images && this.entity.images[0])
          || this.entity.location.primaryImage
        return image
      },
      imageViewerImages() {
        const images = this.entity.hasOwnProperty('images') && this.entity.images.length > 0 ? this.entity.images.filter((image) => {
          const parts = image.path.split('.')
          return parts[parts.length - 1] !== 'pdf'
        }) : [this.entity.location.primaryImage]
        return images
      },
      showLocation() {
        return this.displayContext !== 'venue'
      },
      listingDates() {
        return  this.entity.dateOverride ? this.entity.dateOverride : formatExhibitionDates(this.entity)
      },
      openingDates() {
        return formatExhibitionOpeningDates(this.entity)
      },
      hasFlags() {
        const { entity } = this
        return entity.isMustSee || entity.criticsPick || entity.preview || entity.viewingRoom
      },
      listMemberOf() {
        const locationId = this.entity.location.id;
        return this.featuredLists
          .filter((list) => { return list.location_ids.indexOf(locationId) !== -1 })
          .map((list) => { return { name: list.name, url: list.site_url } });
      },
      hasDescription() {
        return ( this.entity.hasOwnProperty('description') ) && (this.entity.description !== null) && (this.entity.description.length); 
      },
      descriptionReformatted() {
        return this.hasDescription ? this.entity.description.replace(/\n/g, '<br>') : '';
      }
    },
    methods: {
      ...mapMutations('artguide', ['addDisplayedExhibition', 'removeDisplayedExhibition']),
      ...mapMutations('artguideMap', ['enterMapWithLocationId']),
      enlargeImages() {
        this.isShowingImageViewer = true
      },
      hideImageViewer() {
        this.isShowingImageViewer = false
      },
      enterMap() {
        if (this.isMapMounted) {
          this.enterMapWithLocationId(this.entity.location.id)
        }
      },
      toggleSharePanel() {
        this.isShowingSharePanel = !this.isShowingSharePanel
      },
      togglePressRelease() {
        this.isShowingPressRelease = !this.isShowingPressRelease
      },
    },
    watch: {
      entity(entity, oldEntity) {
        if (entity.id !== oldEntity.id) {
          this.removeDisplayedExhibition(oldEntity)
          this.addDisplayedExhibition(entity)
        }
      }
    },
    mounted() {
      this.addDisplayedExhibition(this.entity)
    },
    beforeDestroy() {
      this.removeDisplayedExhibition(this.entity)
    }
  };
</script>

<style lang="scss">
  @import "scss/variables/grid";
  @import "scss/variables/breakpoints";

  .guide-listing {
    // flex-grow: 1;
    margin: 15px 0 30px 0;

    & h3,
    & h4 {
      margin: 0;
      padding: 0;
    }
  }

  .guide-listing__info {
    width: 100%;
    font-size: 14px;
  }

  .guide-listing__location {
    margin-bottom: 10px;

    & h2 {
      margin: 0 0 5px 0;
      text-transform: uppercase;
    }
  }

  .guide-listing__location-data a {
    text-decoration: underline;
  }

  .guide-listing__location-data__address--map-mounted {
    cursor: pointer;
    text-decoration: underline;
  }

  .guide-listing__exhibition__flags {
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: uppercase;
    color: #f00;
    margin-bottom: 4px;

    & li {
      display: inline-block;

      &:not(:last-child):after {
        content: " | ";
        color: #000;
      }

      & a {
        color: inherit;

        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .guide-listing__exhibition__description {
    margin-top: 6px;
  }

  .guide-listing__exhibition__more {
    display: block;
    margin-top: 6px;
    text-decoration: underline;
  }

  .guide-listing__options {
    // position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    user-select: none;

    & li {
      margin: 0 18px 0 0;
      font-style: italic;
      cursor: pointer;
      transition: all 0.2s;

      &.active {
        text-decoration: underline;
      }
    }
  }

  .guide-listing__share-panel {
    margin-top: 15px;
  }

  .guide-listing__press-release {
    margin-top: 25px;
    max-width: 600px;
    white-space: pre-line;
  }

  @media screen and (min-width: $breakpoint-medium){

    .guide-listing__image {
      width: 200px;
      height: 200px;
      margin-right: 30px;
  
      & img {
        min-width: 200px;
        width: 200px;
        min-height: 200px;
        max-height: 200px;
        background-color: #ccc;
        object-fit: cover;
        object-position: center; /* Center the image within the element */
        cursor: pointer;
      }
    }

    .guide-listing__info__content{
      min-height: 184px;
    }

    .guide-listing__info__content {
      position: relative;
    }
    
    .guide-listing__options{
      // position: absolute; 
    }
  }

  @media screen and (max-width: $breakpoint-medium){
    .guide-listing{
      margin-bottom: 4rem;
    }
    .guide-listing__info {
      margin-top: 10px;
    }

    .guide-listing__options{
      margin-top: 1rem;
    }
  }

  @media (min-width: $breakpoint-medium) and (max-width: $artguide-medium-width) {

    .guide-listing__options{
      position: relative;
      top: 15px;
    }
  }
</style>
