<template>

    <div class="subscription-form--submit_wrapper">
        <input v-if="!formComplete" type="submit" class="subscription-form--form__submit form__submit--centered incomplete_form" value="Subscribe!" @click="incompleteForm">

        <input v-else-if="!formSubmitted" type="submit" class="subscription-form--form__submit form__submit--centered" value="Subscribe!" @click="checkForm">

        <input v-else type="submit" class="subscription-form--form__submit form__submit--centered submit_processing" value="Subscribe!" @click="pauseForm">

        <div class="subscription-form--note-small">Transaction secured with SSL encryption.</div>
    </div>

</template>

<script>
    /**
     * prevent duplicate submissions
     */
    import { mapState, mapMutations, mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapState({
                formSubmitted: (state) => state.subscribe.formSubmitted,
                formComplete: (state) => state.subscribe.formComplete,
                condition: (state) => state.subscribe.condition
            }),
            ...mapGetters('subscribe', ['getSectionsIncomplete']),
        },
        methods: {
            ...mapMutations('subscribe', ['markFormSubmitted']),

            checkForm: function(){
                this.markFormSubmitted(true);
                setTimeout(function () { this.markFormSubmitted(false) }.bind(this), 3000)
            },

            pauseForm: function(e){
                e.preventDefault();
                alert('The order is processing, please be patient.');
            },

            incompleteForm: function(e){
                e.preventDefault();
                this.clearMessages();
                var sections = this.getSectionsIncomplete;
                var firstIncomplete = sections.slice(0, 1);
                this.scrollToIncomplete(firstIncomplete);
            },

            clearMessages: function() {
                for (let i = 0; i < this.condition.length; i++) {
                    let section = this.condition[i]['key'];
                    var sectionId = section + '-incomplete';
                    document.getElementById(sectionId).innerHTML = ' ';
                }
            },

            scrollToIncomplete: function(section) {
                var sectionId = section + '-incomplete';
                var el = document.getElementById(sectionId);
                el.innerHTML = this.getIncompleteFormMessage(section);
                el.classList.add('subscription-form--error');
                let newOffset =  el.offsetTop - 150;
                window.scrollTo(0, newOffset);
            },

            getIncompleteFormMessage (section){
                for (let i = 0; i < this.condition.length; i++) {
                    if (this.condition[i]['key'] == section) {
                        return this.condition[i]['msg'];
                    }
                }
                return 'Please complete the form';
            },
        },
        watch: {
            formComplete: function(formComplete){
                if (formComplete) {
                    this.clearMessages();
                }
            }
        },
    };
</script>

<style>

.submit_processing {
    opacity: .3;
}
.incomplete_form {
    opacity: .3;
    background: #666 !important;
} 
.subscription-form--note-small {
    font-size: .87em;
    text-align:center;
}
.subscription-form--submit_wrapper {
    padding: 30px;
}
.subscription-form--error {
    color: red;
    margin-bottom: 20px;
    display:block;
}

</style>