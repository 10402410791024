<template>
    <a class="homepage-news__article" :href="getTrackingUrl" :title="article.titleFormatted | stripTags">
        <div class="image-container image-container--1x1">
            <div v-if="article.primaryImage && article.primaryImage.path_square">
                <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
            <div v-else-if="article.primaryImage">
                <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
        </div>
        <div class="homepage-news__date">{{ article.published | formatDate('MMMM D, YYYY') }}</div>
        <h3 class="homepage-news__title" v-html="article.titleFormatted"></h3>
    </a>
</template>

<script>
    import formatDate from 'shared/components/mixins/FormatDate';
    import { BREAKPOINT_MEDIUM } from 'shared/constants/Common.js';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';
    import addUrlParam from 'lib/utils/AddUrlParam';

    export default {
        props: {
            article: Object,
            index: Number,
            slidesPerScreen: Number,
            slideIndex: Number
        },
        computed: {
            queryMobile: () => '(max-width: ' + BREAKPOINT_MEDIUM + 'px)',
            queryDesktop: () => '(min-width: ' + BREAKPOINT_MEDIUM + 'px)',
            loadImg: function(index){
                return this.index <= ( this.slideIndex + 1 ) * this.slidesPerScreen;
            },
            srcsetComputed: function(){
                return this.loadImg ? this.srcset(this.article.primaryImage) : '';
            },
            srcComputed: function(){
                return this.loadImg ? this.article.primaryImage.path : '';
            },
            getTrackingUrl: function(){
                let url = this.article.url;
                url = addUrlParam(url, 'utm_campaign', 'hp-news-module');
                url = addUrlParam(url, 'utm_medium', 'web');
                url = addUrlParam(url, 'utm_source', 'homepage');
                return url;
            }
        },
        methods: {
            /**
             * not active 20230403 - to revive: add attribute to <a> -> v-on:click="logClick(article)"
             */
            logClick: function(article){
                let title = article.titleFormatted;
                title.replace(/(<([^>]+)>)/gi, "");
                String(title).substr(0, 39);

                window.gtag("event",
                    "hpc_news_clicked",
                    {'item_id' : article.id,
                     'url' : article.url,
                     'item_name': title 
                    });
            },
        },
        mixins: [formatDate, srcsetFromMedia, stripTags]
    };
</script>

<style lang="scss">
    @import "scss/variables/typography";
    
    .homepage-news__date{
        font-size: .9rem;
        font-weight: $book;
        margin: .5rem 0px;
    }
    .homepage-news__title{
        margin: 0px;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: $medium;
    }
</style>
