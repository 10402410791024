<template>
    <input v-show="!billingIsComplete" type="submit" class="form__submit" value="continue" @click.prevent="billingComplete">
</template>

<script>
    /**
     * This is for use with ../animated-form/AnimatedFormSection.vue
     * fakes a form submission, essentially
     */
    import { mapState, mapMutations } from 'vuex';

    export default {
        computed: {
            ...mapState({
                billingIsComplete: (state) => state.subscribe.billingIsComplete,
            }),
        },

        methods: {
            ...mapMutations('subscribe', ['billingComplete']),
        },
    };
</script>