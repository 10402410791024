
export default {
    methods: {

        hasValue(fieldId){
            var val = document.getElementById(fieldId).value;
            return val.length > 0;
        },

    }
}