<template>
    <section class="cart__totals">
        <table class="totals__table">
            <tr>
                <td class="totals__label">
                    <a class="infolink" href="#" v-on:click.prevent="shippingBreakdown = !shippingBreakdown">Shipping</a>
                    <div class="shipping-breakdown" v-if="shippingBreakdown">
                        <p>Postage within the United States: $15.00 for the first issue, add $6.00 for each additional issue.</p>
                        <p>Postage for the rest of the world: $30.00 for the first issue, add $10.00 for each additional issue.</p>
                        <p>In the US call toll free <a href="tel:1-800-966-2783">1-800-966-2783</a>. Outside the US call <a href="tel:212-475-4000">212-475-4000</a> or mail back issue orders to ARTFORUM, Back Issues Dept., 350 Seventh Avenue, New York, NY 10001 or e-mail: <a href="mailto:circulation@artforum.com">circulation@artforum.com</a>.</p>
                    </div>
                </td>
                <td class="totals__amount">${{ shipping }}</td>
            </tr>
        </table>
    </section>
</template>

<script>
    /**
     * A toggle-able note explaining our shipping policies
     */
    import { mapState, mapMutations, mapGetters } from 'vuex';
    export default {
        data: function(){
            return {
                shippingBreakdown: false
            }
        },

        computed: {
            ...mapGetters({
                shipping: 'serverCart/shippingByRegion'
            })
        },

        methods: {
            ...mapMutations('serverCart', [
                'serverCartAssign',
            ])
        },

        mounted: function(){
            const shippingCountrySelect = document.getElementById('order_shiptoCountry');
            const billingCountrySelect = document.getElementById('order_country');
            
            shippingCountrySelect.addEventListener('change', ()=>{
                const val = shippingCountrySelect.value;
                this.serverCartAssign({ shippingCountry: val });
            });

            billingCountrySelect.addEventListener('change', ()=>{
                const val = billingCountrySelect.value;
                this.serverCartAssign({ billingCountry: val });
            });

        }
    };
</script>

<style>
    .shipping-breakdown{
        font-size: .875rem;
        line-height: 1.5em;
        color: #666;
    }
    .totals__amount{
        vertical-align: top;
    }
</style>