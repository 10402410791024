<template>
  <NavSelect ref="select" initialValue="category" :options="categories" :disabled="disabled" @select="onCategorySelect" />
</template>

<script>
  import NavSelect from './NavSelect.vue';
  import navSelector from './nav-selector';
  import { tier1Cities } from '../cities';

  const tier1Places = tier1Cities.map(item => item.place);

  export default {
    components: { NavSelect },
    mixins: [navSelector],
    props: {
      category: { type: String, default: null },
    },
    computed: {
      categories() {
        const categories = [
          'all',
          'galleries',
          'museums',
          'openings',
          'closings',
          'special events',
          'art fairs',
          'auctions'
        ]

        return this.hasCategory ? categories : [{ text: 'category', disabled: true }].concat(categories)
      }
    },
    methods: {
      onCategorySelect(category) {
        if (category === this.category) return

        this.selectHandler(category === 'category' ? null : category)
      }
    },
    watch: {
      category(category) {
        this.$refs.select.setValue(category || 'category')
      }
    }
  }
</script>
