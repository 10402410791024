<template>
  <div class="guide-myguide-list">
    <div v-if="sections.length > 0">
      <ListingsSection
        v-for="section in sections"
        :key="`${section.title}${section.subtitle}`"
        :title="section.title"
        :subtitle="section.subtitle"
        :entities="section.entities"
      />
    </div>
    <div v-else-if="myguidePlaceUrl.placeUrl" class="guide-myguide-cta">
      <p>redirecting to all <b>myguide</b> locations.</p>
    </div>
    <div v-else class="guide-myguide-cta">
      <p><b>myguide</b> is your personal list of shows to see.</p>
      <p>Select <i>+myguide</i> on any listing to add that exhibition.</p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import ListingsSection from './ListingsSection.vue'
  import { formatDateAbbr, getEndDate } from './util'

  export default {
    components: { ListingsSection },
    props: {
      entitiesJson: String,
      initialPlaceUrl: String
    },
    computed: {
      ...mapState({
        userIsLoggedIn: state => state.user.userIsLoggedIn,
        listingsSort: state => state.myguide.listingsSort,
        hasLoadedMyguide: state => state.myguide.hasLoaded,
        myguideExhibitionIds: state => state.myguide.myguideExhibitionIds,
        myguidePlaceUrl: state => state.myguide.placeUrl,
        mapConfig: state => state.artguideMap.mapConfig
      }),
      ...mapGetters({
        activeMyguideExhibitions: 'myguide/currentMyguideEntities',
        currentExhibitions: 'myguide/placeFilteredMyguideEntities'
      }),
      entities() {
        return JSON.parse(this.entitiesJson)
      },
      isMyguideClear() {
        const { currentExhibitions, hasLoadedMyguide } = this
        if (!hasLoadedMyguide) {
          return false
        }

        return currentExhibitions.length === 0
      },
      sections() {
        const sortByDate = this.listingsSort === 'date'

        const list = this.currentExhibitions
        const entityGroups = {}
        list.forEach(entity => {
          const place = entity.location.place
          const date = formatDateAbbr(getEndDate(entity))
          const key = `${place}-${date}`

          const list = entityGroups[key]
          if (list) {
            list.push(entity)
          } else {
            entityGroups[key] = [entity]
          }
        })

        const sections = Object.keys(entityGroups)
          .map(key => {
            const entities = entityGroups[key]
            const place = entities[0].location.place
            const date = new Date(getEndDate(entities[0]))
            const dateTitle = `Ends ${formatDateAbbr(date)}`
            return {
              title: sortByDate ? dateTitle : place,
              subtitle: sortByDate ? place : dateTitle,
              entities,
              place,
              date
            }
          })

        if (sortByDate) {
          sections.sort((a, b) => (a.date - b.date) || a.place.localeCompare(b.place))
        } else {
          sections.sort((a, b) => a.place.localeCompare(b.place) || (a.date - b.date))
        }

        return sections
      }
    },
    methods: {
      ...mapMutations('myguide', ['setMyguideEntities', 'setPlaceUrl']),
      ...mapMutations('artguideMap', ['setMapConfig']),
    },
    watch: {
      isMyguideClear(isClear) {
        // redirect to root myguide if current place is clear
        if (isClear && this.myguidePlaceUrl) {
          setTimeout(() => {
            this.setPlaceUrl({ placeUrl: null, pushState: false, replaceState: true })
          }, 500)
        }
      },
      myguidePlaceUrl(item) {
        // if placeUrl changes, filter exhibitions by new place
        const { placeUrl, pushState, replaceState } = item
        const newMapConfig = {
          ...this.mapConfig,
          useCurrentLocation: !placeUrl
        }

        const currentPlace = ''
        if (placeUrl && this.currentExhibitions.length > 0) {
          const { place: currentPlace } = this.currentExhibitions[0].location

          const otherPlaces = {}
          this.activeMyguideExhibitions.forEach(entity => {
            const { place, place_url } = entity.location
            if (place !== currentPlace) {
              if (otherPlaces[place]) {
                otherPlaces[place].count += 1
              } else {
                otherPlaces[place] = { place, place_url, count: 1, path: place_url }
              }
            }
          })

          newMapConfig.currentPlace = currentPlace
          newMapConfig.otherPlaces = Object.values(otherPlaces)
        } else {
          newMapConfig.currentPlace = null
          newMapConfig.otherPlaces = null
        }

        this.setMapConfig(newMapConfig)

        const historyData = {
          map: { config: newMapConfig },
          myguide: { placeUrl }
        }
        const title = `${document.title} - ${currentPlace}`
        const url = `?place_url=${placeUrl}`

        if (pushState) {
          history.pushState(historyData, title, url)
        } else if (replaceState) {
          history.replaceState(historyData, title, url)
        }
      }
    },
    mounted() {
      this.setMyguideEntities(this.entities)

      if (this.initialPlaceUrl && this.initialPlaceUrl.length > 0 && this.initialPlaceUrl !== 'null') {
        this.setPlaceUrl({ placeUrl: this.initialPlaceUrl, pushState: false })
      }
    }
  }
</script>

<style lang="scss">
  .guide-myguide-cta {
    margin-left: -200px;
    text-align: center;

    @media (max-width: 768px) {
      margin-left: 0;
      text-align: left;
    }
  }
</style>
