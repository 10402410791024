import getParameterByName from 'lib/utils/GetQueryParameterByName';

const setSelectFromQueryParam = function(selectId, param, byDataLabel = false) {

    const paramVal = getParameterByName(param, window.location);
    if ( !paramVal ){
        return false;
    }

    const $select = document.getElementById(selectId);
    if(!$select){
        return false;
    }

    const options = $select.getElementsByTagName('option');

    for (let i = 0; i < options.length; i++){
        let option = options[i];
        let property = byDataLabel ? option.dataset.label : option.value;
        if(property === paramVal){
            $select.value = option.value;
            break;
        }
    }
}   

export default setSelectFromQueryParam;
