/**
 * For fetching subscription options for purchase
 */

import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';
import { ARTICLES_PER_PAGE } from 'shared/constants/Common';

const subscriptions = {
    namespaced: true,

    state: {
        dynamicallyLoadedPlans: [],
        selectedPlan: false,
        countrycode: 'US',
        discount: '',
        price: '',
        planId: '',
        flags: 4
    },

    mutations: {
        setPlans(state, items){
            state.dynamicallyLoadedPlans = items;
        },
        setPlan(state, item){
            state.selectedPlan = item;
            state.planId = item.id;
        },
        setCountryCode(state, data){
            state.countrycode = data.countrycode;
        },
        setDiscountCode(state, data){
            state.discount = data.discountCode;
        },
        setPrice(state, data){
            state.price = data.price;
        },
        setFlags(state, data){
            state.flags = data.flags;
        },
    },
    getters: {
        isGift(state){
            return (state.flags == 36);
        },
        getIsDigital(state){
            let selected = state.selectedPlan;
            return (selected.id == 'D1Y');
        },
        getDiscountCode(state){
            return state.discount;
        },
        getFlags(state){
            return state.flags;
        },
        getPlan(state){
            return state.selectedPlan;
        }
    },
    actions: {
        setDeliveryCountry({ commit, state }, data){
            commit('setCountryCode', data);
        },
        setDiscountCode({ commit, state }, data){
            commit('setDiscountCode', data);
        },
        setFlags({ commit, state }, data){
            commit('setFlags', data);
        },
        setPlan({ commit, state }, data){
            commit('setPlan', data);
        },
        findDiscountCode({ commit, state }, data){
            commit('addActiveCall', 'findDiscountCode', { root: true });

            const apiRoute = '/subscription/code/' + data; 

            const _url = `${API_PATH}${apiRoute}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });
            
            fetch( _url, {
                headers: headers
            }).then( (res) => {
                if(res.ok){
                    return res.json();
                }
                else{
                    const status = res.status;
                    throw { status: status, message:'There was an error validating the code through the API.' };
                }
            }).then( (data) => {
                if (data.isActive != false) {
                    commit('setDiscountCode', {discountCode : data.code});
                }
                else {
                    commit('setDiscountCode', {discountCode : ''});
                }
                commit('removeActiveCall', 'findDiscountCode', { root: true });
            }).catch((error) => {
                commit('removeActiveCall', 'findDiscountCode', { root: true });
                if( error.status === 404 ){
                    console.log('404');
                }
            });
        },
        loadSubscriptionOptions({ commit, state }, options){
            commit('addActiveCall', 'loadSubscriptionOptions', { root: true });
            /* 
             *  options include countrycode, discount and flags, which are passed as url params
             */
            var discount = options.discount ? options.discount : state.discount;
            var flags = options.flags ? options.flags : state.flags;

            const urlParams = { countrycode: state.countrycode, discount: discount, flags: flags };

            const apiRoute = '/subscription/options'; 

            const _url = `${API_PATH}${apiRoute}${toQueryString(urlParams)}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });
            
            fetch( _url, {
                headers: headers
            }).then( (res) => {
                if(res.ok){
                    return res.json();
                }
                else{
                    const status = res.status;
                    throw { status: status, message:'There was an error loading subscriptionoptions from the API.' };
                }
            }).then( (data) => {
                commit('setPlans', data);
                commit('removeActiveCall', 'loadSubscriptionOptions', { root: true });
            }).catch((error) => {
                commit('removeActiveCall', 'loadSubscriptionOptions', { root: true });
                if( error.status === 404 ){
                    console.log('404');
                }
            });
        }
    }
}

export default subscriptions;