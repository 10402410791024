<template>
    <div>
        <dot-dot-dot associated-action="fetchViewingRooms"></dot-dot-dot>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import DotDotDot from '../animations/DotDotDot.vue';

    export default {
        components: { DotDotDot },
        computed: {
            ...mapState({
                activeCalls: (state) => state.activeCalls,
            }),

            ...mapGetters('viewport', ['scrollBottom']),

            isActive: function(){
                return this.activeCalls.indexOf('fetchViewingRooms') !== -1;
            }
        },

        methods: {
            ...mapActions('viewingRoom', ['fetchViewingRooms'])
        },

        watch: {
            scrollBottom: function(val){
                if ( (val > this.$el.offsetTop) && !this.isActive ) {
                    this.fetchViewingRooms();
                }
            }
        }
    };
</script>