<template>
    <nav id="slide-navigation" class="slide-navigation">
        <menu-toggle class="toggle-button" :active="isActive" :callback="toggle"></menu-toggle>
        <div class="slide-navigation__menu" v-bind:class="[{visible: isActive}]">
            <ul class="slide-navigation__pages">
                <nav-item class="slide-navigation__item" v-for="page in pages" :page="page" :deep="true" :expanded="false" :key="page.name" :menu-active="isActive"></nav-item>
            </ul>
            <slot></slot>
        </div>
    </nav>
</template>

<script>
import menuToggle from './MenuToggle.vue';
import navItem from './NavItemSlide.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

import NAVIGATION from 'shared/constants/Navigation';
import { WEBROOT } from 'shared/constants/Paths';
import MODALS from 'shared/constants/Modals';

export default {
    name: 'navigation',

    props: {
        navigation: {
            type: Object,
            required: true,
        }
    },

    data () {
        return {
            webroot: WEBROOT,
        }
    },

    computed: {
        isActive(){
            return this.$store.getters.slideMenuIsActive;
        },
        searchIsActive(){
            return this.$store.state.searchIsActive;
        },
        pages: function(){
            let pages = this.navigation.PAGES.filter((page) => page.slide);
            return pages;
        },
    },

    methods: {
        ...mapMutations(['openModal', 'closeModal']),
        ...mapActions('user', ['userLogout']),
        logoutUser: function(){
            this.userLogout();
        },
        toggle: function(){
            !this.isActive ? this.openModal(MODALS.SLIDE_MENU) : this.closeModal();
        },
    },

    components: {
        menuToggle,
        navItem
    },
};
</script>