<template>
    <a class="related-article" :href="article.url">
        <div class="image-container image-container--2x1">
            <img v-if="hasMedia" class="related-article__image" :srcset="srcset(article.primaryImage)" :sizes="sizes" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path}`" />
        </div>
        <h4 class="related-article__section" v-html="slug"></h4>
        <div class="related-article__info">
            <span class="related-article__title" v-html="article.titleFormatted"></span><br>
             <span v-html="article.writerFormatted"></span>
        </div>
    </a>
</template>
<script>
    import ITEM_TYPES from '../../../constants/ItemTypes';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import dateFormat from 'date-fns/format';
    import { IMAGE_BASE_PATH } from 'shared/constants/Paths';
    import isArray from 'lib/utils/IsArray';

    export default {
        name: 'related-article',
        props: ['article'],
        mixins: [srcsetFromMedia],
        data(){
            return {
                IMAGE_BASE_PATH: IMAGE_BASE_PATH,
            }
        },
        computed: {
            publishedDate: function(){
                return dateFormat(this.article.published, 'MMMM D, YYYY');
            },
            slug: function(){
                if (this.article.issueTitle) {
                    return '<strong>PRINT</strong> ' + this.article.issueTitle;
                }
                else if (this.article.typeTranslated === ITEM_TYPES.ITEM_TYPENAME_VIDEO) {
                    return `<strong>VIDEO</strong> <span>${this.publishedDate}</span>`;
                }
                else if (this.article.typeTranslated === ITEM_TYPES.ITEM_TYPENAME_PICKS) { 
                    return `<strong>CRITICS\' PICKS</strong> <span>${this.publishedDate}</span>`;
                }
                else if (this.article.channel) {
                   return `<strong>${this.article.channel}</strong> <span>${this.publishedDate}</span>`;
                }
                else {
                    return `<strong>${this.article.typeTranslated}</strong> <span>${this.publishedDate}</span>`;
                }
            },
            hasMedia: function(){
                if(this.article.primaryImage === null){
                    return false;
                }
                if(isArray(this.article.primaryImage) && this.article.primaryImage.length === 0){
                    return false;
                }
                return this.article.primaryImage;
            }
        },
        methods: {
            /**
             * add to <a> attributes v-on:click="logClick(article.id)"
             */
            logClick: function(articleId){
                window.gtag("event", "related_article_clicked", { 'clicked_article_id' : articleId});
            },
            /**
             * add inbetween <a> tags {{ logImpression(article.id) }}
             */
            logImpression: function(articleId){
                window.gtag("event", "related_article_impression", {'article_id' : articleId});
            }
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @import "scss/variables/grid";
    @import "scss/variables/typography";

    .related-article{
        font-size: 1.0625rem;
        display: block;
        color: #000;
    }

    .related-article__title{
        font-weight: $medium;
    }
    
    .related-article__image{
        max-width: 100% !important;
    }
    
    .related-article__section{
        padding: 0;
        margin: 1rem 0px 0px 0px;
        text-align: left;
        font-family: $sans-base-font-family;
        text-transform: uppercase;
        font-weight: $book;
        line-height: 1.125em;
        margin-bottom: .25rem;

        span{
            text-transform: none;
        }
    }
    
    .related-article__info{
        text-align: left;
        font-family: $sans-base-font-family;
        line-height: 1.25em;
    }
    
    .related-article__section,
    .related-article__info{
        margin-left: $mobile-margins-left;
    }

    @media screen and (min-width: $breakpoint-medium){
        .related-article__section,
        .related-article__info{
            margin-left: 0;
        }
    }
</style>