<template>
	<div class="toggle">
		<a :class="controlClasses" href="#" v-on:click.prevent="toggle()">
			<slot name="control">
				<img class="toggle-icon" v-if="!active" src="~img/plus.svg" />
				<img class="toggle-icon" v-if="active" src="~img/minus.svg" /> 
				{{ controlText }}
			</slot>
		</a>
		<div class="toggle__content" v-show="active">
		    <div class="toggle__content--close">
		        <a href="#" v-on:click.prevent="toggle()"><img src="/media/icons/close-black.svg" alt="close"></a>
		    </div>
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
/**
 * A container that shows/hides its contents
 */
export default{
	props: {
		openText: String,
		closeText: String,
		opened: {
			type: Number,
			required: false,
			default: false,
		}
	},

	data(){
		return {
			active: this.opened
		}
	},

	computed: {
		controlText: function(){
			return this.active ? this.closeText : this.openText;
		},

		controlClasses: function(){
			return {
				'toggle__control': true,
				'toggle__control--open': this.active,
				'toggle__control--closed': !this.active,
			}
		},
	},

	methods: {
		toggle: function(){
			this.active = !this.active;
		}
	}
};
</script>
<style lang="scss">
	@import "scss/variables/typography";
	.bold-toggle .toggle__control{
		font-weight: $medium;
		padding: 1rem 0px;
		font-size: 1.0625rem;
	}

	.toggle{
		margin-bottom: 1rem;
	}

	.toggle.toggle--slim{
		margin-bottom: 0;
		.toggle__content{
			padding: 0px !important;
		}
		.toggle__control{
			padding: 0;
		}
	}

	.bold-toggle .toggle__control{
		font-weight: $medium;
		padding: 1rem 0px;
		font-size: 1.0625rem;
	}

	.toggle__control{
		display: block;
		margin: 0px 0px .5rem 0px;
		background-size: 1rem 1rem;
		background-position: left; 
		background-repeat: no-repeat;
	}

	.toggle__content{
		padding: 1rem;
    	background: #fafafa;

    	.styled-select, .form__text-input{
    		background-color: #fff;
    	}
	}

	.toggle__content--close {
	    display:none;
	}
</style>
