<template>
    <div v-show="!this.isDigital()">
        <slot></slot>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        methods: {
            ...mapGetters('subscriptions', ['getIsDigital']),

            isDigital: function() {
                let isDigital = this.getIsDigital();
                this.$store.commit('subscribe/shippingComplete', isDigital);
                return isDigital;
            }
        },

    };
</script>
