<template>
    <a class="hp-viewing-room__article hp-viewing-room__article" :href="article.url" :target="target">
        <div class="image-container image-container--1x1">
            <div v-if="article.primaryImage && article.primaryImage.path_square">
                <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
        </div>
    </a>
</template>

<script>

    import formatDate from 'shared/components/mixins/FormatDate';
    import { BREAKPOINT_MEDIUM } from 'shared/constants/Common.js';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';

    export default {
        props: {
            article: Object,
            index: Number,
            slidesPerScreen: Number,
            slideIndex: Number
        },
        computed: {
            queryMobile: () => '(max-width: ' + BREAKPOINT_MEDIUM + 'px)',
            queryDesktop: () => '(min-width: ' + BREAKPOINT_MEDIUM + 'px)',
            loadImg: function(index){
                return this.index <= ( this.slideIndex + 1 ) * this.slidesPerScreen;
            },
            srcsetComputed: function(){
                return this.loadImg ? this.srcset(this.article.primaryImage) : '';
            },
            srcComputed: function(){
                return this.loadImg ? this.article.primaryImage.path : '';
            },
            isOutboundLink: function(){
                return this.article.url.indexOf('//') > -1;
            },
            target: function(){
                return this.isOutboundLink ? '_blank' : '_self';
            }
        },
        methods: {
            /**
             * to revive, add to <a> attribute v-on:click="logClick(article)"
             */
            logClick: function(article){
                let title = article.titleFormatted;
                title.replace(/(<([^>]+)>)/gi, "");
                String(title).substr(0, 39);

                window.gtag("event", "hpc_spotlight_clicked",
                    {'item_id' : article.id,
                     'url' : article.url,
                     'item_name' : title
                    });

                let items = [{
                      item_id: article.id,
                      item_name: title,
                      item_category: "spotlight",
                    }];

                window.gtag("event", "select_promotion", {
                    creative_name: "Spotlight Homepage",
                    creative_slot: "carousel",
                    location_id: "HP_spotlight",
                    promotion_id: "1",
                    promotion_name: "spotlight",
                    items: items
                });
            },
        },
        mixins: [formatDate, srcsetFromMedia, stripTags]
    };
</script>

<style lang="scss">
    @import "scss/variables/typography";
    @import "scss/variables/breakpoints";
    .hp-viewing-room__artists{
        margin-top: .75rem;
    }

    .hp-viewing-room__exhibition-title{
        font-weight: $book;
        font-style: italic;
    }

    .hp-viewing-room__venue{
        margin-top: .25rem;
    }

    .hp-viewing-room__artists, .hp-viewing-room__exhibition-title{
        font-weight: $medium;
    }

    .hp-viewing-room__slider .next, .hp-viewing-room__slider .prev {
        top: 32%;
    }

    .hp-viewing-room__slides{
        display: block;
    }

    .hp-viewing-room__slide{
        width: 33%;
        padding-right: 1rem;
    }
    .hp-viewing-room__slide:nth-of-type(3n){
        width: 34%;
    }

    .hp-viewing-room__more-news-link{
        position: relative;
        display: block;
        height: 0px;
        padding: 50%;
        background: #fafafa;
    }

    .hp-viewing-room__more-news-link__text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 100;
        text-transform: uppercase;
        font-size: 1.25rem;
    }

    @media screen and (max-width: $breakpoint-small){
        .hp-viewing-room__slide:nth-of-type(3n){
            width: 80vw;
            padding: 0 2rem 0 0;
        }
        .hp-viewing-room__frame{
            width: 80vw;
            margin: 0 auto;
            overflow: visible;
        }
    
        .hp-viewing-room__slide{
            width: 80vw;
            padding: 0 2rem 0 0;
        }
    
        .hp-viewing-room__slide:first{    
            position: relative;
        }
    }
    @media screen and (max-width: $breakpoint-medium){
        .hp-viewing-room__artists, 
        .hp-viewing-room__exhibition-title,
        .hp-viewing-room__venue, 
        .hp-viewing-room__city{
            font-size: 1.25rem;
        }
    }
</style>
