/**
 * Utility functions pertaining to the organization of artguide listings.
 *
 * Some of the functions in here, if they don't match the sorting criteria used in SQL queries
 * may reshuffle results leading to erratic behavior on the page. This was an issue in the past.
 *
 * Currently, the SQL sort criteria have been modified to match what's in here.
 * These functions are kept in order to break listings into sections - ie add headings.
 * There is probably a better way to do this.
 */

import dateFormat from 'date-fns/format'
import { getStartDate, getEndDate, getOpeningDate } from './util'

export const SECTION_TYPES = {
  PLACE: 'place',
  PLACE_DATE: 'placeDate',
  DATE: 'date',
  SPECIAL_EXHIBITIONS: 'specialExhibitions',
  CATEGORY: 'category',
  FEATURED_LIST: 'featuredList',
}

/**
 * Dispatcher function to organize entities (API Results) depending on the entity type
 *
 * @param {String} sectionType
 * @param {Array} entities
 * @param {Object} options
 */
export function makeSections (sectionType, entities, options) {
  switch (sectionType) {
    case SECTION_TYPES.SPECIAL_EXHIBITIONS:
      return makeSpecialExhibitionSections(entities, options)

    case SECTION_TYPES.PLACE_DATE:
      return makePlaceDateSections(entities, options)

    case SECTION_TYPES.DATE:
      return makeDateSections(entities, options)

    case SECTION_TYPES.CATEGORY:
      return makeCategorySections(entities, options)

    case SECTION_TYPES.FEATURED_LIST:
      return makeFeaturedListSections(entities, options)

    case SECTION_TYPES.PLACE:
    default:
      return makePlaceSections(entities, options)
  }
}

/**
 * Organize entities into groups based on their "place" (city) or district, 
 * adding the place and district as title and subtitle
 *
 * May re-sort results
 *
 * @param {Array} entities
 * @param {Object} options
 * 
 * @return {Object}
 */
export function makePlaceSections (entities, options = {}) {
  const { includeDistrict = false, subtitle = null } = options

  // group by place
  const exhibitionsByPlace = {}
  entities.forEach(entity => {
    const location = entity.location || entity
    const place = includeDistrict ? `${location.district}, ${location.place}` : location.place
    if (!exhibitionsByPlace[place]) {
      exhibitionsByPlace[place] = []
    }

    exhibitionsByPlace[place].push(entity)
  })

  const sections = Object.keys(exhibitionsByPlace).map(place => {
    return {
      title: place,
      subtitle,
      entities: exhibitionsByPlace[place]
    }
  })

  return sections;
}

/**
 * Add a title and subtitle to featured list to match the expected data structure
 *
 * Does not re-sort results
 *
 * @param {Array} entities
 * @param {Object} options
 * @return {Object}
 */
export function makeFeaturedListSections(entities, options = {}) {
  const { title, subtitle } = options;
  const sections = [{
    entities: entities,
    title: title,
    subtitle: subtitle,
  }]
  return sections;
}

/**
 * Sort entities into buckets by month of start date
 *
 * May re-sort results
 * 
 * @param {Array} entities
 */
export function makeSpecialExhibitionSections (entities) {
  const now = new Date()

  // group by month
  const exhibitionsByMonth = {}
  entities.forEach(entity => {
    const startDate = getStartDate(entity)
    const date = startDate < now ? now : startDate
    const month = dateFormat(date, 'YYYY/MM')
    //const month = moment(date).format('YYYY/MM')

    if (!exhibitionsByMonth[month]) {
      exhibitionsByMonth[month] = []
    }

    exhibitionsByMonth[month].push(entity)
  })

  const sortedMonths = Object.keys(exhibitionsByMonth).sort()

  const sections = sortedMonths.map(month => {
    const exhibitions = exhibitionsByMonth[month]

    const startDate = getStartDate(exhibitions[0])
    const date = startDate < now ? now : startDate
    //const title = moment(date).format('MMMM YYYY')
    const title = dateFormat(date, 'MMMM YYYY')

    return { title, entities: exhibitions }
  })

  return sections
}
/**
 * Groups entities first by place, then by opening or closing date
 *
 * May re-sort results 
 *
 * @param {Array} entities
 * @param {Object} options 
 * @return {Object}
 */
export function makePlaceDateSections (entities, options = {}) {
  const { subtitle = null } = options
  const now = new Date()

  // group by place and date
  const groupedExhibitions = {}
  entities.forEach(entity => {
    const location = entity.location || entity
    const { place } = location // i.e. place = location.place

    let dateKey
    const startDate = getStartDate(entity)
    if (startDate < now) {
      const endDate = getEndDate(entity)
      dateKey = `Closing ${dateFormat(endDate, 'MMMM DD, YYYY')}`
    } else {
      dateKey = `Opening ${dateFormat(startDate, 'MMMM DD, YYYY')}`
    }

    if (!groupedExhibitions[place]) {
      groupedExhibitions[place] = []
    }
    if (!groupedExhibitions[place][dateKey]) {
      groupedExhibitions[place][dateKey] = []
    }

    groupedExhibitions[place][dateKey].push(entity)
  })

  const sections = Object.keys(groupedExhibitions).map(place => {
    const subsections = Object.keys(groupedExhibitions[place]).map(dateKey => {
      return {
        title: dateKey,
        entities: groupedExhibitions[place][dateKey]
      }
    })

    return {
      title: place,
      subtitle,
      subsections
    }
  })

  return sections
}

/**
 * Group entities by date and sort sections sequentially
 *
 * May re-sort results
 *
 * @param {Array} entities
 * @param {Object} options
 * @return {Object}
 */
export function makeDateSections (entities, options = {}) {
  const { category } = options
  const opening = category == 'openings'
  const formatStr = 'MMMM D'

  // create formatted dates for making titles later
  const formattedNow = dateFormat(new Date(), formatStr)
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const formattedTomorrow = dateFormat(tomorrow, formatStr)

  const midnightToday = new Date()
  midnightToday.setHours(0, 0, 0, 0)

  // group by date
  // also filter out exhibitions that occured yesterday (DATE_SUB(1) is performed in the query to grab openings that may have passed but are still *today*)
  const exhibitionsByDate = {}
  entities.forEach(entity => {
    const date = opening ? getOpeningDate(entity) : getEndDate(entity)
    const time = date.setHours(0, 0, 0, 0) // zero the hours to avoid getting multiple entries for each date
    const allow = midnightToday <= time
    if (allow) {
      if (!exhibitionsByDate[time]) {
        exhibitionsByDate[time] = []
      }
      exhibitionsByDate[time].push(entity)
    }
  })

  const sortedDateKeys = Object.keys(exhibitionsByDate).sort()
  const sections = sortedDateKeys.map(key => {
    const entities = exhibitionsByDate[key]
    const date = opening ? getOpeningDate(entities[0]) : getEndDate(entities[0])
    const formattedDate = dateFormat(date, formatStr)

    let dayLabel = ''
    if (formattedNow === formattedDate) {
      dayLabel = 'Today,'
    } else if (formattedTomorrow === formattedDate) {
      dayLabel = 'Tomorrow,'
    }

    // remove the s from category (i.e. openings -> opening/ closings -> closing)
    const subtitle = `${category.substr(0, category.length - 1)} ${dayLabel} ${formattedDate}`

    return {
      title: entities[0].location.place,
      subtitle,
      entities
    }
  })

  return sections;
}

/**
 * Group entities by category
 *
 * May re-sort results
 *
 * @param {Array} entities
 * @return {Object}
 */
export function makeCategorySections(entities) {
  // group by category
  const exhibitionsByCategory = {}
  entities.forEach(entity => {
    const location = entity.location || entity
    if (!exhibitionsByCategory[location.category]) {
      exhibitionsByCategory[location.category] = []
    }

    exhibitionsByCategory[location.category].push(entity)
  })

  const sections = Object.keys(exhibitionsByCategory).map(category => {
    const entities = exhibitionsByCategory[category]
    const location = entities[0].location || entities[0]
    return {
      title: location.place,
      subtitle: category,
      entities
    }
  })

  return sections;
}
