<template>
  <header :class="headerClass">
    <div :class="mapClass" ref="mapContainer">
      <template v-if="loadMaps">
      <gmap-map
        :center="center"
        :zoom="zoom"
        :style="gmapStyle"
        ref="map"
      >
        <gmap-marker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          :icon="markerIcon(m)"
          :zIndex="markerZIndex(m)"
          :clickable="true"
          @click="selectMarker(m)"
        />
      </gmap-map>
    </template>
    <div v-else class="guide-header__map-placeholder"></div>
    </div>
    <div v-if="!isFullscreen" class="guide-header__content">
      <h1 :class="titleClass">
        <span v-html="config.title" />
        <span v-if="config.subtitle" :class="subtitleClass" v-html="config.subtitle" />
      </h1>
      <ul v-if="showPlaces" class="guide-header__places">
        <li v-if="showAllPlace" :class="placeClass(null)">
          <a :href="allPlacesPath" @click="e => onPlaceClick(e, null)">All</a>
        </li>
        <li v-for="place in places" :key="place.text" :class="placeClass(place)" @click="e => onPlaceClick(e, place)">
          <a :href="placeLink(place)" v-html="place.text" />
        </li>
      </ul>
      <button class="guide-header__explore-button" @click="enterMapViaExplore">
        {{ exploreButtonText }}
      </button>
    </div>

    <div v-if="isFullscreen" class="guide-header__controls">
      <button class="guide-header__close-button" @click="close">
        {{ hasPreviousConfig ? '←' : '✕' }}
      </button>
      <a v-if="directionsUrl" :href="directionsUrl" target="_blank" :class="directionsClass">
        directions
      </a>
      <div :class="nearbyButtonClass" @click="toggleShowingNearby">
        {{ nearbyButtonText }}
      </div>
      <div class="guide-header__controls__item" v-if="apiPageIsLoading">loading...</div>
    </div>

    <MapSidebar
      v-if="isFullscreen"
      :expanded="isSidebarExpanded"
      :title="sidebarTitle"
      :locations="markerLocations"
      :isCountry="isCountry"
      @expandRequest="setSidebarExpanded"
    />
  </header>
</template>

<script>
  /**
   * The map at the top of artguide pages
   */
  import * as VueGoogleMaps from 'vue2-google-maps'
  import Vue from 'vue'
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import NavKeys from './mixins/NavKeys'
  import { manhattanDistance, getOutlierStats } from './util'
  import MapSidebar from './MapSidebar.vue';
  import { GOOGLE_MAPS_API_KEY } from '../../constants/APIKeys';
  import { SITE_PATH } from 'shared/constants/Paths';

  import MobileDetect from 'mobile-detect'
  const md = new MobileDetect(window.navigator.userAgent)

  const nyc = {
    coords: { latitude: 40.7, longitude: -74 }
  }

  // Setup Google Maps
  Vue.use(VueGoogleMaps, {
    load: {
      key: GOOGLE_MAPS_API_KEY,
    }
  })

  export default {
    props: {
      // json containing `title` and
      // optionally: `subtitle`, `entities`, `placeStyle`, `exploreStyle`
      configJson: String
    },
    components: { MapSidebar },
    mixins: [NavKeys],
    data() {
      return {
        loadMaps: false,
        hasLoadedMaps: false,
        isSidebarExpanded: false,
        isShowingNearby: false,
        mapDim: null,
        previousCenter: null,
        currentLocation: nyc, // default to nyc
        defaultMarkerIcon:  'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
        selectedMarkerIcon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        nearbyMarkerIcon:   'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
        phone: md.phone(),
        tablet: md.tablet(),
      }
    },
    computed: {
      ...mapState({
        activeModal:            state => state.activeModal,
        stateConfig:            state => state.artguideMap.mapConfig,
        isFullscreen:           state => state.artguideMap.isMapFullscreen,
        selectedLocationId:     state => state.artguideMap.selectedLocationId,
        selectedLocationMethod: state => state.artguideMap.selectedLocationMethod,
        entryLocationId:        state => state.artguideMap.entryLocationId,
        mapNearbyExhibitions:   state => state.artguideMap.nearbyExhibitions,
        loadingNearby:          state => state.activeCalls.includes('fetchMapNearbyExhibitions'),
        apiPagePath:            state => state.artguideApiPage.apiPath,
        apiNextPage:            state => state.artguideApiPage.nextPage,
        apiPageIsLoading:       state => state.artguideApiPage.loading,
      }),
      ...mapGetters({
        displayedExhibitions: 'artguide/displayedExhibitions',
        displayedLocations:   'artguide/displayedLocations',
        canLoadNextApiPage: 'artguideApiPage/canLoadNextPage',

      }),
      config() {
        return this.stateConfig || { entities: [] }
      },

      offsetBottom(){
        return this.$el.offsetTop + this.$el.offsetHeight;
      },

      entities() {
        const { config, displayedExhibitions, displayedLocations, isFullscreen, entryLocationId } = this

        // if entities specified in config, use them; otherwise rely on displayed listings
        let { entities } = config
        if (!entities || entities.length === 0) {
          const locationEntities = displayedLocations.map(location => ({ location, onlyLocation: true }))
          entities = displayedExhibitions.concat(locationEntities)
        }

        // if exploring nearby, only show the entity that we entered with
        if (config.exploreStyle === 'nearby' && isFullscreen) {
          entities = entities.filter(item => item.location.id === entryLocationId)
        }

        return entities
      },
      exploreButtonText() {
        return this.config.exploreStyle === 'nearby' ? 'nearby' : 'explore map'
      },
      nearbyExhibitionsStyle() {
        const { config, entryLocationId } = this
        return config.nearbyGeoCenter || (!this.entryLocationId && config.useCurrentLocation) ? 'geo' : 'place'
      },
      nearbyCenterPosition() {
        return this.config.nearbyGeoCenter || this.currentLocation.coords
      },
      nearbyExhibitionsKey() {
        const { nearbyExhibitionsStyle } = this

        if (nearbyExhibitionsStyle === 'geo') {
          const { latitude, longitude } = this.nearbyCenterPosition
          return JSON.stringify({ latitude, longitude })
        }

        if (this.entities.length > 0) {
          return this.entities[0].location.place_url
        }

        return null
      },
      sidebarTitle() {
        const { config, markerLocations } = this
        if (config.placeStyle === 'none') {
          return markerLocations[0].location.place
        }

        if (config.exploreStyle === 'nearby') {
          return this.nearbyExhibitionsStyle === 'geo' || this.entities.length === 0 ? 'nearby' : this.entities[0].location.place
        }

        return config.subtitle ? `${config.title}: ${config.subtitle}` : config.title
      },
      isCountry() {
        return this.config.placeStyle === 'country'
      },
      isCountryPlace() {
        return this.config.placeStyle === 'countryPlace'
      },
      hasPreviousConfig() {
        return !!this.config.previousConfig
      },
      nearbyExhibitions() {
        const originalIds = {}
        this.entities.forEach(item => originalIds[item.location.id] = true)

        const nearby = this.getNearbyExhibitions()
        return nearby.filter(item => !originalIds[item.location.id])
      },
      nearbyLocationsMap() {
        if (!this.isShowingNearby) return {}

        const map = {}
        this.nearbyExhibitions.forEach(entity => (map[entity.location.id] = true))
        return map
      },
      locationsMap() {
        const locationMap = {}

        let { entities } = this
        if (this.isShowingNearby && this.isFullscreen) {
          entities = entities.concat(this.nearbyExhibitions)
        }

        entities.forEach(entity => {
          const { location, onlyLocation } = entity
          if (!locationMap[location.id]) {
            locationMap[location.id] = {
              location,
              entities: onlyLocation ? [] : [entity]
            }
          } else if (!onlyLocation) {
            locationMap[location.id].entities.push(entity)
          }
        })

        // determine if locations are nearby
        Object.keys(locationMap).forEach(key => {
          locationMap[key].isNearby = !!this.nearbyLocationsMap[key]
        })

        return locationMap
      },
      locations() {
        const geoNearby = this.nearbyExhibitionsStyle === 'geo'
        return Object.values(this.locationsMap)
          .sort((a, b) => {
            if (a.isNearby !== b.isNearby) {
              return a.isNearby ? 1 : -1
            }

            // if both nearby and using current location
            if (a.isNearby && geoNearby) {
              const aDist = manhattanDistance(this.nearbyCenterPosition, a.location)
              const bDist = manhattanDistance(this.nearbyCenterPosition, b.location)
              return aDist - bDist
            }

            return a.location.name.localeCompare(b.location.name)
          })
      },
      showPlaces() {
        return this.places.length > 1 || this.config.myguide
      },
      showAllPlace() {
        const { placeByCategory, config } = this
        return placeByCategory || !!config.category || (config.myguide && this.places.length > 0)
      },
      places() {
        const { placeStyle, places: configPlaces, myguide, otherPlaces } = this.config

        if (placeStyle === 'none' || this.isCountry) {
          return []
        }

        if (placeStyle === 'defaultPlaces') {
          return this.config.defaultPlaces.map(item => Object.assign({ text: item.place }, item))
        }

        // allow config to override places
        if (configPlaces) {
          return configPlaces.sort().map(item => this.processConfigPlace(item))
        }

        const { locationsMap, placeByCategory } = this
        const includeCount = !placeByCategory && placeStyle !== 'cityNoCount'
        const maxPlaces = includeCount ? 7 : 10
        const sorters = {
          city: (a, b) => (b.count - a.count) || a.place.localeCompare(b.place),
          cityNoCount: () => 0
        }

        const placeCounts = {}
        Object.values(locationsMap).map(({ location, entities }) => {
          const key = location.place
          if (!key) {
            return
          }

          const count = entities.length || 1
          if (placeCounts[key]) {
            placeCounts[key].count += count
          } else {
            const path = myguide ? location.place_url : location.placePath
            placeCounts[key] = { place: key, place_url: location.place_url, count, path }
          }
        })

        let places = Object.values(placeCounts)
          .sort(sorters[placeStyle])
          .slice(0, maxPlaces)
          .map(item => Object.assign({
            text: includeCount ? `${item.place} (${item.count})` : item.place
          }, item))

        if (otherPlaces) {
          places = places.concat(otherPlaces.map(item => this.processConfigPlace(item)))
          places.sort(sorters[placeStyle])
        }

        return places
      },
      allPlacesPath() {
        if (this.placeByCategory) {
          return this.placeLink(this.places[0], false)
        }

        if (this.config.myguide) {
          return window.location.pathname
        }

        return window.location
      },
      markerLocations() {
        const { locations } = this

        let markerLocations = []
        if (this.isCountry) {
          const places = {}
          locations.forEach(item => {
            if (!places[item.location.place]) {
              places[item.location.place] = { entities: item.entities }
              markerLocations.push(item)
            } else {
              places[item.location.place].entities = places[item.location.place].entities.concat(item.entities)
            }
          })

          // merge entities for each place
          markerLocations = markerLocations.map(item => {
            const placeEntities = places[item.location.place].entities
            return Object.assign({ placeEntities }, item)
          })
        } else {
          markerLocations = locations
        }

        return markerLocations
      },
      markers() {
        return this.markerLocations
          .map(({ location, isNearby }) => ({
            isNearby,
            locationId: location.id,
            position: this.locationPosition(location)
          }))
          .filter(marker => !!marker.position)
      },
      bounds() {
        const nearbyAndEntry = this.config.exploreStyle === 'nearby' && this.entryLocationId

        if (!nearbyAndEntry && (this.config.useCurrentLocation || this.markers.length === 0)) {
          const { latitude, longitude } = this.currentLocation.coords
          return {
            minLat: latitude - 0.2,
            maxLat: latitude + 0.2,
            avgLat: latitude,
            minLng: longitude - 0.2,
            maxLng: longitude + 0.2,
            avgLng: longitude
          }
        }

        let boundsMarkers = this.markers.filter(marker => !marker.isNearby)

        // filter out outliers (weirdly misplaced locations) to make the map appear cleaner
        if (boundsMarkers.length > 10) {
          const sortedLats = boundsMarkers.map(m => m.position.lat).sort((a, b) => a - b)
          const latStats = getOutlierStats(sortedLats)
          const sortedLngs = boundsMarkers.map(m => m.position.lng).sort((a, b) => a - b)
          const lngStats = getOutlierStats(sortedLngs)

          boundsMarkers = boundsMarkers.filter(marker => {
            return marker.position.lat > latStats.lowCutoff
              && marker.position.lat < latStats.highCutoff
              && marker.position.lng > lngStats.lowCutoff
              && marker.position.lng < lngStats.highCutoff
          })
        }

        const bounds = { minLat: 180, maxLat: -180, minLng: 180, maxLng: -180, avgLat: 0, avgLng: 0 }
        boundsMarkers.forEach(marker => {
          bounds.minLat = Math.min(bounds.minLat, marker.position.lat)
          bounds.maxLat = Math.max(bounds.maxLat, marker.position.lat)
          bounds.avgLat += marker.position.lat

          bounds.minLng = Math.min(bounds.minLng, marker.position.lng)
          bounds.maxLng = Math.max(bounds.maxLng, marker.position.lng)
          bounds.avgLng += marker.position.lng
        })

        bounds.avgLat /= boundsMarkers.length
        bounds.avgLng /= boundsMarkers.length

        return bounds
      },
      zoom() {
        // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
        function getBoundsZoomLevel(bounds, mapDim) {
          var WORLD_DIM = { height: 256, width: 256 };
          var ZOOM_MAX = 21;

          function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
          }

          function zoom(mapPx, worldPx, fraction) {
              return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
          }

          var ne = bounds.getNorthEast();
          var sw = bounds.getSouthWest();

          var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

          var lngDiff = ne.lng() - sw.lng();
          var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

          var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
          var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

          return Math.min(latZoom, lngZoom, ZOOM_MAX);
        }

        const defaultZoom = 12
        const selectionZoomModifier = 1
        const maxZoom = 17

        if (!this.hasLoadedMaps || this.config.useCurrentLocation || this.markers.length === 0) {
          return defaultZoom
        }

        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(this.bounds.minLat, this.bounds.minLng),
          new google.maps.LatLng(this.bounds.maxLat, this.bounds.maxLng)
        )

        const mapDim = this.mapDim || this.$refs.mapContainer.getBoundingClientRect()

        let zoom = getBoundsZoomLevel(bounds, mapDim)
        if (isNaN(zoom)) {
          return defaultZoom // default zoom if calculation fails
        }

        if (this.selectedLocationId) {
          zoom += selectionZoomModifier
        }

        if (zoom >= 14) {
          zoom -= 1 // correct for too-zoomed close results
        }

        return Math.min(maxZoom, zoom)
      },
      selectedLocation() {
        const item = this.locationsMap[this.selectedLocationId]
        return item ? item.location : null
      },
      center() {
        const { selectedLocationMethod, selectedLocation } = this
        const { useCurrentLocation } = this.config

        const manualSelectionMethods = ['entry', 'markerClick', 'sidebarClick', 'sidebarOpen', 'fetchMapPlaceExhibitions']
        const delayedSelectionMethods = ['loadedApiPage']
        const centerSelectedMarker = selectedLocation && (
          manualSelectionMethods.includes(selectedLocationMethod)
          || (!useCurrentLocation && delayedSelectionMethods.includes(selectedLocationMethod))
        )
        if (centerSelectedMarker) {
          // if we selected our marker via a manual method, please center that marker
          const position = this.locationPosition(selectedLocation)
          if (position) {
            return position
          }
        }

        // if we selected via a passive method and have a previous center, please use that to avoid map jumping around
        if (!useCurrentLocation && this.previousCenter) {
          return this.previousCenter
        }

        // by default, center around the average of all markers
        return { lat: this.bounds.avgLat, lng: this.bounds.avgLng }
      },
      directionsUrl() {
        if (!this.selectedLocation) {
          return null
        }

        const position = this.locationPosition(this.selectedLocation)
        return position ? `https://www.google.com/maps/dir/?api=1&destination=${position.lat},${position.lng}` : null
      },
      placeByCategory() {
        return this.config.placeStyle === 'placeByCategory'
      },
      showNearbyButton() {
        if (this.config.disableNearbyButton) return false

        if (this.config.placeStyle === 'none') return true

        if (this.nearbyExhibitionsStyle === 'geo') return this.loadingNearby

        return this.config.exploreStyle === 'nearby'
      },
      nearbyButtonText() {
        if (this.loadingNearby) {
          return 'loading...'
        }

        return this.isShowingNearby ? 'hide nearby' : 'show nearby'
      },
      headerClass() {
        return {
          'guide-header': true,
          'guide-header--fullscreen': this.isFullscreen,
          'guide-header--sidebar-expanded': this.isSidebarExpanded,
        }
      },
      mapClass() {
        return {
          'guide-header__map': true,
          'guide-header__map--small': !this.isFullscreen,
          'guide-header__map--fullscreen': this.isFullscreen
        }
      },
      gmapStyle() {
        return {
          width: '100%',
          height: '100%'
        }
      },
      titleClass() {
        return {
          'guide-header__title': true,
          'guide-header__title--with-subtitle': !!this.config.subtitle,
          'guide-header__title--with-places': this.places.length > 0
        }
      },
      subtitleClass() {
        return {
          'guide-header__subtitle': true,
          'guide-header__subtitle--break-line': this.config.breakLine
        }
      },
      directionsClass() {
        return {
          'guide-header__controls__item': true,
          'guide-header__controls__item--directions': true,
          'guide-header__controls__item--disabled': !this.selectedLocationId
        }
      },
      nearbyButtonClass() {
        return {
          'guide-header__controls__item': true,
          'guide-header__controls__item--disabled': !this.showNearbyButton,
          'guide-header__controls__item--loading': this.loadingNearby
        }
      }
    },
    watch: {
      center(center) {
        if (!this.previousCenter) {
          this.previousCenter = center
          return
        }

        const shouldSetPreviousCenter = center && center.lat !== this.previousCenter.lat && center.lng !== this.previousCenter.lng
        if (shouldSetPreviousCenter) {
          this.previousCenter = center
        }
      },
    },
    mounted() {
      // first time the map mounts, set the vuex map config state based on json prop
      this.setMapAvailable(!this.phone && !this.tablet);
      if (!this.stateConfig && this.configJson) {
        const config = Object.assign({
          entities: [],
          placeStyle: 'city',
          useCurrentLocation: false, // if true, will ask for users location and center map there
          nearbyGeoCenter: null, // if provided (latitude and longitude), will use coordinates for center of "show nearby" api call
          exploreStyle: 'map'
        }, JSON.parse(this.configJson))
        this.setMapConfig(config)
      }

      window.addEventListener('keydown', this.onKeydown)

      this.setMapMounted(true)

      // resize map manually on fullscreen change
      this.$watch('isFullscreen', isFullscreen => {
        if(!this.loadMaps && isFullscreen){
          this.loadMaps = true;
        }
        if(this.loadMaps && typeof this.$refs.map !== 'undefined'){
          this.$refs.map.resizePreserveCenter()
        }

        if (isFullscreen) {
          this.$el.parentElement.classList.add('map-container--sticky');
          if (this.markerLocations.length === 1 && this.selectedLocationId !== this.markerLocations[0].location.id) {
            this.selectMapLocation({ id: this.markerLocations[0].location.id, method: 'entry' })
          }
        }
        else{
          this.$el.parentElement.classList.remove('map-container--sticky');
        }

        setTimeout(() => {
          // recalculate map dim
          this.mapDim = this.$refs.mapContainer.getBoundingClientRect()
        }, 0)
      })

      // watch for google maps to load
      const loadInterval = setInterval(() => {
        if (window.google) {
          this.hasLoadedMaps = true
          clearInterval(loadInterval)
        }
      }, 100)

      // if we are to use current location, request it
      if (this.config.useCurrentLocation && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          if (position) {
            const { longitude, latitude } = position.coords;
            const coords = { longitude, latitude };
            this.currentLocation.coords = coords;
          }
        })
      }
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeydown)

      document.body.style.overflow = ''

      this.setMapMounted(false)
      this.setMapConfig(null)
    },
    methods: {
      ...mapMutations('artguideMap', ['setMapMounted', 'setMapAvailable', 'setMapConfig', 'enterMapWithoutLocation', 'selectMapLocation']),
      ...mapMutations('myguide', { setMyguidePlaceUrl: 'setPlaceUrl' }),
      ...mapActions('artguideMap', ['fetchMapNearbyExhibitions']),
      ...mapActions('artguideApiPage', ['fetchApiPageResults']),
      placeClass(place) {
        return { active: place ? place.place === this.config.currentPlace : !this.config.currentPlace }
      },
      placeLink(place, district = this.placeByCategory) {
        const { category, myguide } = this.config
        if (myguide) {
          return place.place_url ? `${window.location.pathname}?place_url=${place.place_url}` : window.location.pathname
        }

        if ( category === 'must see' ){
          return `${SITE_PATH}/artguide/place/${place.place_url}?category=must+see`;
        }

        const params = new URLSearchParams(location.search)
        params.delete('place_url')

        if (district) {
          params.set('district', place.place)
        } else {
          params.delete('district')
        }

        if (category) {
          params.set('category', category)
        }

        return `${place.path}?${params.toString()}`
      },
      processConfigPlace(item) {
        let text = item.text || item.place
        if (item.count) text = `${item.place} (${item.count})`
        return Object.assign(item, { text })
      },
      locationPosition(location) {
        return location.latitude && location.longitude
          ? { lat: location.latitude, lng: location.longitude }
          : null
      },
      enterMapViaExplore() {
        this.enterMapWithoutLocation()
        if ( this.apiPagePath && this.canLoadNextApiPage ) {
          this.fetchApiPageResults({
            apiPath: this.apiPagePath,
            pageSize: 15,
            fetchAll: 1
          })
        }
        if (this.config.exploreStyle === 'nearby') {
          this.setShowingNearby(true)
        }
      },
      onPlaceClick(ev, place) {
        if (this.config.myguide) {
          ev.preventDefault()
          this.setMyguidePlaceUrl({ placeUrl: place ? place.place_url : null, pushState: true })
        }
      },
      markerIcon(marker) {
        if (marker.locationId === this.selectedLocationId) {
          return this.selectedMarkerIcon
        }

        if (marker.isNearby) {
          return this.nearbyMarkerIcon
        }

        return this.defaultMarkerIcon
      },
      markerZIndex(marker) {
        return marker.locationId === this.selectedLocationId ? 2 : 1
      },
      selectMarker(marker) {
        this.selectMapLocation({ id: marker.locationId, method: 'markerClick' })

        if (!this.isSidebarExpanded) {
          this.isSidebarExpanded = true
        }
      },
      getNearbyExhibitions() {
        return this.mapNearbyExhibitions[this.nearbyExhibitionsKey] || []
      },
      toggleShowingNearby() {
        if (this.loadingNearby) {
          return
        }

        this.setShowingNearby(!this.isShowingNearby)
      },
      setShowingNearby(showingNearby) {
        this.isShowingNearby = showingNearby

        if (this.isShowingNearby && this.getNearbyExhibitions().length === 0) {
          if (this.nearbyExhibitionsStyle === 'geo') {
            const { latitude, longitude } = this.nearbyCenterPosition
            this.fetchMapNearbyExhibitions({ geo: { latitude, longitude } })
          } else if (this.entities.length > 0) {
            this.fetchMapNearbyExhibitions({ place_url: this.entities[0].location.place_url })
          }
        }
      },
      onKeydown(ev) {
        if (this.isFullscreen) {
          this.onNavKeydown(ev)
        }
      },
      onEscPress() {
        // on ESC press (if no modal is open), close fullscreen map
        if (!this.activeModal) {
          this.close()
        }
      },
      close() {
        history.back()
      },
      setSidebarExpanded(isExpanded) {
        this.isSidebarExpanded = isExpanded
      }
    }
  };
</script>

<style lang="scss">
@import "scss/variables/typography";
@import "scss/variables/breakpoints";

.map-container--sticky{
  position: sticky; 
  top: 0; 
  z-index: 1;
}

.guide-header {
  margin: 0 0 15px 0;
  position: relative;
  overflow: hidden;
}

.guide-header--fullscreen {
  margin: 0;
  top: 0;
  height: 100vh;
}

.guide-header__map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.guide-header__map--small {
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.guide-header__content {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  text-align: center;
  position: relative;
  height: 188px;
}

.guide-header__title {
  margin: 0 0 0 0;
  padding: 60px 0 0 0;
  font-size: 40px;
  font-weight: 500;
}

.guide-header__title--with-places {
  padding-top: 40px;
}

.guide-header__title--with-subtitle {
  font-size: 32px;
}

.guide-header__subtitle {
  font-weight: normal;
}

.guide-header__subtitle--break-line {
  display: block;
}

.guide-header__places {
  list-style: none;
  margin: 0;
  padding: 20px 140px 60px 140px;
}

.guide-header__places li {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  font-size:92%;

  &.active {
    font-weight: $medium;
  }

  &:not(:first-child)::before {
    content: " • ";
    margin: 0 5px 0 0;
    cursor: default;
  }
}

.guide-header__map-placeholder {
  width: 100%;
  height: 100%;
  background-image: url(~img/artguide-map-placeholder.svg);
  background-size: cover;
  background-position: center;
}

.guide-header__explore-button {
  position: absolute;
  right: 30px;
  bottom: 10px;
  outline: none;
  border: none;
  background: none;
  font-style: italic;
  cursor: pointer;
}

.guide-header__controls {
  position: absolute;
  top: 5px;
  left: 12px;

  & .guide-header__close-button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    font-size: 32px;
  }

  & .guide-header__controls__item {
    display: block;
    margin: 10px 0;
    width: 200px;
    background: rgba(255, 255, 255, 0.85);
    padding: 8px;
    text-transform: lowercase;
    cursor: pointer;
    user-select: none;

    &.guide-header__controls__item--disabled {
      display: none;
    }

    &.guide-header__controls__item--loading {
      opacity: 0.8;
    }
  }
}

// Google Maps Styles
.guide-header {
  .gm-svpc,
  .gm-style-mtc,
  .gm-fullscreen-control {
    display: none;
  }

  .gm-bundled-control-on-bottom {
    transition: transform 0.3s;
  }
}

.guide-header--sidebar-expanded {
  .gm-bundled-control-on-bottom {
    transform: translateX(-310px);
  }
}

@media (max-width: $breakpoint-medium){
  .guide-header__explore-button{
    display: none;
  }
}

</style>
