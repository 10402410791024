/**
 * Metadata relevant to user's location within the site structure.
 */

import NAVIGATION from 'shared/constants/Navigation';
import ADMIN_NAVIGATION from '../../../admin/constants/AdminNavigation';
import GUIDE_NAVIGATION from '../../../artguideadmin/constants/ArtguideAdminNavigation';

const navigation = {
	namespaced: true, 

	state: {
		category: '',
		tag: '',
		isAdmin: false,
		isArtguideAdmin: false,
	},

	getters: {
		parentCategory: function(state){
			if ( !state.category ){
				return '';
			}
			let NAV = state.isAdmin ? ADMIN_NAVIGATION : NAVIGATION;
			if (state.isArtguideAdmin) {
				NAV = GUIDE_NAVIGATION;
			}
			for ( let i = 0; i < NAV.PAGES.length; i++ ) {
				let page = NAV.PAGES[i];
				let subpageNames = page.hasOwnProperty('subpages') ? page.subpages.map((subpage) => { return subpage.name.toLowerCase() }) : [];
				if (subpageNames.indexOf( state.category.toLowerCase() ) > -1 ){
					return page.name;
				}
			}
			return '';
		},
	},
	
	mutations: {
		setCategory(state, category){
			state.category = category;
		},

		setTag(state, tag){
			state.tag = tag;
		},

		setIsAdmin(state, val){
			state.isAdmin = val;
		},

		setIsArtguideAdmin(state, val){
			state.isArtguideAdmin = val;
		}
	},
}

export default navigation;
