<template>
  <div class="guide-nav-select-wrapper">
    <div :class="classes">
      <select v-model="value" :disabled="disabled">
        <option v-for="option in processedOptions" :disabled="option.disabled">
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      initialValue: String,
      options: Array,
      disabled: {
        type:Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return { value: this.initialValue }
    },
    computed: {
      processedOptions() {
        return this.options.map(option => ({
          text: option.separator ? '──────────' : (option.text || option),
          disabled: option.disabled || option.separator
        }))
      },
      classes() {
        return{
          'styled-select': true,
          'styled-select--disabled': this.disabled,
        }
      }
    },
    methods: {
      setValue (value) {
        this.value = value
      }
    },
    watch: {
      value(value) {
        this.$emit('select', value)
      }
    }
  };
</script>

<style>
.guide-nav-select-wrapper {
  margin: 0 0 10px 0;
  position: relative;
  width: 160px;
  flex-grow: 1;
}

.guide-nav-select-wrapper .styled-select {
  min-width: 160px;
  margin-bottom: 0;
}
</style>
