<template>
    <ul class="results-list">
        <li v-for="row in searchResults" class="results-list__article">

            <div v-if="row.category!='artguide'">

                <div v-if="hasMedia(row.entity)">
                    <figure class="results-list__image">
                        <a :href="row.entity.url">
                            <img v-if="hasMedia(row.entity)" :srcset="srcsetSearch(row.entity.primaryImage, 'square')" :sizes="sizes">
                        </a>
                    </figure>
                </div>
                <div v-else class="results-list__image-blank"></div>

                <div class="results-list__text">

                    <div class="results-list__meta">

                        <span class="results-list__category" v-if="row.category">
                            {{ row.category }}
                        </span>

                        <span class="results-list__date" v-if="row.entity.issueTitle">
                            {{ row.entity.issueTitle }}
                        </span>
                        <span class="results-list__date" v-else-if="row.entity.published">
                            {{ row.entity.published | formatDate('MMMM D, YYYY') }}
                        </span>

                    </div>

                    <h1 class="results-list__h1">
                        <a :href="row.entity.url" v-html="row.entity.titleFormatted"></a>
                    </h1>

                    <div v-if="row.entity.writerFormatted" class="results-list__author" v-html="row.entity.writerFormatted"></div>

                    <p v-if="row.highlight" v-html="row.highlight"></p>
                    <p v-else v-html="row.entity.bodyExcerpt"></p>

                </div>

            </div>


            <div v-else-if="row.columns && isLocation(row.id)">

               <div v-if="row.columns && hasArtguideMedia(row.columns)">
                    <figure class="results-list__image">
                        <a :href="row.columns.path">
                            <img v-if="hasArtguideMedia(row.columns)" :srcset="srcsetSearch(row.columns.media, 'landscape')" :sizes="sizes">
                        </a>
                    </figure>
                </div>
                <div v-else class="results-list__image-blank"></div>

                <div class="results-list__text">
                    <div class="results-list__meta">

                        <span class="results-list__category" v-if="row.category">
                            {{ row.category }}
                        </span>

                        <span class="results-list__date" v-if="getPlace(row)" v-html="getPlace(row)"></span>
                    </div>

                    <a :href="row.columns.path" class="results-list__address" v-if="row.columns.location_name" v-html="row.columns.location_name"></a>
                        
                    <br />
                    <span v-if="getPlace(row)" class="results-list__address" v-html="row.columns.address + `, ` + getPlace(row) + `, ` + row.columns.country"></span>

                    <p v-if="row.highlight" v-html="row.highlight"></p>

                </div>
            </div>


            <div v-else-if="row.columns">
               <div v-if="row.columns && hasArtguideMedia(row.columns)">
                    <figure class="results-list__image">
                        <a :href="row.columns.path">
                            <img v-if="hasArtguideMedia(row.columns)" :srcset="srcsetSearch(row.columns.media, 'landscape')" :sizes="sizes">
                        </a>
                    </figure>
                </div>
                <div v-else class="results-list__image-blank"></div>

                <div class="results-list__text">
                    <div class="results-list__meta">

                        <span class="results-list__category" v-if="row.category">
                            {{ row.category }}
                        </span>

                        <span class="results-list__date" v-if="getPlace(row)" v-html="getPlace(row)"></span>
                    </div>

                    <a :href="row.columns.path" class="results-list__address" v-if="row.columns.exhibition_title" v-html="row.columns.exhibition_title"></a>
                        
                    <br />

                    <div v-if="row.columns.run_dates" class="results-list__exhibition-dates">
                        {{ row.columns.run_dates }}
                    </div>

                    <span v-if="getPlace(row)" class="results-list__address" v-html="row.columns.address + `, ` + getPlace(row) + `, ` + row.columns.country"></span>

                    <p v-if="row.highlight" v-html="row.highlight"></p>

                </div>
            </div>

        </li>
    </ul>
</template>

<script>
    import { mapState } from 'vuex';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import formatDate from 'shared/components/mixins/FormatDate';

    export default {
        mixins: [srcsetFromMedia, formatDate],
        computed: {
            ...mapState({
                searchResults: (state) => state.search.searchResults,
            }),
        },

        methods: {
            hasMedia: function(row){
                return row.hasOwnProperty('primaryImage');
            },
            hasArtguideMedia: function(row){
                return row.hasOwnProperty('media') && row.media;
            },
            isLocation: function(docId){
                return docId.search("Locations") >= 0;
            },
            getPlace: function(row){
                if (row.hasOwnProperty('columns') && row.columns.hasOwnProperty('place')) {
                    return row.columns.place;
                }
                return '';
            }
        }
    };
</script>