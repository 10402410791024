<template>
    <div :class="{ 'multi-video__article': true, 'multi-video__article--current': isCurrent }" v-on:mouseover="setCurrent">
        <slot></slot>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    export default {
        props: {
            idx: {
                type: Number,
                required: true,
            }
        },

        computed: {
            ...mapState('homepage', ['currentMultiVideo']),
            isCurrent: function(){
                return this.currentMultiVideo === this.idx;
            }
        },

        methods: {
            ...mapMutations('homepage', ['setCurrentMultiVideo']),
            setCurrent: function(){
                this.setCurrentMultiVideo(this.idx);
            },
        }
    };
</script>