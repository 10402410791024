/**
 * This enables/disables tab navigation depending on the state of the parent menu
 */

export default {
    props: {
        menuActive: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        tabIndex(){
            return this.menuActive ? 0 : -1;
        },
    }
};