/* mixin for CitySelector and PlaceSelector */
export default {
  props: {
    selectHandler: {
        type: Function,
        required: true,
    },
    disabled: {
        type: Boolean,
        requried: false,
        default: false,
    }
  }
}
