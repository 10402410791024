<template>
	<button class="cart__remove-button" @click="submit" name="remove"><img class="remove-button__icon" src="~img/x_gray.svg" alt="remove"></button>	
</template>

<script>
	/**
	 * Removes an item from the cart
	 */
	import { mapActions } from 'vuex';
	export default {
		props: {
			itemId: Number,   // for serverCart
			itemIndex: Number // for clientCart
		},

		methods: {
			...mapActions(['removeFromCart']),
			submit: function(){
				this.removeFromCart({id: this.itemId, itemIndex: this.itemIndex});
			}
		}
	};
</script>

<style lang="scss">
	@import "scss/variables/breakpoints";
	
	.cart__remove-button{
		height: 22px;
		background: none;
	}

	.remove-button__icon{
		height: 20px;
		width: 20px;
	}

	@media screen and (max-width: $breakpoint-medium){
		.cart__remove-button{
			padding-right: 0px;
			padding-left: 1rem;
		}
	}
</style>