<template>
  <section class="artguide-listings__section">

    <h2 class="section-heading artguide-listings__section__title">
      {{ title }}
      <span v-if="subtitle" class="artguide-listings__section__subtitle">
        {{ subtitle }}
      </span>
    </h2>
    <!-- <a v-if="showAllLink && showAllLink.length > 0" :href="showAllLink" class="artguide-listings__show-all">show all</a> -->
    <div class="artguide-listings__show-all" v-html="allActiveToggle"></div>
    <ul class="artguide-listings__section__list">
      <transition-group name="artguideListing">
        <li v-for="entity in entities" :key="entity.id">
          <template v-if="entity.location">
            <Listing :entityJson="entityJSON(entity)" :section-type="sectionType" />
          </template>
          <template v-else>
            <Venue :entityJson="entityJSON(entity)" listItem="true" :section-type="sectionType" />
          </template>
        </li>
      </transition-group>
    </ul>

    <template v-for="subsection in subsections">
      <h3 class="section-heading artguide-listings__section__title--subsection">
        {{ subsection.title }}
      </h3>
      <ul class="artguide-listings__section__list">
        <transition-group name="artguideListing">
          <li v-for="entity in subsection.entities" :key="entity.id">
            <template v-if="entity.location">
              <Listing :entityJson="entityJSON(entity)" :section-type="sectionType"></Listing>
            </template>
            <template v-else>
              <Venue :entityJson="entityJSON(entity)" listItem="true" :section-type="sectionType" />
            </template>
          </li>
        </transition-group>
      </ul>
    </template>
  </section>
</template>

<script>
  import Listing from 'shared/components/artguide/Listing.vue'
  import Venue from './Venue.vue'

  export default {
    components: { Listing, Venue },
    props: {
      title: String,
      entities: Array,
      subtitle: { type: String, default: null },
      subsections: { type: Array, default: null },
      showAllLink: {type: String, default: null },
      allActiveToggle: {type: String, default: null},
      sectionType: {type: String, default: null},
    },
    methods: {
      entityJSON(entity) {
        return JSON.stringify(entity)
      }
    }
  }
</script>

<style>
  .artguideListing-leave-active {
    transition: all .5s;
  }

  .artguideListing-leave-to {
    opacity: 0;
  }
</style>
