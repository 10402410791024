import { BREAKPOINT_MEDIUM } from 'shared/constants/Common';
import { imagePath } from '../util';

export default{
	computed: {
		mediaQueryMobile(){
        return `(max-width: ${BREAKPOINT_MEDIUM}px)`
      	},

      	mediaQueryDesktop(){
      	  return `(min-width: ${BREAKPOINT_MEDIUM}px)`
      	},
	},
	methods: {
		imagePath(image, square){
			return imagePath(image, square);
		}
	}
}