const artguide = {
	namespaced: true,

	state: {
		displayedExhibitionIds:  {},
		displayedLocationIds:    {},

		activePlaces:          [],
		selectedPlace:         null,
		selectedCategory:      null,

		isArtguideShowing:     false,
	},

	getters: {
		displayedExhibitions: state => Object.values(state.displayedExhibitionIds).filter(item => item.count > 0).map(item => item.entity),
		displayedLocations: state => Object.values(state.displayedLocationIds).filter(item => item.count > 0).map(item => item.entity)
	},

	mutations: {
		addDisplayedExhibition(state, exhibition) {
			let { id } = exhibition
			let item = { entity: exhibition, count: state.displayedExhibitionIds[id] ? state.displayedExhibitionIds[id].count + 1 : 1 }
			state.displayedExhibitionIds = { ...state.displayedExhibitionIds, [id]: item }
		},
		removeDisplayedExhibition(state, exhibition) {
			let { id } = exhibition
			let item = { entity: exhibition, count: state.displayedExhibitionIds[id] ? state.displayedExhibitionIds[id].count - 1 : 0 }
			state.displayedExhibitionIds = { ...state.displayedExhibitionIds, [id]: item }
		},
		addDisplayedLocation(state, location) {
			let { id } = location
			let item = { entity: location, count: state.displayedLocationIds[id] ? state.displayedLocationIds[id].count + 1 : 1 }
			state.displayedLocationIds = { ...state.displayedLocationIds, [id]: item }
		},
		removeDisplayedLocation(state, location) {
			let { id } = location
			let item = { entity: location, count: state.displayedLocationIds[id] ? state.displayedLocationIds[id].count - 1 : 0 }
			state.displayedLocationIds = { ...state.displayedLocationIds, [id]: item }
		},
		setActivePlaces(state, activePlaces) {
			state.activePlaces = activePlaces
		},
		setSelectedPlace(state, place) {
			state.selectedPlace = place
		},
		setSelectedCategory(state, category) {
			state.selectedCategory = category
		},
		setArtguideShowing(state, isArtguideShowing) {
			state.isArtguideShowing = true
		}
	}
}

export default artguide;
