import { API_PATH, SITE_PATH }from 'shared/constants/Paths';
import MODALS from 'shared/constants/Modals';

import _debounce from 'lodash/debounce';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

const _url = API_PATH + '/suggest';
const minInputLength = 3;

export default {
	data(){
		return {
			inputValue: '',
			searchPath: SITE_PATH + '/artguide/search'
		}
	},

	computed: {
		...mapState({
			suggestions: (state) => state.search.suggestions
		})
	},

	methods: {
		...mapActions('search', ['getSuggestions']),
		...mapMutations(['openModal', 'closeModal']),
		...mapMutations('search', ['setSuggestions']),

		preventIfEmpty: function(e){
			if (this.inputValue === ''){
				e.preventDefault();
				return false;
			}
		}
	},

	watch: {
		inputValue: _debounce(function(search){
			if (search.length < minInputLength) {
				this.setSuggestions([]);
			}
			else{
				this.getSuggestions(search);
			}			
		}, 250)
	}
}