<template>
    <figure class="lightbox-video-wrapper">
        <img v-if="!activated" @click.prevent="activate" class="lightbox__image video-placeholder" :src="imagePath">
        <vimeo-embed v-if="showVimeo" :video-id="videoId"></vimeo-embed>
        <you-tube-embed v-if="showYouTube" :video-id="videoId"></you-tube-embed>
        <caption @click.prevent="activate" class="lightbox__caption lightbox__caption--viewing-room-video">
            <div v-html="title"></div>
            <div class="video-activate" v-show="!activated">
                <img v-if="blue" src="~img/play_blue-01.svg">
                <img v-else src="~img/play-02.svg"> Play Video
            </div>
        </caption>
    </figure>
</template>

<script>
    import VimeoEmbed from './VimeoEmbed.vue';
    import YouTubeEmbed from './YouTubeEmbed.vue';
    import isArray from 'lib/utils/IsArray';

    export default {
        components: {
            'vimeo-embed': VimeoEmbed,
            'you-tube-embed': YouTubeEmbed,
        },

        props: {
            video: {
                required: true,
            },
            blue: {
                type: Boolean,
                required: false,
                default: false,
            }
        },

        data(){
            return {
                activated: false,
            };
        },

        computed: {
            vendor: function(){
                return this.videoParsed.videoSource || this.videoParsed.video_source || this.video.url.split(':')[0].toLowerCase();
            },
            videoId: function(){
                return this.videoParsed.videoId || this.videoParsed.video_id || this.video.url.split(':')[1];
            },
            showVimeo: function(){
                return this.activated && (this.vendor.toLowerCase() === 'vimeo');
            },
            showYouTube: function(){
                return this.activated && (this.vendor.toLowerCase() === 'youtube');
            },
            imagePath: function(){
                return this.videoParsed.primary_image ? this.videoParsed.primary_image.path_large : this.videoParsed.primaryImage.pathLarge;   
            },
            title: function(){
                return this.videoParsed.title_formatted || this.videoParsed.titleFormatted;
            },
            videoParsed: function(){
                return  isArray(this.video)? this.video[0] : this.video;
            }
        },

        methods: {
            activate: function(){
                this.activated = true;
            },
        },
    };
</script>

<style lang="scss">
    @import "scss/variables/breakpoints";

    .lightbox__content--visible.lightbox-video-wrapper{
        width: 70vw;
        display: table;
        img{
            display: table-caption;
        }
    }

    .lightbox__caption--viewing-room-video{
        img{
            height: .75rem;
            width: auto;
            display: inline-block !important;
        }
    }

    .video-activate{
        text-transform: uppercase;
        display: block;
        &:hover{
            cursor: pointer;
        }
    }

    .video-placeholder:hover{
        cursor: pointer;
    }

    @media screen and (max-width: $breakpoint-medium){
        .lightbox__content--visible.lightbox-video-wrapper{
            width: 100vw;
            display: table;
        }
    }
</style>