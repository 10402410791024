<template>
	<div class="purchase-cover">
		<slot></slot>
		<div class="purchase-cover__message"></div>
	</div>
</template>

<script>
	/**
	 * This component adds an animation effect to the cover of an issue when it's added to the user's cart
	 */
	import { mapState, mapGetters } from 'vuex';
	import _map from 'lodash/map';

	export default {
		props: {
			itemId: Number
		},
		computed: {
			...mapState({
				userIsLoggedIn: state => state.user.userIsLoggedIn
			}),
			...mapGetters(['cart']),
			isInCart: function(){
				const cartIds = this.userIsLoggedIn ? 
					_map(this.cart, (cartItem) => cartItem.item.id) :
					_map(this.cart, (cartItem) => cartItem.itemId);

				return cartIds.indexOf(this.itemId) > -1; 
			}
		},
		mounted(){
			document.addEventListener('addedToCart', (e) => {
				this.$el.classList.add('in-cart');
				setTimeout( () => {
					this.$el.classList.remove('in-cart');
				}, 2000);
			});
		}
	};
</script>

<style>
	.purchase-cover{
		position: relative;
	}

	.purchase-cover__message{
		display: none;
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		background: #fff;
	}

	.purchase-cover__message::after{
		position: absolute;
		content: ' ';
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-size: 1.5rem;
	}

	.purchase-cover.in-cart .purchase-cover__message{
		display: block;
		animation: fade-out;
		animation-duration: 2s;
	}

	@keyframes fade-out {
		0% {
			opacity: .75;
		}

		100%{
			opacity: 0;
		}
	}

</style>