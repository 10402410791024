<template>
    <transition-group name="drawer-reverse">
        <div class="paywall-counter" key="paywall-counter" v-show="showCounter">

           <div class="paywall-mobile">

                <div class="paywall-counter__link-mobile">
                    <a href="#" @click.prevent="showOptions">
                    <img :src="togglelabel">
                    <span class="visually-hidden">close</span></a>
                </div>

                <div v-if="!options" class="paywall-counter__message" style="margin:0 40px;">
                    <span>{{ message }} <a :href="subscribeLink" style="border-bottom: 1px solid #000;">Subscribe</a> for immediate access to this and over five decades of articles from our archives.
                    </span>
                </div>

                <div v-else class="paywall-counter__content">
                    <div class="paywall-counter__text">
                        <span v-html="text"></span>
                    </div>

                    <div class="paywall-counter__image">
                        <a :href="subscribeLink">
                            <img :src="getImageUrl()">
                        </a>
                    </div>

                    <div class="paywall-counter__login">
                        <span v-if="userIsLoggedIn">
                            Subscriber? <a :href="activateLink">Activate your access</a>.
                        </span>

                        <span v-else>
                            Subscriber? Login <a href="#" v-on:click.prevent="openSigninForm">here</a>.
                        </span>
                    </div>
                </div>

           </div>


            <div class="paywall-desktop">

                <div v-if="!options" class="wrapper">
                    <div class="desktop-flex">
                        <div class="paywall-counter__message" style="text-align: center;width:100%;">
                            <span>{{ message }} <a :href="subscribeLink" style="border-bottom: 1px solid #000;">Subscribe</a> for immediate access to this and over five decades of articles from our archives.
                            </span>
                        </div>

                        <div class="paywall-toggle__link">
                            <span class="paywall-desktop">
                                <a href="#" @click.prevent="showOptions"> <img :src="togglelabel"><span class="visually-hidden">close</span></a>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-else class="wrapper">

                    <div class="desktop-flex">
                        <div class="print-navigation-column"></div>

                        <div class="paywall-column">

                            <div class="paywall-counter__content">
                                <div class="desktop-flex">

                                    <div class="paywall-counter__image">
                                        <a :href="subscribeLink">
                                            <img :src="getImageUrl()">
                                        </a>
                                    </div>

                                    <div class="paywall-desktop paywall-counter__text">
                                        <span v-html="text"></span>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="paywall-navigation-column" style="text-align: right;">
                            <div class="paywall-toggle__link">
                                <span class="paywall-counter__login">
                                    <span v-if="userIsLoggedIn">
                                        Subscriber? <a :href="activateLink">Activate your access</a>.
                                    </span>
                                    <span v-else>
                                        Subscriber? Login <a href="#" v-on:click.prevent="openSigninForm">here</a>.
                                    </span>
                                </span> &nbsp; 
                                <span class="paywall-desktop">
                                    <a href="#" @click.prevent="showOptions"> <img :src="togglelabel"><span class="visually-hidden">close</span></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </transition-group>
</template>

<script>
    import { SITE_PATH } from 'shared/constants/Paths';
    import MODALS from 'shared/constants/Modals';
    import { mapState, mapMutations, mapActions  } from 'vuex';

    export default {
        props: {
            paywalled: {
                type: String,
                required: true,
                default: false,
            },
            remaining: {
                required: true,
                default: 0,
            },
            img: {
                type: String,
                required: false,
                default: false,
            },
        },

        data(){
            return {
                options: false,
                togglelabel: `${SITE_PATH}/static-assets/arrow_black_up.svg`
            }
        },

        computed: {
            ...mapState({
                issueResults: state => state.print.issueResults,
                userIsLoggedIn: state => state.user.userIsLoggedIn
            }),

            showCounter: function(){
                return this.paywalled;
            },

            subscribeLink: function(){
                return `${SITE_PATH}/subscribe?tid=pws1&utm_campaign=pw_counter&utm_medium=web&utm_source=print`;
            },

            activateLink: function(){
                return `${SITE_PATH}/profile/activate`;
            },

            message: function(){
                var pluralization = this.remaining > 1 ? 's' : '';
                return this.remaining > 0 ? 
                    `You have ${this.remaining} article${pluralization} remaining this month.`
                    : 'This is your last free article for the month.'
            },

            text: function(){
                return `<a href="${this.subscribeLink}"><strong>SUBSCRIBE NOW</strong> and get immediate access to this issue, our complete archive, and a year of Artforum delivered to your door&mdash;starting at only $50 a year.</a>`;
            }
        },

        methods: {
            ...mapMutations(['openModal']),
            ...mapActions('print', ['fetchCurrentIssue']),

            openSigninForm: function(){
                this.openModal(MODALS.LOGIN_FORM);
                this.resetMessages();
            },

            getImageUrl: function(){
                if (this.issueResults.length > 0 && this.issueResults[0].primary_image.path_large) {
                    return this.issueResults[0].primary_image.path_large;
                }
                else if (this.issueResults.length > 0 && this.issueResults[0].primary_image.path) {
                    return this.issueResults[0].primary_image.path;
                }
                return this.img;
            },

            showOptions: function(){
                if (!this.options) {
                    this.options = true;
                    this.togglelabel = `${SITE_PATH}/static-assets/arrow_black_down.svg`;
                }
                else {
                    this.options = false;
                    this.togglelabel = `${SITE_PATH}/static-assets/arrow_black_up.svg`;
                }
            }
        },
        beforeMount(){
            this.fetchCurrentIssue();
        },
    };
</script>

<style lang="scss">
    @import "scss/transitions/drawer-reverse";
    @import "scss/variables/breakpoints";
    @import "scss/variables/colors";

    .paywall-counter {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background: #BFF190;
        color: #000;
        z-index: 1;
    }
    .paywall-counter a {
        color: #000;
    }
    .paywall-counter__login a {
        border-bottom: solid 1px #000;
    }
    .paywall-mobile {
        display: none;
    }
    .paywall-counter__content {
        padding: 2.2rem 0;
    }
    .paywall-toggle__right {
        position: fixed;
        bottom: 1.5rem;
        right: 3rem;
        z-index:2;
    }
    .paywall-counter__content,
    .paywall-counter__message,
    .paywall-toggle__link{
        padding: 1.5rem 0;
    }
    .paywall-counter__link-mobile {
        padding: .8rem 0;
    }
    .paywall-counter__link-mobile img {
        vertical-align: baseline;
    }
    .paywall-counter__text,
    .paywall-counter__image {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
    .paywall-counter__image {
        width: 30%;
    }
    .paywall-counter__image img {
        width: 95%;
    }
    .paywall-counter__text {
        padding: 30px 0 0 30px;
        width: 60%;
        margin-right: 50px;
        font-size: 1.2rem;
        text-align:left !important;
    }
    .paywall-toggle__link img {
        width: 15px;
    }
    .paywall-counter__link-mobile img {
       width: 20px;
    }
    .paywall-toggle__link{
        margin-left: 1.5rem;
        font-size: 94%;
    }
    .paywall-counter__message {
        width: auto;
    }

    @media screen and (max-width: 1200px) {
        .paywall-counter__text {
             padding: 0 0 0 20px;
             margin-right: 0 !important;
         }
    }

    @media screen and (max-width: $breakpoint-medium) {
        .paywall-mobile {
            display: block;
            width: 100vw;
        }
        .paywall-desktop {
            display: none;
        }
        .paywall-counter__message,
        .paywall-counter__content,
        .paywall-counter__text {
            padding-top: 0 !important;
        }
        .paywall-counter__content {
           width: 80vw;
           margin: 0 auto;
        }

        .paywall-counter__image {
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .paywall-counter__image img {
            width: 70%;
        }
        .paywall-counter__text {
            font-size: 4.6vmin;
            padding: 0 0 1rem 0;
            margin:0;
            width: 100% !important;
            text-align: center !important;
        }
        .paywall-counter__login {
            padding-top: 10px !important;
            font-size: 3.4vmin;
        }
    }
</style>