<template>

    <input v-if="!formSubmitted" type="submit" :class=buttonclass :value=title @click="checkForm">

    <input v-else type="submit" :class=processingclass :value=title @click="pauseForm">

</template>

<script>
    /**
     * prevent duplicate submissions
     */
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: {
            title:
                { type: String, default: 'place your order' },
            buttonclass:
                { type: String, default: 'form__submit' }
        },

        computed: {
            ...mapState({
                formSubmitted: (state) => state.subscribe.formSubmitted,
            })
        },
        methods: {
            ...mapMutations('subscribe', ['markFormSubmitted']),

            checkForm: function(){
                this.markFormSubmitted(true);
                setTimeout(function () { this.markFormSubmitted(false) }.bind(this), 8000)
            },

            pauseForm: function(e){
                e.preventDefault();
                alert('Your request is processing, please be patient.');
            },
        },
        created(){
            this.processingclass = `${this.buttonclass} submit_processing`;
        },
    };
</script>

<style>

.submit_processing {
    opacity: .3;
}

</style>