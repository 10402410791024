<template>
    <li :class="getClasses(article.isSponsoredContent)">
        <article class="article-list__article">
            <header>
                <div v-if="article.isSponsoredContent" class="slug--sponsored-content">
                    <a href="/video/from_our_sponsors">
                        From Our Partners
                    </a>
                </div>
                <div v-else-if="!currentTag" class="slug--sponsored-content" v-html="formatCategoryLink(article.videoTags)"></div>

                <div class="article-list__primary-image">
                    <div class="image-container image-container--2x1" v-if="typeof article.primaryImage !== 'undefined'">
                        <a :href="article.url">
                            <img v-if="article.primaryImage.path_large" :srcset="srcset(article.primaryImage)" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path_large}`" :sizes="sizes" :alt="article.primaryImage.caption_formatted | stripTags">
                            <img v-else-if="typeof article.primaryImage !== 'undefined'" :srcset="srcset(article.primaryImage)" :src="`${IMAGE_BASE_PATH}${article.primaryImage.path}`" :alt="article.titleFormatted| stripTags">
                        </a>
                    </div>
                </div>
                <h1 class="article-list__h1">
                    <a :href="article.url" v-html="article.titleFormatted"></a>
                </h1>
                <div class="article-list__article-meta">
                    <span class="article-list__publish-date" v-if="!article.issueTitle">
                        {{ article.published | formatDate('MMMM D, YYYY') }}
                        <span v-if="article.subtitleFormatted" v-html="` • ${article.subtitleFormatted}`"></span>
                    </span>
                    <span v-show="isAudioOnly(article.hasVideo, article.hasPodcast)">
                      • <span class="active-highlight">Audio only</span>
                    </span>
                </div>
            </header>

        </article>
    </li>
</template>
<script>
    import article from './mixins/article';
    
    export default {
        name: 'list-article',
        mixins: [article],
        props: {
            currentTag: {
                required: false,
                default: false
            }
        },
        methods: {
            getClasses: function(isSponsored){
                if (isSponsored) {
                     return 'video-listing sponsored-content__video';
                }
                return 'video-listing';
            },
            isAudioOnly: function(hasVideo, hasPodcast){
                return (hasVideo == 0) && hasPodcast;
            },
            formatCategoryLink: function(videoTags){
                return `<a href="/video/${Object.keys(videoTags)[0]}">${Object.values(videoTags)[0]}</a>`;
            }
        }
    };
</script>