/**
 * @param {String} search - window.location.search
 * @param {String} key
 * @param {String|Number} val
 * 
 * @return {String}
 */
const addUrlParam = function(search, key, val){
  var newParam = key + '=' + val,
      params = '?' + newParam;

  if (search) {
    // search within string
    let regex = new RegExp('([?&])' + key + '[^&]*');
    let test = search.search(regex);

    // If nothing to replace, then add the new param to the end
    if (test == -1) {
        let regex2 = new RegExp('([?])');
        let test2 = search.search(regex2);
        let delimiter = test2 == -1 ? '?' : '&';
        params = search + delimiter + newParam;
    }
    else {
        params = search.replace(regex, '$1' + newParam);
    }
  }

  return params;
};

export default addUrlParam;